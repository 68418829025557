import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import PropTypes from 'prop-types'

function InputMonthAndYear({ name, label, monthParams, yearParams }) {
  const [months, setMonths] = useState(
    window.utils.getMonths('past', false, 'asc')
  )

  const AnoCorrente = new Date().getFullYear()
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    if (values[name] !== '' && values[name] !== null) {
      if (name === 'inicio_operacao') {
        const newDate = values[name].split('/')
        setFieldValue(`${name}_dateMonth`, `${newDate[0]}`)
        setFieldValue(`${name}_dateYear`, `${newDate[1]}`)
      } else {
        const newDate = values[name].split('-')
        setFieldValue(`${name}_dateMonth`, `${newDate[1]}`)
        setFieldValue(`${name}_dateYear`, `${newDate[0]}`)
      }
    } else {
      setFieldValue(`${name}_dateMonth`, '01')
      setFieldValue(`${name}_dateYear`, `${AnoCorrente}`)
    }
  }, [])

  useLayoutEffect(() => {
    if (
      typeof values[`${name}_dateYear`] !== 'undefined' &&
      typeof values[`${name}_dateMonth`] !== 'undefined'
    ) {
      setFieldValue(
        name,
        `${values[`${name}_dateYear`]}-${values[`${name}_dateMonth`]}-01`
      )
    }
  }, [values[`${name}_dateYear`], values[`${name}_dateMonth`]])

  // If months in the past show only current and past months when selected year is the current year
  useEffect(() => {
    if (monthParams.orientation === 'past') {
      const currentYear = new Date().getFullYear()
      const currentMonthParams = { ...monthParams }
      if (parseInt(values[`${name}_dateYear`]) < parseInt(currentYear)) {
        currentMonthParams.orientation = 'all'
      } else {
        currentMonthParams.orientation = 'past'
      }
      setMonths(
        window.utils.getMonths(
          currentMonthParams.orientation,
          currentMonthParams.includeCurrentMonth,
          currentMonthParams.order
        )
      )
    }
  }, [values[`${name}_dateYear`]])

  return (
    <label htmlFor={name} style={{ display: 'flex', margin: '10px 0' }}>
      <span style={{ width: '50%' }}>{label}</span>
      <div>
        <Field
          as="select"
          name={`${name}_dateMonth`}
          style={{
            marginLeft: '5px',
            borderRadius: '3px',
            border: '0',
            borderBottom: '1px solid rgb(118, 118, 118)',
            height: '1.7rem',
            backgroundColor: 'var(--white)',
            color: '#333333'
          }}
        >
          {months.map((mes, index) => (
            <option
              key={index}
              value={index + 1 < 10 ? '0' + (index + 1) : index + 1}
            >
              {mes}
            </option>
          ))}
        </Field>
        <Field
          as="select"
          name={`${name}_dateYear`}
          style={{
            marginLeft: '10px',
            borderRadius: '3px',
            border: '0',
            borderBottom: '1px solid rgb(118, 118, 118)',
            height: '1.7rem',
            backgroundColor: 'var(--white)',
            color: '#333333'
          }}
        >
          {window.utils
            .getYears(
              yearParams.orientation,
              yearParams.limit,
              yearParams.order
            )
            .map((ano, index) => (
              <option key={index} value={ano}>
                {ano}
              </option>
            ))}
        </Field>
      </div>
    </label>
  )
}

InputMonthAndYear.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  monthParams: PropTypes.object,
  yearParams: PropTypes.object
}
export default InputMonthAndYear
