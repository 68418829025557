import styled from 'styled-components'
import { Button } from 'components/Button'

export const ContainerFormSanitizacao = styled.section`
  position: fixed;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  min-width: 500px;
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 20%);
  header {
    font-size: 1.3em;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--green);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    h3 {
      font-size: 1em;
      color: var(--white);
    }
    a {
      font-weight: bold;
      color: var(--white);
      border: 2px solid var(--white);
      padding: 2px 6px;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px 30px;
    > div {
      gap: 1px;
      margin: 0;
    }
    input,
    select {
      height: 30px;
    }
    button {
      margin: 0;
      grid-row: 5;
      width: auto;
      justify-self: end;
      padding: 10px 20px;
    }
    p {
      margin: 0;
      font-size: 0.9em;
    }
  }
`

export const ButtonAddSanitization = styled(Button)`
  :hover {
    filter: opacity(0.9);
    color: var(--white);
  }
  :hover > svg path {
    fill: var(--white);
  }
`
