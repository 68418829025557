/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { LoginContext } from 'services/Auth/AuthContext'
import { Form } from 'components/Form'
import { Button } from 'components/Button'
import { REGEX } from 'helpers/formValidationRules'
import useFetch from 'hooks/useFetch'
import { handleLogin } from 'pages/Login/loginLogic'
import { ForgotFormError, Title } from '../../style'
import { Input } from './components/Input'

export function RedefinePasswordForm({ hash }) {
  hash = hash.replace('#', '')
  const [haveError, setHaveError] = useState(false)
  const { authStates, dispatchAuthStates } = useContext(LoginContext)

  const initialValues = {
    senha1: '',
    senha2: ''
  }

  const { fetchData } = useFetch({
    domain: 'usuarios',
    endpoint: 'changeUserPassword',
    sync: false
  })

  const handleSubmit = async (values) => {
    const params = {
      token: hash,
      senha: values.senha2
    }

    const response = await fetchData([params])
    if (response?.status === 200) {
      handleLogin(
        response.data.email,
        params.senha,
        dispatchAuthStates,
        setHaveError,
        true
      )
    } else {
      if (response?.data.message) {
        window.eventBus.dispatch('event-modal-success', {
          title: response.config.endpointInfo.description,
          message: response.data.message
        })
      }
    }
  }

  const schemaValidate = Yup.object().shape({
    senha1: Yup.string()
      .matches(
        REGEX.senhaForte,
        'Mínimo 6 caracteres e ao menos uma letra maiúscula, uma letra minúscula e um número ou caractere especial'
      )
      .required('Senha requerida'),
    senha2: Yup.string()
      .matches(
        REGEX.senhaForte,
        'Mínimo 6 caracteres e ao menos uma letra maiúscula, uma letra minúscula e um número ou caractere especial'
      )
      .when('senha1', {
        is: (val) => val && val.length > 0,
        then: Yup.string().oneOf(
          [Yup.ref('senha1')],
          'As senhas devem coincidir'
        )
      })
      .required('Senha requerida')
  })

  return (
    <>
      <Title>Nova Senha</Title>
      <Form
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        schemaValidate={schemaValidate}
      >
        <Input
          label="Senha"
          type="password"
          name="senha1"
          placeholder="Digite sua senha"
        />
        <Input
          label="Confirme sua Senha"
          type="password"
          name="senha2"
          placeholder="Digite sua senha"
        />
        <Button type="submit" value="Redefinir" />
      </Form>

      {haveError ? (
        <ForgotFormError>Credenciais Invalidas</ForgotFormError>
      ) : (
        ''
      )}
    </>
  )
}

RedefinePasswordForm.propTypes = {
  hash: PropTypes.string
}
