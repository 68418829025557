import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

// adicionar props.outerState com default none
function GroupOfRadioboxes({ options }) {
  return (
    <>
      {options.map((singleOption, i) => {
        return (
          <label
            htmlFor="tipo"
            key={i}
            style={{ display: 'flex', margin: '2px 0 2px 10px' }}
          >
            <Field
              type="radio"
              label={singleOption}
              name="tipo_pessoa"
              value={singleOption.toLowerCase() === 'pessoa fisica' ? 'F' : 'J'}
              style={{ width: '5%' }}
            />
            <span style={{ width: 'auto' }}>{singleOption}</span>
          </label>
        )
      })}
    </>
  )
}

GroupOfRadioboxes.propTypes = {
  options: PropTypes.array
}
export default GroupOfRadioboxes
