import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'sanitizationRule'
const url = '/sanitization-rule'

const endpointsDescriptions = {
  getAllRegrasSanitizacao: {
    action: 'getAll'
  },
  createRegraSanitizacao: {
    action: 'add'
  }
}

const sanitizationRule = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllRegrasSanitizacao = () => {
  const endpointDescription = sanitizationRule.endpointsConfigs.getAllRegrasSanitizacaoConfig
  return axios.get(
    `${baseUrl}/sanitization-rule`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createRegraSanitizacao = (body) => {
  const endpointDescription = sanitizationRule.endpointsConfigs.createRegraSanitizacaoConfig
  return axios.post(
    `${baseUrl}/sanitization-rule`,
    body,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
