/* eslint-disable no-unused-vars */
import axios from 'axios'
import history from '../history'
import domains from './domains'
import { termoDeUsoNotificationEventHelpers } from '../Events/logic/helpers/termoDeUsoNotificationEventHelpers'

const loader = {
  get: () => sessionStorage.getItem('loader'),
  set: (status) => sessionStorage.setItem('loader', status)
}

const USER_TOKEN = {
  get: () => ({
    token: sessionStorage.getItem('token') || localStorage.getItem('token'),
    refreshToken:
      sessionStorage.getItem('refreshToken') ||
      localStorage.getItem('refreshToken')
  }),
  set: ({ token, refreshToken }) => {
    localStorage.setItem('token', token)
    localStorage.setItem('refreshToken', refreshToken)
    sessionStorage.setItem('token', token)
    sessionStorage.setItem('refreshToken', refreshToken)
  },
  remove: () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('refreshToken')
  }
}

const eventHandlers = {
  logoutUser: () => {
    window.eventBus.dispatch('logoutUser', { message: true })
  },
  displayError: (description, errors) => {
    window.eventBus.dispatch('event-modal-error', {
      title: `Falha na ação: ${description} `,
      errors: errors
    })
  },
  treatFeedback: (response) => {
    if (response.config?.endpointInfo?.options.feedbackOnAction) {
      window.eventBus.dispatch('event-modal-success', {
        title: response.config.endpointInfo.description,
        message: 'Operação executada com sucesso'
      })
    }
  },
  treatConfirmation: async (config, controller) => {
    return new Promise((resolve, reject) => {
      if (
        config?.endpointInfo?.options.confirmOnDelete &&
        config?.endpointInfo?.action === 'delete'
      ) {
        function confirmationCallback(data) {
          if (!data.response) {
            controller.abort('Request Aborted By User')
          } else {
            resolve(true)
          }
        }
        window.eventBus.dispatch('event-modal-confirmation', {
          type: 'delete',
          customMessage:
            'Tem certeza que deseja deletar este item? Essa ação não pode ser desfeita.',
          callBack: confirmationCallback
        })
      } else {
        resolve(true)
      }
    })
  },
  toggleLoader: async (config, status) => {
    if (config?.endpointInfo?.endpointName === 'refresh') {
      loader.set(false)
      window.eventBus.dispatch('event-loader-close')
    }
    return new Promise((resolve, reject) => {
      if (config?.endpointInfo?.options.activateLoader) {
        let loaderStatus = loader.get()

        if (!loaderStatus) {
          loader.set(false)
          loaderStatus = false
        }

        if (typeof loaderStatus === 'string') {
          loaderStatus = JSON.parse(loaderStatus)
        }

        if (status === 'open' && !loaderStatus) {
          window.eventBus.dispatch('event-loader-open')
          loader.set(true)
          resolve(true)
        }

        if (status === 'close' && loaderStatus) {
          setTimeout(function () {
            window.eventBus.dispatch('event-loader-close')
            loader.set(false)
            resolve(true)
          }, 300)
        }
      } else {
        resolve(true)
      }
    })
  },
  handleUserTerms: (termoLastVersion, termoDeUsoCallback) => {
    termoDeUsoNotificationEventHelpers.mountTermoDeUsoNotification(
      termoLastVersion,
      domains.acceptTermoDeUso,
      termoDeUsoCallback
    )
  }
}

const logoutUser = () => {
  window.eventBus.dispatch('logoutUser', { message: true })
}

const DEFAULT_HEADER = {
  get: () => ({
    Authorization: `Bearer ${USER_TOKEN.get().token}`
  })
}

// request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  async (config) => {
    const controller = new AbortController()
    config.signal = controller.signal
    await eventHandlers.treatConfirmation(config, controller)

    const accessToken = USER_TOKEN.get().token
    if (accessToken) {
      config.headers = DEFAULT_HEADER.get()
    }

    eventHandlers.toggleLoader(config, 'open')
    return Promise.resolve(config)
  },
  (error) => {
    Promise.reject(error)
  }
)

async function refresh() {
  return domains
    .refresh({ refreshToken: USER_TOKEN.get().refreshToken })
    .then((response) => {
      if (response?.status === 200) {
        USER_TOKEN.set({
          token: response.data.accessToken,
          refreshToken: response.data.refreshToken
        })
        return response.data.accessToken
      } else {
        return new Error('refresh Failed')
      }
    })
    .catch((error) => {
      logoutUser()
      throw new Error(error)
    })
}

let isRefreshing = false
const refreshSubscribers = []

axios.interceptors.response.use(
  async (response) => {
    await eventHandlers
      .toggleLoader(response.config, 'close')
      .then(() => eventHandlers.treatFeedback(response))
    return response
  },
  async (error) => {
    const { config, response } = error
    const originalRequest = config

    let isLoginError = false
    if (
      config?.endpointInfo?.endpointName === 'login' &&
      response?.status === 401
    ) {
      isLoginError = true
    }

    if (response.status === 401 && !isLoginError) {
      let isTermoDeUso = false
      if (response.data?.message) {
        if (
          response.data.message ===
          'Acesso não autorizado - ultima versão dos termos de uso não foi aceita pelo usuário'
        ) {
          isTermoDeUso = true
          const termoLastVersion = await getLastTermoDeUso()
          return await new Promise((resolve, reject) => {
            function termoDeUsoCallback(response) {
              if (!response) {
                logoutUser()
                history.push('/')
                return Promise.reject(new Error('Termo de uso não aceito'))
              } else {
                resolve(axios(originalRequest))
              }
            }
            eventHandlers.handleUserTerms(termoLastVersion, termoDeUsoCallback)
          })
        }

        if (
          originalRequest.endpointInfo.endpointName === 'refresh' &&
          response.data.message === 'refresh token inválido!'
        ) {
          logoutUser()
          history.push('/')
          return Promise.reject(new Error('Refresh Token Expirou.'))
        }
      }

      if (!isRefreshing && !isTermoDeUso) {
        isRefreshing = true
        refresh()
          .then((newToken) => {
            isRefreshing = false
            onRefreshed(newToken)
          })
          .catch((error) => {
            console.error(error)
          })
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          // replace the expired token and retry
          if (typeof originalRequest?.data !== 'undefined') {
            originalRequest.data = JSON.parse(originalRequest.data)
          }
          originalRequest.headers.Authorization = 'Bearer ' + token
          resolve(axios(originalRequest))
        })
      })
      return retryOrigReq
    } else {
      eventHandlers.toggleLoader(error.config, 'close').then(() => {
        if (error?.config?.endpointInfo?.options?.displayOnErrors) {
          let errors = error.response.data.errors

          if (!errors) {
            if (error.response?.data?.message) {
              errors = [{ message: error.response.data.message }]
            }
          }

          if (error.response.status === 413) {
            errors = [{ message: 'Arquivo muito grande!' }]
          }

          const description = error?.config?.endpointInfo?.description
          eventHandlers.displayError(description, errors)
        }
      })

      return Promise.reject(error)
    }
  }
)

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb)
}

function onRefreshed(token) {
  refreshSubscribers[refreshSubscribers.length - 1]()
  // refreshSubscribers.map((cb) => cb(token))
}

async function getLastTermoDeUso() {
  return domains
    .getTermoDeUsoLastVersion()
    .then((response) => {
      if (response?.status === 200) {
        return response.data
      } else {
        return new Error('Falha ao buscar ultima versão de Termo de Uso')
      }
    })
    .catch((error) => {
      throw new Error(error)
    })
}

const api = {
  ...domains
}

export default api
