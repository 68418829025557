import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

function InputSelectorStatusSistemaGestao({ name }) {
  return (
    <Field
      as="select"
      name={name}
      style={{
        marginLeft: '10px',
        width: 'calc(100% - 20px)'
      }}
    >
      <option value="" disabled hidden>
        Status
      </option>

      <option value={'suportado'}>Suportado</option>
      <option value={'nao_suportado'}>Não Suportado</option>
    </Field>
  )
}

InputSelectorStatusSistemaGestao.propTypes = {
  option: PropTypes.string,
  name: PropTypes.string
}
export default InputSelectorStatusSistemaGestao
