import React from 'react'
import Div from 'components/layout/div'
import Spinner from './Spinner.index'

export default function SpinnerFullpage() {
  return (
    <Div
      flex
      alignItems="center"
      justifyContent="center"
      style={{
        height: '100vh',
        width: '100vw'
      }}
    >
      <Spinner />
    </Div>
  )
}
