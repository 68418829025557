/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import api from '../../services/api'
import history from '../../services/history'

async function handleLogin(
  email,
  password,
  dispatchAuthStates,
  setHaveError,
  lembrarDeMim
) {
  try {
    const { data } = await api.login({
      email: email,
      senha: password
    })

    sessionStorage.setItem('token', data.accessToken)
    sessionStorage.setItem('refreshToken', data.refreshToken)
    sessionStorage.setItem('loader', false)

    if (lembrarDeMim) {
      localStorage.setItem('token', data.accessToken)
      localStorage.setItem('refreshToken', data.refreshToken)
    }
    dispatchAuthStates({ type: 'setAuthenticated', payload: true })
  } catch (error) {
    console.error(error)
  }
}

async function handleRefresh(dispatchAuthStates) {
  try {
    const refreshToken = sessionStorage.getItem('refreshToken')
    const lastLocation = sessionStorage.getItem('lastLocation')
    const { data } = await api.refresh({ refreshToken: refreshToken })
    sessionStorage.setItem('token', data.accessToken)
    sessionStorage.setItem('refreshToken', data.refreshToken)
    dispatchAuthStates({ type: 'setAuthenticated', payload: true })
    dispatchAuthStates({ type: 'setLoading', payload: true })
    sessionStorage.removeItem('lastLocation')
    history.push(lastLocation)
  } catch (error) {
    console.error(error)
  }
}

handleLogin.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  setAuthenticated: PropTypes.func.isRequired
}

export { handleLogin, handleRefresh }
