import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from '@plick-solucoes-contabeis/ui-components'

const TextoCellSpan = styled.p`
  word-break: break-word;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

function TextoCell({ value }) {
  return (
    <Tooltip delay={800} title={String(value)} position="top">
      <TextoCellSpan>{value}</TextoCellSpan>
    </Tooltip>
  )
}

TextoCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
export default TextoCell
