import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'outputFileRowFormats'
const url = '/output-file-row-formats'

const endpointsDescriptions = {
  getOutputRows: {
    action: 'getAll'
  },
  getOutputRowById: {
    action: 'getOne'
  },
  createOutputRow: {
    action: 'add'
  },
  editOutputRow: {
    action: 'update'
  },
  deleteOutputRow: {
    action: 'delete'
  }
}

const outputFileRowFormats = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getOutputRows = (layout) => {
  const endpointDescription = outputFileRowFormats.endpointsConfigs.getOutputRowsConfig
  return axios.get(
    `${baseUrl}/output-file-row-formats/layout/${layout}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getOutputRowById = (id) => {
  const endpointDescription = outputFileRowFormats.endpointsConfigs.getOutputRowByIdConfig
  return axios.get(
    `${baseUrl}/output-file-row-formats/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createOutputRow = (body) => {
  const endpointDescription = outputFileRowFormats.endpointsConfigs.createOutputRowConfig
  return axios.post(
    `${baseUrl}/output-file-row-formats`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.editOutputRow = (id, body) => {
  const endpointDescription = outputFileRowFormats.endpointsConfigs.editOutputRowConfig
  return axios.put(
    `${baseUrl}/output-file-row-formats/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteOutputRow = (id) => {
  const endpointDescription = outputFileRowFormats.endpointsConfigs.deleteOutputRowConfig
  return axios.delete(
    `${baseUrl}/output-file-row-formats/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
