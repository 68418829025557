import styled from 'styled-components'
import { FormContainer } from '../../subPages/style'

export const LayoutFormContainer = styled(FormContainer)`
   margin-top:49px;
   form {
     height:min-content;
     > div:nth-child(n+1) {
        grid-column: auto;
     }
   }

   button {
       grid-column: 2;
       width:105px;
       place-self: end;
       margin-top:20px;
   }
`

export const DoubleInputContainer = styled.div`
    display: flex; 
    justify-content: space-between;
    & div {
        gap: 1px;
        width: 100%;
    }
`
