import styled, { css } from 'styled-components'

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  overflow-y: auto;
  ${({ isVisible }) =>
    !isVisible &&
    css`
      pointer-events: none;
    `}
  visibility: ${({ isVisible }) => {
    return isVisible ? 'visible' : 'hidden'
  }};
`

export const ModalContents = styled.div`
  position: relative;
  background: white;
  max-width: 800px;
  min-width: 400px;
  height: min-content;
  max-height: 650px;
  width: auto;
  transform: translateY(40px);
  transition: transform 350ms cubic-bezier(0.65, 0.05, 0.36, 1),
    opacity 350ms cubic-bezier(0.65, 0.05, 0.36, 1);
  border-radius: 8px;
  opacity: 0;
  will-change: transform, opacity;
  -webkit-box-shadow: 0px 0px 0px 100vw rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 0px 0px 100vw rgba(0, 0, 0, 0.45);
  box-shadow: 0px 0px 0px 100vw rgba(0, 0, 0, 0.45);

  &.enter {
    transform: translateY(0px);
    opacity: 1;
  }

  &.exit {
    transform: translateY(40px);
    opacity: 0;
  }
`

export const HeaderWrapper = styled.header`
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  padding-bottom: 10px;
  text-align: left;
  z-index: 2;
  padding: 20px;
  background: var(--white);
  color: var(--text-color-dark);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  h3 {
    font-size: 20px;
  }
  & svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    width: 18px;
    height: 18px;
    z-index: 11;
    cursor: pointer;
  }
`

export const BodyWrapper = styled.article`
  overflow-y: auto;
  max-height: 550px;
  padding: 20px;

  ::-webkit-scrollbar {
    width: 0.5em !important;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--text-color-dark);
    border: none;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: black;
  }

  > p {
    font-size: 16px;
    line-height: 1.6;
    margin: 0 0 10px;
  }
`
