import React, { useState, useEffect, useContext } from 'react'
import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'
import * as logic from './layoutSaidaLogic'
import { LayoutSaidaTable } from './subComponents/Table'
import { LayoutForm } from './subComponents/LayoutForm'
import history from 'services/history'

import {
  ButtonAdd,
  HeaderFilter,
  SelectWrapper,
  MainTopBarHeaderButtons
} from './style'
import useNonInitialEffect from 'hooks/useNonInitialEffect'

export function LayoutSaida() {
  const { dispatchRestrictedLayout } = useContext(RestrictedLayoutContext)

  const [outputFileLayouts, setOutputFileLayouts] = useState([])
  const [showFileLayoutForm, setShowFileLayoutForm] = useState(false)

  const [layoutInitialValues, setLayoutInitialValues] = useState({})

  // No layout de saida é o sistema contabil
  const [sistemasContabil, setSistemasContabil] = useState([])
  const [sistemaContabilFilter, setSistemaContabilFilter] = useState(0)

  useEffect(async () => {
    await logic.getAllSistemaContabil(setSistemasContabil)
  }, [])

  useNonInitialEffect(async () => {
    dispatchRestrictedLayout({
      type: 'setHeaderData',
      payload: 'Layouts de saída'
    })
    await logic.getAllOutputLayouts(setOutputFileLayouts)
    dispatchRestrictedLayout({
      type: 'setMainTopBarContent',
      payload: (
        <MainTopBarHeaderButtons>
          <ButtonAdd
            colorText="var(--white)"
            buttonFunction="ButtonTextIcon"
            buttonIcon="PlusIcon"
            value="ADICIONAR"
            onClick={handleShowAddFileLayoutForm}
          />
          <HeaderFilter>
            <label>Sistema Contabil:</label>
            <SelectWrapper>
              <select
                onChange={(event) =>
                  setSistemaContabilFilter(parseInt(event.target.value))
                }
              >
                <option value="0">Sem Filtro</option>
                {sistemasContabil.map((sistemaContabil, index) => {
                  return (
                    <option key={index} value={sistemaContabil.id}>
                      {sistemaContabil.nome}
                    </option>
                  )
                })}
              </select>
            </SelectWrapper>
          </HeaderFilter>
        </MainTopBarHeaderButtons>
      )
    })
  }, [sistemasContabil])

  const handleShowAddFileLayoutForm = () => {
    setLayoutInitialValues({
      nome: '',
      nome_arquivo: '',
      sistema_contabil_id: '',
      file_type: 'txt',
      start_row: 1,
      delimiter_char: '',
      default: false,
      update: false
    })

    setShowFileLayoutForm(true)
    dispatchRestrictedLayout({ type: 'setToggleContextMenu', payload: false })
  }

  const handleShowUpdateFileLayout = (id) => {
    const data = outputFileLayouts.filter(
      (outputLayout) => outputLayout.id === parseInt(id)
    )[0]

    // eslint-disable-next-line camelcase
    const { updated_at, created_at, ...rest } = data

    // avoids to initialize null parameters
    for (const key in rest) {
      if (rest[key] === null) {
        rest[key] = ''
      }
    }
    setLayoutInitialValues({
      ...rest,
      update: true
    })
    setShowFileLayoutForm(true)
    dispatchRestrictedLayout({ type: 'setToggleContextMenu', payload: false })
  }

  const handleDelete = async (id) => {
    if (!window.utils.isFalsy(layoutInitialValues.id)) {
      if (layoutInitialValues.id === parseInt(id)) {
        dispatchRestrictedLayout({
          type: 'setToggleContextMenu',
          payload: false
        })
        dispatchRestrictedLayout({
          type: 'setContextMenuContent',
          payload: <></>
        })
      }
    }
    await logic.deleteOutputLayout(id, setOutputFileLayouts)
  }

  const handleDuplicate = async (id) => {
    await logic.duplicateOutputLayout(id, setOutputFileLayouts)
  }

  const handleEnterDetailsCadastroLayoutSaida = (id) => {
    if (outputFileLayouts.length > 0) {
      const thisLayout = outputFileLayouts.find(
        (layout) => layout.id === parseInt(id)
      )
      history.push('output-file-layout-details', {
        layoutId: thisLayout.id,
        nome: thisLayout.nome
      })
    }
  }

  useEffect(() => {
    if (showFileLayoutForm) {
      dispatchRestrictedLayout({
        type: 'setContextMenuContent',
        payload: (
          <>
            <LayoutForm
              layoutInitialValues={layoutInitialValues}
              setOutputFileLayouts={setOutputFileLayouts}
              sistemasContabil={sistemasContabil}
            />
          </>
        )
      })
    }
  }, [showFileLayoutForm, layoutInitialValues])
  return (
    <>
      {outputFileLayouts.length > 0 ? (
        <LayoutSaidaTable
          items={outputFileLayouts.filter((outputFileLayout) => {
            if (sistemaContabilFilter === 0) {
              return outputFileLayout
            }

            if (
              parseInt(outputFileLayout.sistema_contabil_id) ===
              sistemaContabilFilter
            ) {
              return outputFileLayout
            }
            return ''
          })}
          fields={[
            { fieldName: 'nome', as: 'NOME' },
            { fieldName: 'file_type', as: 'TIPO ARQUIVO' },
            {
              fieldName: 'sistema_contabil_id',
              as: 'SISTEMA CONTÁBIL',
              fromData: sistemasContabil,
              refName: 'nome'
            }
          ]}
          actionButtons={[
            {
              buttonFunction: 'ButtonTable',
              buttonIcon: 'IconeEditar',
              onClick: (event) =>
                handleShowUpdateFileLayout(event.target.dataset.id),
              tooltipText: 'Editar'
            },
            {
              buttonFunction: 'ButtonTable',
              buttonIcon: 'IconeTable',
              onClick: (event) =>
                handleEnterDetailsCadastroLayoutSaida(event.target.dataset.id),
              tooltipText: 'Configurar'
            },
            {
              buttonFunction: 'ButtonTable',
              buttonIcon: 'IconeCopiar',
              onClick: (event) => handleDuplicate(event.target.dataset.id),
              tooltipText: 'Copiar'
            },
            {
              buttonFunction: 'ButtonTable',
              buttonIcon: 'IconeExcluir',
              onClick: (event) => handleDelete(event.target.dataset.id),
              tooltipText: 'Excluir'
            }
          ]}
        />
      ) : (
        ''
      )}
    </>
  )
}
