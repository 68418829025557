import React, { useContext } from 'react'
import useNonInitialEffect from 'hooks/useNonInitialEffect'
import { EventModalContext } from 'context/EventModalContext/EventModalContext'

export function useFormEvent(data) {
  const { dispatchEventModal } = useContext(EventModalContext)

  const resetModal = () => {
    dispatchEventModal({ type: 'setModalContentTop', payload: <></> })
    dispatchEventModal({ type: 'setModalContentMiddle', payload: <></> })
    dispatchEventModal({ type: 'setModalContentBottom', payload: <></> })
  }

  useNonInitialEffect(() => {
    if (data && typeof data !== 'undefined') {
      const { title } = data

      resetModal()

      dispatchEventModal({ type: 'setModalContentTop', payload: <></> })
      dispatchEventModal({
        type: 'setModalContentMiddle',
        payload: data.form
      })
      window.eventBus.dispatch('openModal', { title: title })
    }
  }, [data])
}
