import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

function GroupOfCheckboxes({ options, name, groupLabel }) {
  return (
    <div
      style={{
        display: 'flex'
      }}
    >
      <p
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#333333'
        }}
      >
        {groupLabel}
      </p>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridTemplateRows: '1fr 1fr'
        }}
      >
        {options.map((singleOption, i) => {
          return (
            <label
              htmlFor="tipo"
              key={i}
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '2px 0 2px 10px'
              }}
            >
              <Field
                type="checkbox"
                label={singleOption}
                name={name}
                value={singleOption.toLowerCase()}
              />
              <span
                style={{
                  width: 'auto',
                  marginLeft: '2px',
                  color: '#333333'
                }}
              >
                {singleOption}
              </span>
            </label>
          )
        })}
      </div>
    </div>
  )
}

GroupOfCheckboxes.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  groupLabel: PropTypes.string
}
export default GroupOfCheckboxes
