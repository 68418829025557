// Initialize Object that will contain all functions from the domains modules in this folder
const cellComponents = {}

const excludedFiles = ['./index.js']

// Import all functions from the modules defaults into an object
function importAll(cellComponentsContext) {
  cellComponentsContext.keys().forEach((key) => {
    if (!excludedFiles.includes(key)) {
      const myKey = key.split('./')[1].split('.js')[0]
      cellComponents[myKey] = cellComponentsContext(key).default
    }
  })
}

// Create a new webpack context for this folder
const cellComponentsContext = require.context('./', false, /.*\.js/)

importAll(cellComponentsContext)

export default cellComponents
