import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'contatos'
const url = '/contatos'

const endpointsDescriptions = {
  paginateContatos: {
    action: 'getPaginated'
  },
  getAllContatos: {
    action: 'getAll'
  },
  addContatos: {
    action: 'add'
  },
  editContatos: {
    action: 'update'
  },
  deleteContatos: {
    action: 'delete'
  }
}

const contatos = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.paginateContatos = (page, perPage) => {
  const endpointDescription = contatos.endpointsConfigs.paginateContatosConfig
  return axios.get(`${baseUrl}/contatos/paginate/${page}/${perPage}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getAllContatos = () => {
  const endpointDescription = contatos.endpointsConfigs.getAllContatosConfig
  return axios.get(`${baseUrl}/contatos`, { endpointInfo: endpointDescription })
}

endpoints.addContatos = (body) => {
  const endpointDescription = contatos.endpointsConfigs.addContatosConfig

  if (body?.escritorio_id === '') {
    delete body.escritorio_id
  }
  if (body?.empresa_id === '') {
    delete body.empresa_id
  }

  if (body?.empresa_id) {
    if (body?.escritorio_id) {
      delete body.escritorio_id
    }
  }

  return axios.post(`${baseUrl}/contatos`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.editContatos = (id, body) => {
  const endpointDescription = contatos.endpointsConfigs.editContatosConfig
  if (!body.tipo) {
    body.tipo = ['']
  }

  if (body?.escritorio_id === '') {
    delete body.escritorio_id
  }
  if (body?.empresa_id === '') {
    delete body.empresa_id
  }

  if (body?.empresa_id) {
    if (body?.escritorio_id) {
      delete body.escritorio_id
    }
  }

  return axios.put(`${baseUrl}/contatos/${id}`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.deleteContatos = (id) => {
  const endpointDescription = contatos.endpointsConfigs.deleteContatosConfig
  return axios.delete(`${baseUrl}/contatos/${id}`, {
    endpointInfo: endpointDescription
  })
}

export default endpoints
