import { useContext, useState, useEffect } from 'react'
import permissionHelpers from 'helpers/permissionHelper'
import { LoginContext } from 'services/Auth/AuthContext'
import { TableContext } from 'context/TableContext/TableContext'
import useNonInitialEffect from 'hooks/useNonInitialEffect'

export default function useHeaders(
  featureName,
  headersConfig,
  dependencies = []
) {
  const { authStates } = useContext(LoginContext)
  const { dispatchTableConfigs } = useContext(TableContext)
  const [permissionLevel, setPermissionLevel] = useState('')

  let headers = []
  if (authStates.loggedUserData.role?.nome.search('escritorio') !== -1) {
    headers = headersConfig.escritorio
  } else {
    headers = headersConfig.plick
  }

  useEffect(() => {
    if (Object.keys(authStates.loggedUserData).length > 0) {
      setPermissionLevel(
        permissionHelpers.getPermissionLevel(authStates, featureName)
      )
    }
  }, [authStates.loggedUserData])

  useNonInitialEffect(() => {
    dispatchTableConfigs({ type: 'setHeaders', payload: headers })
  }, [permissionLevel, ...dependencies])
}
