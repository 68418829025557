import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import LayoutDefault from '../pages/layouts/default'
import { LoginContext } from '../services/Auth/AuthContext'
import { Svg } from 'components/Icons'
import LayoutRestricted from '../pages/layouts/restricted'
import history from 'services/history'

/*
  Improvement Futuro:
    Há atualmente aqui uma série de estados
    que influenciam como se dará renderização do Component recebido.

    Ao invés desse tratamendo se dar por ternários dentro do return
    Caberá muito melhor o uso do hook 'useReducer'
*/
function CustomRouter({
  component: Component,
  isPrivate,
  layoutType,
  ...rest
}) {
  const { authStates } = useContext(LoginContext)
  // authStates.authenticated = false
  if (!authStates.authenticated && isPrivate) {
    return <Redirect to="/" />
  }

  if (authStates.authenticated && !isPrivate) {
    return <Redirect to="/dashboard" />
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          Object.keys(authStates.loggedUserData).length > 0 &&
          authStates.authenticated
        ) {
          return (
            <LayoutRestricted layoutType={layoutType}>
              <Component {...{ ...props, authStates, layoutType }} />
              {!history.location.pathname.includes('demandas') && <HelpIcon />}
            </LayoutRestricted>
          )
        }
        if (!authStates.authenticated) {
          return (
            <LayoutDefault>
              <Component {...props} />
            </LayoutDefault>
          )
        }
      }}
    />
  )
}

function HelpIcon() {
  return (
    <button
      onClick={() => {
        history.push('/demandas', {
          trigger: 'open-support-modal'
        })
      }}
      style={{
        position: 'absolute',
        right: 14,
        bottom: 14,
        zIndex: 10,
        cursor: 'pointer',
        background: 'rgba(5, 191, 111, 0.2)',
        border: '1.5px solid #05BF6F',
        padding: '7px',
        display: 'flex',
        borderRadius: '100px',
        alignItems: 'center',
        boxShadow:
          '0px 10px 10px 0px rgba(0,0,0,0.1),6px 4px 43px -3px rgba(0,0,0,0.1)',
        justifyContent: 'center',
        lineHeight: 0
      }}
    >
      <Svg
        name="help"
        width="40"
        height="40"
        viewBox="0 0 91.999 92"
        xmlns="http://www.w3.org/2000/svg"
        // pathStyle={{
        //   fill: 'var(--green)'
        // }}
      />
    </button>
  )
}

CustomRouter.defaultProps = {
  isPrivate: false
}

CustomRouter.propTypes = {
  component: PropTypes.any,
  isPrivate: PropTypes.bool,
  layoutType: PropTypes.string
}

export default CustomRouter
