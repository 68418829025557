/* eslint-disable camelcase */
import React, { createContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import * as Reducer from './reducer'
import api from 'services/api'
import { getUuid } from '../../helpers/jwtHelper'
import history from '../history'

const LoginContext = createContext({})
function AuthProvider({ children }) {
  const [authStates, dispatchAuthStates] = useReducer(
    Reducer.authReducer,
    Reducer.authStates
  )

  useEffect(async () => {
    window.eventBus.on('logoutUser', async (data) => {
      if (data?.message) {
        // await clearTokens()
        sessionStorage.clear()
        localStorage.clear()
        dispatchAuthStates({
          type: 'setAuthenticated',
          payload: false
        })
        history.push('/')
      }
    })
    return () => {
      window.eventBus.remove('logoutUser')
    }
  }, [])

  useEffect(async () => {
    if (authStates.authenticated) {
      try {
        const response = await api.getSingleUser(getUuid())

        const { claims, role_id, updated_at, created_at, deletedAt, ...rest } =
          response.data

        let escritorio

        if (rest.escritorio.length > 0) {
          const { uuid, ativo, cpf_cnpj, nome, razao_social } =
            rest.escritorio[0]

          escritorio = {
            uuid,
            ativo,
            cpf_cnpj,
            nome,
            razao_social
          }
        }

        sessionStorage.setItem('user-c', JSON.stringify(claims))
        sessionStorage.setItem(
          'user-i',
          JSON.stringify({ ...rest, escritorio })
        )

        const event = new CustomEvent('sessionUserAdded', {
          detail: { ...rest, escritorio }
        })
        dispatchEvent(event)

        if (response?.status === 200) {
          dispatchAuthStates({
            type: 'setLoggedUserData',
            payload: response.data
          })

          if (response.data.role.nome === 'empresa') {
            history.push('/empresas')
          } else {
            history.push('/dashboard')
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [authStates.authenticated])

  // useEffect(() => {
  //   console.log(authStates)
  // }, [authStates])

  // useEffect(async () => {
  //   console.log(authStates.loggedUserData)
  // }, [authStates.loggedUserData])

  const reloadLoggedUserData = async () => {
    try {
      const response = await api.getSingleUser(getUuid())

      if (response?.status === 200) {
        dispatchAuthStates({
          type: 'setLoggedUserData',
          payload: response.data
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleLogout = async () => {
    const refreshToken =
      sessionStorage.getItem('refreshToken') ||
      localStorage.getItem('refreshToken')
    window.eventBus.dispatch('logoutUser', { message: true })
    await api.logout({ refreshToken: refreshToken })
  }

  return (
    <LoginContext.Provider
      value={{
        authStates,
        dispatchAuthStates,
        reloadLoggedUserData,
        handleLogout
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export { LoginContext, AuthProvider }
