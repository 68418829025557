/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import {
  InputContainer,
  Label,
  TextFieldInput,
  ErrorMessage
} from './../FormCRUD/style'
import Patterns from './Patterns/index'
import useNonInitialEffect from 'hooks/useNonInitialEffect'

function MaskedText({ label, inline, ...props }) {
  const [field, meta, helpers] = useField(props)
  const { pattern, ...rest } = props
  const [error, setError] = useState(false)

  const {
    pattern: { applyMask, validate }
  } = new Patterns({
    pattern: pattern,
    value: field.value,
    setValue: helpers.setValue,
    setError
  })

  useEffect(() => {
    applyMask(field.value)
  }, [field.value])

  useNonInitialEffect(() => {
    validate()
  }, [field.value])

  return (
    <InputContainer
      style={
        inline && {
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 0',
          alignItems: 'end'
        }
      }
    >
      <Label htmlFor={field.name} style={{ width: '50%' }}>
        {label}
      </Label>
      <TextFieldInput
        type="text"
        autoComplete="off"
        autoFocus={label === 'Nome'}
        {...field}
        {...rest}
        style={inline && { width: '50%', marginLeft: '10px' }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputContainer>
  )
}

export function withMaskedText(Component) {
  const _Component = ({ pattern = '', ...props }) => {
    const [field, meta, helpers] = useField(props)
    const [error, setError] = useState(false)

    const {
      pattern: { applyMask, validate }
    } = new Patterns({
      pattern: pattern,
      value: field.value,
      setValue: helpers.setValue,
      setError
    })

    useEffect(() => {
      applyMask(field.value)
    }, [field.value])

    useNonInitialEffect(() => {
      validate()
    }, [field.value])

    return <Component {...field} {...props} />
  }

  return _Component
}
MaskedText.propTypes = {
  label: PropTypes.string,
  inline: PropTypes.any,
  pattern: PropTypes.string
}

export default MaskedText
