import React from 'react'
import PropTypes from 'prop-types'
import { Button } from './../style'
import icones from './Icones'
import history from 'services/history'
import { Tooltip } from '@plick-solucoes-contabeis/ui-components'

function CadastroDeParaButton(props) {
  return (
    <Tooltip title="De-Para" delay={900}>
      <Button
        onClick={() =>
          history.push('contas', {
            escritorioId: props.rowValues.escritorio_id,
            empresaId: props.rowValues.uuid
          })
        }
      >
        <icones.IconeCadastroDePara />
      </Button>
    </Tooltip>
  )
}

CadastroDeParaButton.propTypes = {
  setShowModal: PropTypes.func,
  setModalContent: PropTypes.func,
  rowValues: PropTypes.object
}
export default CadastroDeParaButton
