import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

function InputSelectorFilesExtension({ name }) {
  const validExtensions = ['txt', 'csv', 'pdf', 'xls', 'xlsx', 'ofx']
  return (
    <Field
      as="select"
      name={name}
      style={{
        marginLeft: '10px',
        width: 'calc(100% - 20px)'
      }}
    >
      <option value="" disabled hidden>
        Extensão de arquivos
      </option>
      {validExtensions.map((extension) => (
        <option key={extension} value={extension}>
          {extension}
        </option>
      ))}
    </Field>
  )
}

InputSelectorFilesExtension.propTypes = {
  option: PropTypes.string,
  name: PropTypes.string
}
export default InputSelectorFilesExtension
