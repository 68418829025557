import React, { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Form } from 'components/Form'
import { InputComponent } from 'components/InputComponent'
import * as logic from './linhaContainerLogic'
import { ColumnCell } from './ColumnCell'

import {
  LinhaContainerMain,
  LinhaContainerHeader,
  ColumnsContainer,
  ColumnsContainerHeader
} from './style'

export function LinhaContainer ({
  row,
  handleSubmitLinha
}) {
  let initialChar = ''
  let previousColumn = 0
  let columnChar = ''
  let resetLetterNext = false

  const [columns, setColumns] = useState([])

  const Colums = useMemo(() =>
    [...Array(row.row_columns * row.row_lines)].map((item, columnIndex) => {
      const currentLine = Math.ceil((columnIndex + 1) / row.row_columns)
      let currentColumn

      if ((columnIndex + 1) <= row.row_columns) {
        currentColumn = (columnIndex + 1)
      } else {
        currentColumn = (columnIndex + 1) - ((currentLine - 1) * row.row_columns)
      }

      if (currentColumn > previousColumn) {
        previousColumn = currentColumn
        columnChar = logic.incrementChar(columnChar)
        resetLetterNext = false
      }
      if (previousColumn === row.row_columns) {
        previousColumn = 0
        resetLetterNext = true
      }
      const copyOfColumnChar = columnChar
      //   Set the Background text identifying position of the cell
      if (resetLetterNext) {
        columnChar = ''
      }

      return (<ColumnCell
        row={row}
        columns={columns}
        setColumns={setColumns}
        columnIndex={columnIndex}
        key={columnIndex}
        columnChar={copyOfColumnChar}
        currentLine={currentLine}
        currentColumn={currentColumn}
      />)
    })
  , [row, columns])

  useEffect(async () => {
    await logic.getColumns(row.id, setColumns)
  }, [])

  const submitRowRef = useRef(null)

  const handleInputChange = () => {
    submitRowRef.current.click()
  }

  return (
    <LinhaContainerMain>
        <LinhaContainerHeader>
        {/* <h3>{row.nome}</h3> */}
        <h3>Linha:</h3>
        <Form
          handleSubmit={handleSubmitLinha}
          initialValues={row}
          reset={false}
        >
          <InputComponent label='№ linhas:' type='number' name='row_lines' min={1} onInput={handleInputChange} />
          <InputComponent label='№ colunas:' type='number' name='row_columns' min={1} onInput={handleInputChange} />
          <button type='submit' ref={submitRowRef}></button>
        </Form>
        </LinhaContainerHeader>
        <ColumnsContainer lines={row.row_lines} columns={row.row_columns} >
          {
          [...Array(row.row_columns)].map((item, columnHeaderIndex) => {
            initialChar = logic.incrementChar(initialChar)
            return <ColumnsContainerHeader key={`header_${columnHeaderIndex}`}> {initialChar} </ColumnsContainerHeader>
          })
          }
          {Colums}

        </ColumnsContainer>
    </LinhaContainerMain>
  )
}

LinhaContainer.propTypes = {
  row: PropTypes.object,
  handleSubmitLinha: PropTypes.func,
  handleShowColunaForm: PropTypes.func
}
