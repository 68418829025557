import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getAllRegrasSanitizacao } from '../SanitizationRuleForm/sanitizationRuleLogic'
import * as logic from '../../subPages/CelulasEntrada/celulasEntradaLogic'
import { Form } from 'components/Form'
import * as Yup from 'yup'
import { InputComponent } from 'components/InputComponent'
import { SanitizationRuleForm } from '../SanitizationRuleForm'
import { SanitizationRuleModal } from '../SanitizationRuleForm/SanitizationRuleModal'
import { ValidationRulesForm } from '../ValidationRulesForm'

import {
  FormContainer,
  Button
} from '../../subPages/style'

import {
  FormTabsContainer,
  FormTabs,
  CellFormSection,
  DoubleInputContainer,
  ButtonNext,
  ValidationRulesContainer
} from './style'

export function CelulaForm ({
  celulaInitialValues,
  setInputFileCells
}) {
  const [selectedForm, setSelectedForm] = useState('dados')
  const [sanitizationRules, setSanitizationRules] = useState([])
  const [showSanitizationRuleModal, setShowSanitizationRuleModal] = useState(false)

  useEffect(async () => {
    await getAllRegrasSanitizacao(setSanitizationRules)
  }, [])

  const handleSubmitCell = (values) => {
    if (window.utils.isFalsy(values.id)) {
      logic.handleAddInputFileCell(values, setInputFileCells)
    } else {
      logic.handleEditInputFileCell(values.id, values, setInputFileCells)
    }
    setSelectedForm('dados')
  }

  const schemaValidateCelula = Yup.object().shape({
    nome: Yup.string().required('Nome não pode ser vazio'),
    line: Yup.number().min(1, 'Deve ser maior que zero').integer('Deve ser numérico'),
    column: Yup.number().optional().min(1, 'Deve ser maior que zero').integer('Deve ser numérico'),
    char_start_position: Yup.number().optional().min(0, 'Deve ser maior ou igual a zero').integer('Deve ser numérico'),
    char_end_position: Yup.number().optional().min(0, 'Deve ser maior ou igual a zero').integer('Deve ser numérico')
  })

  return (
    <>
        <FormTabsContainer>
          <FormTabs selected={selectedForm === 'dados'} onClick={ () => setSelectedForm('dados')} >Dados da Celula</FormTabs>
          <FormTabs selected={selectedForm === 'sanitizacao'} onClick={ () => setSelectedForm('sanitizacao')} >Sanitização</FormTabs>
          {
            celulaInitialValues.update
              ? <FormTabs selected={selectedForm === 'validacao'} onClick={ () => setSelectedForm('validacao')} >Validação</FormTabs>
              : ''
          }
        </FormTabsContainer>
        <FormContainer>
          <header>
            <h3>
              {selectedForm === 'dados' && celulaInitialValues.update ? `Editar dados da Célula Avulsa -${celulaInitialValues.nome}` : ''}
              {selectedForm === 'dados' && !celulaInitialValues.update ? 'Cadastrar nova Célula Avulsa' : ''}
              {selectedForm === 'sanitizacao' ? 'Regras de sanitização ' : ''}
              {selectedForm === 'validacao' ? 'Regras de validação' : ''}
            </h3>
          </header>
          <div style={{ display: selectedForm === 'validacao' ? 'none' : 'block' }}>
            <Form
              handleSubmit={handleSubmitCell}
              initialValues={celulaInitialValues}
              schemaValidate={schemaValidateCelula}
              reset={true}
            >
              <CellFormSection selected={selectedForm === 'dados'}>
                <InputComponent label='Nome da celula:' type='text' name='nome'/>
                <DoubleInputContainer>
                  <InputComponent label='Posição na linha:' type='number' name='line' min={1} />
                  <InputComponent label='Posição na coluna:' type='number' name='column' min={1} />
                </DoubleInputContainer>
                <DoubleInputContainer>
                  <InputComponent label='Caracter inicial:' type='number' name='char_start_position' min={0} />
                  <InputComponent label='Caracter final:' type='number' name='char_end_position' min={0} />
                </DoubleInputContainer>

                <ButtonNext type='button' onClick={ () => setSelectedForm('sanitizacao')}>Próximo</ButtonNext>
              </CellFormSection>
              <CellFormSection selected={selectedForm === 'sanitizacao'}>
                <InputComponent label='Aplicar em todas as linhas da planilha como:' type='text' name='apply_to_every_line_in_sheet_as'/>
                <SanitizationRuleForm
                  sanitizationRules={sanitizationRules}
                  setShowSanitizationRuleModal={setShowSanitizationRuleModal}
                />
                <Button type='submit' style={{ marginTop: '30px' }}>
                    {!celulaInitialValues.update ? 'Criar' : 'Editar'}
                </Button>
              </CellFormSection>
              </Form>
            </div>
            {
            celulaInitialValues.update
              ? <CellFormSection selected={selectedForm === 'validacao'}>
                <ValidationRulesContainer>
                  <ValidationRulesForm
                    updateElementInitialValues={celulaInitialValues}
                    elementType="celula"
                  />
              </ValidationRulesContainer>
            </CellFormSection>
              : ''
            }
        </FormContainer>
        <SanitizationRuleModal
          setSanitizationRules={setSanitizationRules}
          showSanitizationRuleModal={showSanitizationRuleModal}
          setShowSanitizationRuleModal={setShowSanitizationRuleModal}
        />
      </>
  )
}

CelulaForm.propTypes = {
  celulaInitialValues: PropTypes.object,
  setInputFileCells: PropTypes.func
}
