import React from 'react'
// import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import icones from 'components/Table/subcomponents/Icones'
import getHumanNameForEndpoint from 'helpers/getHumanNameForEndpoint'
import { SearchButton } from './style'
import api from 'services/api'
import history from 'services/history'
import { transformData } from 'components/Table/logic/utils'
import { control } from 'components/Paginator/logic/hooks/usePaginatorControl'

const ITEMSPORPAGINA = parseInt(process.env.REACT_APP_PER_PAGE)
export default function SearchBar({
  listUrl,
  apiFetcher,
  setRows,
  setPaginatorControl,
  setActive,
  setNumberOfPages
}) {
  return (
    <div style={{ marginLeft: 'auto' }}>
      <Formik
        initialValues={{
          search: ''
        }}
        onSubmit={async (value) => {
          console.log(value, apiFetcher, listUrl)
          if (listUrl === apiFetcher) {
            const feature = history.location.pathname.replace(/\//g, '')
            const search = value.search
              ? JSON.stringify({ value: value.search })
              : JSON.stringify({})

            const scopes = listUrl === 'Usuarios' ? 'role,escritorio' : ''
            const res = await api.filteredPaginated(
              feature,
              search,
              1,
              ITEMSPORPAGINA,
              scopes
            )

            const items = transformData(res.data.rows)
            setRows(items)
            setActive(1)
            setNumberOfPages(res.data.numberOfPages)
            setPaginatorControl(control(feature, search).filtered)
          } else {
            const feature = history.location.pathname.replace(/\//g, '')
            const search = value.search
              ? JSON.stringify({
                  value: value.search,
                  escritorioId: history.location?.state?.id
                })
              : JSON.stringify({
                  escritorioId: history.location?.state?.id
                })

            const res = await api.filteredPaginated(feature, search)

            const items = transformData(res.data.rows)
            setRows(items)
            setActive(1)
            setNumberOfPages(res.data.numberOfPages)
            setPaginatorControl(control(feature, search).filtered)
          }
        }}
      >
        <Form
          style={{
            display: 'flex'
          }}
        >
          <Field
            type="search"
            name="search"
            placeholder={`Pesquisar ${getHumanNameForEndpoint(
              apiFetcher,
              'plural'
            )}`}
            style={{
              height: '38px',
              borderRadius: '5px 0 0 5px',
              paddingLeft: '10px'
            }}
          />
          <SearchButton type="submit">
            <icones.IconeLupaPesquisa />
          </SearchButton>
        </Form>
      </Formik>
    </div>
  )
}

SearchBar.propTypes = {
  setRows: PropTypes.func,
  listUrl: PropTypes.string,
  apiFetcher: PropTypes.string,
  setPaginatorControl: PropTypes.func,
  setNumberOfPages: PropTypes.func,
  setActive: PropTypes.func
}
