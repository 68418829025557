import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'outputFileColumn'
const url = '/output-file-column'

const endpointsDescriptions = {
  getAllOutputColumnsLayout: {
    action: 'getAll'
  },
  getOutputRowColumns: {
    action: 'getOne'
  },
  createOutputColumn: {
    action: 'add'
  },
  editOutputColumn: {
    action: 'update'
  },
  deleteOutputColumn: {
    action: 'delete'
  }
}

const outputFileColumn = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllOutputColumnsLayout = (layoutId) => {
  const endpointDescription = outputFileColumn.endpointsConfigs.getAllOutputColumnsLayoutConfig
  return axios.get(
    `${baseUrl}/output-file-column/layout/${layoutId}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getOutputRowColumns = (rowId) => {
  const endpointDescription = outputFileColumn.endpointsConfigs.getOutputRowColumnsConfig
  return axios.get(
    `${baseUrl}/output-file-column/row/${rowId}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createOutputColumn = (body) => {
  const endpointDescription = outputFileColumn.endpointsConfigs.createOutputColumnConfig
  return axios.post(
    `${baseUrl}/output-file-column`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.editOutputColumn = (id, body) => {
  const endpointDescription = outputFileColumn.endpointsConfigs.editOutputColumnConfig
  return axios.put(
    `${baseUrl}/output-file-column/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteOutputColumn = (id) => {
  const endpointDescription = outputFileColumn.endpointsConfigs.deleteOutputColumnConfig
  return axios.delete(
    `${baseUrl}/output-file-column/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
