import Pattern from './Pattern'
export default class PatternPhone extends Pattern {
  constructor({ value, setValue, setError }) {
    super(setValue, setError)
    super.setCleanValue(value.replace(/\D/g, ''))
    return {
      applyMask: (value) => this.applyMask(value),
      validate: () => this.validate()
    }
  }

  applyMask(value) {
    super.setMaskedValue(value)
    const elementValue = Boolean(this.maskedValue?.match(/\d/g))

    if (!elementValue && this.maskedValue !== null) {
      const as = this.maskedValue.replace(this.maskedValue, '')
      super.setMaskedValue(as)
    }

    super.setMaskedValue(this.cleanValue)
    // Telefone Fixo
    if (this.cleanValue.length < 11) {
      super.setMaskedValue(this.maskedValue.replace(/^(\d{1})$/g, '($&'))
      super.setMaskedValue(this.maskedValue.replace(/^(\d{2})$/g, '($&'))
      super.setMaskedValue(this.maskedValue.replace(/^(\d{2})(\d)/g, '($1) $2'))
      super.setMaskedValue(
        this.maskedValue.replace(/(\d{4})(\d{1,4})$/, '$1-$2')
      )
    }
    // Celular
    else {
      super.setMaskedValue(this.maskedValue.slice(0, 11))
      super.setMaskedValue(
        this.maskedValue.replace(
          /(\d{2})(\d{1})(\d{4})(\d{4})/,
          '($1) $2 $3-$4'
        )
      )
    }

    super.setMaskedValue(this.maskedValue)
    super.setValue()
  }

  validate() {
    const isValidPhone = this.cleanValue.match(
      /(^(?:0800\)?)\s*?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{3}$)|(^(?:0800|\(?[1-9]{2}\)?)\s*?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$)/
    )
    if (!isValidPhone) {
      super.setError('Telefone Inválido.')
      return
    }
    super.removeError()
  }
}
