/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'

import { InputComponent } from 'components/InputComponent'
import { useFormikContext } from 'formik'

export function CabecalhoFormFields () {
  const [displayFillInputs, setDisplayFillInputs] = useState(false)

  const {
    values: {
      char_start_position,
      char_end_position
    },
    setFieldValue
  } = useFormikContext()

  useEffect(() => {
    if (window.utils.isFalsy(char_start_position) || window.utils.isFalsy(char_end_position)) {
      setDisplayFillInputs(false)
      setFieldValue('fill_char', '')
      setFieldValue('fill_direction', '')
    } else {
      setDisplayFillInputs(true)
    }
  }, [
    char_start_position,
    char_end_position
  ])
  return (
    <>
        <InputComponent
            label='Nome do Cabeçalho:'
            type='text'
            name='value'
        />
        <InputComponent
            label='Caracter inicial:'
            type='number'
            name='char_start_position'
            min={0}
        />
        <InputComponent
            label='Caracter final:'
            type='number'
            name='char_end_position'
            min={0}
        />
        <InputComponent
            label='Caractere de preenchimento:'
            type='text'
            name='fill_char'
            customStyle={displayFillInputs ? 'display: flex;' : 'display: none;' }
        />

        <InputComponent
            label='Direção do preenchimento:'
            type='select'
            name='fill_direction'
            customStyle={displayFillInputs ? 'display: flex;' : 'display: none;' }
        >
            <option value="" disabled hidden>Escolha a direção...</option>
            <option value={'left'}>Esquerda</option>
            <option value={'right'}>Direita</option>
        </InputComponent>
    </>
  )
}
