import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as logic from '../../subPages/CelulasSaida/celulasSaidaLogic'
import * as Yup from 'yup'
import { Form } from 'components/Form'
import { CellFormFields } from './CellFormFields'
import { Button } from 'components/Button'

import { FormContainer } from './style'

export function CelulaForm({ celulaInitialValues, setOutputFileCells }) {
  const [selectedForm, setSelectedForm] = useState('dados')

  const handleSubmitCell = (values) => {
    if (window.utils.isFalsy(values.id)) {
      logic.handleAddOutputFileCell(values, setOutputFileCells)
    } else {
      logic.handleEditOutputFileCell(values.id, values, setOutputFileCells)
    }
    setSelectedForm('dados')
  }

  const schemaValidateCelula = Yup.object().shape({
    nome: Yup.string().required('Nome não pode ser vazio'),
    output_cell_content: Yup.string().required(
      'Conteúdo da célula não pode ser vazio'
    ),
    line: Yup.number()
      .min(1, 'Deve ser maior que zero')
      .integer('Deve ser numérico'),
    column: Yup.number()
      .optional()
      .min(1, 'Deve ser maior que zero')
      .integer('Deve ser numérico'),
    char_start_position: Yup.number()
      .optional()
      .min(0, 'Deve ser maior ou igual a zero')
      .integer('Deve ser numérico'),
    char_end_position: Yup.number()
      .optional()
      .min(0, 'Deve ser maior ou igual a zero')
      .integer('Deve ser numérico')
  })

  return (
    <>
      <FormContainer>
        <header>
          <h3>
            {celulaInitialValues.update
              ? 'Editar Célula'
              : 'Cadastrar nova Célula'}
          </h3>
        </header>
        <div
          style={{ display: selectedForm === 'validacao' ? 'none' : 'block' }}
        >
          <Form
            handleSubmit={handleSubmitCell}
            initialValues={celulaInitialValues}
            schemaValidate={schemaValidateCelula}
            reset={!celulaInitialValues.update}
          >
            <CellFormFields />

            <Button
              bgColor="var(--green)"
              colorText="var(--white)"
              value={!celulaInitialValues.update ? 'Criar' : 'Editar'}
              type="submit"
            />
          </Form>
        </div>
      </FormContainer>
    </>
  )
}

CelulaForm.propTypes = {
  celulaInitialValues: PropTypes.object,
  setOutputFileCells: PropTypes.func
}
