import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from '@plick-solucoes-contabeis/ui-components'

const EmpresaEscritorioCellSpan = styled.p`
  word-break: break-word;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

function EmpresaEscritorioCell({ value }) {
  return (
    <Tooltip title={value?.nome} delay={800}>
      <EmpresaEscritorioCellSpan>{value?.nome}</EmpresaEscritorioCellSpan>
    </Tooltip>
  )
}

EmpresaEscritorioCell.propTypes = {
  value: PropTypes.object
}

export default EmpresaEscritorioCell
