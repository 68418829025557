import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'inputFileColumn'
const url = '/input-file-column'

const endpointsDescriptions = {
  getAllColumnsLayout: {
    action: 'getAll'
  },
  getColumn: {
    action: 'getOne'
  },
  getRowColumns: {
    action: 'getOne'
  },
  createColumn: {
    action: 'add'
  },
  editColumn: {
    action: 'update'
  },
  deleteColumn: {
    action: 'delete'
  }
}

const inputFileColumn = new BaseDomainsProvider(
  domain,
  url,
  endpointsDescriptions
)

endpoints.getAllColumnsLayout = (layoutId) => {
  const endpointDescription =
    inputFileColumn.endpointsConfigs.getAllColumnsLayoutConfig
  return axios.get(`${baseUrl}/input-file-column/layout/${layoutId}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getColumn = (id) => {
  const endpointDescription = inputFileColumn.endpointsConfigs.getColumnConfig
  return axios.get(`${baseUrl}/input-file-column/${id}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getRowColumns = (rowId) => {
  const endpointDescription =
    inputFileColumn.endpointsConfigs.getRowColumnsConfig
  return axios.get(`${baseUrl}/input-file-column/row/${rowId}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.createColumn = (body) => {
  const endpointDescription =
    inputFileColumn.endpointsConfigs.createColumnConfig
  endpointDescription.options.feedbackOnAction = false
  return axios.post(`${baseUrl}/input-file-column`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.editColumn = (id, body) => {
  const endpointDescription = inputFileColumn.endpointsConfigs.editColumnConfig
  return axios.put(`${baseUrl}/input-file-column/${id}`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.deleteColumn = (id) => {
  const endpointDescription =
    inputFileColumn.endpointsConfigs.deleteColumnConfig
  return axios.delete(`${baseUrl}/input-file-column/${id}`, {
    endpointInfo: endpointDescription
  })
}

export default endpoints
