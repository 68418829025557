import { useContext, useState, useEffect } from 'react'
import permissionHelpers from 'helpers/permissionHelper'
import { LoginContext } from 'services/Auth/AuthContext'
import { TableContext } from 'context/TableContext/TableContext'
import useNonInitialEffect from 'hooks/useNonInitialEffect'

export default function useTopBar(
  featureName,
  componentsList,
  dependencies = []
) {
  const { authStates } = useContext(LoginContext)
  const { dispatchTableConfigs } = useContext(TableContext)
  const [permissionLevel, setPermissionLevel] = useState('')

  useEffect(() => {
    if (Object.keys(authStates.loggedUserData).length > 0) {
      if (typeof authStates.loggedUserData?.claims !== 'undefined') {
        setPermissionLevel(
          permissionHelpers.getPermissionLevel(authStates, featureName)
        )
      }
    }
  }, [authStates.loggedUserData])

  useNonInitialEffect(() => {
    const topBarItems = []
    componentsList.forEach((component) => {
      if (component === 'add' && permissionLevel === 'manage') {
        topBarItems.push(component)
      } else if (component !== 'add') {
        topBarItems.push(component)
      }
    })
    dispatchTableConfigs({ type: 'setTopBarItems', payload: topBarItems })
  }, [permissionLevel, ...dependencies])
}
