import { useEffect, useContext } from 'react'
import cellComponents from 'components/Table/subcomponents'
import { TableContext } from 'context/TableContext/TableContext'

export default function useCellComponents(
  cellComponentList,
  dependencies = []
) {
  const { dispatchTableConfigs } = useContext(TableContext)

  useEffect(() => {
    const components = {}
    if (Object.keys(cellComponentList).length > 0) {
      Object.entries(cellComponentList).forEach((cellComponent) => {
        components[cellComponent[0]] = cellComponents[cellComponent[1]]
      })
    }
    dispatchTableConfigs({ type: 'setCellComponents', payload: components })
  }, dependencies)
}
