import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL
const ITEMSPORPAGINA = parseInt(process.env.REACT_APP_PER_PAGE)

const domain = 'filter'
const url = '/filter'

const endpointsDescriptions = {
  filteredList: {
    action: 'getAll'
  },
  filteredPaginated: {
    action: 'getPaginated'
  }
}

const filter = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.filteredPaginated = (
  feature,
  filters,
  page = 1,
  perPage = ITEMSPORPAGINA,
  scopes
) => {
  const endpointDescription = filter.endpointsConfigs.filteredPaginated

  const body = {
    feature,
    filters: JSON.parse(filters)
  }

  return axios.post(
    `${baseUrl}/filter/paginate/${page}/${perPage}${
      scopes ? `?scopes=${scopes}` : ''
    }`,
    body,
    {
      endpointInfo: endpointDescription
    }
  )
}

endpoints.filteredList = (feature, filters) => {
  const endpointDescription = filter.endpointsConfigs.filteredList

  const body = {
    feature,
    filters: JSON.parse(filters)
  }

  return axios.post(`${baseUrl}/filter/list`, body, {
    endpointInfo: endpointDescription
  })
}

export default endpoints
