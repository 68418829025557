import history from 'services/history'

export const treatNavigation = (condition, path) => {
  if (condition) {
    const confirm = window.confirm('Se sair, suas alterações não serão salvas.')

    if (confirm) {
      history.push(path)
    }
  } else {
    history.push(path)
  }
}
