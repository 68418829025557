import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'outputFileHeader'
const url = '/output-file-headers'

const endpointsDescriptions = {
  getAllOutputHeaders: {
    action: 'getAll'
  },
  createOutputHeader: {
    action: 'add'
  },
  editOutputHeader: {
    action: 'update'
  },
  deleteOutputHeader: {
    action: 'delete'
  }
}

const outputFileHeader = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllOutputHeaders = (layoutId) => {
  const endpointDescription = outputFileHeader.endpointsConfigs.getAllOutputHeadersConfig
  return axios.get(
    `${baseUrl}/output-file-headers/layout/${layoutId}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createOutputHeader = (body) => {
  const endpointDescription = outputFileHeader.endpointsConfigs.createOutputHeaderConfig
  return axios.post(
    `${baseUrl}/output-file-headers`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.editOutputHeader = (id, body) => {
  const endpointDescription = outputFileHeader.endpointsConfigs.editOutputHeaderConfig
  return axios.put(
    `${baseUrl}/output-file-headers/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteOutputHeader = (id) => {
  const endpointDescription = outputFileHeader.endpointsConfigs.deleteOutputHeaderConfig
  return axios.delete(
    `${baseUrl}/output-file-headers/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
