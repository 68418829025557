import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'
import { Button } from 'components/Button'
import * as logic from './ignoresEntradaLogic'
import { LayoutEntradaTable } from '../../subComponents/Table'
import { IgnoreForm } from '../../subComponents/IgnoreForm'

export function IgnoresEntrada({ layoutId }) {
  const { dispatchRestrictedLayout } = useContext(RestrictedLayoutContext)
  const [inputFileIgnores, setInputFileIgnores] = useState([])
  const [showIgnoreForm, setShowIgnoreForm] = useState(false)

  const [ignoreInitialValues, setIgnoreInitialValues] = useState({})

  const handleShowAddIgnoreForm = () => {
    setIgnoreInitialValues({
      file_layout_id: layoutId,
      ignore_text_sample: '',
      empty: 0,
      discard_all_lines_below: false,
      discard_all_lines_above: false,
      update: false
    })

    setShowIgnoreForm(true)
    dispatchRestrictedLayout({ type: 'setToggleContextMenu', payload: false })
  }

  const handleShowUpdateIgnore = (id) => {
    logic
      .getInputIgnoreById(id)
      .then((data) => {
        // eslint-disable-next-line camelcase
        const { updated_at, created_at, ...rest } = data

        // avoids to initialize null parameters
        for (const key in rest) {
          if (rest[key] === null) {
            rest[key] = ''
          }
        }
        setIgnoreInitialValues({
          ...rest,
          update: true
        })
        setShowIgnoreForm(true)
        dispatchRestrictedLayout({
          type: 'setToggleContextMenu',
          payload: false
        })
      })
      .catch((error) => console.error(error))
  }

  const handleDelete = async (event) => {
    if (!window.utils.isFalsy(ignoreInitialValues.id)) {
      if (ignoreInitialValues.id === parseInt(event.target.dataset.id)) {
        dispatchRestrictedLayout({
          type: 'setToggleContextMenu',
          payload: false
        })
        dispatchRestrictedLayout({
          type: 'setContextMenuContent',
          payload: <></>
        })
      }
    }
    await logic.handleDeleteInputFileIgnore(
      event.target.dataset.id,
      setInputFileIgnores
    )
  }

  useEffect(async () => {
    await logic.getInputIgnores(layoutId, setInputFileIgnores)
  }, [layoutId])

  useEffect(() => {
    if (showIgnoreForm) {
      dispatchRestrictedLayout({
        type: 'setContextMenuContent',
        payload: (
          <>
            <IgnoreForm
              ignoreInitialValues={ignoreInitialValues}
              setInputFileIgnores={setInputFileIgnores}
            />
          </>
        )
      })
    }
  }, [showIgnoreForm, ignoreInitialValues])

  return (
    <>
      <Button
        bgColor="var(--background-medium-blue)"
        colorText="var(--white)"
        buttonFunction="ButtonTextIcon"
        buttonIcon="PlusIcon"
        value="ADICIONAR"
        onClick={handleShowAddIgnoreForm}
      />

      {inputFileIgnores.length > 0 ? (
        <LayoutEntradaTable
          items={inputFileIgnores}
          fields={[
            {
              fieldName: 'ignore_text_sample',
              as: 'PEDAÇO DE TEXTO A SER IGNORADO'
            }
          ]}
          actionButtons={[
            {
              buttonFunction: 'ButtonTable',
              buttonIcon: 'IconeEditar',
              tooltipText: 'Editar',
              onClick: (event) =>
                handleShowUpdateIgnore(event.target.dataset.id)
            },
            {
              bgColor: '#a91803',
              buttonFunction: 'ButtonTable',
              buttonIcon: 'IconeExcluir',
              tooltipText: 'Excluir',
              onClick: handleDelete
            }
          ]}
        />
      ) : (
        ''
      )}
    </>
  )
}

IgnoresEntrada.propTypes = {
  layoutId: PropTypes.string
}
