/* eslint-disable camelcase */
import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'balancetesPlanosDeContas'
const url = '/balancetes-planos-contas'

const endpointsDescriptions = {
  uploadBalancetePlanoDeConta: {
    action: 'upload'
  },
  getBalancetePlanoDeContaFile: {
    action: 'download'
  },
  getAllBalancetesPlanosDeContas: {
    action: 'getAll'
  },
  getBalancetePlanoDeConta: {
    action: 'getOne'
  },
  getBalancetePlanoDeContaByEmpresa: {
    action: 'getBy',
    extraParams: ['da Empresa']
  },
  createBalancetePlanoDeConta: {
    action: 'add'
  },
  updateBalancetePlanoDeConta: {
    action: 'update'
  },
  deleteBalancetePlanoDeConta: {
    action: 'delete'
  }
}

const balancetesPlanosDeContas = new BaseDomainsProvider(
  domain,
  url,
  endpointsDescriptions
)

endpoints.uploadBalancetePlanoDeConta = (body) => {
  const endpointDescription =
    balancetesPlanosDeContas.endpointsConfigs.uploadBalancetePlanoDeContaConfig
  return axios.post(`${baseUrl}/balancetes-planos-contas/upload`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.getBalancetePlanoDeContaFile = (filename) => {
  const endpointDescription =
    balancetesPlanosDeContas.endpointsConfigs.getBalancetePlanoDeContaFileConfig
  return axios.get(`${baseUrl}/file/balancetes-planos-contas/${filename}`, {
    responseType: 'arraybuffer',
    endpointInfo: endpointDescription
  })
}

endpoints.getAllBalancetesPlanosDeContas = () => {
  const endpointDescription =
    balancetesPlanosDeContas.endpointsConfigs
      .getAllBalancetesPlanosDeContasConfig
  return axios.get(`${baseUrl}/balancetes-planos-contas`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getBalancetePlanoDeConta = (id) => {
  const endpointDescription =
    balancetesPlanosDeContas.endpointsConfigs.getBalancetePlanoDeContaConfig
  return axios.get(`${baseUrl}/balancetes-planos-contas/${id}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getBalancetePlanoDeContaByEmpresa = (empresa_id) => {
  const endpointDescription =
    balancetesPlanosDeContas.endpointsConfigs
      .getBalancetePlanoDeContaByEmpresaConfig
  return axios.get(
    `${baseUrl}/balancetes-planos-contas/empresa/${empresa_id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createBalancetePlanoDeConta = (body) => {
  const endpointDescription =
    balancetesPlanosDeContas.endpointsConfigs.createBalancetePlanoDeContaConfig
  return axios.post(`${baseUrl}/balancetes-planos-contas`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.updateBalancetePlanoDeConta = (id, body) => {
  const endpointDescription =
    balancetesPlanosDeContas.endpointsConfigs.updateBalancetePlanoDeContaConfig
  return axios.patch(`${baseUrl}/balancetes-planos-contas/${id}`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.deleteBalancetePlanoDeConta = (id) => {
  const endpointDescription =
    balancetesPlanosDeContas.endpointsConfigs.deleteBalancetePlanoDeContaConfig
  return axios.delete(`${baseUrl}/balancetes-planos-contas/${id}`, {
    endpointInfo: endpointDescription
  })
}

export default endpoints
