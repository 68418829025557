import React, { useEffect, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'

import { PagesTabsContainer, PagesTabs } from './style'
import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'

import history from 'services/history'
import { LinhasEntrada } from './LinhasEntrada'
import { CelulasEntrada } from './CelulasEntrada'
import { IgnoresEntrada } from './IgnoresEntrada'

const tabsOptions = [
  { tabKey: 'linhas', tabText: 'Linhas e colunas' },
  { tabKey: 'celulas', tabText: 'Celulas' },
  { tabKey: 'ignores', tabText: 'Ignores' }
]

export function DetailsCadastroLayoutEntrada() {
  const layoutId = history.location.state?.layoutId
  const nomeLayout = history.location.state?.nome

  function selectPageReducer(state, action) {
    switch (action.type) {
      case 'linhas':
        return {
          ...state,
          selectedPage: 'linhas',
          currentPage: <LinhasEntrada layoutId={layoutId} />
        }
      case 'celulas':
        return {
          ...state,
          selectedPage: 'celulas',
          currentPage: <CelulasEntrada layoutId={layoutId} />
        }
      case 'ignores':
        return {
          ...state,
          selectedPage: 'ignores',
          currentPage: <IgnoresEntrada layoutId={layoutId} />
        }
      default:
        break
    }
  }

  const initialState = {
    selectedPage: 'linhas',
    currentPage: <LinhasEntrada layoutId={layoutId} />,
    toggleContextMenu: true,
    contextMenuContent: <></>
  }

  const [state, dispatch] = useReducer(selectPageReducer, initialState)
  const { dispatchRestrictedLayout } = useContext(RestrictedLayoutContext)

  useEffect(() => {
    dispatchRestrictedLayout({
      type: 'setHeaderData',
      payload: `CADASTRO DE LAYOUT DE ENTRADA - ${nomeLayout}`
    })
  }, [])

  useEffect(() => {
    dispatchRestrictedLayout({
      type: 'setMainTopBarContent',
      payload: (
        <MainTopBarPagesTabs
          state={state}
          dispatch={dispatch}
          tabsOptions={tabsOptions}
        />
      )
    })
    dispatchRestrictedLayout({
      type: 'setContextMenuContent',
      payload: state.contextMenuContent
    })
    dispatchRestrictedLayout({
      type: 'setToggleContextMenu',
      payload: state.toggleContextMenu
    })
  }, [state.selectedPage])

  return state.currentPage
}

function MainTopBarPagesTabs({ state, dispatch, tabsOptions }) {
  return (
    <PagesTabsContainer>
      {tabsOptions.map((tab, index) => {
        return (
          <PagesTabs
            key={index}
            selected={state.selectedPage === tab.tabKey}
            onClick={() => dispatch({ type: tab.tabKey })}
          >
            {tab.tabText}
          </PagesTabs>
        )
      })}
    </PagesTabsContainer>
  )
}

MainTopBarPagesTabs.propTypes = {
  state: PropTypes.object,
  dispatch: PropTypes.func,
  tabsOptions: PropTypes.array
}
