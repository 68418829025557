import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import * as Reducer from './reducer'

const RestrictedLayoutContext = createContext({})
function RestrictedLayoutProvider({ children }) {
  const [restrictedLayoutState, dispatchRestrictedLayout] = useReducer(
    Reducer.restrictedLayoutReducer,
    Reducer.restrictedLayoutStates
  )

  return (
    <RestrictedLayoutContext.Provider
      value={{
        restrictedLayoutState,
        dispatchRestrictedLayout
      }}
    >
      {children}
    </RestrictedLayoutContext.Provider>
  )
}

RestrictedLayoutProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export { RestrictedLayoutContext, RestrictedLayoutProvider }
