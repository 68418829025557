/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react'
import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'
import { Button } from '../../components/Button'
import { Container } from './styles'
import { getUuid } from 'helpers/jwtHelper'
import api from 'services/api'
import * as Yup from 'yup'
import { InputComponent } from 'components/InputComponent'
import { Formik, Form } from 'formik'
import { LoginContext } from 'services/Auth/AuthContext'
import { REGEX } from 'helpers/formValidationRules'
import Modal from 'components/Modal'

export function AlterarSenha() {
  const { dispatchRestrictedLayout } = useContext(RestrictedLayoutContext)
  const { handleLogout } = useContext(LoginContext)
  const [userData, setUserData] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState({
    title: '',
    component: React.Component
  })

  const getUserData = async () => {
    api.getSingleUser(getUuid()).then((response) => {
      setUserData({
        nome: response.data.nome,
        email: response.data.email
      })
    })
  }

  const schemaValidate = Yup.object().shape({
    senhaAtual: Yup.string().min(6).required('Senha requerida'),
    senha: Yup.string()
      .matches(
        REGEX.senhaForte,
        'Mínimo 6 caracteres, incluindo 1 letra maiscula, 1 minuscula e 1 caracter especial'
      )
      .required('Senha requerida'),
    confirmar_senha: Yup.string()
      .min(6)
      .when('senha', {
        is: (val) => val && val.length > 0,
        then: Yup.string().oneOf(
          [Yup.ref('senha')],
          'As senhas devem coincidir'
        )
      })
      .required('É necessário confirmar a senha')
  })

  useEffect(getUserData, [])
  useEffect(() => {
    dispatchRestrictedLayout({
      type: 'setHeaderData',
      payload: `Alteração de senha do usuário ${userData.nome}`
    })
  }, [userData])

  return (
    <Container style={{ width: '50%', margin: '0 auto' }}>
      <Formik
        onSubmit={async (values) => {
          const parsedbody = { senha: values.senha }
          api
            .login({ email: userData.email, senha: values.senhaAtual })
            .then((response) => {
              api.editUsuarios(getUuid(), parsedbody).then(() => handleLogout())
            })
            .catch((error) => {
              setModalContent({
                title: 'Erro',
                component: (
                  <p style={{ textAlign: 'center' }}>Senha atual inválida</p>
                )
              })
              setShowModal(true)
              console.error(error)
            })
        }}
        initialValues={{
          senhaAtual: '',
          senha: '',
          confirmar_senha: ''
        }}
        validationSchema={schemaValidate}
      >
        <Form>
          <InputComponent
            type="password"
            name="senhaAtual"
            placeholder={'Senha atual'}
          />
          <InputComponent
            type="password"
            name="senha"
            placeholder="Nova senha"
          />
          <InputComponent
            type="password"
            name="confirmar_senha"
            placeholder="Confirmar nova senha"
          />
          <Button type="submit" value="Enviar" />
        </Form>
      </Formik>
      {/* <Modal
        title={modalContent.title}
        showModal={showModal}
        setShowModal={setShowModal}
      >
        {modalContent.component}
      </Modal> */}
    </Container>
  )
}
