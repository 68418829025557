import {
  cnpjCpfFormat,
  cnpjFormat,
  dateTimeString,
  phoneFormat
} from 'helpers/formatters'

const valuesToMask = new Set([
  'cpf_cnpj',
  'cnpj',
  'telefone',
  'created_at',
  'inicio_operacao'
])

const valuesToMaskMethods = {
  cpf_cnpj: (value) => {
    return cnpjCpfFormat(value.toString())
  },
  cnpj: (value) => {
    return cnpjFormat(value.toString())
  },
  telefone: (value) => {
    return phoneFormat(value.toString())
  },
  created_at: (value) => {
    return dateTimeString(value)
  },
  inicio_operacao: (value) => {
    const timeStampIndex = value.indexOf('T')
    const newString = value.slice(0, timeStampIndex)

    const formattedValue = newString.replace(
      /(\d{4})-(\d{2})-(\d{2})/g,
      '$2/$1'
    )

    return formattedValue
  }
}

const transformData = (data) => {
  return data.reduce((acc, curr) => {
    let newResult = {}

    newResult = { ...curr }

    for (const property in newResult) {
      const hasValueToMask = valuesToMask.has(property)

      if (hasValueToMask) {
        const maskedValue = valuesToMaskMethods[property](newResult[property])
        newResult[property] = maskedValue
      }
    }

    const newArray = [...acc, newResult]

    return newArray
  }, [])
}

export { transformData }
