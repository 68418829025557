import styled from 'styled-components'
import { Button } from 'components/Button'

export const Wrapper = styled.section`
    display: flex;
    margin-top: 5px;
    justify-content: space-around;
    align-items:flex-end;
    ${props => props.customTabContainerStyle}
`
export const TabButton = styled(Button)`
    height: 35px;
    margin:0;
    width:auto;
    padding: 0 15px;
    border-radius:0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    ${props => props.customTabButtonStyle}
`
