/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const context = require.context('../../assets/Images/Icons', false, /\.svg$/)

async function importIcon(context, key) {
  const icon = context.keys().find((_key) => _key.includes(key))
  const response = await fetch(context(icon).default).then((res) => res.text())

  return response
}

const SVG = styled.svg`
  & > path {
    ${({ pathStyle }) => ({ ...pathStyle })};
  }
`

export function Svg({ name, style, width, height, pathStyle, ...rest }) {
  const nodeRef = useRef()

  useEffect(() => {
    importIcon(context, name).then((value) => {
      nodeRef.current.innerHTML = value
    })
  }, [])

  return (
    <SVG
      ref={nodeRef}
      width={width}
      height={height}
      style={style}
      pathStyle={pathStyle}
      {...rest}
    />
  )
}

Svg.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  pathStyle: PropTypes.object
}
