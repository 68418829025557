/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useRef, useLayoutEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  ModalContainer,
  ModalContents,
  HeaderWrapper,
  BodyWrapper
} from './style'
import { XIcon } from 'assets/icons'
import { useObserve } from 'hooks/useObserve'
function Header({ children }) {
  return <HeaderWrapper>{children}</HeaderWrapper>
}
function Body({ children }) {
  return <BodyWrapper>{children}</BodyWrapper>
}

function centerModal(height, ref) {
  const windowHeight = window.innerHeight / 2
  const modalHeight = height / 2
  const result = windowHeight - modalHeight

  if (height > 200 && modalHeight <= windowHeight) {
    ref.style.margin = `${result}px auto 0`
  } else {
    ref.style.margin = `10px auto`
  }
}

function Modal({ children, isVisible, onClose, context }) {
  const modalRef = useRef()
  const modalContainerRef = useRef()

  let modal = false
  let setModalVisible = null
  let restContext = {}

  if (context) {
    const { modals: get, setModalVisible: set, ...rest } = context
    modal = get
    setModalVisible = set
    restContext = rest
  }

  const resize = () => {
    const modalHeight = modalRef.current.getBoundingClientRect().height

    if (isVisible || modal.visible) {
      modalRef.current.classList.add('enter')
      centerModal(modalHeight, modalRef.current)
    }
  }

  const close = () => {
    if (onClose instanceof Function || setModalVisible) {
      modalRef.current.classList.remove('enter')
    }
    // modalRef.current.classList.toggle('exit')

    setTimeout(() => {
      // modalRef.current.classList.toggle('exit')

      if (onClose instanceof Function) {
        onClose()
      }

      if (setModalVisible) {
        setModalVisible(modal.id, false)
      }
    }, 350)
  }

  useObserve(
    {
      entries: [modalRef]
    },
    ({ contentRect }, prev) => {
      const prevHeight = Math.ceil(prev.contentRect.height).toFixed(0)
      const currentHeight = Math.ceil(contentRect.height).toFixed(0)

      if (prevHeight !== currentHeight) {
        resize()
      }
    },
    [isVisible, modal.visible]
  )

  useLayoutEffect(() => {
    if (isVisible || modal.visible) {
      resize()
      window.addEventListener('resize', resize)
    }
    return () => {
      if (isVisible || modal.visible) {
        window.removeEventListener('resize', resize)
      }
    }
  }, [isVisible, modal.visible])

  function renderChildren() {
    if (Array.isArray(children)) {
      const __children = children.map((child, index) => {
        if (child.type.displayName === 'Header') {
          return React.cloneElement(
            child,
            { key: index },
            <>
              {child.props.children}
              <XIcon color="var(--text-color-dark)" onClick={close} />
            </>
          )
        }

        if (typeof child.props.children === 'function') {
          return React.cloneElement(
            child,
            { key: index },
            child.props.children({
              close,
              isVisible,
              context: { modal, ...restContext }
            })
          )
        }

        return child
      })

      return __children
    }

    return []
  }

  const render = useCallback(renderChildren, [isVisible, modal.visible])

  return (
    <ModalContainer
      ref={modalContainerRef}
      isVisible={isVisible || modal.visible}
    >
      <ModalContents ref={modalRef}>{render()}</ModalContents>
    </ModalContainer>
  )
}

Modal.propTypes = {
  children: PropTypes.any,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func
}

Header.propTypes = {
  children: PropTypes.any
}

Body.propTypes = {
  children: PropTypes.any
}

Modal.displayName = 'Modal'
Header.displayName = 'Header'

export default Modal
export { Header, Body }
