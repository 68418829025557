import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Main, MainTopBar, MainContent, Footer } from '../../style'

import RestrictedContextMenu from '../RestrictedContextMenu'

import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'

export default function RestrictedMain({ layoutType, children }) {
  const { restrictedLayoutState } = useContext(RestrictedLayoutContext)

  return (
    <Main id="plick-main" layoutType={layoutType}>
      <MainTopBar layoutType={layoutType}>
        {restrictedLayoutState.mainTopBarContent}
      </MainTopBar>

      <MainContent layoutType={layoutType} id="main-content">
        {children}
        <Footer className="nomargin" layoutType={layoutType}>
          <p>{`Plick, ${new Date().getFullYear()} | Todos os direitos reservados`}</p>
        </Footer>
      </MainContent>

      {/* Componente do menu de contexto */}
      <RestrictedContextMenu layoutType={layoutType} />
    </Main>
  )
}

RestrictedMain.propTypes = {
  children: PropTypes.array,
  layoutType: PropTypes.string
}
