import jwt from 'jsonwebtoken'
import history from 'services/history'

function checkJwt() {
  try {
    const token =
      sessionStorage.getItem('token') || localStorage.getItem('token')

    const decode = jwt.decode(token)

    if (decode.id !== 'undefined' && decode.id !== null) {
      return true
    }
    return false
  } catch (error) {
    return false
    // console.error(error)
  }
}

function getUuid() {
  const token = sessionStorage.getItem('token') || localStorage.getItem('token')

  const decode = jwt.decode(token)

  if (window.utils.isFalsy(decode)) {
    window.eventBus.dispatch('logoutUser', { message: true })
  }

  return decode.id
}

function checkIfExpired(token) {
  try {
    const decode = jwt.decode(token)

    if (window.utils.isFalsy(decode)) {
      return true
    }

    const expirationTokenTime = new Date(decode.exp * 1000).toLocaleTimeString(
      'pt-BR',
      { timezone: 'America/Sao_Paulo' }
    )
    const now = new Date().toLocaleTimeString('pt-BR', {
      timezone: 'America/Sao_Paulo'
    })

    return now > expirationTokenTime
  } catch (error) {
    console.error(error)
  }
}

function checkAuthenticated() {
  const token = sessionStorage.getItem('token') || localStorage.getItem('token')

  if (!token) {
    return false
  }
  const currentLocation = window.location.pathname

  const isJwtValid = checkJwt()
  if (isJwtValid) {
    return true
  } else {
    const refreshToken =
      sessionStorage.getItem('refreshToken') ||
      localStorage.getItem('refreshToken')
    if (!refreshToken) {
      return false
    }
    if (currentLocation !== '/') {
      const currentLocation = window.location
      sessionStorage.setItem('lastLocation', currentLocation.pathname)
      history.push('/')
      return false
    }
  }
}

export { checkJwt, getUuid, checkIfExpired, checkAuthenticated }
