import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'tipoMovimento'
const url = '/tipo-movimentos'

const endpointsDescriptions = {
  getAllTiposMovimentos: {
    action: 'getAll'
  },
  getSingleTipoMovimento: {
    action: 'getOne'
  },
  addSingleTipoMovimento: {
    action: 'add'
  },
  updateSingleTipoMovimento: {
    action: 'update'
  },
  deleteSingleTipoMovimento: {
    action: 'delete'
  }
}

const tipoMovimento = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllTiposMovimentos = () => {
  const endpointDescription = tipoMovimento.endpointsConfigs.getAllTiposMovimentosConfig
  return axios.get(
    `${baseUrl}/tipo-movimentos/`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getSingleTipoMovimento = (id) => {
  const endpointDescription = tipoMovimento.endpointsConfigs.getSingleTipoMovimentoConfig
  return axios.get(
    `${baseUrl}/tipo-movimentos/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.addSingleTipoMovimento = (body) => {
  const endpointDescription = tipoMovimento.endpointsConfigs.addSingleTipoMovimentoConfig
  return axios.post(
    `${baseUrl}/tipo-movimentos`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.updateSingleTipoMovimento = (id, body) => {
  const endpointDescription = tipoMovimento.endpointsConfigs.updateSingleTipoMovimentoConfig
  return axios.put(
    `${baseUrl}/tipo-movimentos/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteSingleTipoMovimento = (id) => {
  const endpointDescription = tipoMovimento.endpointsConfigs.deleteSingleTipoMovimentoConfig
  return axios.delete(
    `${baseUrl}/tipo-movimentos/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
