import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

function GroupOfRadioboxesTipoDeTributacao({ name }) {
  return (
    <div>
      <p style={{ width: '50%', color: '#333333' }}>Tipo de Tributação: </p>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridTemplateRows: '1fr 1fr'
        }}
      >
        {[
          'Simples',
          'Lucro Presumido',
          'Lucro Real',
          'MEI',
          'PF',
          'Isenta'
        ].map((singleOption, i) => {
          return (
            <label
              htmlFor="tributacao"
              key={i}
              style={{
                display: 'flex',
                margin: '2px 0 2px 10px',
                color: '#333333'
              }}
            >
              <Field
                type="radio"
                name={name}
                value={singleOption}
                style={{ width: '9%' }}
              />
              <span style={{ width: 'auto', marginLeft: '5px' }}>
                {singleOption}
              </span>
            </label>
          )
        })}
      </div>
    </div>
  )
}

GroupOfRadioboxesTipoDeTributacao.propTypes = {
  name: PropTypes.string
}
export default GroupOfRadioboxesTipoDeTributacao
