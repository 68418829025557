/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import api from 'services/api'
import AutoComplete from 'components/AutoComplete'
function InputSelectorEscritorio({ name, user }) {
  const [
    selectorEscritorioField,
    selectorEscritorioMeta,
    selectorEscritorioHelpers
  ] = useField(name)

  useEffect(() => {
    if (!user.plick) {
      selectorEscritorioHelpers.setValue(user.data.escritorio[0].uuid)
    }
  }, [])

  return (
    <AutoComplete
      name={name}
      oneTimeRequest
      inputProps={{
        disabled: !user.plick,
        style: {
          display: !user.plick && 'none'
        }
      }}
      renderMenuItem={(value, labelProps) => {
        return <span {...labelProps}>{value.nome}</span>
      }}
      filter={(value, searchValue) => {
        return value.nome.match(new RegExp(`${searchValue.trim()}`, 'gi'))
      }}
      startSearch={async () => {
        try {
          const { data } = await api.getAllEscritorio()
          return data
        } catch (e) {
          throw new Error(e)
        }
      }}
      extractKey={(item) => item.uuid}
      createOptions={(data) => {
        if (data instanceof Array) {
          return data.map((item) => ({
            label: item.nome,
            value: item.uuid
          }))
        }
        return {
          label: data.nome,
          value: data.uuid
        }
      }}
    />
  )
}

InputSelectorEscritorio.propTypes = {
  name: PropTypes.string,
  user: PropTypes.object
}

export default InputSelectorEscritorio
