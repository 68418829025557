import React from 'react'
import PropTypes from 'prop-types'
import * as Styled from '../style'
import { transformData } from 'components/Table/logic/utils'

export const VoltarPaginacaoButton = ({
  active,
  perPage,
  setRows,
  setActive,
  apiFetcher,
  listUrl,
  setNumberOfPages,
  paginatorControl
}) => {
  const handleClick = async () => {
    const primeiraPagina = active - 1 <= 0 ? 1 : active - 1
    const data = await paginatorControl(
      primeiraPagina,
      perPage,
      apiFetcher,
      listUrl
    )
    const result = transformData(data.rows || data)
    setRows(result)
    setActive(primeiraPagina)
    setNumberOfPages(data.numberOfPages)
  }

  return (
    <Styled.Button onClick={handleClick}>
      <Styled.IconVoltarPaginacao />
    </Styled.Button>
  )
}

VoltarPaginacaoButton.propTypes = {
  perPage: PropTypes.number,
  setRows: PropTypes.func,
  active: PropTypes.number,
  setActive: PropTypes.func,
  apiFetcher: PropTypes.string,
  listUrl: PropTypes.string,
  setNumberOfPages: PropTypes.func,
  paginatorControl: PropTypes.func
}
