import React from 'react'
import styled from 'styled-components'

export const FlexWrapperUl = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: 10px;
  margin-top: 30px;
  padding-bottom: 20px;

  /* border-top: 2px solid hsla(198, 5%, 50%, 1); */
`

const BasicButton = styled.button`
  height: 30px;
  font-size: 0.9rem;
  margin: 0 5px;
  padding-bottom: 2px;
  border-radius: 5px;
`

export const Button = styled(BasicButton)`
  width: 2rem;
  color: var(--green);
  font-weight: bold;
  background-color: transparent;
  text-decoration: ${(props) => (props.active ? 'underline' : 'none')};
`

export const ButtonPrimeiraUltima = styled(BasicButton)`
  min-width: 90px;
  background-color: var(--white);
  border: var(--green) solid 1px;
  color: var(--green);
`

export const IconAvancarPaginacao = () => {
  return (
    <svg
      width="35"
      height="30"
      viewBox="0 0 35 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="35" height="30" rx="5" fill="var(--green)" />
      <path
        d="M13.938 20.938L15 22L21 16L15 10L13.938 11.062L18.876 16L13.938 20.938Z"
        fill="white"
      />
    </svg>
  )
}

export const IconVoltarPaginacao = () => {
  return (
    <svg
      width="35"
      height="30"
      viewBox="0 0 35 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="35" height="30" rx="5" fill="var(--green)" />
      <path
        d="M21.062 11.062L20 10L14 16L20 22L21.062 20.938L16.124 16L21.062 11.062Z"
        fill="white"
      />
    </svg>
  )
}
