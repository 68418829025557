import { treatNavigation } from './treatNavigation'

export const createConfig = (() => {
  const plickDefaulConfigPlick = {
    home: {
      icon: 'home',
      title: 'Home',
      onClick: () => {
        const items = window.contasPendentItems
        const condition = items && items.size > 0
        treatNavigation(condition, '/dashboard')
      }
    },
    arquivos: {
      title: 'Arquivos',
      onClick: () => {
        const items = window.contasPendentItems
        const condition = items && items.size > 0
        treatNavigation(condition, '/arquivos-originais')
      }
    },
    layouts: {
      icon: 'stack',
      title: 'Fluxos',
      onClick: () => {
        const items = window.contasPendentItems
        const condition = items && items.size > 0
        treatNavigation(condition, '/dataflows')
      }
    },
    demands: {
      icon: 'stack',
      title: 'Suporte',
      onClick: () => {
        const items = window.contasPendentItems
        const condition = items && items.size > 0
        treatNavigation(condition, '/demandas')
      }
      // list: {
      //   fileCardsDemands: {
      //     feature: 'file-cards-demands',
      //     title: 'Arquivos',
      //     accessLevel: [],
      //     onClick: () => {
      //       const items = window.contasPendentItems
      //       const condition = items && items.size > 0
      //       treatNavigation(condition, '/demandas')
      //     }
      //   },
      //   generalSupport: {
      //     feature: 'file-cards-demands',
      //     title: 'Suporte',
      //     accessLevel: [],
      //     onClick: () => {
      //       const items = window.contasPendentItems
      //       const condition = items && items.size > 0
      //       treatNavigation(condition, '/demandas')
      //     }
      //   }
      // }
    },
    // layouts: {
    //   icon: 'stack',
    //   title: 'Layouts',
    //   onClick: 'open-options',
    //   list: {
    //     layoutsEntrada: {
    //       feature: 'input-file-layout',
    //       title: 'Layouts de entrada',
    //       accessLevel: [],
    //       onClick: () => {
    //         history.push('/input-file-layout')
    //       }
    //     },
    //     layoutsSaida: {
    //       feature: 'output-file-layout',
    //       title: 'Layouts de saída',
    //       accessLevel: [],
    //       onClick: () => {
    //         history.push('/output-file-layout')
    //       },
    //     },
    //     dataflows: {
    //       feature: '@@_ALL_@@',
    //       title: 'Dataflows',
    //       accessLevel: ['admin-plick', 'analista-plick'],

    //     }
    //   }
    // },
    ferramentas: {
      icon: 'tool',
      title: 'Ferramentas',
      onClick: 'open-options',
      list: {
        sistemasContabeis: {
          feature: 'sistemas-contabeis',
          title: 'Sistema contábeis',
          accessLevel: [],
          onClick: () => {
            const items = window.contasPendentItems
            const condition = items && items.size > 0
            treatNavigation(condition, '/sistemas-contabeis')
          }
        },
        sistemasGestao: {
          feature: 'sistema-gestao',
          title: 'Sistema de gestão',
          accessLevel: [],
          onClick: () => {
            const items = window.contasPendentItems
            const condition = items && items.size > 0
            treatNavigation(condition, '/sistema-gestao')
          }
        }
      }
    },
    cadastros: {
      // icon: 'addSquare',
      title: 'Cadastros Básicos',
      onClick: 'open-options',
      list: {
        cargos: {
          feature: 'roles',
          title: 'Cargos',
          accessLevel: ['admin-plick', 'analista-plick'],
          onClick: () => {
            const items = window.contasPendentItems
            const condition = items && items.size > 0
            treatNavigation(condition, '/roles')
          }
        },
        usuarios: {
          feature: 'usuarios',
          title: 'Usuários',
          accessLevel: [],
          onClick: () => {
            const items = window.contasPendentItems
            const condition = items && items.size > 0
            treatNavigation(condition, '/usuarios')
          }
        },
        empresas: {
          feature: 'empresas',
          title: 'Empresas',
          accessLevel: [],
          onClick: () => {
            const items = window.contasPendentItems
            const condition = items && items.size > 0
            treatNavigation(condition, '/empresas')
          }
        },
        escritorios: {
          feature: 'escritorios',
          title: 'Escritorios',
          accessLevel: ['admin-plick', 'analista-plick'],
          onClick: () => {
            const items = window.contasPendentItems
            const condition = items && items.size > 0
            treatNavigation(condition, '/escritorios')
          }
        }
        // contatos: {
        //   feature: 'contatos',
        //   title: 'Contatos',
        //   accessLevel: [],
        //   onClick: () => {
        //     history.push('/contatos')
        //   }
        // }
      }
    },
    monday: {
      onClick: () => {
        window.open(
          'https://plicksolucoes.monday.com/',
          '__blank',
          'noopener noreferrer'
        )
      },
      title: 'Monday'
    }
    // notificacoes: {
    //   icon: 'notification',
    //   title: 'Notificações',
    //   onClick: 'open-options'
    // },
    // configuracoes: {
    //   icon: 'config',
    //   title: 'Configurações',
    //   onClick: null
    // }
  }
  const plickDefaulConfigEscritorio = {
    home: {
      icon: 'home',
      title: 'Home',
      onClick: () => {
        const items = window.contasPendentItems
        const condition = items && items.size > 0
        treatNavigation(condition, '/dashboard')
      }
    },
    arquivos: {
      icon: 'archives',
      title: 'Arquivos',
      onClick: () => {
        const items = window.contasPendentItems
        const condition = items && items.size > 0
        treatNavigation(condition, '/arquivos-originais')
      }
    },
    demands: {
      icon: 'stack',
      title: 'Demands',
      onClick: () => {
        const items = window.contasPendentItems
        const condition = items && items.size > 0
        treatNavigation(condition, '/demandas')
      }
    },
    cadastros: {
      icon: 'addSquare',
      title: 'Cadastros Básicos',
      onClick: 'open-options',
      list: {
        cargos: {
          feature: 'roles',
          title: 'Cargos',
          accessLevel: ['admin-plick', 'analista-plick'],
          onClick: () => {
            const items = window.contasPendentItems
            const condition = items && items.size > 0
            treatNavigation(condition, '/roles')
          }
        },
        usuarios: {
          feature: 'usuarios',
          title: 'Usuários',
          accessLevel: [],
          onClick: () => {
            const items = window.contasPendentItems
            const condition = items && items.size > 0
            treatNavigation(condition, '/usuarios')
          }
        },
        empresas: {
          feature: 'empresas',
          title: 'Empresas',
          accessLevel: [],
          onClick: () => {
            const items = window.contasPendentItems
            const condition = items && items.size > 0
            treatNavigation(condition, '/empresas')
          }
        },
        escritorios: {
          feature: 'escritorios',
          title: 'Escritorios',
          accessLevel: ['admin-plick', 'analista-plick'],
          onClick: () => {
            const items = window.contasPendentItems
            const condition = items && items.size > 0
            treatNavigation(condition, '/escritorios')
          }
        }
        // contatos: {
        //   feature: 'contatos',
        //   title: 'Contatos',
        //   accessLevel: [],
        //   onClick: () => {
        //     history.push('/contatos')
        //   }
        // }
      }
    }
    // notificacoes: {
    //   icon: 'notification',
    //   title: 'Notificações',
    //   onClick: 'open-options'
    // },
    // configuracoes: {
    //   icon: 'config',
    //   title: 'Configurações',
    //   onClick: null
    // }
  }
  const empresaUserConfig = {
    cadastros: {
      icon: 'addSquare',
      title: 'Cadastros Básicos',
      onClick: 'open-options',
      list: {
        empresas: {
          feature: 'empresas',
          title: 'Empresas',
          accessLevel: [],
          onClick: () => {
            const items = window.contasPendentItems
            const condition = items && items.size > 0
            treatNavigation(condition, '/empresas')
          }
        }
      }
    }
  }

  const handleUserConfig = (role, config) => {
    if (
      role.nome === 'admin-escritorio' ||
      role.nome === 'analista-escritorio'
    ) {
      return plickDefaulConfigEscritorio || config
    }
    if (role.nome === 'empresa') {
      return empresaUserConfig || config
    }

    return plickDefaulConfigPlick || config
  }

  // Aqui da pra manter um valor default caso não seja passada uma configuracao externa pra sidebar, pois futuramente há uma possibilidade de arrumar o código pra ser mais flexivel com outros cenários
  return ({
    config = {
      plickDefaulConfigPlick,
      plickDefaulConfigEscritorio,
      empresaUserConfig
    },
    claims,
    role
  }) => {
    const config__ = handleUserConfig(role, config)

    const values = Object.entries(config__)
    const valuesFiltered = values.filter(([_, value]) => value.list)

    const mappedValues = new Map(valuesFiltered)

    mappedValues.forEach((value, key) => {
      const lists = Object.values(value.list)

      const res = lists.filter((item) => {
        if (item.feature === '@@_ALL_@@') return true

        const feature = claims.find(
          (claim) => claim.feature.feature === item.feature
        )

        if (!feature) return false

        return feature.valor === 'manage' || feature.valor === 'read'
      })

      mappedValues.set(key, res)
    })

    const configMap = new Map(Object.entries(config__))

    mappedValues.forEach((value, key) => {
      const item = configMap.get(key)

      item.list = value
    })

    return config__
  }
})()
