import { useEffect, useContext } from 'react'
import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'
import { TableContext } from 'context/TableContext/TableContext'
// import React, { useEffect, useContext } from 'react'
// import { RestrictedLayoutContext } from 'Context/RestrictedLayoutContext/RestrictedLayoutContext'
// import { Table } from 'components/Table'
// import { TextoCell } from 'components/Table/subcomponents/TextoCell'
// import { AcoesCell } from 'components/Table/subcomponents/AcoesCell'
// import { AtivoCell } from 'components/Table/subcomponents/AtivoCell'

export default function Roles() {
  const featureName = 'roles'
  const { tableHooks } = useContext(TableContext)
  const { dispatchRestrictedLayout } = useContext(RestrictedLayoutContext)
  useEffect(() => {
    dispatchRestrictedLayout({ type: 'setHeaderData', payload: 'Cargos' })
  }, [])

  const headers = ['Nome', 'Ações']
  const headersConfig = {
    escritorio: headers,
    plick: headers
  }
  const actionPermissions = {
    manage: ['roles'],
    read: ['roles']
  }
  const cellComponents = {
    nome: 'TextoCell',
    email: 'TextoCell',
    telefone: 'TextoCell',
    ativo: 'AtivoCell',
    ações: 'AcoesCell'
  }
  const crudFields = []

  tableHooks.useReset()
  tableHooks.useHeaders(featureName, headersConfig)
  tableHooks.useTopBar(featureName, [])
  tableHooks.useActions(featureName, actionPermissions)
  tableHooks.useCellComponents(cellComponents)
  tableHooks.useCrudFields(crudFields)

  const { Table } = tableHooks.useTable('Roles')

  return Table

  // return (
  //   <Table
  //     dispatchRestrictedLayout={dispatchRestrictedLayout}
  //     topBarItems={[]}
  //     apiFetcher={'Roles'}
  //     headers={['Nome', 'Ações']}
  //     actions={['roles']}
  //     cellComponents={{
  //       nome: TextoCell,
  //       email: TextoCell,
  //       telefone: TextoCell,
  //       ativo: AtivoCell,
  //       ações: AcoesCell
  //     }}
  //   />
  // )
  // return <></>
}
