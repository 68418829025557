import React, { useState, useContext, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { LoginContext } from 'services/Auth/AuthContext'
import { Field } from 'formik'
import { WrapperSelectorInline } from '../style'

function InputSelectorInline({
  options,
  name,
  defaultLabel,
  onlyPlick = false
}) {
  const { authStates } = useContext(LoginContext)
  const [isDisabled, setIsDisabled] = useState(false)

  useLayoutEffect(() => {
    if (onlyPlick) {
      if (authStates.loggedUserData?.role?.nome.search('plick') === -1) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    }
  }, [authStates.loggedUserData?.role])

  return (
    <WrapperSelectorInline>
      <label htmlFor={name}>{defaultLabel}</label>
      <Field name={name}>
        {({ field }) => {
          return (
            <select disabled={isDisabled} {...field}>
              <option value="" hidden disabled>
                ...
              </option>
              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )
        }}
      </Field>
    </WrapperSelectorInline>
  )
}

InputSelectorInline.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  defaultLabel: PropTypes.string,
  onlyPlick: PropTypes.bool
}
export default InputSelectorInline
