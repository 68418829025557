/* eslint-disable camelcase */
import api from 'services/api'

async function getAllRegrasSanitizacao (setSanitizationRules) {
  try {
    const { status, data } = await api.getAllRegrasSanitizacao()
    if (status === 200) {
      setSanitizationRules(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function handleAddRegraSanitizacao (
  nome,
  tipo,
  regexMatch,
  regexExtract,
  setSanitizationRules
) {
  try {
    tipo = getSanitizationRulesTypes().find(sanitizationRuleType => sanitizationRuleType.id === parseInt(tipo)).nome

    const { status, data } = await api.createRegraSanitizacao({
      nome: nome,
      type: tipo,
      regex_match: regexMatch,
      regex_extract: regexExtract
    })

    if (status === 201) {
      setSanitizationRules((oldRules) => [...oldRules, data])
    }

    return window.utils.checkIfValidData(data)
  } catch (error) {
    if (error.response.status === 401) {
      //   setHaveError(true)
      console.error(error)
    }
  }
}

function getSanitizationRulesTypes () {
  return [
    {
      id: 1,
      nome: 'vazio'
    },
    {
      id: 2,
      nome: 'numérico'
    },
    {
      id: 3,
      nome: 'alfabético'
    },
    {
      id: 4,
      nome: 'custom'
    },
    {
      id: 5,
      nome: 'datas'
    }
  ]
}

export {
  getAllRegrasSanitizacao,
  handleAddRegraSanitizacao,
  getSanitizationRulesTypes
}
