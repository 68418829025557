import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form as FormikForm } from 'formik'
import { FormDefault } from './style'

export function Form({
  children,
  initialValues,
  handleSubmit,
  schemaValidate,
  reset = true,
  customStyle = ''
}) {
  return (
    <FormDefault customStyle={customStyle}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schemaValidate}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values)
          setSubmitting(false)
          if (reset) {
            resetForm()
          }
        }}
      >
        <FormikForm>{children}</FormikForm>
      </Formik>
    </FormDefault>
  )
}

Form.propTypes = {
  children: PropTypes.any.isRequired,
  initialValues: PropTypes.object,
  schemaValidate: PropTypes.object,
  handleSubmit: PropTypes.func,
  reset: PropTypes.bool,
  customStyle: PropTypes.string
}
