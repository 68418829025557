/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import AddButton from 'components/Table/subcomponents/AddButton'
import SearchBar from 'components/SearchBar'
import CsvButton from './components/CsvButton'

export default function MainTopBarContent({
  items,
  setModalVisible,
  apiFetcher,
  listUrl,
  setLoading,
  setActive,
  setRows,
  setNumberOfPages,
  setPaginatorControl,
  modalConfig
}) {
  let exportSource = ''
  let hasExportCsv = false

  items.forEach((item) => {
    if (item.match(/export_csv/g)) {
      exportSource = item.replace(/.+csv_/, '')
      hasExportCsv = true
    }
  })

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      }}
    >
      {items.indexOf('add') !== -1 && (
        <AddButton
          apiFetcher={apiFetcher}
          modalConfig={modalConfig}
          setModalVisible={setModalVisible}
        />
      )}
      {hasExportCsv && (
        <CsvButton source={exportSource} setLoading={setLoading} />
      )}
      {items.indexOf('search') !== -1 ? (
        <SearchBar
          listUrl={listUrl}
          apiFetcher={apiFetcher}
          setActive={setActive}
          setRows={setRows}
          setPaginatorControl={setPaginatorControl}
          setNumberOfPages={setNumberOfPages}
        />
      ) : (
        <div></div>
      )}
    </div>
  )
}

MainTopBarContent.propTypes = {
  items: PropTypes.array,
  setModalContent: PropTypes.func,
  setModalVisible: PropTypes.func,
  setShowModal: PropTypes.func,
  setCrudTimeStamp: PropTypes.func,
  listUrl: PropTypes.string,
  apiFetcher: PropTypes.string,
  headers: PropTypes.array,
  setLoading: PropTypes.func,
  crudFields: PropTypes.array,
  perPage: PropTypes.number,
  setActive: PropTypes.func,
  setNumberOfPages: PropTypes.func,
  setRows: PropTypes.func,
  setPaginatorControl: PropTypes.func,
  numberOfPages: PropTypes.number,
  modalConfig: PropTypes.object
}
