import api from 'services/api'
import history from 'services/history'

export const paginationFetcher = async (page, perPage, apiFetcher, listUrl) => {
  try {
    let response

    if (listUrl === apiFetcher) {
      response = await api[`paginate${listUrl}`](page, perPage)
    } else {
      const feature = history.location.pathname.replace(/\//g, '')
      const filter = JSON.stringify({
        escritorioId: history.location?.state?.id
      })
      response = await api.filteredPaginated(feature, filter)
    }

    if (response?.status === 200) {
      return response.data
    }
  } catch (error) {
    console.error(error)
  }
}
