import styled from 'styled-components'

export const Container = styled.div`
  width: 50%;
  min-width: 250px;

  form {
      width: 100%;
  }
`
