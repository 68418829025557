import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'cellValidationRules'
const url = '/cell-validation-rules'

const endpointsDescriptions = {
  getCellValidationRules: {
    action: 'getOne'
  },
  createCellValidationRule: {
    action: 'add'
  },
  deleteCellValidationRule: {
    action: 'delete'
  }
}

const cellValidationRules = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getCellValidationRules = (id) => {
  const endpointDescription = cellValidationRules.endpointsConfigs.getCellValidationRulesConfig
  return axios.get(
    `${baseUrl}/cell-validation-rules/cell/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createCellValidationRule = (body) => {
  const endpointDescription = cellValidationRules.endpointsConfigs.createCellValidationRuleConfig
  return axios.post(
    `${baseUrl}/cell-validation-rules`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteCellValidationRule = (id) => {
  const endpointDescription = cellValidationRules.endpointsConfigs.deleteCellValidationRuleConfig
  return axios.delete(
    `${baseUrl}/cell-validation-rules/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
