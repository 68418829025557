/* eslint-disable camelcase */
import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL
const ITEMSPORPAGINA = parseInt(process.env.REACT_APP_PER_PAGE)

const domain = 'fileCards'
const url = '/file-cards'

const endpointsDescriptions = {
  getAllFileCards: {
    action: 'getAll'
  },
  getFileCardById: {
    action: 'getOne'
  },
  getAllFileCardsFromCompany: {
    action: 'getBy',
    extraParams: ['da Empresa']
  },
  getAllFileCardsFromCompanyByPeriod: {
    action: 'getBy',
    extraParams: ['da Empresa por período']
  },
  addFileCard: {
    action: 'add'
  },
  editFileCard: {
    action: 'update'
  },
  deleteFileCard: {
    action: 'delete'
  },
  fileCardAddNotificationUsers: {
    action: 'add'
  },
  getDashboardArquivosList: {
    action: 'getPaginated'
  },
  getPieChartReport: {
    action: 'getReports'
  },
  getCsvReport: {
    action: 'getReports'
  }
}

const fileCards = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllFileCards = () => {
  const endpointDescription = fileCards.endpointsConfigs.getAllFileCardsConfig

  return axios.get(`${baseUrl}/file-cards`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getFileCardById = (id) => {
  const endpointDescription = fileCards.endpointsConfigs.getFileCardByIdConfig

  return axios.get(`${baseUrl}/file-cards/${id}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getAllFileCardsFromCompany = (empresaId) => {
  const endpointDescription =
    fileCards.endpointsConfigs.getAllFileCardsFromCompanyConfig

  return axios.get(`${baseUrl}/file-cards/empresa/${empresaId}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getAllFileCardsFromCompanyByPeriod = (empresaId, timeReference) => {
  const endpointDescription =
    fileCards.endpointsConfigs.getAllFileCardsFromCompanyByPeriodConfig
  endpointDescription.options.activateLoader = true

  return axios.get(
    `${baseUrl}/file-cards/empresa-month/${empresaId}/${timeReference}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.addFileCard = function (body) {
  const endpointDescription = fileCards.endpointsConfigs.addFileCardConfig

  return axios.post(`${baseUrl}/file-cards`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.editFileCard = (id, body) => {
  const endpointDescription = fileCards.endpointsConfigs.editFileCardConfig

  return axios.patch(`${baseUrl}/file-cards/${id}`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.deleteFileCard = (id, query) => {
  const endpointDescription = fileCards.endpointsConfigs.deleteFileCardConfig
  endpointDescription.options.confirmOnDelete = false
  return axios.delete(`${baseUrl}/file-cards/${id}${query}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.fileCardAddNotificationUsers = (body) => {
  const endpointDescription =
    fileCards.endpointsConfigs.fileCardAddNotificationUsersConfig
  return axios.post(`${baseUrl}/file-cards/notifications/usuarios`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.getDashboardArquivosList = ({
  page = 1,
  per_page = ITEMSPORPAGINA,
  ...rest /* rest =  {status, escritorio_id, empresa_id} */
}) => {
  const body = {
    page,
    per_page,
    ...rest
  }
  const endpointDescription =
    fileCards.endpointsConfigs.getDashboardArquivosListConfig
  return axios.post(`${baseUrl}/file-cards/dashboard/list`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.getPieChartReportFileCards = (body) => {
  const endpointDescription = fileCards.endpointsConfigs.getPieChartReportConfig
  return axios.post(`${baseUrl}/file-cards/reports/pieChart`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.getCsvReportFileCards = (body) => {
  const endpointDescription = fileCards.endpointsConfigs.getCsvReportConfig
  return axios.post(`${baseUrl}/file-cards/reports/download/csv`, body, {
    endpointInfo: endpointDescription
  })
}

export default endpoints
