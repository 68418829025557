import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as logic from '../../layoutSaidaLogic'
import { Form } from 'components/Form'
import { InputComponent } from 'components/InputComponent'
import * as Yup from 'yup'
import FILETYPES from 'helpers/lists/fileTypes'

import { CheckBoxWrapper, Button } from '../../subPages/style'

import { LayoutFormContainer } from './style'

export function LayoutForm({
  layoutInitialValues,
  setOutputFileLayouts,
  sistemasContabil
}) {
  useEffect(async () => {
    if (layoutInitialValues.sistema_contabil_id === '') {
      if (sistemasContabil.length > 0) {
        layoutInitialValues.sistema_contabil_id = sistemasContabil[0].id
      }
    }
  }, [sistemasContabil])

  const handleSubmitOutputLayout = (values) => {
    if (window.utils.isFalsy(values.id)) {
      logic.createOutputLayout(values, setOutputFileLayouts)
    } else {
      logic.editOutputLayout(values.id, values, setOutputFileLayouts)
    }
  }

  const schemaValidateOutputLayout = Yup.object().shape({
    nome: Yup.string()
      .required('Nome não pode ser vazio')
      .min(2, 'Nome precisa ter ao menos 2 caracteres'),
    nome_arquivo: Yup.string().required('Nome do arquivo não pode ser vazio'),
    start_row: Yup.number()
      .optional()
      .min(0, 'Deve ser maior ou igual a zero')
      .integer('Deve ser numérico')
  })

  return (
    <>
      <LayoutFormContainer>
        <header>
          <h3>
            {layoutInitialValues.update
              ? 'Editar Layout de Saida'
              : 'Cadastrar novo Layout de Saida'}
          </h3>
        </header>
        <Form
          handleSubmit={handleSubmitOutputLayout}
          initialValues={layoutInitialValues}
          schemaValidate={schemaValidateOutputLayout}
          reset={!layoutInitialValues.update}
        >
          <InputComponent label="Nome:" type="text" name="nome" />
          <InputComponent
            label="Nome do Arquivo:"
            type="text"
            name="nome_arquivo"
          />
          <InputComponent
            label="Linha de Início:"
            type="number"
            name="start_row"
            min={1}
          />
          <InputComponent
            label="Extensão do arquivo:"
            type="select"
            name="file_type"
          >
            {FILETYPES.map((fileType, index) => {
              return (
                <option key={index} value={fileType}>
                  {fileType}
                </option>
              )
            })}
          </InputComponent>

          <InputComponent
            label="Sistema Contabil:"
            type="select"
            name="sistema_contabil_id"
          >
            {sistemasContabil.length > 0
              ? sistemasContabil.map((sistemaContabil, index) => {
                  return (
                    <option key={index} value={sistemaContabil.id}>
                      {sistemaContabil.nome}
                    </option>
                  )
                })
              : ''}
          </InputComponent>
          <InputComponent
            label="Caractere delimitador:"
            type="text"
            name="delimiter_char"
          />
          <CheckBoxWrapper>
            <InputComponent
              label="Layout Padrão:"
              type="checkbox"
              name="default"
            />
          </CheckBoxWrapper>
          <Button type="submit">
            {!layoutInitialValues.update ? 'Criar' : 'Editar'}
          </Button>
        </Form>
      </LayoutFormContainer>
    </>
  )
}

LayoutForm.propTypes = {
  layoutInitialValues: PropTypes.object,
  setOutputFileLayouts: PropTypes.func,
  sistemasContabil: PropTypes.array
}
