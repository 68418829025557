import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import * as logic from './linhaContainerLogic'

import {
  LinhaContainerMain,
  LinhaContainerHeader,
  ColumnsContainer,
  ColumnsContainerHeader,
  ColumnsContainerCell
} from './style'

import { EditIcon, DeleteIcon } from 'assets/icons'

export function LinhaContainer({
  row,
  handleShowUpdateLine,
  handleDeleteLine,
  columns,
  handleShowColunaForm
}) {
  let initialChar = ''
  if (window.utils.isFalsy(columns)) {
    columns = []
  }

  const columnsForThisRow = columns.filter(
    (column) => column.file_row_id === row.id
  )
  const numberOfTableColumns = Math.ceil(
    columnsForThisRow.length / row.row_lines
  )

  const Collumns = useMemo(
    () =>
      columns.length > 0 ? (
        <>
          {columnsForThisRow.map((column, columnIndex) => {
            // Colore as linhas da tabela
            if (Math.floor(columnIndex / numberOfTableColumns) % 2) {
              // Caso anterior ao fix acima quando achei que o campo line representaria uma tabela cheia
              // if (!(column.line % 2)) {
              return (
                <ColumnsContainerCell isEven={true} key={columnIndex}>
                  <button onClick={() => handleShowColunaForm(column)}>
                    <EditIcon color="#3FA1D8" />
                  </button>
                </ColumnsContainerCell>
              )
            }
            return (
              <ColumnsContainerCell isEven={false} key={columnIndex}>
                <button onClick={() => handleShowColunaForm(column)}>
                  <EditIcon color="#3FA1D8" />
                </button>
              </ColumnsContainerCell>
            )
          })}
          {
            // descobrir o numero de celulas que faltam caso não preencham toda a tabela e implementar celulas disabled
            row.row_lines * numberOfTableColumns - columnsForThisRow.length > 0
              ? [
                  ...Array(
                    row.row_lines * numberOfTableColumns -
                      columnsForThisRow.length
                  )
                ].map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        backgroundColor: 'hsl(205,25%,35%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        color: 'var(--white)',
                        fontSize: '1.4em',
                        cursor: 'not-allowed'
                      }}
                    >
                      Vazio
                    </div>
                  )
                })
              : ''
          }
        </>
      ) : (
        ''
      ),
    [columns]
  )

  return (
    <LinhaContainerMain>
      <LinhaContainerHeader>
        <h3>{row.nome}</h3>
        <button
          onClick={() =>
            handleShowUpdateLine(
              row,
              columns.filter((column) => column.file_row_id === row.id),
              row.id
            )
          }
        >
          <EditIcon color="#7A8286" /> Editar
        </button>
        <button onClick={() => handleDeleteLine(row.id)}>
          <DeleteIcon color="#7A8286" /> Excluir
        </button>
      </LinhaContainerHeader>
      <ColumnsContainer
        columns={columns.length > 0 ? Math.ceil(numberOfTableColumns) : 1}
      >
        {columns.length > 0
          ? [...Array(numberOfTableColumns)].map((item, columnHeaderIndex) => {
              initialChar = logic.incrementChar(initialChar)
              return (
                <ColumnsContainerHeader key={`header_${columnHeaderIndex}`}>
                  {' '}
                  {initialChar}{' '}
                </ColumnsContainerHeader>
              )
            })
          : ''}

        {Collumns}
      </ColumnsContainer>
    </LinhaContainerMain>
  )
}

LinhaContainer.propTypes = {
  row: PropTypes.object,
  handleShowUpdateLine: PropTypes.func,
  handleDeleteLine: PropTypes.func,
  columns: PropTypes.array,
  handleShowColunaForm: PropTypes.func
}
