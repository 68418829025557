import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'columnValidationRules'
const url = '/column-validation-rules'

const endpointsDescriptions = {
  getColumnValidationRules: {
    action: 'getOne'
  },
  createColumnValidationRule: {
    action: 'add'
  },
  deleteColumnValidationRule: {
    action: 'delete'
  }
}

const columnValidationRules = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getColumnValidationRules = (id) => {
  const endpointDescription = columnValidationRules.endpointsConfigs.getColumnValidationRulesConfig
  return axios.get(
    `${baseUrl}/column-validation-rules/column/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createColumnValidationRule = (body) => {
  const endpointDescription = columnValidationRules.endpointsConfigs.createColumnValidationRuleConfig
  return axios.post(
    `${baseUrl}/column-validation-rules`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteColumnValidationRule = (id) => {
  const endpointDescription = columnValidationRules.endpointsConfigs.deleteColumnValidationRuleConfig
  return axios.delete(
    `${baseUrl}/column-validation-rules/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
