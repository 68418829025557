import React from 'react'
import PropTypes from 'prop-types'
import { InputComponent } from 'components/InputComponent'

function PasswordField ({ name, label }) {
  return (
    <>
      <InputComponent
        label={label}
        type='password'
        name={name}
        smallFont
      />
    </>
  )
}

PasswordField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
}

export default PasswordField
