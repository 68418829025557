/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react'
import { LoginContext } from 'services/Auth/AuthContext'
import { createConfig } from './createConfig'
import { Sidebar } from '@plick-solucoes-contabeis/ui-components'

import defaultProfileImage from 'assets/Images/user.png'
import history from 'services/history'

// Solução temporária, o ideal é deixar um gerenciador de estado renderizar
// export let renderSidebarImageProfile

export default function SidebarWrapper() {
  const { authStates, handleLogout } = useContext(LoginContext)
  const [uploadedImage, setUploadedImage] = useState(false)

  const {
    email,
    nome,
    role,
    claims,
    profile_img: profileImg
  } = authStates.loggedUserData

  const config = createConfig({
    claims,
    role
  })

  const renderImage = () => {
    if (uploadedImage === null) {
      return defaultProfileImage
    }

    return uploadedImage || profileImg || defaultProfileImage
  }

  window.renderSidebarImageProfile = (url) => {
    setUploadedImage(url)
  }

  config.user = {
    imageSource: renderImage(),
    onClick: 'open-options',
    title: 'Usuário',
    list: {
      minhaConta: {
        feature: '@@_ALL_@@',
        title: 'Minha Conta',
        accessLevel: [],
        onClick: () => {
          history.push('/minha-conta')
        }
      }
    }
  }

  if (role.nome.includes('escritorio')) {
    config.user.list.meuEscritorio = {
      feature: '@@_ALL_@@',
      title: 'Meu escritório',
      accessLevel: [],
      onClick: () => {
        const { escritorio } = authStates.loggedUserData
        history.push(`/escritorios?office=${escritorio[0].uuid}`)
      }
    }
  }


  return (
    <Sidebar
      config={config}
      user={{
        role: role.nome,
        email,
        name: nome,
        logout: handleLogout
      }}
    />
  )
}
