import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'validationRules'
const url = '/validation-rules'

const endpointsDescriptions = {
  getAllRegrasValidacao: {
    action: 'getAll'
  }
}

const validationRules = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllRegrasValidacao = () => {
  const endpointDescription = validationRules.endpointsConfigs.getAllRegrasValidacaoConfig
  return axios.get(
    `${baseUrl}/validation-rules`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
