import styled from 'styled-components'

export const HeaderContainerCell = styled.div`
  background-color: var(--white);
  height: 60px;
  display: flex;
  gap: 13px;
  align-items: center;
  justify-content: center;

  :hover > button {
    display: grid;
  }
  :hover > svg {
    display: none;
  }

  button > svg {
    width: 20px;
    height: 20px;
  }
  button {
    display: none;
    place-items: center;
    background-color: #c6e6ff;
    border: 2px solid #3fa1d8;
    padding: 5px 10px;
    border-radius: 8px;
  }
  button:hover {
    background-color: #d4f4e7;
    border-color: var(--green);
  }
  button:hover > svg path {
    fill: var(--green);
  }
  button:nth-child(3):hover {
    background-color: hsl(14, 78%, 80%);
    border-color: hsl(14, 78%, 40%);
  }
  button:nth-child(3):hover > svg path {
    fill: hsl(14, 78%, 40%);
  }
`
export const HeaderPositionBackground = styled.svg`
  width: 100%;
  height: 100%;

  > text {
    font-size: 1.4rem;
    fill: #3fa1d8;
    fill: ${(props) => (props.hasContent ? 'var(--green)' : '#3FA1D8')};
    opacity: ${(props) => (props.hasContent ? '0.6' : '0.3')};
  }
`
