import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'components/Button'
import { features } from 'helpers/fakeApiEndpoint'
import RolePermissionLevelOptions from 'components/FormCRUD/subComponents/RolePermissionLevelOptions'
import { claims } from 'helpers/claims'
import { Tooltip } from '@plick-solucoes-contabeis/ui-components'

function RolesPermissoesButton(props) {
  const ModalPermissoes = ({ id }) => {
    return (
      <div
        style={{
          maxHeight: '80vh'
        }}
      >
        {features.map((singleFeature, index) => {
          let permissionLevel = 'none'
          for (const singleClaim of claims) {
            if (
              singleClaim.role_id === id &&
              singleClaim.feature_id === singleFeature.id
            ) {
              permissionLevel = singleClaim.valor
            }
          }
          return (
            <TableRow key={index} zebra={index}>
              <Botton bgcolor="#4070A0" color="#FFEEDD" className={'botton'}>
                {singleFeature.humanName}
              </Botton>
              <RolePermissionLevelOptions permissionLevel={permissionLevel} />
            </TableRow>
          )
        })}
      </div>
    )
  }
  ModalPermissoes.propTypes = {
    id: PropTypes.string
  }

  const handleClick = ({ rowValues, modalConfig, setModalVisible }) => {
    modalConfig.current = {
      title: `Permissões de ${rowValues.nome}`,
      externalComp: true,
      comp: <ModalPermissoes id={rowValues.id} />
    }
    setModalVisible(true)
  }

  return (
    <Tooltip title="Funções" delay={900}>
      <Button
        buttonFunction={'ButtonTable'}
        buttonIcon={'IconePermissoesDeUsuario'}
        colorText={'var(--background-medium-blue)'}
        onClick={() => handleClick(props)}
      />
    </Tooltip>
  )
}

const Botton = styled.p`
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  border-radius: 7px;
  width: 150px;
  font-size: 12px;
  padding: 5px;
  text-align: center;
  transform: translate(-10px);
`

const TableRow = styled.div`
  background-color: ${(props) =>
    props.zebra % 2 === 0 ? 'rgb(243, 243, 243)' : 'var(--white)'};
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  height: 50px;
  padding: 0 10px 0 30px;
`

RolesPermissoesButton.propTypes = {
  setShowModal: PropTypes.func,
  setModalContent: PropTypes.func,
  rowValues: PropTypes.object
}
export default RolesPermissoesButton
