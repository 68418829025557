/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, useFormikContext } from 'formik'

function PasswordValidator({ name, label }) {
  return <PasswordValidatorField name={name} label={label} />
}

function PasswordValidatorField({ name, label }) {
  //   const [errorMessage, setErrorMessage] = useState(false)

  const {
    values: { senha, senha_validacao },
    touched,
    status,
    setStatus
  } = useFormikContext()

  useEffect(() => {
    if (touched.senha_validacao) {
      if (senha_validacao !== senha) {
        setStatus({ senha_validacao: '*Senha difere do valor anterior.' })
      } else {
        setStatus()
      }
    }
  }, [senha, senha_validacao, touched.senha_validacao])
  return (
    <>
      <label
        htmlFor={name}
        style={{ display: 'flex', margin: '10px 0', fontSize: '1rem' }}
      >
        <span style={{ width: '50%', color: 'var(--text-color-dark)' }}>
          {label}
        </span>
      </label>
      <Field
        name={name}
        type="password"
        style={{
          marginLeft: '10px',
          width: '100%',
          borderRadius: '3px',
          border: '0',
          borderBottom: '1px solid rgb(118, 118, 118)',
          height: '1.7rem',
          backgroundColor: 'var(--white)'
        }}
      ></Field>
      {status?.senha_validacao ? (
        <div
          style={{ color: '#FF0000', marginTop: '5px', fontSize: '0.88rem' }}
        >
          *Senha difere do valor anterior.
        </div>
      ) : (
        ''
      )}
    </>
  )
}

PasswordValidatorField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
}

PasswordValidator.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
}

export default PasswordValidator
