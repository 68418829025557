/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react'
import { Field, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import api from 'services/api'
import { LoginContext } from 'services/Auth/AuthContext'

// import { InputSelectContainer } from '../style'

function InputSelectorEscritorioId({ name }) {
  const { authStates } = useContext(LoginContext)
  const [roles, setRoles] = useState([])

  const [isEscritorioSelected, setIsEscritorioSelected] = useState(false)
  const [isUserPlick, setIsUserPlick] = useState(false)
  const [escritorioIDs, setEscritorioIDs] = useState([])

  const {
    values: { role_id },
    setFieldValue
  } = useFormikContext()

  useEffect(async () => {
    const { data, status } = await api.getAllRoles()
    if (status === 200) {
      setRoles(data)
    }
  }, [])

  useEffect(() => {
    if (roles.length > 0) {
      if (role_id) {
        const selectedRole = roles.filter(
          (role) => parseInt(role.id) === parseInt(role_id)
        )
        if (selectedRole[0].nome.includes('escritorio')) {
          setIsEscritorioSelected(true)
        } else {
          setIsEscritorioSelected(false)
        }
      }
    }
  }, [role_id, roles])

  useEffect(async () => {
    if (authStates.loggedUserData?.role?.nome.search('plick') !== -1) {
      const resposta = await api.getAllEscritorio()
      setEscritorioIDs(resposta.data)
    } else {
      setFieldValue(
        'escritorio_id',
        authStates.loggedUserData?.escritorio[0]?.uuid
      )
    }
  }, [authStates.loggedUserData])

  if (isEscritorioSelected) {
    if (authStates.loggedUserData?.role?.nome.search('plick') !== -1) {
      return (
        <label htmlFor={name} style={{ display: 'flex', margin: '10px 0' }}>
          <span style={{ width: '50%', color: 'var(--text-color-dark)' }}>
            Escritorio:
          </span>
          <Field name={name}>
            {({ field }) => {
              return (
                <select
                  {...field}
                  style={{
                    marginLeft: '10px',
                    width: '50%',
                    borderRadius: '3px',
                    border: '0',
                    borderBottom: '1px solid rgb(118, 118, 118)',
                    height: '1.7rem',
                    backgroundColor: 'var(--white)'
                  }}
                >
                  <option value="" disabled hidden>
                    ...
                  </option>
                  {escritorioIDs &&
                    escritorioIDs.map((escritorio, index) => (
                      <option key={index} value={escritorio.uuid}>
                        {escritorio.nome}
                      </option>
                    ))}
                </select>
              )
            }}
          </Field>
        </label>
      )
    } else {
      return <></>
    }
  }

  return (
    <Field
      name={name}
      style={{
        display: 'none'
      }}
    />
  )
}

InputSelectorEscritorioId.propTypes = {
  name: PropTypes.string
}
export default InputSelectorEscritorioId
