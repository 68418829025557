import styled from 'styled-components'
import { HEIGHT, WIDTH } from './logic/constants/sizes'

function getSize({ size }) {
  if (size) {
    return `width:${WIDTH[size]}; height:${HEIGHT[size]}`
  }
  return 'width:100%; height:44px'
}

export const Container = styled.button`
  color: ${(props) => props.colorText || 'var(--text-color-light)'};
  background: ${(props) => props.bgColor || 'var(--green)'};
  ${getSize};
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-top: 25px;

  &:hover {
    filter: opacity(0.9);
  }

  ${(props) => props.customStyle}
`

export const ButtonTextIcon = styled.button`
  display: flex;
  height: 38px;
  align-items: center;
  gap: 15px;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 8px;
  background-color: var(--green);
  color: ${(props) => props.colorText};
  font-size: 1em;
  align-self: flex-start;

  > svg {
    margin-top: -3px;
  }
  ${(props) => props.customStyle}
`

export const ButtonTable = styled.button`
  background-color: transparent;
  border-radius: 5px;
  width: 32px;
  height: 30px;
  margin-right: 5px;

  > svg {
    pointer-events: none;
    color: var(--background-medium-blue);
    fill: var(--background-medium-blue);
    width: 19px;
    height: 19px;
  }

  &:hover {
    border: solid 1px var(--background-medium-blue);
  }

  ${(props) => props.customStyle}
`

export const ButtonUploadFile = styled.input.attrs({ type: 'file' })`
  ${(props) => props.customStyle}
`

export const ButtonCustom = styled.button`
  ${(props) => props.customStyle}
`
