import React from 'react'

const eventModalStates = {
  modalContentTop: <></>,
  modalContentMiddle: <></>,
  modalContentBottom: <></>
}

function eventModalReducer(state, action) {
  switch (action.type) {
    case 'setModalContentTop':
      return {
        ...state,
        modalContentTop: action.payload
      }
    case 'setModalContentMiddle':
      return {
        ...state,
        modalContentMiddle: action.payload
      }
    case 'setModalContentBottom':
      return {
        ...state,
        modalContentBottom: action.payload
      }
    default:
      return state
  }
}

export { eventModalStates, eventModalReducer }
