import EndpointConfig from './EndpointConfig'
import domainsTranslationList from './Lists/domainsTranslationList'

function Domain (domain, url, endpointsDescriptions) {
  this.domain = domain
  this.translatedDomain = getTranslation(domain)
  this.url = {
    baseURL: process.env.REACT_APP_API_URL,
    domainURL: url
  }
  this.endpointsConfigs = setupEndpointsConfigs(this, endpointsDescriptions)
}

function getTranslation (domain) {
  try {
    if (typeof domainsTranslationList[domain] !== 'undefined') {
      return domainsTranslationList[domain]
    } else {
      throw new Error(`Domain: ${domain} not in the list`)
    }
  } catch (error) {
    console.error(error)
  }
}

function setupEndpointsConfigs (domain, endpointsDescriptions) {
  const endpointsConfigs = {}

  if (typeof endpointsDescriptions !== 'undefined') {
    Object.entries(endpointsDescriptions).forEach(endpointDescriptionItem => {
      const endpointName = endpointDescriptionItem[0]
      const endpointContent = endpointDescriptionItem[1]
      const endpoint = new EndpointConfig(domain, endpointName, endpointContent)
      endpointsConfigs[`${endpointName}Config`] = endpoint
    })
  }
  return endpointsConfigs
}

export default Domain
