/* eslint-disable no-unused-vars */
import React from 'react'
import { Passos } from './components/Passos'
import { ForgotForm } from './components/ForgotForm'
import { Container } from './styles'

export function EsqueciMinhaSenha() {
  return (
    <Container>
      <Passos />
      <ForgotForm />
    </Container>
  )
}
