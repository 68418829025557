import styled from 'styled-components'

export const ErrorsContainer = styled.div`
  background-color: var(--white);
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`
export const ErrorsTitle = styled.h1``

export const ErrorsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 30px;
`

export const ErrorListItem = styled.li``
