import React, { useReducer, useContext, useState, useEffect } from 'react'
import useNonInitialEffect from 'hooks/useNonInitialEffect'
import PropTypes from 'prop-types'
import * as logic from '../../../subPages/LinhaSaida/linhaSaidaLogic'
import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'
import { PlusIcon, EditIcon, DeleteIcon } from 'assets/icons'
import { ColunaForm } from '../ColunaForm'

import { ColumnsContainerCell, ColumnPositionBackground } from './style'

function controlContextMenuReducer(state, action) {
  switch (action.type) {
    case 'displayColunaForm':
      return {
        ...state,
        colunaFormInitialValues: action.value,
        showColunaForm: true,
        toggleContextMenu: false
      }
    case 'removeColunaForm':
      return {
        ...state,
        showColunaForm: false
      }
    default:
      return {
        ...state
      }
  }
}

const contextMenuControllerInitialState = {
  colunaFormInitialValues: {},
  showColunaForm: false,
  toggleContextMenu: true
}

export function ColumnCell({
  row,
  columns,
  setColumns,
  columnIndex,
  columnChar,
  currentLine,
  currentColumn
}) {
  const { dispatchRestrictedLayout } = useContext(RestrictedLayoutContext)
  const [contextMenuController, dispatchContextMenuController] = useReducer(
    controlContextMenuReducer,
    contextMenuControllerInitialState
  )
  const [currentColumnItem, setCurrentColumnItem] = useState(false)

  useEffect(() => {
    if (columns.length > 0) {
      columns.forEach((column) => {
        if (currentLine === column.line && currentColumn === column.column) {
          setCurrentColumnItem(column)
        }
      })
    }
  }, [columns])

  const colunaFormInitialValues = {
    nome: '',
    output_column_name: '',
    file_layout_id: row.file_layout_id,
    file_row_id: row.id,
    char_start_position: '',
    char_end_position: '',
    fill_char: '',
    fill_direction: '',
    line_sequence_number: 0,
    update: false
  }

  const substituteNullValues = (value) => {
    const newValues = { ...value }
    Object.entries(value).forEach((oldValues, index) => {
      if (oldValues[1] === null) {
        newValues[oldValues[0]] = ''
      }
    })
    return newValues
  }

  const handleDisplayForm = (value) => {
    dispatchContextMenuController({
      type: 'displayColunaForm',
      value: {
        update: true,
        line: currentLine,
        column: currentColumn,
        ...substituteNullValues(value)
      }
    })
  }

  const handleDeleteColumn = (id) => {
    logic.deleteOutputColumn(id, setColumns)
  }

  useNonInitialEffect(() => {
    dispatchRestrictedLayout({
      type: 'setToggleContextMenu',
      payload: contextMenuController.toggleContextMenu
    })
    dispatchRestrictedLayout({
      type: 'setContextMenuContent',
      payload: (
        <>
          <ColunaForm
            colunaInitialValues={contextMenuController.colunaFormInitialValues}
            setColumns={setColumns}
          />
        </>
      )
    })
  }, [
    contextMenuController.toggleContextMenu,
    contextMenuController.colunaFormInitialValues
  ])

  const columnButtons = () => {
    if (columns.indexOf(currentColumnItem) !== -1) {
      return (
        <>
          <button onClick={() => handleDisplayForm(currentColumnItem)}>
            <EditIcon color="#3FA1D8" />
          </button>
          <button onClick={() => handleDeleteColumn(currentColumnItem.id)}>
            <DeleteIcon color="#3FA1D8" />
          </button>
        </>
      )
    }
    return (
      <button onClick={() => handleDisplayForm(colunaFormInitialValues)}>
        <PlusIcon color="#3FA1D8" />
      </button>
    )
  }

  // choose background color of the current line of cells
  let isEven
  if (Math.floor(columnIndex / row.row_columns) % 2) {
    isEven = true
  } else {
    isEven = false
  }

  return (
    <ColumnsContainerCell isEven={isEven} key={columnIndex}>
      <ColumnPositionBackground
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
        hasContent={columns.indexOf(currentColumnItem) !== -1}
      >
        <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
          {columnChar}-{currentLine}
        </text>
      </ColumnPositionBackground>
      {columnButtons()}
    </ColumnsContainerCell>
  )
}

ColumnCell.propTypes = {
  row: PropTypes.object,
  columns: PropTypes.array,
  setColumns: PropTypes.func,
  columnIndex: PropTypes.number,
  columnChar: PropTypes.string,
  currentLine: PropTypes.number,
  currentColumn: PropTypes.number
}
