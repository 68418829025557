/* eslint-disable camelcase */
import api from 'services/api'

async function getAllRegrasValidacao (setValidationRules) {
  try {
    const { status, data } = await api.getAllRegrasValidacao()
    if (status === 200) {
      setValidationRules(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function getElementValidationRules (elementId, setValidationRulesInitialValues, elementType) {
  try {
    let status, data
    switch (elementType) {
      case 'coluna':
        ({ status, data } = await api.getColumnValidationRules(elementId))
        break
      case 'celula':
        ({ status, data } = await api.getCellValidationRules(elementId))
        break
    }

    if (status === 200) {
      setValidationRulesInitialValues(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function handleAddElementValidationRule (params, setConsolidatedElementValidationRules, elementType) {
  try {
    //  remove dos parâmetros o que não precisa ser passado
    //  e chama o tratamento de parâmetros da regra
    const body = (() => {
      const {
        validation_rule_name,
        parameters,
        ...rest
      } = params

      const validation_rule_param = treatValidationParams(validation_rule_name, parameters)

      return { validation_rule_param, ...rest }
    })()

    let status, data
    switch (elementType) {
      case 'coluna':
        ({ status, data } = await api.createColumnValidationRule(body))
        break
      case 'celula':
        ({ status, data } = await api.createCellValidationRule(body))
        break
    }

    if (status === 201) {
      setConsolidatedElementValidationRules((oldElementRules) => [...oldElementRules, data])
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function handleDeleteElementValidationRule (id, setConsolidatedElementValidationRules, elementType) {
  try {
    let status, data
    switch (elementType) {
      case 'coluna':
        ({ status, data } = await api.deleteColumnValidationRule(id))
        break
      case 'celula':
        ({ status, data } = await api.deleteCellValidationRule(id))
        break
    }

    if (status === 204) {
      setConsolidatedElementValidationRules(
        (oldElementRules) => oldElementRules.filter((elementRule) => elementRule.id !== id)
      )
    }

    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

function getDateFormats () {
  return [
    'dd/mm/yyyy',
    'd/m/yyyy',
    'dd/mm/yy',
    'd/m/yy',
    'mm/dd/yyyy',
    'm/d/yyyy',
    'mm/dd/yy',
    'm/d/yy',
    'yyyy-mm-dd',
    'yyyy-m-d',
    'yy-mm-dd',
    'yy-m-d',
    'd mmm yyyy',
    'dd mmm yyyy',
    'd/mmm/yyyy',
    'dd/mmm/yyyy',
    'd mmm yy',
    'dd mmm yy',
    'd/mmm/yy',
    'dd/mmm/yy',
    'd de mmm de yyyy',
    'dd de mmm de yyyy',
    'd de mmm de yy',
    'dd de mmm de yy',
    'd mmmm yyyy',
    'dd mmmm yyyy',
    'd/mmmm/yyyy',
    'dd/mmmm/yyyy',
    'd mmmm yy',
    'dd mmmm yy',
    'd/mmmm/yy',
    'dd/mmmm/yy',
    'd de mmmm de yyyy',
    'dd de mmmm de yyyy',
    'd de mmmm de yy',
    'dd de mmmm de yy'
  ]
}

function getMonths () {
  return [
    {
      value: 1,
      name: 'Janeiro'
    },
    {
      value: 2,
      name: 'Fevereiro'
    },
    {
      value: 3,
      name: 'Março'
    },
    {
      value: 4,
      name: 'Abril'
    },
    {
      value: 5,
      name: 'Maio'
    },
    {
      value: 6,
      name: 'Junho'
    },
    {
      value: 7,
      name: 'Julho'
    },
    {
      value: 8,
      name: 'Agosto'
    },
    {
      value: 9,
      name: 'Setembro'
    },
    {
      value: 10,
      name: 'Outubro'
    },
    {
      value: 11,
      name: 'Novembro'
    },
    {
      value: 12,
      name: 'Dezembro'
    }
  ]
}

// Recebe os parâmetros com nome genérico adquiridos pelo formulário
// e os transforma para para formato esperado pela API
function treatValidationParams (validationRuleName, parameters) {
  // Inicializa validation_rule_param para casos onde se passa apenas o valor fora de um objeto
  let validation_rule_param = parameters.param1

  const paramNamesList = [
    {
      rule_names: [
        'Entre x e y',
        'Entre x e y, inclusive'
      ],
      paramNames: [
        'lesser',
        'greater'
      ]
    },
    {
      rule_names: ['Transformar formato de data'],
      paramNames: [
        'originalFormat',
        'targetFormat'
      ]
    },
    {
      rule_names: [
        'Data maior que x',
        'Data menor que x',
        'Data maior ou igual a x'
      ],
      paramNames: [
        'format',
        'compareDate'
      ]
    },
    {
      rule_names: [
        'Data entre x e y',
        'Data entre x e y, inclusive'
      ],
      paramNames: [
        'format',
        'startDate',
        'endDate'
      ]
    },
    {
      rule_names: ['Dentro do mês x'],
      paramNames: [
        'format',
        'month'
      ]
    }
  ]

  const findRule = paramNamesList.find((rule) =>
    rule.rule_names.filter((ruleName) => validationRuleName === ruleName).length > 0
  )

  // se a regra estiver presente na lista, setaremos validation_rule_param como objeto
  if (findRule) {
    validation_rule_param = {}
    // Descobre qual nome dar a cada propriedade e passa os valores coletados no form
    findRule.paramNames.forEach((value, index) => {
      const param = 'param' + (index + 1)
      validation_rule_param[findRule.paramNames[index]] = parameters[param]
    })
    validation_rule_param = JSON.stringify(validation_rule_param)
  }
  return validation_rule_param
}

export {
  getAllRegrasValidacao,
  getElementValidationRules,
  handleAddElementValidationRule,
  handleDeleteElementValidationRule,
  getDateFormats,
  getMonths
}
