import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'escritorios'
const url = '/escritorios'

const endpointsDescriptions = {
  paginateEscritorio: {
    action: 'getPaginated'
  },
  getAllEscritorio: {
    action: 'getAll'
  },
  getOneEscritorio: {
    action: 'getOne'
  },
  addEscritorio: {
    action: 'add'
  },
  editEscritorio: {
    action: 'update'
  },
  deleteEscritorio: {
    action: 'delete'
  },
  paginateEscritorioRelation: {
    action: 'getPaginated'
  },
  getCsvReport: {
    action: 'getReports'
  }
}

const escritorios = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.paginateEscritorio = (page, perPage) => {
  const endpointDescription =
    escritorios.endpointsConfigs.paginateEscritorioConfig
  return axios.get(`${baseUrl}/escritorios/paginate/${page}/${perPage}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getAllEscritorio = () => {
  const endpointDescription =
    escritorios.endpointsConfigs.getAllEscritorioConfig
  return axios.get(`${baseUrl}/escritorios`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getOneEscritorio = (id) => {
  const endpointDescription =
    escritorios.endpointsConfigs.getOneEscritorioConfig
  return axios.get(`${baseUrl}/escritorios/${id}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.addEscritorio = (body) => {
  const endpointDescription = escritorios.endpointsConfigs.addEscritorioConfig

  if (!body.contatos) {
    body.contatos = []
  }

  if (!body.ativo) {
    body.ativo = parseInt(body.ativo)
  }

  return axios.post(`${baseUrl}/escritorios`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.editEscritorio = (id, body) => {
  const endpointDescription = escritorios.endpointsConfigs.editEscritorioConfig

  return axios.put(`${baseUrl}/escritorios/${id}`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.deleteEscritorio = (id) => {
  const endpointDescription =
    escritorios.endpointsConfigs.deleteEscritorioConfig
  return axios.delete(`${baseUrl}/escritorios/${id}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.paginateEscritorioRelation = (id, relation, page, perPage) => {
  const endpointDescription =
    escritorios.endpointsConfigs.paginateEscritorioRelationConfig
  return axios.get(
    `${baseUrl}/escritorios/${id}/${relation}/paginate/${page}/${perPage}`,
    { endpointInfo: endpointDescription }
  )
}
endpoints.getCsvReportEscritorios = (body) => {
  const endpointDescription = escritorios.endpointsConfigs.getCsvReportConfig
  return axios.post(`${baseUrl}/escritorios/reports/download/csv`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.getCsvReportEscritorios = (body) => {
  const endpointDescription = escritorios.endpointsConfigs.getCsvReportConfig
  return axios.post(`${baseUrl}/escritorios/reports/download/csv`, body, {
    endpointInfo: endpointDescription
  })
}

export default endpoints
