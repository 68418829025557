/* eslint-disable no-unused-vars */
import domainContext from 'services/api/domains/index2'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * @typedef {Object} FetchConfig
 * @property {string} domain - Domain name [ref: services/api/domains]
 * @property {string} endpoint - One of the endpoints contained in the given domains
 * @property {(Object|Array)} [initialState=[]] - Sets initial content of Data (before request is handled)
 * @property {Array} [dependencies=[]] - Dependencies array
 * @property {Array} [params=[]] - Parameters to be used when making the request.
 * @property {boolean} [condition=true] - Condition to run the request.
 * @property {function} treatParams - Manipulate params before they are sent.
 * @property {function} treatResponse - Manipulate response before data is set.
 * @property {function} treatError - Manipulate Error.
 * @property {boolean} [sync=true] - Whether you want the component sync or not
 */

/**
 * @param {FetchConfig} args
 * @returns
 */
export default function useFetch({
  domain,
  endpoint,
  initialState = [],
  dependencies = [],
  params = [],
  condition = true,
  treatParams = (params) => params,
  treatResponse = (data) => data,
  treatError = (error) => console.error(error),
  sync = true
}) {
  const [data, setData] = useState(initialState)
  const [status, setStatus] = useState(false)

  const fetchData = (params) => {
    treatParams(params)
    const requestedEndpoint = getEndpoint(domain, endpoint)

    if (condition) {
      return requestedEndpoint(...params)
        .then((response) => {
          if (sync === false) {
            return response
          }
          if (response.data) {
            setData(treatResponse(response.data))
          }
          if (response.status) {
            setStatus(response.status)
          }
        })
        .catch(treatError)
    }
  }
  useEffect(() => {
    if (sync) {
      fetchData(params)
    }
  }, dependencies)

  return { status, data, fetchData }
}

export const getEndpoint = (domain, endpoint) => {
  const domainKey = `./${domain}.js`
  const requestedEndpoint = domainContext(domainKey).default[endpoint]
  return requestedEndpoint
}

useFetch.propTypes = {
  domain: PropTypes.string,
  endpoint: PropTypes.string,
  initialState: PropTypes.oneOf([PropTypes.object, PropTypes.array]),
  dependencies: PropTypes.array,
  params: PropTypes.array,
  condition: PropTypes.bool,
  treatParams: PropTypes.func,
  treatResponse: PropTypes.func,
  treatError: PropTypes.func
}
