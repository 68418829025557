import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'
import { Button } from 'components/Button'
import * as logic from './celulasSaidaLogic'
import { LayoutSaidaTable } from '../../subComponents/Table'
import { CelulaForm } from '../../subComponents/CelulaForm'

export function CelulasSaida({ layoutId }) {
  const { dispatchRestrictedLayout } = useContext(RestrictedLayoutContext)
  const [inputFileCells, setOutputFileCells] = useState([])
  const [showCelulaForm, setShowCelulaForm] = useState(false)

  const [celulaInitialValues, setCelulaInitialValues] = useState({})

  const handleShowAddCelulaForm = () => {
    setCelulaInitialValues({
      nome: '',
      file_layout_id: layoutId,
      output_file_cell_type: 'dynamic',
      output_cell_content: '',
      line: 1,
      column: 1,
      char_start_position: '',
      char_end_position: '',
      fill_char: '',
      fill_direction: '',
      update: false
    })

    setShowCelulaForm(true)
    dispatchRestrictedLayout({
      type: 'setToggleContextMenu',
      payload: false
    })
  }

  const handleShowUpdateCelula = (id) => {
    logic
      .getOutputFileCellById(id)
      .then((data) => {
        // eslint-disable-next-line camelcase
        const { updated_at, created_at, ...rest } = data

        // avoids to initialize null parameters
        for (const key in rest) {
          if (rest[key] === null) {
            rest[key] = ''
          }
        }
        setCelulaInitialValues({
          ...rest,
          update: true
        })
        setShowCelulaForm(true)
        dispatchRestrictedLayout({
          type: 'setToggleContextMenu',
          payload: false
        })
      })
      .catch((error) => console.error(error))
  }

  const handleDelete = async (event) => {
    if (!window.utils.isFalsy(celulaInitialValues.id)) {
      if (celulaInitialValues.id === parseInt(event.target.dataset.id)) {
        dispatchRestrictedLayout({
          type: 'setToggleContextMenu',
          payload: false
        })
        dispatchRestrictedLayout({
          type: 'setContextMenuContent',
          payload: <></>
        })
      }
    }
    await logic.handleDeleteOutputFileCell(
      event.target.dataset.id,
      setOutputFileCells
    )
  }

  useEffect(async () => {
    await logic.getOutputFileCells(layoutId, setOutputFileCells)
  }, [layoutId])

  useEffect(() => {
    if (showCelulaForm) {
      dispatchRestrictedLayout({
        type: 'setContextMenuContent',
        payload: (
          <>
            <CelulaForm
              celulaInitialValues={celulaInitialValues}
              setOutputFileCells={setOutputFileCells}
            />
          </>
        )
      })
    }
  }, [showCelulaForm, celulaInitialValues])

  return (
    <>
      <Button
        bgColor="var(--background-medium-blue)"
        colorText="var(--white)"
        buttonFunction="ButtonTextIcon"
        buttonIcon="PlusIcon"
        value="ADICIONAR"
        onClick={handleShowAddCelulaForm}
      />
      {inputFileCells.length > 0 ? (
        <LayoutSaidaTable
          items={inputFileCells}
          fields={[
            { fieldName: 'nome', as: 'NOME' },
            { fieldName: 'line', as: 'LINHA' },
            { fieldName: 'column', as: 'COLUNA' }
          ]}
          actionButtons={[
            {
              buttonFunction: 'ButtonTable',
              buttonIcon: 'IconeEditar',
              tooltipText: 'Editar',
              onClick: (event) =>
                handleShowUpdateCelula(event.target.dataset.id)
            },
            {
              bgColor: '#a91803',
              buttonFunction: 'ButtonTable',
              buttonIcon: 'IconeExcluir',
              tooltipText: 'Excluir',
              onClick: handleDelete
            }
          ]}
        />
      ) : (
        ''
      )}
    </>
  )
}

CelulasSaida.propTypes = {
  layoutId: PropTypes.number
}
