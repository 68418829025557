/* eslint-disable no-unused-vars */
import React, { useLayoutEffect, useRef, useState } from 'react'
import { Wrapper } from './styles'
import PropTypes from 'prop-types'
import Input from './components/Input'
import { MenuWithFormik } from './components/Menu'
import { isArrayAndHasValue } from 'helpers/arrays/isArrayAndHasValue'

function AutoComplete({
  name,
  inputStyle,
  renderMenuItem,
  startSearch,
  createOptions,
  delay,
  onChange,
  filter,
  oneTimeRequest,
  errorConfig = {},
  inputProps = {},
  extractKey,
  options = {
    freezeOnMount: false,
    searchDeps: []
  },
  afterSearch = () => {},
  helper = () => {}
}) {
  const [state, setState] = useState({
    data: [],
    options: [],
    errors: [],
    loading: false
  })
  const inputRef = useRef()
  const menuRef = useRef()
  const loading = useRef(false)
  const cacheData = useRef({ allData: [], filteredData: [], onMount: false })
  let timer = null

  const onChangeData = (data, cache) => {
    const options = createOptions(data)

    loading.current = false

    if (cache) {
      saveCache(cache)
    }

    if (cacheData.current.filteredData.length !== state.data.length) {
      setState({
        data,
        options,
        errors: [],
        loading: false
      })
    }
  }

  const runCache = (type) => {
    if (type === 'allData') {
      const data = cacheData.current.allData
      onChangeData(data, { allData: data, filteredData: data })
      return
    }

    const filteredCache = cacheData.current.filteredData.filter((item) =>
      filter(item, inputRef.current.value)
    )

    const options = createOptions(filteredCache)

    loading.current = false

    setState({
      data: filteredCache,
      options,
      errors: [],
      loading: false
    })
  }

  const saveCache = ({ allData, filteredData }, onMount) => {
    if (onMount) {
      cacheData.current = { allData, filteredData, onMount: true }
    } else {
      cacheData.current = { allData, filteredData, onMount: false }
    }
  }

  const runRequest = async (event) => {
    const { value } = event.target

    const data = await startSearch()

    const filteredData = data.filter((item) => filter(item, value))
    afterSearch(filteredData, value)

    if (filteredData.length === 0) throw new Error('Nenhum resultado.')

    onChangeData(filteredData, { allData: data, filteredData })
  }

  const debounce = (event) => {
    if (menuRef.current.dataset.hide === 'true') {
      menuRef.current.dataset.hide = 'false'
    }

    if (!loading.current) {
      loading.current = true

      setState((current) => ({ ...current, loading: true }))
    }

    if (timer) {
      clearTimeout(timer)
    }

    if (event.target.value === '' && cacheData.current.allData.length > 0) {
      runCache('allData')
      return
    }

    timer = setTimeout(async () => {
      try {
        await runRequest(event)
      } catch (e) {
        loading.current = false
        setState({ data: [], options: [], errors: [e.message], loading: false })
      }
    }, delay ?? 700)
  }

  const noDebounce = async (event) => {
    const { value } = event.target

    if (menuRef.current.dataset.hide === 'true') {
      menuRef.current.dataset.hide = 'false'
    }

    try {
      const result = cacheData.current.allData.filter((item) =>
        filter(item, value)
      )
      if (result.length === 0) throw new Error('Nenhum resultado.')

      saveCache({ allData: cacheData.current.allData, filteredData: result })
      onChangeData(result)
    } catch (e) {
      setState({ data: [], options: [], errors: [e.message], loading: false })
    }
  }

  const handleSelect = (index) => {
    inputRef.current.value = state.options[index].label
    menuRef.current.dataset.hide = 'true'

    if (onChange instanceof Function) {
      onChange(state.options[index])
    }
    runCache()
  }

  const handleAdd = async () => {
    const value = inputRef.current.value

    if (errorConfig.addItem) {
      const { addItem } = errorConfig
      const { data } = await addItem(value)
      const options = createOptions(data)
      setState({
        data: [data],
        options: [options],
        errors: [],
        loading: false
      })
      saveCache({
        allData: [...cacheData.current.allData, data],
        filteredData: [data]
      })

      return options.value
    }

    return ''
  }

  if (errorConfig.addItem) {
    errorConfig = { ...errorConfig, handleAdd }
  }

  const focusOut = () => {
    if (menuRef.current.dataset.hide === 'false') {
      menuRef.current.dataset.hide = 'true'
    }
  }

  const focusIn = () => {
    if (cacheData.current.onMount) {
      const options = createOptions(cacheData.current.filteredData)

      cacheData.current.onMount = false
      setState({
        data: cacheData.current.filteredData,
        options: options,
        errors: [],
        loading: false
      })
    }
    if (menuRef.current.dataset.hide === 'true') {
      menuRef.current.dataset.hide = 'false'
    }
  }

  const updateInputValue = (items, inputValue) => {
    const data__ = items ?? []
    const options = createOptions(data__)

    const itemResult =
      options.find((option) => String(option.value) === String(inputValue)) ??
      options.find((option) => String(option.label) === String(inputValue))

    const filteredData = data__.filter((item) =>
      filter(item, itemResult?.label ?? '')
    )

    saveCache({ allData: data__, filteredData: filteredData }, true)

    inputRef.current.value = itemResult?.label ?? ''
    inputRef.current.blur()
  }

  const handleMenuOnInputClick = (e) => {
    if (e.target.value !== '' && menuRef.current.dataset.hide === 'true') {
      menuRef.current.dataset.hide = 'false'
    }
  }

  const getInitialValue = async () => {
    return await startSearch()
  }

  const inputChange = (data, value) => {
    updateInputValue(data, value)
  }

  useLayoutEffect(() => {
    inputRef.current.addEventListener('focusout', focusOut)
    inputRef.current.addEventListener('focusin', focusIn)

    return () => {
      inputRef.current.removeEventListener('focusout', focusOut)
      inputRef.current.removeEventListener('focusin', focusIn)
    }
  }, [])

  useLayoutEffect(() => {
    helper({
      ref: {
        nodeRef: inputRef.current,
        setValue: (value) => {
          if (value instanceof Function) {
            const value_ = value(cacheData.current.allData)
            inputChange(cacheData.current.allData, value_)
          }

          if (
            isArrayAndHasValue(cacheData.current.allData) &&
            !isArrayAndHasValue(options.searchDeps)
          ) {
            inputChange(cacheData.current.allData, value)
            return value
          }
          inputRef.current.value = value
          return value
        }
      }
    })

    if (oneTimeRequest) {
      inputRef.current.addEventListener('input', noDebounce)
    }

    return () => {
      if (oneTimeRequest) {
        inputRef.current.removeEventListener('input', noDebounce)
      }
    }
  }, [state.data])

  useLayoutEffect(() => {
    if (!oneTimeRequest) {
      inputRef.current.addEventListener('input', debounce)
    }
    return () => {
      if (!oneTimeRequest) {
        inputRef.current.removeEventListener('input', debounce)
      }
    }
  }, [])

  const handleInitialValue = (value) => {
    if (oneTimeRequest && !value) {
      setState({
        data: [],
        options: [],
        errors: [],
        loading: true
      })
      getInitialValue()
        .then((items) => {
          afterSearch(items)
          updateInputValue(items, inputRef.current.value)
        })
        .finally(() => {
          if (menuRef.current.dataset.hide === 'false') {
            cacheData.current.onMount = false
            setState({
              data: cacheData.current.filteredData,
              options: createOptions(cacheData.current.filteredData),
              errors: [],
              loading: false
            })
          }
        })
    }

    if (value) {
      setState({
        data: [],
        options: [],
        errors: [],
        loading: true
      })
      getInitialValue()
        .then((items) => {
          afterSearch(items, value)
          updateInputValue(items, value)
        })
        .finally(() => {
          if (menuRef.current.dataset.hide === 'false') {
            cacheData.current.onMount = false
            setState({
              data: cacheData.current.filteredData,
              options: createOptions(cacheData.current.filteredData),
              errors: [],
              loading: false
            })
          }
        })
    }
  }

  return (
    <Wrapper>
      <Input
        ref={inputRef}
        {...inputProps}
        name={name}
        style={{ ...inputStyle, ...inputProps.style }}
        handleInitialValue={handleInitialValue}
        onClick={handleMenuOnInputClick}
        deps={options.searchDeps ? [...options.searchDeps] : []}
        freezeOnMount={options.freezeOnMount}
        autoComplete="off"
      />
      <MenuWithFormik
        ref={menuRef}
        errorConfig={errorConfig}
        formikConfig={{ name }}
        stateConfig={{ state }}
        renderItem={renderMenuItem}
        extractKey={extractKey}
        handleSelect={handleSelect}
        handleAdd={handleAdd}
      />
    </Wrapper>
  )
}

AutoComplete.propTypes = {
  name: PropTypes.string,
  inputStyle: PropTypes.object,
  renderMenuItem: PropTypes.func,
  startSearch: PropTypes.func,
  createOptions: PropTypes.func,
  delay: PropTypes.number,
  CustomInput: PropTypes.any,
  useFormik: PropTypes.any,
  onChange: PropTypes.func,
  filter: PropTypes.func,
  oneTimeRequest: PropTypes.bool,
  errorConfig: PropTypes.object,
  inputProps: PropTypes.object,
  extractKey: PropTypes.func,
  options: PropTypes.object,
  afterSearch: PropTypes.func,
  helper: PropTypes.func
}

export default AutoComplete
