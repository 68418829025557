import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Header, HeaderTitle } from '../../style'

import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'

export default function RestrictedHeader({ layoutType }) {
  const { restrictedLayoutState } = useContext(RestrictedLayoutContext)
  return (
    <Header layoutType={layoutType}>
      <HeaderTitle>{restrictedLayoutState.headerData}</HeaderTitle>
    </Header>
  )
}

RestrictedHeader.propTypes = {
  layoutType: PropTypes.string
}
