import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'outputFileLayout'
const url = '/output-file-layout'

const endpointsDescriptions = {
  getAllOutputLayouts: {
    action: 'getAll'
  },
  getOutputLayoutById: {
    action: 'getOne'
  },
  getOutputLayoutBySistemaContabil: {
    action: 'getBy',
    extraParams: ['por Sistema Contábil']
  },
  createOutputLayout: {
    action: 'add'
  },
  editOutputLayout: {
    action: 'update'
  },
  deleteOutputLayout: {
    action: 'delete'
  },
  duplicateOutputLayout: {
    action: 'duplicate'
  }
}

const outputFileLayout = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllOutputLayouts = () => {
  const endpointDescription = outputFileLayout.endpointsConfigs.getAllOutputLayoutsConfig
  return axios.get(
    `${baseUrl}/output-file-layout`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getOutputLayoutById = (id) => {
  const endpointDescription = outputFileLayout.endpointsConfigs.getOutputLayoutByIdConfig
  return axios.get(
    `${baseUrl}/output-file-layout/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getOutputLayoutBySistemaContabil = (sistemaContabilId) => {
  const endpointDescription = outputFileLayout.endpointsConfigs.getOutputLayoutBySistemaContabilConfig
  return axios.get(
    `${baseUrl}/output-file-layout/sistema-contabil/${sistemaContabilId}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createOutputLayout = (body) => {
  const endpointDescription = outputFileLayout.endpointsConfigs.createOutputLayoutConfig
  return axios.post(
    `${baseUrl}/output-file-layout`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.editOutputLayout = (id, body) => {
  const endpointDescription = outputFileLayout.endpointsConfigs.editOutputLayoutConfig
  return axios.put(
    `${baseUrl}/output-file-layout/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteOutputLayout = (id) => {
  const endpointDescription = outputFileLayout.endpointsConfigs.deleteOutputLayoutConfig
  return axios.delete(
    `${baseUrl}/output-file-layout/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.duplicateOutputLayout = (id) => {
  const endpointDescription = outputFileLayout.endpointsConfigs.duplicateOutputLayoutConfig
  return axios.get(
    `${baseUrl}/output-file-layout/duplicate/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
