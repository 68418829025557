import { useEffect, useRef } from 'react'

export const useComponentController = (initialData = {}) => {
  const info = useRef({ ...initialData, renders: 1 })

  const addData = (args) => {
    info.current = { ...info.current, ...args, renders: info.current.renders }
  }

  const getData = () => {
    const { renders, ...rest } = info.current
    return { ...rest }
  }

  const getRendersQuantity = () => {
    const { renders } = info.current
    return renders
  }

  useEffect(() => {
    info.current.renders++
  })

  return {
    getRendersQuantity,
    addData,
    getData
  }
}
