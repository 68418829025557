import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import { InputContainer, Label, TextFieldInput } from './../FormCRUD/style'

function TextField({ label, ...props }) {
  const [field] = useField(props)
  return (
    <InputContainer className={'InputContainer'}>
      <Label htmlFor={field.name}>{label}</Label>
      <TextFieldInput
        type="text"
        autoComplete="off"
        autoFocus={label === 'Nome'}
        {...field}
        {...props}
      />
    </InputContainer>
  )
}

TextField.propTypes = {
  label: PropTypes.string
}

export default TextField
