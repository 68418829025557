import Pattern from './Pattern'
export default class PatternDate extends Pattern {
  constructor({ value, setValue, setError }) {
    super(setValue, setError)
    super.setCleanValue(value.replace(/\D/g, ''))
    return {
      applyMask: (value) => this.applyMask(value),
      validate: () => this.validate()
    }
  }

  applyMask(value) {
    super.setMaskedValue(value)
    const completeDate = this.maskedValue.match(
      /(^\d{1,2}\/\d{1,2}\/\d{1,4}$)|(^\d{1,2}\/\/\d{1,4}$)|(^\/\d{1,2}\/\d{1,4}$)/g
    )
    const sizeExceeded = this.maskedValue.match(/^\d{2}\/\d{2}\/\d{4}.+$/g)

    if (sizeExceeded) {
      super.setMaskedValue(this.maskedValue.replace(/(\d{4}).+$/, '$1'))
      super.setValue()
      return
    }

    if (completeDate) {
      super.setValue()
      return
    }

    super.setMaskedValue(
      this.cleanValue
        .replace(/^(\d{2})(\d)/, '$1/$2')
        .replace(/(.)\/(\d{2})(\d{1})/, '$1/$2/$3')
    )
    super.setValue()
  }

  validate() {
    if (this.cleanValue?.length < 8) {
      super.setError('Numero incorreto de digitos.')
      return
    }
    super.removeError()
  }
}
