import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    :root {
        /* New Colors: */
        --white:hsl(0, 0%, 100%);
        --black: hsl(0, 0%, 0%);

        --green: hsl(154, 99%, 38%);
        --blue: hsl(215, 89%, 14%);
        --background-medium-blue: hsl(206, 88%, 20%);

        --background-light-blue: hsla(205, 36%, 91%, 1);
        --light-gray: hsl(0, 0%, 97%);

        --text-unfocus: hsl(216, 5%, 60%);
        --background-unfocus: hsl(0, 0%, 96%);

        --light-blue: hsl(205, 85%, 69%);

        --text-color-dark: hsl(0, 0%, 20%);
        --text-color-light: hsl(0, 100%, 100%);
        --text-color-yellow: hsl(45%, 70%, 50%);

        --carmesin: hsl(8, 97%, 34%);
        --yellow: hsl(52, 100%, 46%);
    }

    body {
        margin: 0;
        font-family: 'Poppins', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        font-weight: 400;
        color: #222;
    }

    input::placeholder{
        font-family: 'Poppins', sans-serif;
    }

    html {
        @media (max-width: 1080px) {
            font-size: 93.75%;
        }

        @media (max-width: 720px) {
            font-size: 87.5%;
        }
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    button {
        cursor: pointer;
        border: 0;
        font-family: 'Poppins', sans-serif;

    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }

    ::-webkit-scrollbar {
        width: 0.5em !important;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #09253a;
        border: none;
        border-radius: 100vw
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: black;
    }

    /* Reset */
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        outline: 0;
    }
    /* END Reset */
`
