export default class Pattern {
  constructor(setValue, setError) {
    this.setErrorCallback = setError
    this.setValueCallback = setValue
    this.cleanValue = ''
    this.maskedValue = ''
  }

  setCleanValue(value) {
    this.cleanValue = value
  }

  setIsValid(isValid) {
    this.isValid = isValid
  }

  setMaskedValue(maskedValue) {
    this.maskedValue = maskedValue
  }

  setError(message) {
    this.setErrorCallback(message)
  }

  removeError() {
    this.setErrorCallback(false)
  }

  setValue() {
    this.setValueCallback(this.maskedValue)
  }
}
