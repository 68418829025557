import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'inputFileLayout'
const url = '/input-file-layout'

const endpointsDescriptions = {
  getAllInputLayouts: {
    action: 'getAll'
  },
  getInputLayoutById: {
    action: 'getOne'
  },
  getInputLayoutBySistemaGestao: {
    action: 'getBy',
    extraParams: ['por Sistema de Gestão']
  },
  createInputLayout: {
    action: 'add'
  },
  editInputLayout: {
    action: 'update'
  },
  deleteInputLayout: {
    action: 'delete'
  },
  duplicateInputLayout: {
    action: 'duplicate'
  }
}

const inputFileLayout = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllInputLayouts = () => {
  const endpointDescription = inputFileLayout.endpointsConfigs.getAllInputLayoutsConfig
  return axios.get(
    `${baseUrl}/input-file-layout`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getInputLayoutById = (id) => {
  const endpointDescription = inputFileLayout.endpointsConfigs.getInputLayoutByIdConfig
  return axios.get(
    `${baseUrl}/input-file-layout/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getInputLayoutBySistemaGestao = (sistemaGestaoId) => {
  const endpointDescription = inputFileLayout.endpointsConfigs.getInputLayoutBySistemaGestaoConfig
  return axios.get(
    `${baseUrl}/input-file-layout/sistema-gestao/${sistemaGestaoId}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createInputLayout = (body) => {
  const endpointDescription = inputFileLayout.endpointsConfigs.createInputLayoutConfig
  return axios.post(
    `${baseUrl}/input-file-layout`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.editInputLayout = (id, body) => {
  const endpointDescription = inputFileLayout.endpointsConfigs.editInputLayoutConfig
  return axios.put(
    `${baseUrl}/input-file-layout/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteInputLayout = (id) => {
  const endpointDescription = inputFileLayout.endpointsConfigs.deleteInputLayoutConfig
  return axios.delete(
    `${baseUrl}/input-file-layout/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.duplicateInputLayout = (id) => {
  const endpointDescription = inputFileLayout.endpointsConfigs.duplicateInputLayoutConfig
  return axios.get(
    `${baseUrl}/input-file-layout/duplicate/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
