import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'inputFileCell'
const url = '/input-file-cell'

const endpointsDescriptions = {
  getAllInputCellsLayout: {
    action: 'getAll'
  },
  getInputCellById: {
    action: 'getOne'
  },
  createInputCell: {
    action: 'add'
  },
  editInputCell: {
    action: 'update'
  },
  deleteInputCell: {
    action: 'delete'
  }
}

const inputFileCell = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllInputCellsLayout = (layoutId) => {
  const endpointDescription = inputFileCell.endpointsConfigs.getAllInputCellsLayoutConfig
  return axios.get(
    `${baseUrl}/input-file-cell/layout/${layoutId}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getInputCellById = (id) => {
  const endpointDescription = inputFileCell.endpointsConfigs.getInputCellByIdConfig
  return axios.get(
    `${baseUrl}/input-file-cell/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createInputCell = (body) => {
  const endpointDescription = inputFileCell.endpointsConfigs.createInputCellConfig
  return axios.post(
    `${baseUrl}/input-file-cell`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.editInputCell = (id, body) => {
  const endpointDescription = inputFileCell.endpointsConfigs.editInputCellConfig
  return axios.put(
    `${baseUrl}/input-file-cell/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteInputCell = (id) => {
  const endpointDescription = inputFileCell.endpointsConfigs.deleteInputCellConfig
  return axios.delete(
    `${baseUrl}/input-file-cell/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
