export const claims = [
  {
    role_id: 1,
    feature_id: 1,
    valor: 'manage'
  },
  {
    role_id: 1,
    feature_id: 2,
    valor: 'manage'
  },
  {
    role_id: 1,
    feature_id: 3,
    valor: 'manage'
  },
  {
    role_id: 1,
    feature_id: 4,
    valor: 'manage'
  },
  {
    role_id: 1,
    feature_id: 5,
    valor: 'manage'
  },
  {
    role_id: 1,
    feature_id: 6,
    valor: 'manage'
  },
  {
    role_id: 1,
    feature_id: 7,
    valor: 'manage'
  },
  {
    role_id: 1,
    feature_id: 8,
    valor: 'manage'
  },
  {
    role_id: 1,
    feature_id: 9,
    valor: 'manage'
  },
  {
    role_id: 1,
    feature_id: 10,
    valor: 'manage'
  },
  {
    role_id: 1,
    feature_id: 11,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 1,
    valor: 'read'
  },
  {
    role_id: 2,
    feature_id: 2,
    valor: 'read'
  },
  {
    role_id: 2,
    feature_id: 3,
    valor: 'read'
  },
  {
    role_id: 2,
    feature_id: 4,
    valor: 'read'
  },
  {
    role_id: 2,
    feature_id: 5,
    valor: 'read'
  },
  {
    role_id: 2,
    feature_id: 6,
    valor: 'read'
  },
  {
    role_id: 2,
    feature_id: 7,
    valor: 'read'
  },
  {
    role_id: 2,
    feature_id: 8,
    valor: 'read'
  },
  {
    role_id: 2,
    feature_id: 9,
    valor: 'read'
  },
  {
    role_id: 2,
    feature_id: 10,
    valor: 'read'
  },
  {
    role_id: 2,
    feature_id: 11,
    valor: 'read'
  },
  {
    role_id: 3,
    feature_id: 3,
    valor: 'manage'
  },
  {
    role_id: 3,
    feature_id: 4,
    valor: 'manage'
  },
  {
    role_id: 3,
    feature_id: 5,
    valor: 'manage'
  },
  {
    role_id: 3,
    feature_id: 6,
    valor: 'manage'
  },
  {
    role_id: 3,
    feature_id: 10,
    valor: 'manage'
  },
  {
    role_id: 3,
    feature_id: 11,
    valor: 'manage'
  },
  {
    role_id: 4,
    feature_id: 3,
    valor: 'read'
  },
  {
    role_id: 4,
    feature_id: 4,
    valor: 'read'
  },
  {
    role_id: 4,
    feature_id: 5,
    valor: 'read'
  },
  {
    role_id: 4,
    feature_id: 6,
    valor: 'read'
  },
  {
    role_id: 4,
    feature_id: 10,
    valor: 'read'
  },
  {
    role_id: 4,
    feature_id: 11,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 12,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 12,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 13,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 13,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 14,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 14,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 16,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 16,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 17,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 17,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 18,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 18,
    valor: 'read'
  },
  {
    role_id: 3,
    feature_id: 18,
    valor: 'manage'
  },
  {
    role_id: 4,
    feature_id: 18,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 19,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 19,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 20,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 20,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 21,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 21,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 22,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 22,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 23,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 23,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 24,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 24,
    valor: 'read'
  },
  {
    role_id: 1,
    feature_id: 25,
    valor: 'manage'
  },
  {
    role_id: 2,
    feature_id: 25,
    valor: 'read'
  }
]
