/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import jsonp from 'jsonp'
import { useField, useFormikContext } from 'formik'
import { parseCnpj, parseCnpjCpf } from 'helpers/inputsParser'
import { cnpjValidation } from 'helpers/documentNumberValidation'
import useNonInitialEffect from 'hooks/useNonInitialEffect'
import { useComponentController } from 'hooks/useComponentController'
import { Button } from '@plick-solucoes-contabeis/ui-components'

export function useCNPJ(crudFields, isUpdate) {
  const containsCNPJField = checkContainsCNPJField(crudFields)
  let fieldName = ''
  if (containsCNPJField) {
    fieldName = getFieldName(crudFields)
  }
  const { values, setFieldValue } = useFormikContext()
  const [field, meta, helpers] = useField(fieldName)
  const [dataFromCnpj, setDataFromCnpj] = useState({})
  const controller = useComponentController({
    prevFieldValue: field.value,
    currFieldValue: field.value
  })

  const location = useRef({
    uf: null,
    municipio: null
  })

  location.current.municipio = values.municipio
  location.current.uf = values.uf

  function notificationCallback() {
    requestCNPJ({
      formik: { field, helpers },
      fieldName,
      triggerRender: false
    }).then((data) => {
      // Importante: o municipio deve ser atribuido antes
      setFieldValue(
        'municipio',
        window.utils.treatString(
          data?.municipio?.toLowerCase() ?? '',
          'capitalizeWords'
        )
      )
      setFieldValue('uf', data?.uf ?? '')
    })
  }

  useEffect(() => {
    if (isUpdate && !location.current.municipio && !location.current.uf) {
      window.eventBus.dispatch(
        'event-modal-notification',
        alteredDataNotification(notificationCallback)
      )
    }
  }, [])

  useNonInitialEffect(() => {
    if (field?.name === 'cpf_cnpj') {
      controller.addData({ currFieldValue: field.value.replace(/\W/g, '') })
      if (
        isUpdate &&
        controller.getData().prevFieldValue !==
          controller.getData().currFieldValue
      ) {
        requestCNPJ({
          formik: { field, helpers },
          fieldName,
          setDataFromCnpj
        })
      }
      if (!isUpdate) {
        requestCNPJ({
          formik: { field, helpers },
          fieldName,
          setDataFromCnpj
        })
      }
    }
  }, [field.value])

  useNonInitialEffect(() => {
    if (
      typeof dataFromCnpj.fantasia !== 'undefined' &&
      typeof dataFromCnpj.nome !== 'undefined' &&
      typeof dataFromCnpj.abertura !== 'undefined' &&
      typeof dataFromCnpj.uf !== 'undefined' &&
      typeof dataFromCnpj.municipio !== 'undefined'
    ) {
      if (dataFromCnpj.fantasia !== '') {
        setFieldValue('nome', dataFromCnpj.fantasia)
      } else {
        setFieldValue('nome', dataFromCnpj.nome)
      }
      setFieldValue('razao_social', dataFromCnpj.nome)
      setFieldValue('uf', dataFromCnpj.uf)
      setFieldValue(
        'municipio',
        window.utils.treatString(
          dataFromCnpj.municipio.toLowerCase(),
          'capitalizeWords'
        )
      )

      const dataFundacao = parseAbertura(dataFromCnpj.abertura)
      setFieldValue('data_fundacao_dateMonth', dataFundacao.month)
      setFieldValue('data_fundacao_dateYear', dataFundacao.year)
      setFieldValue('data_fundacao', dataFundacao.full)
    }
  }, [dataFromCnpj])
}

async function requestCNPJ({
  formik: { field, helpers },
  fieldName,
  setDataFromCnpj,
  triggerRender = true
}) {
  let cleanValue = field.value

  if (field.value.match(/\W/g)) {
    switch (fieldName) {
      case 'cnpj':
        cleanValue = parseCnpj(cleanValue)
        break
      case 'cpf_cnpj':
        cleanValue = parseCnpjCpf(cleanValue)
        break
    }
  }

  if (cleanValue?.length === 14) {
    const isValidCNPJ = cnpjValidation(cleanValue)
    if (isValidCNPJ && !triggerRender) {
      return await fetchCnpj(cleanValue, null, helpers)
    }
    if (isValidCNPJ && triggerRender) {
      await fetchCnpj(cleanValue, setDataFromCnpj, helpers)
    }
  }
}

function checkContainsCNPJField(crudFields) {
  return window.utils.arrayObjectsStringMatch({
    arrayObjects: crudFields,
    objPropertiesPath: ['properties', 'name'],
    stringsToMatch: ['cnpj', 'cpf_cnpj']
  })
}

function getFieldName(crudFields) {
  const isCnpJ = window.utils.arrayObjectsStringMatch({
    arrayObjects: crudFields,
    objPropertiesPath: ['properties', 'name'],
    stringsToMatch: ['cnpj']
  })
  if (isCnpJ) {
    return 'cnpj'
  }
  const isCpfCnpj = window.utils.arrayObjectsStringMatch({
    arrayObjects: crudFields,
    objPropertiesPath: ['properties', 'name'],
    stringsToMatch: ['cpf_cnpj']
  })
  if (isCpfCnpj) {
    return 'cpf_cnpj'
  }
  return ''
}

async function fetchCnpj(cnpj, setDataFromCnpj, fieldHelper) {
  const url = `https://www.receitaws.com.br/v1/cnpj/${cnpj}`
  return new Promise((resolve, reject) =>
    jsonp(url, { timeout: 3000 }, function (error, data) {
      if (error) {
        fieldHelper.setError(
          'CNPJ não encontrado ou limite de requisicões atingido | Aguarde um minuto para tentar novamente.'
        )
        reject(error)
      } else {
        setDataFromCnpj ? setDataFromCnpj(data) : resolve(data)
      }
    })
  )
}

function parseAbertura(abertura) {
  abertura = abertura.split('/')
  const aberturaMonth = abertura[1]
  const aberturaYear = abertura[2]

  const dataFundacao = {
    month: aberturaMonth,
    year: aberturaYear,
    full: `${aberturaYear}-${aberturaMonth}-01`
  }

  return dataFundacao
}

const alteredDataNotification = (callback) => ({
  header: { title: 'Atenção!' },
  content: {
    body: [
      <>
        <p style={{ fontSize: '14px', fontWeight: 'normal', lineHeight: 2 }}>
          Percebemos que há dados da <strong>UF</strong> e{' '}
          <strong>Município</strong> vazios, podemos inserir automaticamente o
          cadastro conforme consta na Receita Federal. O que acha?
        </p>
      </>
    ]
  },
  footer: {
    body: [
      <>
        <Button
          title="Sim"
          onClick={async () => {
            await callback()
            window.eventBus.dispatch('event-modal-close')
          }}
        />
        <Button
          title="Preencher manualmente"
          onClick={() => window.eventBus.dispatch('event-modal-close')}
          style={{
            background: '#FF4242',
            color: '#fff',
            marginLeft: '5px',
            border: 'none'
          }}
        />
      </>
    ]
  },
  configs: { header: { hideCloseButton: true } }
})
