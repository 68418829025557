import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'usuarios'
const url = '/usuarios'

const endpointsDescriptions = {
  login: {
    action: 'custom',
    extraParams: ['Logar']
  },
  logout: {
    action: 'custom',
    extraParams: ['Deslogar']
  },
  refresh: {
    action: 'custom',
    extraParams: ['Refresh Token']
  },
  paginateUsuarios: {
    action: 'getPaginated'
  },
  getAllUsuarios: {
    action: 'getAll'
  },
  getSingleUser: {
    action: 'getOne'
  },
  getUserPermissions: {
    action: 'custom',
    extraParams: ['Retornar Permissões de Usuário']
  },
  addUsuarios: {
    action: 'add'
  },
  uploadProfile: {
    action: 'update'
  },
  editUsuarios: {
    action: 'update'
  },
  updateUserPermissions: {
    action: 'custom',
    extraParams: ['Editar permissões de Usuário'],
    options: { feedbackOnAction: true, activateLoader: true }
  },
  sendForgotPasswordEmail: {
    action: 'custom',
    extraParams: ['Enviar email para troca de senha']
  },
  changeUserPassword: {
    action: 'custom',
    extraParams: ['Alterar Senha de Usuário'],
    options: { displayOnErrors: true, activateLoader: true }
  },
  deleteUsuarios: {
    action: 'delete'
  },
  acceptTermoDeUso: {
    action: 'custom',
    extraParams: ['Aceite de termos de uso'],
    options: {
      displayOnErrors: true,
      activateLoader: true,
      feedbackOnAction: true
    }
  },
  getCsvReport: {
    action: 'getReports'
  }
}

const usuarios = new BaseDomainsProvider(domain, url, endpointsDescriptions)

// Authentication
endpoints.login = (body) => {
  const endpointDescription = usuarios.endpointsConfigs.loginConfig
  endpointDescription.options.activateLoader = true
  endpointDescription.options.displayOnErrors = true
  return axios.post(`${baseUrl}/usuarios/login`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.logout = (body) => {
  const endpointDescription = usuarios.endpointsConfigs.logoutConfig
  endpointDescription.options.displayOnErrors = false
  endpointDescription.options.confirmOnDelete = false
  endpointDescription.options.feedbackOnAction = false
  endpointDescription.options.activateLoader = false
  return axios.post(`${baseUrl}/usuarios/logout`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.refresh = (body) => {
  const endpointDescription = usuarios.endpointsConfigs.refreshConfig
  endpointDescription.options.displayOnErrors = false
  endpointDescription.options.confirmOnDelete = false
  endpointDescription.options.feedbackOnAction = false
  endpointDescription.options.activateLoader = false
  return axios.post(`${baseUrl}/usuarios/refresh`, body, {
    endpointInfo: endpointDescription
  })
}

// Gets
endpoints.paginateUsuarios = (page, perPage) => {
  const endpointDescription = usuarios.endpointsConfigs.paginateUsuariosConfig
  return axios.get(`${baseUrl}/usuarios/paginate/${page}/${perPage}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getAllUsuarios = () => {
  const endpointDescription = usuarios.endpointsConfigs.getAllUsuariosConfig
  return axios.get(`${baseUrl}/usuarios`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getSingleUser = (uuid) => {
  const endpointDescription = usuarios.endpointsConfigs.getSingleUserConfig
  return axios.get(`${baseUrl}/usuarios/${uuid}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getUserPermissions = (id) => {
  const endpointDescription = usuarios.endpointsConfigs.getUserPermissionsConfig
  return axios.get(`${baseUrl}/usuarios/permissions/${id}`, {
    endpointInfo: endpointDescription
  })
}

// Add
endpoints.addUsuarios = (body) => {
  const endpointDescription = usuarios.endpointsConfigs.addUsuariosConfig
  if (window.utils.isFalsy(body.escritorio_id)) {
    delete body.escritorio_id
  }

  return axios.post(`${baseUrl}/usuarios`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.uploadProfile = (id, body) => {
  const endpointDescription = usuarios.endpointsConfigs.uploadProfileConfig
  return axios.patch(`${baseUrl}/usuarios/${id}/upload-profile`, body, {
    endpointInfo: endpointDescription
  })
}

// Edits
endpoints.editUsuarios = (id, body) => {
  const endpointDescription = usuarios.endpointsConfigs.editUsuariosConfig
  return axios.patch(`${baseUrl}/usuarios/${id}`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.updateUserPermissions = (body) => {
  const endpointDescription =
    usuarios.endpointsConfigs.updateUserPermissionsConfig
  return axios.put(`${baseUrl}/usuarios/update-permission/`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.sendForgotPasswordEmail = (body) => {
  const endpointDescription =
    usuarios.endpointsConfigs.sendForgotPasswordEmailConfig
  return axios.post(`${baseUrl}/usuarios/esqueci-minha-senha`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.changeUserPassword = (body) => {
  const endpointDescription = usuarios.endpointsConfigs.changeUserPasswordConfig
  return axios.post(`${baseUrl}/usuarios/trocar-senha`, body, {
    endpointInfo: endpointDescription
  })
}

// Deletes
endpoints.deleteUsuarios = (id) => {
  const endpointDescription = usuarios.endpointsConfigs.deleteUsuariosConfig
  return axios.delete(`${baseUrl}/usuarios/${id}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.acceptTermoDeUso = (body) => {
  const endpointDescription = usuarios.endpointsConfigs.acceptTermoDeUsoConfig
  return axios.post(`${baseUrl}/usuarios/termo-de-uso`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.getCsvReportUsuarios = (body) => {
  const endpointDescription = usuarios.endpointsConfigs.getCsvReportConfig
  return axios.post(`${baseUrl}/usuarios/reports/download/csv`, body, {
    endpointInfo: endpointDescription
  })
}

export default endpoints
