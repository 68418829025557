import React from 'react'
import PropTypes from 'prop-types'
import { FlexWrapperUl } from './style'
import { PagerButton } from './subComponents/PagerButton'
import { VoltarPaginacaoButton } from './subComponents/VoltarPaginacaoButton'
import { AvancarPaginacaoButton } from './subComponents/AvancarPaginacaoButton'
import { PrimeiraPaginaButton } from './subComponents/PrimeiraPaginaButton'
import { UltimaPaginaButton } from './subComponents/UltimaPaginaButton'

export default function Paginator({
  setRows,
  perPage,
  active,
  setActive,
  apiFetcher,
  listUrl,
  numberOfPages,
  setNumberOfPages,
  paginatorControl
}) {
  // lógica que fixa em 10 a quantidade de páginas numeradas visíveis
  const listOfButtons = []
  if (numberOfPages) {
    const pagesLimit = numberOfPages > 10 ? 10 : numberOfPages
    let i, j
    if (active > 5) {
      i = active - 4
    } else {
      i = 1
      j = pagesLimit
    }
    if (active < numberOfPages - 5 && active > 5) {
      j = active + 5
    } else if (numberOfPages - i > pagesLimit) {
      j = numberOfPages - (numberOfPages - pagesLimit)
    } else {
      j = numberOfPages
      i = numberOfPages - (pagesLimit - 1)
    }
    for (i; i <= j; i++) {
      listOfButtons.push(
        <PagerButton
          key={i}
          value={i}
          perPage={perPage}
          paginatorControl={paginatorControl}
          setRows={setRows}
          setActive={setActive}
          active={active}
          apiFetcher={apiFetcher}
          listUrl={listUrl}
          setNumberOfPages={setNumberOfPages}
        />
      )
    }
  }

  return (
    <FlexWrapperUl>
      <PrimeiraPaginaButton
        perPage={perPage}
        setRows={setRows}
        setActive={setActive}
        apiFetcher={apiFetcher}
        listUrl={listUrl}
        paginatorControl={paginatorControl}
        setNumberOfPages={setNumberOfPages}
      />
      <VoltarPaginacaoButton
        perPage={perPage}
        setRows={setRows}
        setActive={setActive}
        active={active}
        apiFetcher={apiFetcher}
        listUrl={listUrl}
        paginatorControl={paginatorControl}
        setNumberOfPages={setNumberOfPages}
      />
      {listOfButtons}
      <AvancarPaginacaoButton
        perPage={perPage}
        setRows={setRows}
        setActive={setActive}
        active={active}
        numberOfPages={numberOfPages}
        apiFetcher={apiFetcher}
        listUrl={listUrl}
        paginatorControl={paginatorControl}
        setNumberOfPages={setNumberOfPages}
      />
      <UltimaPaginaButton
        numberOfPages={numberOfPages}
        perPage={perPage}
        setRows={setRows}
        setActive={setActive}
        apiFetcher={apiFetcher}
        listUrl={listUrl}
        paginatorControl={paginatorControl}
        setNumberOfPages={setNumberOfPages}
      />
    </FlexWrapperUl>
  )
}

Paginator.propTypes = {
  setRows: PropTypes.func,
  perPage: PropTypes.number,
  active: PropTypes.number,
  setActive: PropTypes.func,
  apiFetcher: PropTypes.string,
  listUrl: PropTypes.string,
  numberOfPages: PropTypes.number,
  paginatorControl: PropTypes.any,
  setNumberOfPages: PropTypes.func
}
