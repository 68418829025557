/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useCampoSistemaContabil } from '../logic/hooks/useCampoSistemaContabil'
import { useField } from 'formik'
import {
  CamposWrapper,
  CampoWrapper,
  CampoHeader,
  CampoInputsContainer,
  Toggler
} from '../styles/CamposSistemaContabil'

function setAvailablePos(campos) {
  const allPos = [...Array(campos.length).keys()].map((pos) => pos + 1)
  const defaultPos = campos
    .filter((campo) => campo.is_default)
    .map((campo) => campo.num_order)

  const availablePos = allPos.filter((pos) => defaultPos.indexOf(pos) === -1)

  campos.forEach((campo) => {
    if (campo.is_default) {
      campo.availablePositions = allPos
    } else {
      campo.availablePositions = availablePos
    }
  })

  return campos
}

function initialValuesSetup(campos, rawInitialValues) {
  let formattedInitialValues = campos
  if (typeof rawInitialValues.value !== 'undefined') {
    if (rawInitialValues.value.length > 0) {
      const currentFields = JSON.parse(rawInitialValues.value[0].fields)

      formattedInitialValues = campos.map((campo) => {
        const shouldChange = currentFields.filter(
          (field) => field.id_field === campo.id
        )[0]

        if (shouldChange) {
          return {
            ...campo,
            is_enabled: true,
            num_order: parseInt(shouldChange.num_order),
            dataType: shouldChange.field_type
          }
        }
        return campo
      })
    }
  }
  formattedInitialValues = setAvailablePos(formattedInitialValues)
  return formattedInitialValues
}

// eslint-disable-next-line react/prop-types
function CamposSistemaContabil({ name, campos }) {
  const [rawInitialValues] = useField('templateContas')
  const [initialValues] = useState(initialValuesSetup(campos, rawInitialValues))
  const { editValue } = useCampoSistemaContabil({ name, campos: initialValues })

  return (
    <CamposWrapper name={name}>
      {initialValues.map((campoVal, index) => {
        return (
          <CampoWrapper key={index} isDefault={campoVal.is_default}>
            <CampoHeader>{campoVal.name}</CampoHeader>
            <CampoInputsContainer>
              <Enabled
                option={'Ativo'}
                campo={campoVal}
                editValue={editValue}
              />
              <DataType campo={campoVal} editValue={editValue} />
              <NumOrder campo={campoVal} editValue={editValue} />
            </CampoInputsContainer>
          </CampoWrapper>
        )
      })}
    </CamposWrapper>
  )
}

CamposSistemaContabil.propTypes = {
  name: PropTypes.string,
  campos: PropTypes.array
}

function Enabled({ option, campo, editValue }) {
  function changeEnabled(event) {
    if (!campo.is_default) {
      editValue({
        id: campo.id,
        inputName: 'enabled',
        value: event.target.checked
      })
    }
  }

  return (
    <div>
      <label htmlFor={campo.name} className="toggler-label">
        <span
          style={{
            width: 'auto',
            color: '#333333'
          }}
        >
          {option}
        </span>
        <Toggler
          type="checkbox"
          name={campo.name}
          id={campo.name}
          disabled={campo.is_default}
          defaultChecked={campo.is_enabled}
          onChange={changeEnabled}
        />
      </label>
    </div>
  )
}

Enabled.propTypes = {
  option: PropTypes.string,
  campo: PropTypes.object,
  editValue: PropTypes.func
}

function DataType({ campo, editValue }) {
  function changeDataType(event) {
    if (!campo.is_default) {
      editValue({
        id: campo.id,
        inputName: 'field_type',
        value: event.target.value
      })
    }
  }

  return (
    <div>
      <label>Tipo:</label>
      <select
        disabled={campo.is_default}
        onChange={changeDataType}
        defaultValue={campo.dataType}
      >
        <option value="string">Texto</option>
        <option value="number">Numero</option>
        <option value="boolean">Booleano</option>
      </select>
    </div>
  )
}

DataType.propTypes = {
  campo: PropTypes.object,
  editValue: PropTypes.func
}

function NumOrder({ campo, editValue }) {
  function changeNumOrder(event) {
    if (!campo.is_default) {
      editValue({
        id: campo.id,
        inputName: 'num_order',
        value: event.target.value
      })
    }
  }
  return (
    <div>
      <label>Ordenação:</label>
      <select
        disabled={campo.is_default}
        defaultValue={campo.num_order}
        onChange={changeNumOrder}
      >
        {typeof campo.availablePositions !== 'undefined' ? (
          campo.availablePositions.map((posNumber, index) => {
            return (
              <option key={index} value={posNumber}>
                {posNumber}
              </option>
            )
          })
        ) : (
          <></>
        )}
      </select>
    </div>
  )
}

NumOrder.propTypes = {
  campo: PropTypes.object,
  editValue: PropTypes.func
}

export default CamposSistemaContabil
