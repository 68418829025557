const tableStates = {
  headers: [],
  topBarItems: [],
  actions: [],
  cellComponents: {},
  crudFields: [],
  displayTable: false
}

function tableReducer(state, action) {
  switch (action.type) {
    case 'setHeaders':
      return {
        ...state,
        headers: action.payload
      }
    case 'setTopBarItems':
      return {
        ...state,
        topBarItems: action.payload
      }
    case 'setActions':
      return {
        ...state,
        actions: action.payload
      }
    case 'setCellComponents':
      return {
        ...state,
        cellComponents: action.payload
      }
    case 'setCrudFields':
      return {
        ...state,
        crudFields: action.payload
      }
    case 'setDisplayTable':
      return {
        ...state,
        displayTable: action.payload
      }
    default:
      return {
        headers: [],
        topBarItems: [],
        actions: [],
        cellComponents: {},
        crudFields: [],
        displayTable: false
      }
  }
}

export { tableStates, tableReducer }
