import styled from 'styled-components'

export const LoginContainer = styled.article`
  & > div > form > div:nth-child(2) {
    margin-top: 25px;
  }
  & > div > form > div {
    gap: 5px;

    & > label {
      font-size: 14px;
    }
    & > input[type='text'],
    & > input[type='password'] {
      height: 44px;
      font-size: 16px;
      border: solid 1px #d0d5dd;
      border-radius: 8px;
    }
  }
  & > div > form > button {
    margin-top: 10px;
  }
`

export const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 600;
`

export const LoginError = styled.p`
  font-size: 14px;
  text-align: center;
  padding: 5px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  background-color: red;
`

export const Loader = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  border: 0.2em solid currentcolor;
  border-radius: 50%;
  animation: loader 1.15s infinite -0.3s;

  @keyframes loader {
    to {
      transform: rotateX(180deg);
    }
  }
`

export const Content = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px 0;
  margin-top: 20px;

  & > p {
    margin: 0 auto;
  }

  div {
    display: flex;
    align-items: center;

    input {
      width: 25px;
      height: 17px;
    }

    label {
      line-height: 12px;
    }
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: var(--green);

    &:hover {
      color: hsl(154, 99%, 18%);
    }
  }
`
