import React from 'react'

const IconeEditar = () => {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4H11.5263V6H0V4ZM0 2H11.5263V0H0V2ZM0 10H7.3349V8H0V10ZM15.7281 6.87L16.4721 6.16C16.8807 5.77 17.5409 5.77 17.9495 6.16L18.6935 6.87C19.1022 7.26 19.1022 7.89 18.6935 8.28L17.9495 8.99L15.7281 6.87ZM14.9841 7.58L9.43058 12.88V15H11.652L17.2056 9.7L14.9841 7.58Z"
        fill="var(--background-medium-blue)"
      />
    </svg>
  )
}

const IconeExcluir = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.42857 5.33333V14.2222H2.57143V5.33333H9.42857ZM8.14286 0H3.85714L3 0.888889H0V2.66667H12V0.888889H9L8.14286 0ZM11.1429 3.55556H0.857143V14.2222C0.857143 15.2 1.62857 16 2.57143 16H9.42857C10.3714 16 11.1429 15.2 11.1429 14.2222V3.55556Z"
        fill="var(--background-medium-blue)"
      />
    </svg>
  )
}

const IconePermissoesDeUsuario = () => {
  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 5.66667H9.75V4.04762C9.75 1.81333 8.07 0 6 0C3.93 0 2.25 1.81333 2.25 4.04762V5.66667H1.5C0.675 5.66667 0 6.39524 0 7.28571V15.381C0 16.2714 0.675 17 1.5 17H10.5C11.325 17 12 16.2714 12 15.381V7.28571C12 6.39524 11.325 5.66667 10.5 5.66667ZM3.75 4.04762C3.75 2.70381 4.755 1.61905 6 1.61905C7.245 1.61905 8.25 2.70381 8.25 4.04762V5.66667H3.75V4.04762ZM10.5 15.381H1.5V7.28571H10.5V15.381ZM6 12.9524C6.825 12.9524 7.5 12.2238 7.5 11.3333C7.5 10.4429 6.825 9.71429 6 9.71429C5.175 9.71429 4.5 10.4429 4.5 11.3333C4.5 12.2238 5.175 12.9524 6 12.9524Z"
        fill="var(--background-medium-blue)"
      />
    </svg>
  )
}

const IconeCadastroDePara = () => {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3158 0H1.47368C0.663158 0 0 0.695454 0 1.54545V12.3636H1.47368V1.54545H10.3158V0ZM9.57895 3.09091H4.42105C3.61053 3.09091 2.95474 3.78636 2.95474 4.63636L2.94737 15.4545C2.94737 16.3045 3.60316 17 4.41368 17H12.5263C13.3368 17 14 16.3045 14 15.4545V7.72727L9.57895 3.09091ZM4.42105 15.4545V4.63636H8.8421V8.5H12.5263V15.4545H4.42105Z"
        fill="var(--background-medium-blue)"
      />
    </svg>
  )
}

const IconeResponsavelSim = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="var(--white)" />
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z"
        fill="var(--green)"
      />
    </svg>
  )
}

const IconeResponsavelNao = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.6099" cy="10" r="10" fill="var(--white)" />
      <path
        d="M6.55 3.12L5.1 1.66C6.68 0.61 8.57 0 10.61 0C16.13 0 20.61 4.48 20.61 10C20.61 12.04 20 13.93 18.95 15.51L17.49 14.05C18.2 12.86 18.61 11.48 18.61 10C18.61 5.59 15.02 2 10.61 2C9.13 2 7.75 2.41 6.55 3.12ZM16.27 7.53L14.86 6.12L12.21 8.77L13.62 10.18L16.27 7.53ZM18.39 20.61L16.12 18.34C14.54 19.39 12.65 20 10.61 20C5.09 20 0.61 15.52 0.61 10C0.61 7.96 1.22 6.07 2.27 4.49L0 2.22L1.41 0.81L19.79 19.19L18.39 20.61ZM14.67 16.88L10.79 13L9.2 14.59L4.96 10.35L6.37 8.94L9.2 11.77L9.38 11.59L3.73 5.94C3.02 7.14 2.61 8.52 2.61 10C2.61 14.41 6.2 18 10.61 18C12.09 18 13.47 17.59 14.67 16.88Z"
        fill="var(--green)"
      />
    </svg>
  )
}

const IconePessoaJuridica = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="var(--green)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text>J</text>
    </svg>
  )
}

const IconePessoaFisica = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="var(--green)"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text>F</text>
    </svg>
  )
}

const IconeUsuarios = () => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.6 6.875C3.728 6.875 0 7.79429 0 9.625V11H11.2V9.625C11.2 7.79429 7.472 6.875 5.6 6.875ZM1.872 9.42857C2.544 8.97286 4.168 8.44643 5.6 8.44643C7.032 8.44643 8.656 8.97286 9.328 9.42857H1.872ZM5.6 5.5C7.144 5.5 8.4 4.26643 8.4 2.75C8.4 1.23357 7.144 0 5.6 0C4.056 0 2.8 1.23357 2.8 2.75C2.8 4.26643 4.056 5.5 5.6 5.5ZM5.6 1.57143C6.264 1.57143 6.8 2.09786 6.8 2.75C6.8 3.40214 6.264 3.92857 5.6 3.92857C4.936 3.92857 4.4 3.40214 4.4 2.75C4.4 2.09786 4.936 1.57143 5.6 1.57143ZM11.232 6.92214C12.16 7.58214 12.8 8.46214 12.8 9.625V11H16V9.625C16 8.03786 13.2 7.13429 11.232 6.92214ZM10.4 5.5C11.944 5.5 13.2 4.26643 13.2 2.75C13.2 1.23357 11.944 0 10.4 0C9.968 0 9.568 0.102143 9.2 0.275C9.704 0.974286 10 1.83071 10 2.75C10 3.66929 9.704 4.52571 9.2 5.225C9.568 5.39786 9.968 5.5 10.4 5.5Z"
        fill="var(--background-medium-blue)"
      />
    </svg>
  )
}

const IconeContatos = () => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3 3.33333H1.7C0.765 3.33333 0 4.08333 0 5V15C0 15.9167 0.765 16.6667 1.7 16.6667H15.3C16.235 16.6667 17 15.9167 17 15V5C17 4.08333 16.235 3.33333 15.3 3.33333ZM15.3 15H1.7V5H15.3V15ZM1.7 0H15.3V1.66667H1.7V0ZM1.7 18.3333H15.3V20H1.7V18.3333ZM8.5 10C9.673 10 10.625 9.06667 10.625 7.91667C10.625 6.76667 9.673 5.83333 8.5 5.83333C7.327 5.83333 6.375 6.76667 6.375 7.91667C6.375 9.06667 7.327 10 8.5 10ZM8.5 7.08333C8.9675 7.08333 9.35 7.45833 9.35 7.91667C9.35 8.375 8.9675 8.75 8.5 8.75C8.0325 8.75 7.65 8.375 7.65 7.91667C7.65 7.45833 8.0325 7.08333 8.5 7.08333ZM12.75 13.325C12.75 11.5833 9.9365 10.8333 8.5 10.8333C7.0635 10.8333 4.25 11.5833 4.25 13.325V14.1667H12.75V13.325ZM5.7885 12.9167C6.307 12.4833 7.514 12.0833 8.5 12.0833C9.4945 12.0833 10.7015 12.4833 11.22 12.9167H5.7885Z"
        fill="var(--background-medium-blue)"
      />
    </svg>
  )
}

const IconeEmpresas = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.72727 6.90918H0V16.4092C0 17.3678 0.768636 18.1365 1.72727 18.1365H16.4091V16.4092H1.72727V6.90918Z"
        fill="var(--background-medium-blue)"
      />
      <path
        d="M14.6818 3.45455V1.72727C14.6818 0.777273 13.9045 0 12.9545 0H9.49998C8.54998 0 7.77271 0.777273 7.77271 1.72727V3.45455H3.45453V12.9545C3.45453 13.9045 4.2318 14.6818 5.1818 14.6818H17.2727C18.2227 14.6818 19 13.9045 19 12.9545V3.45455H14.6818ZM9.49998 1.72727H12.9545V3.45455H9.49998V1.72727ZM17.2727 12.9545H5.1818V5.18182H17.2727V12.9545Z"
        fill="var(--background-medium-blue)"
      />
    </svg>
  )
}

const IconeLupaPesquisa = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 15"
      fill="blue"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
        fill="white"
      />
    </svg>
  )
}

const icones = {
  IconeEditar,
  IconeExcluir,
  IconePermissoesDeUsuario,
  IconeCadastroDePara,
  IconeResponsavelSim,
  IconeResponsavelNao,
  IconePessoaJuridica,
  IconePessoaFisica,
  IconeUsuarios,
  IconeContatos,
  IconeEmpresas,
  IconeLupaPesquisa
}

export default icones
