/* eslint-disable camelcase */
import api from 'services/api'

async function getOutputFileCells (fileLayoutId, setOutputFileCells) {
  try {
    const { status, data } = await api.getAllOutputCellsLayout(fileLayoutId)
    if (status === 200) {
      setOutputFileCells(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function getOutputFileCellById (fileCellId) {
  try {
    const { data } = await api.getOutputCellById(fileCellId)

    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

function removeInvalidOptionalElements (values) {
  const newValues = { ...values }

  for (const key in newValues) {
    if ([
      'column',
      'char_start_position',
      'char_end_position',
      'fill_direction'
    ].find((item) => key === item)) {
      if (window.utils.isFalsy(newValues[key])) {
        delete newValues[key]
      }
    }
  }
  return newValues
}

async function handleAddOutputFileCell (values, setOutputFileCells) {
  try {
    const newValues = removeInvalidOptionalElements(values)

    const { status, data } = await api.createOutputCell(newValues)
    if (status === 201) {
      setOutputFileCells((oldCells) => [...oldCells, data])
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}
async function handleEditOutputFileCell (id, values, setOutputFileCells) {
  try {
    const newValues = removeInvalidOptionalElements(values)

    const { status, data } = await api.editOutputCell(id, newValues)
    if (status === 200) {
      setOutputFileCells((oldCells) => oldCells.map((oldCell) => oldCell.id === id ? data : oldCell))
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}
async function handleDeleteOutputFileCell (id, setOutputFileCells) {
  try {
    const { status, data } = await api.deleteOutputCell(id)
    if (status === 204) {
      setOutputFileCells((oldCells) => oldCells.filter((cell) => parseInt(cell.id) !== parseInt(id)))
    } else {
      console.error(data)
      throw new Error('Não foi possível realizar a operação')
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

export {
  getOutputFileCells,
  getOutputFileCellById,
  handleAddOutputFileCell,
  handleEditOutputFileCell,
  handleDeleteOutputFileCell
}
