import styled from 'styled-components'

export const ModalLayer = styled.section`
  display: ${(props) => props.display};
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`

export const ModalContainer = styled.article`
  display: grid;
  position: absolute;
  border-radius: 8px;
  grid-template-rows: 50px min-content auto min-content;
  top: 50%;
  left: 50%;
  min-height: 30%;
  transform: translate(-50%, -50%);
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  grid-template-areas:
    'header'
    'top'
    'middle'
    'bottom';
`
export const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  padding: 10px 15px;
  background-color: var(--background-medium-blue);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  & > h3 {
    margin-right: 25px;
  }
`
const ModalContent = styled.section`
  background-color: var(--white);
  padding: 0 15px;
`
export const ModalContentTop = styled(ModalContent)`
  min-height: 0px;
`
export const ModalContentMiddle = styled(ModalContent)`
  min-height: 0px;
`
export const ModalContentBottom = styled(ModalContent)`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`
