import React from 'react'
import PropTypes from 'prop-types'
import history from 'services/history'
import { Button } from 'components/Button'
import { Tooltip } from '@plick-solucoes-contabeis/ui-components'
function EditEmpresasButton({ rowValues }) {
  return (
    <Tooltip title="Empresas" delay={900}>
      <Button
        buttonFunction={'ButtonTable'}
        buttonIcon={'IconeEmpresas'}
        colorText={'var(--background-medium-blue)'}
        onClick={() => {
          history.push('/empresas', {
            id: rowValues.uuid,
            type: 'EscritorioRelation',
            relation: 'empresa'
          })
        }}
      />
    </Tooltip>
  )
}

EditEmpresasButton.propTypes = {
  rowValues: PropTypes.object
}
export default EditEmpresasButton
