import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'outputFileCell'
const url = '/output-file-cell'

const endpointsDescriptions = {
  getAllOutputCellsLayout: {
    action: 'getAll'
  },
  getOutputCellById: {
    action: 'getOne'
  },
  createOutputCell: {
    action: 'add'
  },
  editOutputCell: {
    action: 'update'
  },
  deleteOutputCell: {
    action: 'delete'
  }
}

const outputFileCell = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllOutputCellsLayout = (layoutId) => {
  const endpointDescription = outputFileCell.endpointsConfigs.getAllOutputCellsLayoutConfig
  return axios.get(
    `${baseUrl}/output-file-cell/layout/${layoutId}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getOutputCellById = (id) => {
  const endpointDescription = outputFileCell.endpointsConfigs.getOutputCellByIdConfig
  return axios.get(
    `${baseUrl}/output-file-cell/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createOutputCell = (body) => {
  const endpointDescription = outputFileCell.endpointsConfigs.createOutputCellConfig
  return axios.post(
    `${baseUrl}/output-file-cell`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.editOutputCell = (id, body) => {
  const endpointDescription = outputFileCell.endpointsConfigs.editOutputCellConfig
  return axios.put(
    `${baseUrl}/output-file-cell/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteOutputCell = (id) => {
  const endpointDescription = outputFileCell.endpointsConfigs.deleteOutputCellConfig
  return axios.delete(
    `${baseUrl}/output-file-cell/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
