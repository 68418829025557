const states = {
  title: '',
  display: false
}

function reducer(state, action) {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        display: true
      }
    case 'close':
      return {
        ...state,
        display: false
      }
    case 'setTitle':
      return {
        ...state,
        title: action.payload
      }
    default:
      return {
        title: '',
        display: false
      }
  }
}

export { states, reducer }
