import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, useField } from 'formik'
import OpenedEye from 'assets/Images/opened_eye.svg'

import {
  Label,
  InputContainer,
  InputStyle,
  ErrorMessage,
  PasswordEye
} from './styles'

export function Input({
  label,
  type,
  name,
  placeholder,
  toolTip,
  smallFont,
  min,
  max,
  disabled = false,
  customStyle,
  children,
  customChildren,
  ...props
}) {
  const [eyeToggle, setEyeToggle] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [field_, meta_] = useField(name)

  const handleEyeToggler = () => {
    setEyeToggle(!eyeToggle)
  }

  return (
    <>
      <InputContainer customStyle={customStyle}>
        <Label smallFont={smallFont}>{label}</Label>
        <Field name={name}>
          {({ field, meta }) => (
            <>
              <InputStyle
                type={eyeToggle ? 'text' : 'password'}
                placeholder={placeholder}
                disabled={disabled}
                {...field}
                {...props}
              />

              <PasswordEye src={OpenedEye} onClick={handleEyeToggler} />
            </>
          )}
        </Field>
      </InputContainer>
      {meta_.touched && meta_.error && (
        <ErrorMessage>{meta_.error}</ErrorMessage>
      )}
    </>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.any,
  placeholder: PropTypes.string,
  toolTip: PropTypes.string,
  smallFont: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  customStyle: PropTypes.string,
  customChildren: PropTypes.any,
  children: PropTypes.any
}
