/**
 * General function to check Falsy data
 * @param {any} param any param to check if Falsy
 * @returns {boolean}
 */
function isFalsy (param) {
  try {
    if (!param) { return true }
    return false
  } catch (err) {
    return true
  }
}

/**
   *  For data coming from HTTP calls,
   *  It's a check with a conversor which avoids sendind undefined to the components
   *  when for some reason the response fails
   * @param {Array} data Response from api calls you wanna treat
   * @returns {Array}
   */
function checkIfValidData (data) {
  if (window.utils.isFalsy(data)) {
    return []
  }
  return data
}

const dataValidationHelpers = {
  isFalsy,
  checkIfValidData
}

export default dataValidationHelpers
