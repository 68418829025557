const { useState, useLayoutEffect, useRef } = require('react')

export const useObserve = (
  { entries, reRenderWhen = () => false, windowEvents, runObserve = true },
  callback,
  deps
) => {
  // eslint-disable-next-line no-unused-vars
  const [_, setObserveState] = useState({})
  const observer = useRef({
    prev: null,
    actual: null
  })
  const ENTRIES_EQUAL_ONE = entries.length === 1

  useLayoutEffect(() => {
    let resizeObserver

    // const listeners = {
    //   resize: () => {
    //     console.log(
    //       '>>>>>>',
    //       observer.current.prev.contentRect.height,
    //       observer.current.actual.contentRect.height
    //     )
    //     windowEvents.resize()
    //   }
    // }

    if (runObserve) {
      resizeObserver = new ResizeObserver((entries) => {
        const entries_ = ENTRIES_EQUAL_ONE ? entries[0] : entries

        observer.current.prev = observer.current.actual
          ? observer.current.actual
          : entries_

        callback(entries_, observer.current.prev)

        observer.current.actual = entries_

        if (reRenderWhen(entries_)) {
          setObserveState(entries_)
        }
      })

      entries.forEach((entrie) => resizeObserver.observe(entrie.current))
      // window.addEventListener('resize', listeners.resize)
    }

    return () => {
      if (runObserve) {
        resizeObserver.disconnect()
        // window.removeEventListener('resize', listeners.resize)
      }
    }
  }, [...deps])

  return observer.current.actual
}
