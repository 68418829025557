import React from 'react'
import PropTypes from 'prop-types'
import * as logic from '../../subPages/IgnoresEntrada/ignoresEntradaLogic'
import * as Yup from 'yup'
import { Form } from 'components/Form'

import { InputComponent } from 'components/InputComponent'

import {
  Button,
  CheckBoxWrapper
} from '../../subPages/style'

import {
  IgnoresFormContainer
} from './style'

export function IgnoreForm ({
  ignoreInitialValues,
  setInputFileIgnores
}) {
  const handleSubmitIgnore = async (values) => {
    if (window.utils.isFalsy(values.id)) {
      await logic.handleAddInputFileIgnore(values, setInputFileIgnores)
    } else {
      await logic.handleEditInputFileIgnore(values.id, values, setInputFileIgnores)
    }
  }

  const schemaValidateIgnore = Yup.object().shape({
    discard_all_lines_below: Yup.bool()
      .when('discard_all_lines_above', {
        is: true,
        then: Yup.bool().isFalse('Não é possivel descartar todas as linhas acima e abaixo ao mesmo tempo'),
        otherwise: Yup.bool()
      }),
    discard_all_lines_above: Yup.bool()
      .when('discard_all_lines_below', {
        is: true,
        then: Yup.bool().isFalse('Não é possivel descartar todas as linhas acima e abaixo ao mesmo tempo'),
        otherwise: Yup.bool()
      })
  }, ['discard_all_lines_above', 'discard_all_lines_below'])

  return (
    <>
      <IgnoresFormContainer>
      <header>
        <h3>
          {ignoreInitialValues.update
            ? 'Editar Regra de Ignore'
            : 'Cadastrar nova regra de Ignore'
          }
        </h3>
      </header>
      <Form
        handleSubmit={handleSubmitIgnore}
        initialValues={ignoreInitialValues}
        schemaValidate={schemaValidateIgnore}
        reset={true}
      >
        <InputComponent label='Pedaço de texto a ser ignorado:' type='text' name='ignore_text_sample'/>
        <CheckBoxWrapper>
            <InputComponent label="Ignorar todas linhas acima" type='checkbox' name='discard_all_lines_above' />
        </CheckBoxWrapper>
        <CheckBoxWrapper>
            <InputComponent label="Ignorar todas linhas abaixo" type='checkbox' name='discard_all_lines_below' />
        </CheckBoxWrapper>
        <Button type='submit' >
            {!ignoreInitialValues.update ? 'Criar' : 'Editar'}
        </Button>
      </Form>
      </IgnoresFormContainer>
    </>
  )
}

IgnoreForm.propTypes = {
  ignoreInitialValues: PropTypes.object,
  setInputFileIgnores: PropTypes.func
}
