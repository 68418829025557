/* eslint-disable camelcase */
import api from 'services/api'

async function getInputIgnores (fileLayoutId, setInputIgnores) {
  try {
    const { status, data } = await api.getInputIgnores(fileLayoutId)
    if (status === 200) {
      setInputIgnores(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function getInputIgnoreById (ignoreId) {
  try {
    const { data } = await api.getInputIgnoreById(ignoreId)

    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}
function convertBooleans (values) {
  const newValues = { ...values }

  for (const key in newValues) {
    if ([
      'discard_all_lines_below',
      'discard_all_lines_above'
    ].find((item) => key === item)) {
      if (newValues[key]) {
        newValues[key] = 1
      } else {
        newValues[key] = 0
      }
    }
  }
  return newValues
}

async function handleAddInputFileIgnore (values, setInputFileIgnores) {
  try {
    const newValues = convertBooleans(values)

    const { status, data } = await api.createInputIgnore(newValues)
    if (status === 201) {
      setInputFileIgnores((oldIgnores) => [...oldIgnores, data])
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}
async function handleEditInputFileIgnore (id, values, setInputFileIgnores) {
  try {
    const newValues = convertBooleans(values)

    const { status, data } = await api.editInputIgnore(id, newValues)
    if (status === 200) {
      setInputFileIgnores((oldIgnores) => oldIgnores.map((oldIgnore) => oldIgnore.id === id ? data : oldIgnore))
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function handleDeleteInputFileIgnore (id, setInputFileIgnores) {
  try {
    const { status, data } = await api.deleteInputIgnore(id)
    if (status === 204) {
      setInputFileIgnores((oldIgnores) => oldIgnores.filter((ignore) => parseInt(ignore.id) !== parseInt(id)))
    } else {
      console.error(data)
      throw new Error('Não foi possível realizar a operação')
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

export {
  getInputIgnores,
  getInputIgnoreById,
  handleAddInputFileIgnore,
  handleEditInputFileIgnore,
  handleDeleteInputFileIgnore
}
