/* eslint-disable camelcase */
import api from 'services/api'

async function getInputFileCells (fileLayoutId, setInputFileCells) {
  try {
    const { status, data } = await api.getAllInputCellsLayout(fileLayoutId)
    if (status === 200) {
      setInputFileCells(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function getInputFileCellById (fileCellId) {
  try {
    const { data } = await api.getInputCellById(fileCellId)

    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

function removeInvalidOptionalElements (values) {
  const newValues = { ...values }

  for (const key in newValues) {
    if ([
      'column',
      'char_start_position',
      'char_end_position',
      'apply_to_every_line_in_sheet_as'
    ].find((item) => key === item)) {
      if (window.utils.isFalsy(newValues[key])) {
        delete newValues[key]
      }
    }
  }
  return newValues
}

async function handleAddInputFileCell (values, setInputFileCells) {
  try {
    const newValues = removeInvalidOptionalElements(values)

    const { status, data } = await api.createInputCell(newValues)
    if (status === 201) {
      setInputFileCells((oldCells) => [...oldCells, data])
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}
async function handleEditInputFileCell (id, values, setInputFileCells) {
  try {
    const newValues = removeInvalidOptionalElements(values)

    const { status, data } = await api.editInputCell(id, newValues)
    if (status === 200) {
      setInputFileCells((oldCells) => oldCells.map((oldCell) => oldCell.id === id ? data : oldCell))
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}
async function handleDeleteInputFileCell (id, setInputFileCells) {
  try {
    const { status, data } = await api.deleteInputCell(id)
    if (status === 204) {
      setInputFileCells((oldCells) => oldCells.filter((cell) => parseInt(cell.id) !== parseInt(id)))
    } else {
      console.error(data)
      throw new Error('Não foi possível realizar a operação')
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

export {
  getInputFileCells,
  getInputFileCellById,
  handleAddInputFileCell,
  handleEditInputFileCell,
  handleDeleteInputFileCell
}
