/* eslint-disable no-unused-vars */
import React from 'react'
import { useLocation } from 'react-router-dom'
import { SendEmailForm } from './components/SendEmailForm'
import { RedefinePasswordForm } from './components/RedefinePasswordForm'
import { ForgotFormContainer, ForgotFormError, Title } from './style'

export function ForgotForm() {
  const { hash } = useLocation()

  return (
    <ForgotFormContainer>
      {hash === '' ? <SendEmailForm /> : <RedefinePasswordForm hash={hash} />}
    </ForgotFormContainer>
  )
}
