import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as logic from './linhaSaidaLogic'

import { LinhaContainer } from '../../subComponents/LinhaContainer'
import { CabecalhoContainer } from '../../subComponents/CabecalhoContainer'

export function LinhaSaida ({ layoutId }) {
  const [row, setRow] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(async () => {
    await logic.getOutputRows(layoutId, setRow)
  }, [layoutId])

  useEffect(async () => {
    if (row.length > 0) {
      await logic.getOutputRowColumns(row[0].id, setColumns)
    }
  }, [row])

  const handleSubmitLinha = async (values) => {
    await logic.handleUpdateLine(values, setRow)
  }

  return (
    <>
    <CabecalhoContainer
      layoutId={layoutId}
    />
    {row.length > 0
      ? <LinhaContainer
        row={row[0]}
        handleSubmitLinha={handleSubmitLinha}
        columns={columns}
        setColumns={setColumns}
      />
      : ''
    }
    </>
  )
}

LinhaSaida.propTypes = {
  layoutId: PropTypes.number
}
