/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useLayoutEffect
} from 'react'
import PropTypes from 'prop-types'
import useNonInitialEffect from 'hooks/useNonInitialEffect'
import { LoginContext } from 'services/Auth/AuthContext'
import { useFormikContext, useField } from 'formik'
import api from 'services/api'
import InputSelectorInline from './InputSelectorInline'
import { WrapperSelectorInline } from 'components/FormCRUD/style'

const relationTypeStates = {
  visible: false,
  options: ['Escritorio', 'Empresa']
}
const escritorioSelectorStates = { visible: false, options: [] }
const empresaSelectorStates = { visible: false, options: [] }

function selectorsReducer(state, action) {
  switch (action.type) {
    case 'setVisible':
      return {
        ...state,
        visible: action.payload
      }
    case 'setOptions':
      return {
        ...state,
        options: action.payload
      }
    case 'setAll':
      return {
        ...action.payload
      }
  }
}

function InputSelectorContatoRelation() {
  const [visible, setVisible] = useState(false)

  const [haveRelationsField, haveRelationsMeta, haveRelationsHelpers] =
    useField('have_relations')
  const [relationTypeField, relationTypeMeta, relationTypeHelpers] =
    useField('type_relation')
  const [escritorioField, escritorioMeta, escritorioHelpers] =
    useField('escritorio_id')
  const [empresaField, empresaMeta, empresaHelpers] = useField('empresa_id')

  const [relationTypeState, dispatchRelationType] = useReducer(
    selectorsReducer,
    relationTypeStates
  )
  const [escritorioSelectorState, dispatchEscritorioSelector] = useReducer(
    selectorsReducer,
    escritorioSelectorStates
  )
  const [empresaSelectorState, dispatchEmpresaSelector] = useReducer(
    selectorsReducer,
    empresaSelectorStates
  )

  const { authStates } = useContext(LoginContext)

  const {
    values: { empresa, escritorio }
  } = useFormikContext()

  // Inicializa seletor type of relations com valor Escritorio
  useLayoutEffect(() => {
    relationTypeHelpers.setValue('Escritorio')
  }, [])

  /* 
    Em formulários de update, a relação virá dentro dos campos
    escritorio e empresa.
    Aqui, caso hajam valores no retorno da api
    atribuimos aos valores dos seletores de empresa e escritório
  */
  useLayoutEffect(() => {
    if (escritorio?.length > 0) {
      escritorioHelpers.setValue(escritorio[0].uuid)
      if (authStates.loggedUserData.role?.nome.search('plick') !== -1) {
        haveRelationsHelpers.setValue(true)
      }
    } else {
      escritorioHelpers.setValue('')
    }
  }, [])

  useLayoutEffect(() => {
    if (empresa?.length > 0) {
      escritorioHelpers.setValue(empresa[0].escritorio.uuid)
      empresaHelpers.setValue(empresa[0].uuid)
      relationTypeHelpers.setValue('Empresa')
      haveRelationsHelpers.setValue(true)
    } else {
      empresaHelpers.setValue('')
    }
  }, [])

  // Caso a caixa de escolha de relações seja desselecionada
  // resetamos todas as relações
  useNonInitialEffect(() => {
    if (!haveRelationsField.value) {
      dispatchRelationType({ type: 'setAll', payload: relationTypeStates })
      dispatchEscritorioSelector({
        type: 'setAll',
        payload: escritorioSelectorStates
      })
      relationTypeHelpers.setValue('Escritorio')
      dispatchEmpresaSelector({
        type: 'setAll',
        payload: empresaSelectorStates
      })
      empresaHelpers.setValue('')
    }
  }, [haveRelationsField.value])

  /* 
    Neste efeito, coletamos da API as opções de escritório
    Quando identificamos que o checkbox de relações estiver marcado
    Tendo como regra:
      - Usuário Plick:
        Coleta todos os escritórios e exibe o seletor de escritorios

      - Usuário Escritório:
          Atribui ao seletor de escritório o escritório do usuário.
          Remove exibição de seletores de tipo de relação e de escritório
  */
  useEffect(() => {
    setVisible(haveRelationsField.value)
    if (
      haveRelationsField.value &&
      authStates.loggedUserData?.role?.nome.search('plick') !== -1
    ) {
      dispatchRelationType({ type: 'setVisible', payload: true })
      api
        .getAllEscritorio()
        .then((response) => {
          if (response?.status === 200) {
            if (response.data?.length > 0) {
              dispatchEscritorioSelector({ type: 'setVisible', payload: true })
              dispatchEscritorioSelector({
                type: 'setOptions',
                payload: response.data
              })
              if (escritorioField.value === '') {
                escritorioHelpers.setValue(response.data[0].uuid)
              }
            }
          }
        })
        .catch((error) => console.error(error))
    } else {
      if (authStates.loggedUserData.escritorio.length > 0) {
        dispatchEscritorioSelector({ type: 'setVisible', payload: false })
        dispatchEscritorioSelector({
          type: 'setOptions',
          payload: authStates.loggedUserData.escritorio
        })
        if (escritorioField.value === '') {
          escritorioHelpers.setValue(
            authStates.loggedUserData.escritorio[0].uuid
          )
        }
        dispatchRelationType({
          type: 'setAll',
          payload: {
            visible: false,
            options: ['Escritorio', 'Empresa']
          }
        })
        relationTypeHelpers.setValue('Empresa')
      }
    }
  }, [haveRelationsField.value, authStates.loggedUserData.role?.nome])

  /* 
    Neste efeito, coletamos da API as opções de empresas
    Ao identificar uma seleção de tipo de relacionamento com 'Empresa':
  */
  useNonInitialEffect(() => {
    if (
      haveRelationsField.value &&
      relationTypeField.value === 'Empresa' &&
      escritorioField.value !== ''
    ) {
      api
        .getEmpresasFromEscritorio(escritorioField.value)
        .then((response) => {
          if (response?.status === 200) {
            dispatchEmpresaSelector({ type: 'setVisible', payload: true })
            dispatchEmpresaSelector({
              type: 'setOptions',
              payload: response.data
            })
            if (empresaField.value === '') {
              empresaHelpers.setValue(response.data[0].uuid)
            }
          }
        })
        .catch((error) => console.error(error))
    } else {
      dispatchEmpresaSelector({
        type: 'setAll',
        payload: {
          visible: false,
          options: []
        }
      })
      empresaHelpers.setValue('')
    }
  }, [haveRelationsField.value, relationTypeField.value, escritorioField.value])

  return visible ? (
    <div>
      <InputSelectorRelation
        relationType={relationTypeField.value}
        relationTypeState={relationTypeState}
        setRelationsField={relationTypeHelpers.setValue}
      />
      <InputSelectorEscritorioRelation
        escritorios={escritorioSelectorState}
        relationSelection={relationTypeField.value}
      />
      <InputSelectorEmpresaRelation
        escritorioSelectorState={escritorioSelectorState}
        escritorio={escritorioField.value}
        setEscritorio={escritorioHelpers.setValue}
        empresa={empresa}
        empresaSelectorState={empresaSelectorState}
        setEmpresa={empresaHelpers.setValue}
        relationSelection={relationTypeField.value}
      />
    </div>
  ) : (
    <></>
  )
}

// ------------------------------------------------------ //

function InputSelectorRelation({
  relationType,
  relationTypeState,
  setRelationsField
}) {
  const handleChangeRelationSelection = (event) => {
    setRelationsField(event.target.value)
  }

  return (
    <WrapperSelectorInline visible={relationTypeState.visible}>
      <label>Atribuição:</label>
      <select value={relationType} onChange={handleChangeRelationSelection}>
        <option value="Escritorio">Escritório</option>
        <option value="Empresa">Empresa</option>
      </select>
    </WrapperSelectorInline>
  )
}
InputSelectorRelation.propTypes = {
  relationType: PropTypes.string,
  relationTypeState: PropTypes.object,
  setRelationsField: PropTypes.func
}

// ------------------------------------------------------ //

function InputSelectorEscritorioRelation({ escritorios, relationSelection }) {
  return escritorios.visible && escritorios.options.length > 0 ? (
    <InputSelectorInline
      name="escritorio_id"
      options={escritorios.options.map((escritorio) => ({
        value: escritorio.uuid,
        label: escritorio.nome
      }))}
      defaultLabel="Escritorio:"
    />
  ) : (
    <></>
  )
}
InputSelectorEscritorioRelation.propTypes = {
  escritorios: PropTypes.object,
  relationSelection: PropTypes.string
}

// ------------------------------------------------------ //

function InputSelectorEmpresaRelation({
  escritorioSelectorState,
  empresa,
  empresaSelectorState,
  setEmpresa,
  relationSelection
}) {
  /* 
    Ao identificar uma alteração no estado das opções de empresa
    Atribui ao campo de empresa a primeira dentre as opções.

    Mas caso haja uma opção vinda da API, só realiza a atribuição 
    caso a empresa não esteja presente dentre as opções.
  */
  useLayoutEffect(() => {
    const hasEmpresa = typeof empresa !== 'undefined' && empresa?.length > 0
    const hasEmpresasOptions = empresaSelectorState.options.length > 0

    if (hasEmpresa && hasEmpresasOptions) {
      const empresaInCurrentOptions = empresaSelectorState.options.find(
        (option) => option.uuid === empresa[0].uuid
      )

      if (typeof empresaInCurrentOptions === 'undefined') {
        setEmpresa(empresaSelectorState.options[0].uuid)
      }
    }

    if (!hasEmpresa && hasEmpresasOptions) {
      setEmpresa(empresaSelectorState.options[0].uuid)
    }
  }, [empresaSelectorState.options])

  return relationSelection === 'Empresa' &&
    empresaSelectorState.visible &&
    empresaSelectorState.options.length > 0 &&
    escritorioSelectorState.options.length > 0 ? (
    <InputSelectorInline
      name="empresa_id"
      options={empresaSelectorState.options.map((optionItem) => ({
        value: optionItem.uuid,
        label: optionItem.nome
      }))}
      defaultLabel="Empresa:"
    />
  ) : (
    <></>
  )
}
InputSelectorEmpresaRelation.propTypes = {
  escritorioSelectorState: PropTypes.object,
  empresa: PropTypes.array,
  empresaSelectorState: PropTypes.object,
  setEmpresa: PropTypes.func,
  relationSelection: PropTypes.string
}

// ------------------------------------------------------ //

export default InputSelectorContatoRelation
