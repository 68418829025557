/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import useNonInitialEffect from 'hooks/useNonInitialEffect'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { InputComponent } from 'components/InputComponent'
import * as logic from './validationRulesLogic'

import {
  ColumnValidationRulesSection
} from './style'

export function ValidationRulesCustomInputs () {
  const {
    values: { validation_rule_name, parameters, validation_rule_param_type },
    setFieldValue
  } = useFormikContext()

  const [validationParametersInputs, setValidationParametersInput] = useState(<></>)

  const [selectedInputMask, setSelectedInputMask] = useState(false)

  const [months, setMonths] = useState([])
  const [dateFormats, setDateFormats] = useState([])

  // UseEffect para pegar dados da api necessários a alguns campos
  useEffect(async () => {
    setMonths(await logic.getMonths())
    setDateFormats(await logic.getDateFormats())
  }, [])

  useNonInitialEffect(() => {
    setFieldValue('parameters.param1', '')
    if (validation_rule_param_type === '') {
      setValidationParametersInput(
        <>
          <InputComponent label='Amostra de texto:' type='text' name='parameters.param1' />
          <SelectReferenceInput/>
        </>
      )
    }
    if (validation_rule_param_type === 'cell') {
      setValidationParametersInput(
        <>
          <InputComponent label='Nome da Célula:' type='text' name='parameters.param1' />
          <SelectReferenceInput/>
        </>
      )
    }
    if (validation_rule_param_type === 'empresa') {
      setFieldValue('parameters.param1', 'nome')
      setValidationParametersInput(
        <>
          <SelectCampoEmpresaInput name='parameters.param1' />
          <SelectReferenceInput/>
        </>
      )
    }
  }, [validation_rule_param_type])

  // UseEffect para resetar ou inicializar campos de input dinâmicos
  useNonInitialEffect(() => {
    if (parameters.param1.length > 0) {
      setFieldValue('parameters.param1', '')
    }
    if (parameters.param2.length > 0) {
      setFieldValue('parameters.param2', '')
    }
    if (parameters.param3.length > 0) {
      setFieldValue('parameters.param3', '')
    }
    if (selectedInputMask) {
      setSelectedInputMask(false)
    }
    switch (validation_rule_name.trim()) {
      case 'Normaliza número':
        setSelectedInputMask('caractere-separador')
        break
      case 'Dentro do mês x':
        setFieldValue('parameters.param1', dateFormats[0])
        setFieldValue('parameters.param2', months[0].value)
        break
      case 'Transformar formato de data':
        setFieldValue('parameters.param1', dateFormats[0])
        setFieldValue('parameters.param2', dateFormats[0])
        break
      case 'Data maior que x':
      case 'Data menor que x':
      case 'Data maior ou igual a x':
      case 'Data entre x e y':
      case 'Data entre x e y, inclusive':
        setFieldValue('parameters.param1', dateFormats[0])
        break
    }
  }, [validation_rule_name])

  // UseEffect de regras e máscaras de campos
  useNonInitialEffect(() => {
    // Set mask effect
    if (selectedInputMask) {
      switch (selectedInputMask) {
        case 'caractere-separador':
          if (parameters.param1.length > 1) {
            setFieldValue('parameters.param1', parameters.param1.slice(0, 1))
          }
          break
      }
    }
  }, [parameters.param1])

  useEffect(() => {
    switch (validation_rule_name.trim()) {
      // Não recebem parâmetro:
      case 'Transformar em maiúsculas':
      case 'Transformar em minúsculas':
      case 'Tratar nome':
      case 'Remove decimais':
      case 'Arredonda número para cima':
      case 'Arredonda número para baixo':
      case 'Arredonda número':
      case 'E-mail':
      case 'URL':
      case 'Telefone':
      case 'CEP':
      case 'CNPJ':
      case 'CPF':
        setValidationParametersInput('')
        break

      // 1 input de tipo texto sem validação:
      case 'Texto igual':
      case 'Texto diferente':
      case 'Contém texto':
        setValidationParametersInput(
          <>
            <InputComponent label='Amostra de texto:' type='text' name='parameters.param1' />
            <SelectReferenceInput/>
          </>
        )
        break

        // 1 input de tipo texto sem validação com titulo personalizado
      case 'De / Para':
        setValidationParametersInput(
          <InputComponent label='Digite o tipo da constante:' type='text' name='parameters.param1' />
        )
        break

        // 1 input de tipo texto validação regex:
      case 'Compara regex':
      case 'Extrai texto com regex':
        setValidationParametersInput(
            <InputComponent label='Regex:' type='text' name='parameters.param1' />
        )
        break

        // 1 input de tipo texto limitação de 1 caractere separador:
      case 'Normaliza número':
        setValidationParametersInput(
            <InputComponent label='Caractere Separador:' type='text' name='parameters.param1' />
        )
        break

        // 1 input de tipo number:
      case 'Maior que x':
      case 'Menor que x':
      case 'Maior ou igual a x':
      case 'Menor ou igual a x':
      case 'Igual a x':
      case 'Diferente a x':
        setValidationParametersInput(
            <InputComponent label='Valor:' type='number' name='parameters.param1' />
        )
        break

        // 2 inputs do tipo number:
      case 'Entre x e y':
      case 'Entre x e y, inclusive':
        setValidationParametersInput(
            <>
                <InputComponent label='Valor menor:' type='number' name='parameters.param1' />
                <InputComponent label='Valor maior:' type='number' name='parameters.param2' />
            </>
        )
        break
        // 2 selects com tipos de formato de data disponíveis:
      case 'Transformar formato de data':
        setValidationParametersInput(
            <>
                <DateFormatInput label='Formato data da origem:' name='parameters.param1' dateFormats={dateFormats} />
                <DateFormatInput label='Formato data alvo:' name='parameters.param2'dateFormats={dateFormats} />
            </>
        )
        break

        // 1 selects com tipos de formato de data disponíveis e 1 input do tipo date:
      case 'Data maior que x':
      case 'Data menor que x':
      case 'Data maior ou igual a x':

        setValidationParametersInput(
            <>
                <DateFormatInput label='Formato:' name='parameters.param1' dateFormats={dateFormats} />
                <InputComponent label='Data:' type='date' name='parameters.param2' />
            </>
        )
        break

        // 1 selects com tipos de formato de data disponíveis e 1 select de mês:
      case 'Dentro do mês x':
        setValidationParametersInput(
          <>
            <DateFormatInput label='Formato:' name='parameters.param1' dateFormats={dateFormats} />
            <SelectMonthInput name='parameters.param2' months={months} />
          </>
        )
        break

        // 1 selects com tipos de formato de data disponíveis e 2 select de mês:
      case 'Data entre x e y':
      case 'Data entre x e y, inclusive':

        setValidationParametersInput(
          <>
              <DateFormatInput label="Formato:" name='parameters.param1' dateFormats={dateFormats} />
              <InputComponent label='Data Inicial:' type='date' name='parameters.param2' />
              <InputComponent label='Data Final:' type='date' name='parameters.param3' />
          </>
        )
        break
    }
  }, [validation_rule_name])

  return validationParametersInputs !== ''
    ? <ColumnValidationRulesSection>{validationParametersInputs}</ColumnValidationRulesSection>
    : ''
}

const DateFormatInput = (props) => {
  return (<InputComponent label={props.label} type='select' name={props.name}>
      {props.dateFormats.map((dateFormat, index) => {
        return (
          <option key={index} value={dateFormat}>
              {dateFormat}
          </option>
        )
      })}
  </InputComponent>)
}

const SelectMonthInput = (props) => {
  return (<InputComponent label='Selecione o mês:' type='select' name={props.name}>
      {props.months.map((month, index) => {
        return (
          <option key={index} value={month.value}>
              {month.name}
          </option>
        )
      })}
  </InputComponent>)
}

const SelectReferenceInput = () => {
  return (<InputComponent label='Referência:' type='select' name='validation_rule_param_type'>
    <option value=''>
        Sem Referência
    </option>
    <option value='cell'>
        Célula
    </option>
    <option value='empresa'>
        Empresa
    </option>
  </InputComponent>)
}

const SelectCampoEmpresaInput = (props) => {
  return (<InputComponent label='Dado da Empresa :' type='select' name={props.name}>
    <option value='nome' title="Nome da Empresa">
        Nome
    </option>
    <option value='tipo_pessoa' title="Fisica ou Juridica">
        Tipo de pessoa
    </option>
    <option value='cpf_cnpj' title="CNPJ ou CPF da Empresa">
        Nº Documento
    </option>
  </InputComponent>)
}

DateFormatInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  dateFormats: PropTypes.array
}
SelectMonthInput.propTypes = {
  name: PropTypes.string,
  months: PropTypes.array
}
SelectCampoEmpresaInput.propTypes = {
  name: PropTypes.string
}
