import styled from 'styled-components'

export const SearchButton = styled.button`
  background: var(--green);
  border-radius: 0 5px 5px 0;
  width: 36px;
  height: 38px;
  /* margin-right: 10px; */
  padding: 5px;
`
