const states = {
  title: '',
  errors: [],
  display: false
}

function reducer (state, action) {
  switch (action.type) {
    case 'setErrors':
      return {
        ...state,
        errors: action.payload
      }
    case 'setTitle':
      return {
        ...state,
        title: action.payload
      }
    case 'setDisplay':
      return {
        ...state,
        display: action.payload
      }
    default:
      return {
        title: '',
        errors: [],
        display: false
      }
  }
}

export {
  states,
  reducer
}
