import styled from 'styled-components'
import {
  setWrapperGrid,
  setHeaderLayout,
  setMainContentMenuLayout,
  setMainContexMenuLayout,
  setFooterLayout
} from './setLayout'

export const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: fit-content(300px) auto;
  ${({ layoutType }) => setWrapperGrid(layoutType)}

  transition: all 0.3s;
`

export const Header = styled.header`
  ${({ layoutType }) => setHeaderLayout(layoutType)}
  grid-area: header;
  background-color: var(--white);
  border-bottom: var(--green) solid 1px;
  align-items: center;
`
export const HeaderTitle = styled.h3`
  color: var(--green);
  font-weight: bold;
  font-size: 16px;
  margin: 3px 0 0 42px;
`
export const Aside = styled.aside`
  grid-area: aside;
  display: flex;
  border-right: solid 1px var(--green);
  ul {
    list-style-type: none;
    flex: 0 1 80%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  a,
  label {
    display: block;
    text-align: center;
    border-radius: 10px;
    padding: 10px 0;
    text-decoration: none;
    font-weight: bold;
    color: white;
  }
`
export const Main = styled.main`
  position: relative;
  grid-area: content;
  display: grid;
  grid-template-rows: auto 1fr auto;
`

export const MainTopBar = styled.section`
  ${({ layoutType }) => setHeaderLayout(layoutType)}
  background-color: var(--white);
  height: 60px;
  align-items: center;
  padding: 0 41px;
  position: sticky;
  box-shadow: 0 4px 4px 0 rgb(0, 0, 0, 0.25);
`

export const MainContent = styled.section`
  ${({ layoutType }) => setMainContentMenuLayout(layoutType)}
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: overlay;

  > *:not(.nomargin) {
    margin-left: 41px;
  }
`
export const ContextMenu = styled.section`
  ${({ layoutType }) => setMainContexMenuLayout(layoutType)}
  position: relative;
  width: 100%;
  height: ${(props) => props.height};
  background-color: var(--white);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  overflow-y: overlay;
  transition: all 0.3s;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 46px auto;
  padding-top: 3px;
  z-index: 1;
`
export const ContextMenuToggle = styled.section`
  content: '';
  position: absolute;
  bottom: ${(props) => props.bottom};
  right: 70px;
  width: 50px;
  height: 25px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: var(--blue);
  display: flex;
  align-content: center;
  justify-content: center;
  transition: all 0.3s;
`

export const Draggy = styled.div`
  position: absolute;
  width: 100%;
  height: 43px;
  /* cursor: row-resize; */
  cursor: n-resize;
  display: flex;
  justify-content: flex-end;
  > svg {
    margin: 10px 35px;
    height: 30px;
    width: 30px;
    border-radius: 8px;
  }

  /* cursor: col-resize;*/
  /* cursor: w-resize; */
  /* cursor: s-resize; */
  /* cursor: n-resize; */
`

export const Footer = styled.footer`
  ${({ layoutType }) => setFooterLayout(layoutType)};
  flex: 0 0 65px;
  width: 100%;
  background-color: hsl(220, 17%, 93%);
  color: hsl(221, 13%, 46%);
  align-items: center;
  padding: 0 41px;
  margin: 50px 0 0 0;

  p {
    font-size: 14px;
  }
`
