// alguns textos de exibição são formatados a partir dos nomes de cada endpoint da api
export default function getHumanNameForEndpoint (stringRepresentingEndpointName, pluralOuSingular) {
  if (pluralOuSingular === 'plural') {
    switch (stringRepresentingEndpointName.toLowerCase()) {
      case 'contatos':
        return 'Contatos'
      case 'sistemagestao':
        return 'Sistemas de Gestão'
      case 'escritorio':
        return 'Escritórios'
      case 'sistemacontabil':
        return 'Sistemas Contábeis'
      case 'empresas':
        return 'Empresas'
      case 'usuarios':
        return 'Usuários'
      default:
        return 'string recebida não tem correspondente'
    }
  } else {
    switch (stringRepresentingEndpointName.toLowerCase()) {
      case 'contatos':
        return 'Contato'
      case 'sistemagestao':
        return 'Sistema de Gestão'
      case 'escritorio':
        return 'Escritório'
      case 'sistemacontabil':
        return 'Sistema Contábil'
      case 'empresas':
        return 'Empresa'
      case 'usuarios':
        return 'Usuário'
      default:
        return 'string recebida não tem correspondente'
    }
  }
}
