import React from 'react'
import PropTypes from 'prop-types'

const niveisDeAcesso = [
  {
    apiValue: 'manage',
    label: 'Gerenciar',
    color: 'orange'
  },
  {
    apiValue: 'read',
    label: 'Acessar',
    color: 'green'
  },
  {
    apiValue: 'none',
    label: 'Nenhum',
    color: 'red'
  }
]

function RolePermissionLevelOptions({ permissionLevel }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {niveisDeAcesso.map((nivelDeAcesso, index) => (
        <button
          style={{
            color: 'white',
            backgroundColor: `${
              permissionLevel === nivelDeAcesso.apiValue
                ? nivelDeAcesso.color
                : '#ccc'
            }`,
            padding: '5px 10px',
            borderRadius: '5px'
          }}
          key={index}
        >
          {nivelDeAcesso.label}
        </button>
      ))}
    </div>
  )
}

RolePermissionLevelOptions.propTypes = {
  permissionLevel: PropTypes.string
}
export default RolePermissionLevelOptions
