const extraSmall = 'xs'
const small = 'sm'
const medium = 'md'
const large = 'lg'
const extralarge = 'xl'
const fullWidth = 1

const defaultHeight = '44px'

const WIDTH = {
  auto: 'auto',
  [extraSmall]: '30px',
  [small]: '50px',
  [medium]: '100px',
  [large]: '300px',
  [extralarge]: '500px',
  [fullWidth]: '100%'
}
const HEIGHT = {
  auto: 'auto',
  [extraSmall]: '30px',
  [small]: defaultHeight,
  [medium]: defaultHeight,
  [large]: defaultHeight,
  [extralarge]: defaultHeight,
  [fullWidth]: defaultHeight
}

export { WIDTH, HEIGHT }
