import React, { useState, useEffect, useContext } from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import api from 'services/api'

import { LoginContext } from 'services/Auth/AuthContext'
// import { InputSelectContainer } from '../style'

function InputSelectorRoleID({ name }) {
  const { authStates } = useContext(LoginContext)
  const [roleIDs, setRoleIDs] = useState([])

  useEffect(async () => {
    const resposta = await api.getAllRoles()
    if (authStates.loggedUserData?.role?.nome.search('plick') !== -1) {
      setRoleIDs(resposta.data)
    } else {
      setRoleIDs(
        resposta.data.filter((role) => role.nome.includes('escritorio'))
      )
    }
  }, [authStates.loggedUserData])

  return (
    <label htmlFor={name} style={{ display: 'flex', margin: '10px 0' }}>
      <span style={{ width: '50%', color: 'var(--text-color-dark)' }}>
        Cargo:
      </span>
      <Field name={name}>
        {({ field }) => {
          return (
            <select
              {...field}
              style={{
                marginLeft: '10px',
                width: '50%',
                borderRadius: '3px',
                border: '0',
                borderBottom: '1px solid rgb(118, 118, 118)',
                height: '1.7rem',
                backgroundColor: 'var(--white)'
              }}
            >
              <option value="" disabled hidden>
                ...
              </option>
              {roleIDs &&
                roleIDs.map((role, index) => (
                  <option key={index} value={role.id}>
                    {role.nome}
                  </option>
                ))}
            </select>
          )
        }}
      </Field>
    </label>
  )
}

InputSelectorRoleID.propTypes = {
  name: PropTypes.string
}
export default InputSelectorRoleID
