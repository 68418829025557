/* eslint-disable camelcase */
import api from 'services/api'

async function getAllOutputLayouts(setOutputFileLayouts) {
  try {
    const { status, data } = await api.getAllOutputLayouts()
    if (status === 200) {
      setOutputFileLayouts(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

function treatValues(values) {
  const newValues = { ...values }

  newValues.default = newValues.default ? 1 : 0

  if (newValues.delimiter_char === '') {
    delete newValues.delimiter_char
  }

  return newValues
}

async function createOutputLayout(values, setOutputFileLayouts) {
  try {
    const newValues = treatValues(values)
    const { status, data } = await api.createOutputLayout(newValues)
    if (status === 201) {
      const linhaParams = {
        nome: 'Linha Padrão',
        row_lines: 1,
        row_columns: 1,
        file_layout_id: data.id
      }
      await api.createOutputRow(linhaParams)
      setOutputFileLayouts((oldOutputLayouts) => [...oldOutputLayouts, data])
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function editOutputLayout(id, values, setOutputFileLayouts) {
  try {
    const newValues = treatValues(values)

    const { status, data } = await api.editOutputLayout(id, newValues)
    if (status === 200) {
      setOutputFileLayouts((oldOutputLayouts) =>
        oldOutputLayouts.map((oldOutputLayout) =>
          oldOutputLayout.id === id ? data : oldOutputLayout
        )
      )
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function deleteOutputLayout(id, setOutputFileLayouts) {
  try {
    const { status, data } = await api.deleteOutputLayout(id)
    if (status === 204) {
      setOutputFileLayouts((oldOutputLayouts) =>
        oldOutputLayouts.filter(
          (oldOutputLayout) => parseInt(oldOutputLayout.id) !== parseInt(id)
        )
      )
    } else {
      console.error(data)
      throw new Error('Não foi possível realizar a operação')
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function duplicateOutputLayout(id, setOutputFileLayouts) {
  try {
    const { status, data } = await api.duplicateOutputLayout(id)
    if (status === 200) {
      setOutputFileLayouts((oldOutputLayouts) => [...oldOutputLayouts, data])
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function getAllSistemaContabil(setSistemasContabil) {
  try {
    const { status, data } = await api.getAllSistemaContabil()
    if (status === 200) {
      setSistemasContabil(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

export {
  getAllOutputLayouts,
  // getOutputLayoutBySistemaGestao,
  createOutputLayout,
  editOutputLayout,
  deleteOutputLayout,
  duplicateOutputLayout,
  getAllSistemaContabil
}
