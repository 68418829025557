import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import { InputContainer, Label, TextFieldInput } from './../FormCRUD/style'

function TextFieldInline({ label, ...props }) {
  const [field] = useField(props)
  return (
    <InputContainer
      className={'InputContainer'}
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: '10px 0',
        alignItems: 'end'
      }}
    >
      <Label htmlFor={field.name} style={{ width: '50%' }}>
        {label}
      </Label>
      <TextFieldInput
        type="text"
        autoComplete="off"
        autoFocus={label === 'Nome'}
        {...field}
        {...props}
        style={{ width: '50%', marginLeft: '10px' }}
      />
    </InputContainer>
  )
}

TextFieldInline.propTypes = {
  label: PropTypes.string
}

export default TextFieldInline
