// alguns textos de exibição são formatados a partir dos nomes de cada endpoint da api
export const features = [
  {
    name: 'sistemas-contabeis',
    humanName: 'Sistemas Contábeis',
    id: 1
  },
  {
    name: 'roles',
    humanName: 'Cargos',
    id: 2
  },
  {
    name: 'usuarios',
    humanName: 'Usuários',
    id: 3
  },
  {
    name: 'empresas',
    humanName: 'Empresas',
    id: 4
  },
  {
    name: 'escritorios',
    humanName: 'Escritórios',
    id: 5
  },
  {
    name: 'contatos',
    humanName: 'Contatos',
    id: 6
  },
  {
    name: 'tipo-movimento-categorias',
    humanName: 'Categorias de tipos de movimentos',
    id: 7
  },
  {
    name: 'tipo-movimentos',
    humanName: 'Tipos de Movimentos',
    id: 8
  },
  {
    name: 'sistema-gestao',
    humanName: 'Sistemas de Gestão',
    id: 9
  },
  {
    name: 'balancetes-planos-contas',
    humanName: 'Balancetes de planos de contas',
    id: 10
  },
  {
    name: 'arquivos-originais',
    humanName: 'Arquivos Originais',
    id: 11
  },
  {
    name: 'input-file-layout',
    humanName: 'Layout de arquivos de entrada',
    id: 12
  },
  {
    name: 'sanitization-rules',
    humanName: 'Regras de Sanitização',
    id: 13
  },
  {
    name: 'input-file-ignore',
    humanName: 'Ignores de arquivos de entrada',
    id: 14
  },
  {
    name: 'input-file-column',
    humanName: 'Colunas de arquivos de entrada',
    id: 15
  },
  {
    name: 'input-file-cells',
    humanName: 'Células de arquivos de entrada',
    id: 16
  },
  {
    name: 'input-file-row-format',
    humanName: 'Formato de fileiras de arquivos de entrada',
    id: 17
  },
  {
    name: 'column-validation-rules',
    humanName: 'Regras de validação de colunas',
    id: 19
  },
  {
    name: 'cell-validation-rules',
    humanName: 'Regras de validação de células',
    id: 20
  },
  {
    name: 'output-file-layout',
    humanName: 'Layout de arquivos de saída',
    id: 21
  },
  {
    name: 'output-file-row-format',
    humanName: 'Formato de fileiras de arquivos de saída',
    id: 22
  },
  {
    name: 'output-file-column',
    humanName: 'Colunas de arquivos de saída',
    id: 23
  },
  {
    name: 'output-file-cell',
    humanName: 'Células de arquivos de saída',
    id: 24
  },
  {
    name: 'output-file-header',
    humanName: 'Cabeçalhos de arquivos de saída',
    id: 25
  }
]

// alguns textos de exibição são formatados a partir dos nomes de cada endpoint da api
export const humanNameFor = {
  'sistemas-contabeis': 'Sistemas Contábeis',
  roles: 'Cargos',
  usuarios: 'Usuários',
  empresas: 'Empresas',
  escritorios: 'Escritórios',
  contatos: 'Contatos',
  'tipo-movimento-categorias': 'Categorias de tipos de movimentos',
  'tipo-movimentos': 'Tipos de Movimentos',
  'sistema-gestao': 'Sistemas de Gestão',
  'balancetes-planos-contas': 'Balancetes de planos de contas',
  'arquivos-originais': 'Arquivos Originais',
  'input-file-layout': 'Layout de arquivos de entrada',
  'sanitization-rules': 'Regras de Sanitização',
  'input-file-ignore': 'Ignores de arquivos de entrada',
  'input-file-column': 'Colunas de arquivos de entrada',
  'input-file-cells': 'Células de arquivos de entrada',
  'input-file-row-format': 'Formato de fileiras de arquivos de entrada',
  'column-validation-rules': 'Regras de validação de colunas',
  'cell-validation-rules': 'Regras de validação de células',
  'output-file-layout': 'Layout de arquivos de saída',
  'output-file-row-format': 'Formato de fileiras de arquivos de saída',
  'output-file-column': 'Colunas de arquivos de saída',
  'output-file-cell': 'Células de arquivos de saída',
  'output-file-header': 'Cabeçalhos de arquivos de saída'
}
