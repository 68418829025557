import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

function InputSelector({ options, name, defaultLabel }) {
  return (
    <Field
      as="select"
      name={name}
      style={{
        marginLeft: '10px',
        width: 'calc(100% - 20px)'
      }}
    >
      <option value="" selected disabled hidden>
        {defaultLabel}
      </option>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </Field>
  )
}

InputSelector.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  defaultLabel: PropTypes.string
}
export default InputSelector
