import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TipoCellContainer = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
  height: 40px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.8em;
  }
  ::-webkit-scrollbar-track {
    background-color: var(--text-unfocus);
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--text-color-dark);
    border: 0.3em solid var(--text-unfocus);
  }
`
const TipoCellItem = styled.p`
  flex: 0 1 clamp(60px, 25%, 60px);
  height: 18px;
  background-color: var(--light-gray);
  border: 1px solid;
  padding: 1px 3px;
  border-radius: 5px;
`

function TipoCell({ value }) {
  const parsed = JSON.parse(value)
  const title = parsed.join(', ')
  const parsedElements = parsed.map((tipo, index) => (
    <TipoCellItem key={index}>{tipo}</TipoCellItem>
  ))
  return <TipoCellContainer title={title}>{parsedElements}</TipoCellContainer>
}

TipoCell.propTypes = {
  value: PropTypes.string
}
export default TipoCell
