import styled from 'styled-components'

export const ForgotFormContainer = styled.article`
  margin-top: 100px;
  min-height: 40vh;

  & > div > form > div:nth-child(2) {
    margin-top: 25px;
  }

  & > div > form > div {
    gap: 5px;

    & > label {
      font-size: 14px;
    }
    & > input[type='text'],
    & > input[type='password'] {
      height: 44px;
      font-size: 16px;
      border: solid 1px #d0d5dd;
      border-radius: 8px;
    }
  }
  & > div > form > button {
    margin-top: 30px;
  }
`

export const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 600;
`

export const ForgotFormError = styled.p`
  font-size: 14px;
  text-align: center;
  padding: 5px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  background-color: red;
`
