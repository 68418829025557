import React, { useReducer, useContext, useState, useEffect } from 'react'
import useNonInitialEffect from 'hooks/useNonInitialEffect'
import PropTypes from 'prop-types'
import * as logic from '../cabecalhoContainerLogic'
import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'
import { PlusIcon, EditIcon, DeleteIcon } from 'assets/icons'
import { CabecalhoForm } from '../CabecalhoForm'

import { HeaderContainerCell, HeaderPositionBackground } from './style'

function controlContextMenuReducer(state, action) {
  switch (action.type) {
    case 'displayHeaderForm':
      return {
        ...state,
        cabecalhoFormInitialValues: action.value,
        showHeaderForm: true,
        toggleContextMenu: false
      }
    case 'removeHeaderForm':
      return {
        ...state,
        showHeaderForm: false
      }
    default:
      return {
        ...state
      }
  }
}

const contextMenuControllerInitialState = {
  cabecalhoFormInitialValues: {},
  showHeaderForm: false,
  toggleContextMenu: true
}

export function CabecalhoCell({
  headers,
  setHeaders,
  layoutId,
  rowId,
  headerIndex,
  columnChar,
  currentLine,
  currentColumn
}) {
  const { dispatchRestrictedLayout } = useContext(RestrictedLayoutContext)
  const [contextMenuController, dispatchContextMenuController] = useReducer(
    controlContextMenuReducer,
    contextMenuControllerInitialState
  )
  const [currentCabecalhoItem, setCurrentCabecalhoItem] = useState(false)

  const cabecalhoFormInitialValues = {
    file_layout_id: layoutId,
    value: '',
    row: rowId,
    column: currentColumn,
    char_start_position: '',
    char_end_position: '',
    fill_char: '',
    fill_direction: '',
    update: false
  }

  const substituteNullValues = (value) => {
    const newValues = { ...value }
    Object.entries(value).forEach((oldValues, index) => {
      if (oldValues[1] === null) {
        newValues[oldValues[0]] = ''
      }
    })
    return newValues
  }

  const handleDisplayForm = (value) => {
    dispatchContextMenuController({
      type: 'displayHeaderForm',
      value: {
        update: true,
        line: currentLine,
        column: currentColumn,
        ...substituteNullValues(value)
      }
    })
  }

  const handleDeleteColumn = (id) => {
    logic.deleteOutputHeader(id, setHeaders)
  }

  useNonInitialEffect(() => {
    dispatchRestrictedLayout({
      type: 'setToggleContextMenu',
      payload: contextMenuController.toggleContextMenu
    })
    dispatchRestrictedLayout({
      type: 'setContextMenuContent',
      payload: (
        <>
          <CabecalhoForm
            cabecalhoFormInitialValues={
              contextMenuController.cabecalhoFormInitialValues
            }
            setHeaders={setHeaders}
          />
        </>
      )
    })
  }, [
    contextMenuController.toggleContextMenu,
    contextMenuController.cabecalhoFormInitialValues
  ])

  const columnButtons = () => {
    if (headers.indexOf(currentCabecalhoItem) !== -1) {
      return (
        <>
          <button onClick={() => handleDisplayForm(currentCabecalhoItem)}>
            <EditIcon color="#3FA1D8" />
          </button>
          <button onClick={() => handleDeleteColumn(currentCabecalhoItem.id)}>
            <DeleteIcon color="#3FA1D8" />
          </button>
        </>
      )
    }
    return (
      <button onClick={() => handleDisplayForm(cabecalhoFormInitialValues)}>
        <PlusIcon color="#3FA1D8" />
      </button>
    )
  }

  useEffect(() => {
    if (headers.length > 0) {
      headers.forEach((header) => {
        if (currentColumn === header.column) {
          setCurrentCabecalhoItem(header)
        }
      })
    }
  }, [headers])

  return (
    <HeaderContainerCell key={headerIndex}>
      <HeaderPositionBackground
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
        hasContent={headers.indexOf(currentCabecalhoItem) !== -1}
      >
        <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
          {columnChar}-1
        </text>
      </HeaderPositionBackground>
      {columnButtons()}
    </HeaderContainerCell>
  )
}

CabecalhoCell.propTypes = {
  headers: PropTypes.array,
  setHeaders: PropTypes.func,
  layoutId: PropTypes.number,
  rowId: PropTypes.number,
  headerIndex: PropTypes.number,
  columnChar: PropTypes.string,
  currentLine: PropTypes.number,
  currentColumn: PropTypes.number
}
