import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { Form } from 'components/Form'
import * as Yup from 'yup'
import { InputComponent } from 'components/InputComponent'
import * as logic from './cabecalhoContainerLogic'
import { CabecalhoCell } from './CabecalhoCell'

import {
  CabecalhosContainerMain,
  CabecalhosContainerHeader,
  CabecalhosContainer,
  CabecalhoContainerHeader
} from './style'

export function CabecalhoContainer ({
  layoutId
}) {
  let initialChar = ''
  let previousColumn = 0
  let headerChar = ''

  const submitRowRef = useRef(null)

  const [flagUsarCabecalhos, setFlagUsarCabecalhos] = useState(false)
  const [layout, setLayout] = useState({})
  const [headers, setHeaders] = useState([])

  const [headersContainerProps, setHeadersContainerProps] = useState({ row: 1, columns: 1 })

  useEffect(() => {
    logic.getOutputLayoutById(layoutId, setLayout)
    logic.getAllOutputHeaders(layoutId, setHeaders)
  }, [])

  useEffect(() => {
    if (headers.length > 0) {
      setFlagUsarCabecalhos(true)
      const highestColumn = Math.max.apply(Math, headers.map((header) => header.column))
      setHeadersContainerProps({ ...headersContainerProps, columns: highestColumn })
    }
  }, [headers])

  const handleDisplayHeadersContainer = (values) => {
    setHeadersContainerProps(values)
  }

  const handleInputChange = () => {
    submitRowRef.current.click()
  }

  const schemaValidateHeader = Yup.object().shape({
    row:
      Yup.number().required()
        .min(1, 'Deve ser maior ou igual a um')
        .max(layout.start_row, 'Deve ser menor que linha inicial do formulário')
        .integer('Deve ser numérico'),
    columns: Yup.number().required()
      .min(1, 'Deve ser maior ou igual a um')
  })

  if (layout.start_row > 1) {
    return (
    <CabecalhosContainerMain>
        <CabecalhosContainerHeader>
        <h3>Cabeçalho:</h3>
        <div>
          <input type="checkbox" defaultChecked={flagUsarCabecalhos} onChange={() => setFlagUsarCabecalhos(!flagUsarCabecalhos)} />
          <label>Usar Cabeçalhos?</label>
        </div>
        {
        flagUsarCabecalhos
          ? <Form
            handleSubmit={handleDisplayHeadersContainer}
            initialValues={headersContainerProps}
            schemaValidate={schemaValidateHeader}
            reset={false}
          >
            <InputComponent label='Linha de inserção cabeçalho:' type='number' name='row' min={1} max={layout.start_row} onInput={handleInputChange} />
            <InputComponent label='№ colunas:' type='number' name='columns' min={1} onInput={handleInputChange} />
            <button type='submit' ref={submitRowRef}></button>
          </Form>
          : <></>
        }
        </CabecalhosContainerHeader>
        {
        flagUsarCabecalhos
          ? <CabecalhosContainer lines={1} columns={headersContainerProps.columns} >
            {
            [...Array(headersContainerProps.columns)].map((item, headersHeaderIndex) => {
              initialChar = logic.incrementChar(initialChar)
              return <CabecalhoContainerHeader key={`header_${headersHeaderIndex}`}> {initialChar} </CabecalhoContainerHeader>
            })
            }
            {
              [...Array(headersContainerProps.columns)].map((item, headerIndex) => {
                const currentColumn = headerIndex + 1

                if (currentColumn > previousColumn) {
                  previousColumn = currentColumn
                  headerChar = logic.incrementChar(headerChar)
                }

                return (<CabecalhoCell
                  headers={headers}
                  setHeaders={setHeaders}
                  layoutId={layoutId}
                  rowId={headersContainerProps.row}
                  headerIndex={headerIndex}
                  key={headerIndex}
                  columnChar={headerChar}
                  currentColumn={currentColumn}
                />)
              })
            }

          </CabecalhosContainer>
          : <></>
        }

    </CabecalhosContainerMain>
    )
  }

  return (<></>)
}

CabecalhoContainer.propTypes = {
  layoutId: PropTypes.number
}
