/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable no-undef */
// eslint-disable-next-line no-unused-vars
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import CustomRouter from './CustomRouter'
import { LAYOUT_TYPES } from 'layout/Master/Constants/LayoutTypes'

import { AlterarSenha } from 'pages/AlterarSenha'
// import Arquivos from 'pages/Arquivos'
import { AtualizarDados } from 'pages/AtualizarDados'
import { DetailsCadastroLayoutEntrada } from 'pages/LayoutEntrada/subPages'
import { DetailsCadastroLayoutSaida } from 'pages/LayoutSaida/subPages'
import { EsqueciMinhaSenha } from '../pages/EsqueciMinhaSenha'
import { LayoutEntrada } from '../pages/LayoutEntrada'
import { LayoutSaida } from '../pages/LayoutSaida'
import { Login } from 'pages/Login'
import Roles from 'pages/Roles'
// import SistemasContabeis from 'pages/SistemasContabeis'
// import { SistemasDeGestao } from 'pages/SistemasDeGestao'

import SpinnerFullpage from 'components/Spinner/Spinner.fullpage'

const makeLazy = (importFunc, selectorFunc) => {
  const load = () =>
    importFunc().then((module) => {
      return {
        default: selectorFunc(module)
      }
    })
  return lazy(load)
}

const DashboardLazy = makeLazy(
  () => import('pages/Dashboard'),
  (module) => module.Dashboard
)

const ContasLazy = makeLazy(
  () => import('pages/Contas'),
  (module) => module.Contas
)

const ContatosLazy = makeLazy(
  () => import('pages/Contatos'),
  (module) => module.default
)

const EmpresasLazy = makeLazy(
  () => import('pages/Empresas'),
  (module) => module.default
)

const EscritoriosLazy = makeLazy(
  () => import('pages/Escritorios'),
  (module) => module.default
)

const UsuariosLazy = makeLazy(
  () => import('pages/Usuarios'),
  (module) => module.default
)

const ArquivosLazy = makeLazy(
  () => import('pages/Arquivos'),
  (module) => module.default
)

const MinhaContaLazy = makeLazy(
  () => import('pages/MinhaConta'),
  (module) => module.default
)

const SistemasContabeisLazy = makeLazy(
  () => import('pages/SistemasContabeis'),
  (module) => module.default
)

const SistemasGestaoLazy = makeLazy(
  () => import('pages/SistemasDeGestao'),
  (module) => module.default
)

const DataflowsLazy = makeLazy(
  () => import('pages/Dataflows'),
  (module) => module.default
)

const DemandasLazy = makeLazy(
  () => import('pages/Demandas'),
  (module) => module.default
)

const Dashboard = (props) => {
  return (
    <Suspense fallback={<SpinnerFullpage />}>
      <DashboardLazy {...props} />
    </Suspense>
  )
}

const Contatos = (props) => {
  return (
    <Suspense fallback={<SpinnerFullpage />}>
      <ContatosLazy {...props} />
    </Suspense>
  )
}

const Usuarios = (props) => {
  return (
    <Suspense fallback={<SpinnerFullpage />}>
      <UsuariosLazy {...props} />
    </Suspense>
  )
}
const Empresas = (props) => {
  return (
    <Suspense fallback={<SpinnerFullpage />}>
      <EmpresasLazy {...props} />
    </Suspense>
  )
}

const Escritorios = (props) => {
  return (
    <Suspense fallback={<SpinnerFullpage />}>
      <EscritoriosLazy {...props} />
    </Suspense>
  )
}

const MinhaConta = (props) => {
  return (
    <Suspense fallback={<SpinnerFullpage />}>
      <MinhaContaLazy {...props} />
    </Suspense>
  )
}

const SistemasContabeis = (props) => {
  return (
    <Suspense fallback={<SpinnerFullpage />}>
      <SistemasContabeisLazy {...props} />
    </Suspense>
  )
}

const SistemasGestao = (props) => {
  return (
    <Suspense fallback={<SpinnerFullpage />}>
      <SistemasGestaoLazy {...props} />
    </Suspense>
  )
}

const Arquivos = (props) => {
  return (
    <Suspense fallback={<SpinnerFullpage />}>
      <ArquivosLazy {...props} />
    </Suspense>
  )
}

const Contas = (props) => {
  return (
    <Suspense fallback={<SpinnerFullpage />}>
      <ContasLazy {...props} />
    </Suspense>
  )
}

const Dataflows = (props) => {
  return (
    <Suspense fallback={<SpinnerFullpage />}>
      <DataflowsLazy {...props} />
    </Suspense>
  )
}

const Demandas = (props) => {
  return (
    <Suspense fallback={<SpinnerFullpage />}>
      <DemandasLazy {...props} />
    </Suspense>
  )
}

function Routes() {
  return (
    <Switch>
      <CustomRouter path="/" exact component={Login} />
      <CustomRouter
        path="/dashboard"
        isPrivate
        component={Dashboard}
        layoutType={LAYOUT_TYPES.TYPE_1}
      />

      <CustomRouter path="/recuperar-senha" component={EsqueciMinhaSenha} />

      <CustomRouter
        path="/atualizar-dados"
        isPrivate
        component={AtualizarDados}
        layoutType={LAYOUT_TYPES.CLASSICAL}
      />

      <CustomRouter
        path="/alterar-senha"
        isPrivate
        component={AlterarSenha}
        layoutType={LAYOUT_TYPES.CLASSICAL}
      />

      <CustomRouter
        path="/contatos"
        isPrivate
        component={Contatos}
        layoutType={LAYOUT_TYPES.CLASSICAL}
      />

      <CustomRouter
        path="/minha-conta"
        isPrivate
        component={MinhaConta}
        layoutType={LAYOUT_TYPES.TYPE_1}
      />
      <CustomRouter
        path="/usuarios"
        isPrivate
        component={Usuarios}
        layoutType={LAYOUT_TYPES.TYPE_1}
      />

      <CustomRouter
        path="/empresas"
        isPrivate
        component={Empresas}
        layoutType={LAYOUT_TYPES.TYPE_1}
      />

      <CustomRouter
        path="/escritorios"
        isPrivate
        component={Escritorios}
        layoutType={LAYOUT_TYPES.TYPE_1}
      />

      <CustomRouter
        path="/sistemas-contabeis"
        isPrivate
        component={SistemasContabeis}
        layoutType={LAYOUT_TYPES.TYPE_1}
      />

      <CustomRouter
        path="/sistema-gestao"
        isPrivate
        component={SistemasGestao}
        layoutType={LAYOUT_TYPES.TYPE_1}
      />

      <CustomRouter
        path="/arquivos-originais"
        isPrivate
        component={Arquivos}
        layoutType={LAYOUT_TYPES.TYPE_1}
      />

      <CustomRouter
        path="/roles"
        isPrivate
        component={Roles}
        layoutType={LAYOUT_TYPES.CLASSICAL}
      />

      <CustomRouter
        path="/input-file-layout"
        isPrivate
        component={LayoutEntrada}
        layoutType={LAYOUT_TYPES.CLASSICAL}
      />

      <CustomRouter
        path="/input-file-layout-details"
        isPrivate
        component={DetailsCadastroLayoutEntrada}
        layoutType={LAYOUT_TYPES.CLASSICAL}
      />

      <CustomRouter
        path="/output-file-layout"
        isPrivate
        component={LayoutSaida}
        layoutType={LAYOUT_TYPES.CLASSICAL}
      />

      <CustomRouter
        path="/output-file-layout-details"
        isPrivate
        component={DetailsCadastroLayoutSaida}
        layoutType={LAYOUT_TYPES.CLASSICAL}
      />

      <CustomRouter
        path="/contas"
        isPrivate
        component={Contas}
        layoutType={LAYOUT_TYPES.TYPE_1}
      />

      <CustomRouter
        path="/dataflows"
        isPrivate
        component={Dataflows}
        layoutType={LAYOUT_TYPES.TYPE_1}
      />
      <CustomRouter
        path="/demandas"
        isPrivate
        component={Demandas}
        layoutType={LAYOUT_TYPES.TYPE_1}
      />
    </Switch>
  )
}

export default Routes
