import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

import * as Reducer from './reducer'
import tableHooks from './Logic/Hooks'

const TableContext = createContext({})

function TableProvider({ children }) {
  const [tableConfigsState, dispatchTableConfigs] = useReducer(
    Reducer.tableReducer,
    Reducer.tableStates
  )

  return (
    <TableContext.Provider
      value={{
        tableHooks,
        tableConfigsState,
        dispatchTableConfigs
      }}
    >
      {children}
    </TableContext.Provider>
  )
}

TableProvider.propTypes = {
  children: PropTypes.array
}

export { TableContext, TableProvider }
