/* eslint-disable camelcase */
import api from 'services/api'

function nextLetter (char) {
  return char === 'Z' ? 'A' : String.fromCharCode(char.charCodeAt(0) + 1)
}

function incrementChar (l) {
  let newCharArray = []
  const lastChar = l[l.length - 1]
  const remString = l.slice(0, l.length - 1)
  const newChar = lastChar === undefined ? 'A' : nextLetter(lastChar)
  newCharArray.unshift(newChar)

  if (lastChar === 'Z') {
    return incrementChar(remString) + 'A'
  }

  const batchString = remString + [...newCharArray].join('')
  newCharArray = []
  return batchString
}

async function getOutputLayoutById (layoutId, setLayout) {
  try {
    const { status, data } = await api.getOutputLayoutById(layoutId)
    if (status === 200) {
      setLayout(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function getAllOutputHeaders (layoutId, setHeaders) {
  try {
    const { status, data } = await api.getAllOutputHeaders(layoutId)
    if (status === 200) {
      setHeaders(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function treatColumnParams (params) {
  // assigning to newParams to avoid a warning when trying to change params
  const newParams = { ...params }

  const { char_start_position, char_end_position, fill_char, fill_direction } = newParams

  if (char_start_position === '' || window.utils.isFalsy(char_start_position)) {
    delete newParams.char_start_position
  }
  if (char_end_position === '' || window.utils.isFalsy(char_end_position)) {
    delete newParams.char_end_position
  }
  if (fill_char === '' || window.utils.isFalsy(fill_char)) {
    delete newParams.fill_char
  }
  if (fill_direction === '' || window.utils.isFalsy(fill_direction)) {
    delete newParams.fill_direction
  }

  return newParams
}

async function createOutputHeader (params, setHeaders) {
  try {
    const newParams = await treatColumnParams(params)

    const { status, data } = await api.createOutputHeader(newParams)
    if (status === 201) {
      setHeaders((oldHeaders) => [...oldHeaders, data])
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}
async function editOutputHeader (params, setHeaders) {
  try {
    // assigning to newParams to avoid a warning when trying to change params
    const newParams = await treatColumnParams(params)

    const { status, data } = await api.editOutputHeader(params.id, newParams)
    if (status === 200) {
      setHeaders(
        (oldHeaders) => oldHeaders.map((oldHeaders) => oldHeaders.id === data.id ? data : oldHeaders)
      )
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}
async function deleteOutputHeader (id, setHeaders) {
  try {
    const { status, data } = await api.deleteOutputHeader(id)
    if (status === 204) {
      setHeaders((oldHeaders) => oldHeaders.filter((oldHeaders) => parseInt(oldHeaders.id) !== parseInt(id)))
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

export {
  getOutputLayoutById,
  incrementChar,
  getAllOutputHeaders,
  createOutputHeader,
  editOutputHeader,
  deleteOutputHeader
}
