import styled from 'styled-components'

export const Table = styled.table`
    margin-top: 25px;
    margin-right: 41px;
    & tbody {
        display: grid;
    }
    & thead, 
    & tr {
        display: grid;
        grid-auto-rows: 2.5rem;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
    }
    & thead { margin-bottom: 10px;}
    
    & tr:nth-child(even) {
        background-color: #F3F3F3;
    }

    & th, & td {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & th {
        color: hsl(215,89%,14%);
        font-size: 14px;
    }
    & td span {
        font-size: 12px;
        color: #7A8286;
        overflow-x: hidden;
        word-wrap: break-word;
        text-align: center;
    }
`
