/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useField } from 'formik'
import React, { forwardRef } from 'react'

export default function withFormikMenu(Component) {
  return forwardRef(function MyComponent(
    { errorConfig, formikConfig, stateConfig, handleSelect, ...rest },
    ref
  ) {
    const [field, meta, helpers] = useField({ ...formikConfig })
    const { state } = stateConfig

    const _handleSelect = (index) => {
      handleSelect(index)
      helpers.setValue(state.options[index].value)
    }

    let handleAdd = null
    let _handleAdd = null

    if (errorConfig.addItem) {
      _handleAdd = errorConfig.handleAdd

      handleAdd = async () => {
        const value = await _handleAdd()

        if (value) {
          helpers.setValue(value)
        }
      }

      errorConfig.handleAdd = handleAdd
    }

    return (
      <Component
        ref={ref}
        handleSelect={_handleSelect}
        errorConfig={errorConfig}
        stateConfig={stateConfig}
        {...rest}
      />
    )
  })
}
