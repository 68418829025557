import React, { useState, useReducer, useContext } from 'react'
import useNonInitialEffect from '../../useNonInitialEffect'
import { EventModalContext } from 'context/EventModalContext/EventModalContext'
import * as FeedbacksReducer from './FeedbacksReducer'
import BottomButtonsContainer from 'components/EventModal/EventsComponents/BottomButtonsContainer'
import { Button } from 'components/Button'
import { FeedbackContainer, FeedbackMessage } from './styles'

export function useFeedbackEvent(data) {
  const { dispatchEventModal } = useContext(EventModalContext)
  const [feedbackState, dispatchFeedbackStates] = useReducer(
    FeedbacksReducer.reducer,
    FeedbacksReducer.states
  )
  const [elementFeedback, setElementFeedback] = useState(<></>)

  useNonInitialEffect(() => {
    if (data && typeof data !== 'undefined') {
      const { title, message } = data
      dispatchFeedbackStates({ type: 'setTitle', payload: title })
      dispatchFeedbackStates({ type: 'setMessage', payload: message })
      dispatchFeedbackStates({ type: 'setDisplay', payload: true })
    }
  }, [data])

  useNonInitialEffect(() => {
    if (window.utils.isFalsy(feedbackState.errors?.length)) {
      setElementFeedback(
        <FeedbackContainer>
          <FeedbackMessage>{feedbackState.message}</FeedbackMessage>
        </FeedbackContainer>
      )
    }
  }, [feedbackState.message])

  useNonInitialEffect(() => {
    if (feedbackState.display) {
      dispatchEventModal({ type: 'setModalContentTop', payload: <></> })
      dispatchEventModal({
        type: 'setModalContentMiddle',
        payload: elementFeedback
      })
      dispatchEventModal({
        type: 'setModalContentBottom',
        payload: (
          <BottomButtonsContainer>
            <Button
              value="OK"
              buttonFunction="ButtonCustom"
              customStyle={`
                  background-color: var(--background-medium-blue);
                  border-radius: 5px;
                  width: 80px;
                  height: 30px;
                  padding: 5px;
                  color: var(--white);
                  font-size: 1em;
                  font-weight: bold;
                `}
              onClick={() => window.eventBus.dispatch('closeModal')}
            />
          </BottomButtonsContainer>
        )
      })
      window.eventBus.dispatch('openModal', { title: feedbackState.title })
    }
  }, [feedbackState, elementFeedback])
}
