/* eslint-disable camelcase */
import api from 'services/api'
import { getAllRegrasSanitizacao } from '../../subComponents/SanitizationRuleForm/sanitizationRuleLogic'

async function handleAddLine (
  nome,
  rowLines,
  rowColumns,
  fileLayoutId,
  setRows,
  setColumns
) {
  try {
    const { status, data } = await api.createRow({ nome: nome, row_lines: rowLines, file_layout_id: fileLayoutId })
    if (status === 201) {
      setRows((oldRows) => [...oldRows, data])
      await handleAddColumn(data.nome, fileLayoutId, data.id, data.row_lines, rowColumns, setColumns)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    if (error.response.status === 401) {
    //   setHaveError(true)
      console.error(error)
    }
  }
}

async function handleUpdateLine (
  id,
  nome,
  rowLines,
  fileLayoutId,
  setRows
) {
  try {
    const { status, data } = await api.editRow(id, { nome: nome, row_lines: rowLines, file_layout_id: fileLayoutId })
    if (status === 200) {
      setRows((oldRows) => oldRows.map((oldRow) => oldRow.id === id ? data : oldRow))
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    if (error.response.status === 401) {
    //   setHaveError(true)
      console.error(error)
    }
  }
}

async function handleDeleteLine (id, fileLayoutId, setRows) {
  try {
    const { status, data } = await api.deleteRow(id)
    if (status === 204) {
      setRows((oldRows) => oldRows.filter((row) => row.id !== id))
    } else {
      console.error(data)
      throw new Error('Não foi possível realizar a operação')
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    if (error.response.status === 401) {
    //   setHaveError(true)
      console.error(error)
    }
  }
}

async function getDefaultSanitizationRule () {
  try {
    let rules
    const setSanitizationRules = (sanitizationRules) => {
      rules = sanitizationRules
    }
    await getAllRegrasSanitizacao(setSanitizationRules)

    return rules.filter(rule => rule.nome === 'qualquer caractere')[0]
  } catch (errors) {
    console.errors(errors)
  }
}

async function handleAddColumn (
  nomeLinha,
  fileLayoutId,
  rowId,
  rowLines,
  rowColumns,
  setColumns,
  startLine = 0,
  startColumn = 0
) {
  try {
    const defaultSanitizationRule = await getDefaultSanitizationRule()

    for (let line = startLine; line < rowLines; line++) {
      for (let column = startColumn; column < rowColumns; column++) {
        const params = {
          nome: `${nomeLinha}__linha${line + 1}__coluna${column + 1}`,
          file_layout_id: fileLayoutId,
          file_row_id: rowId,
          line: line + 1,
          column: column + 1,
          sanitization_rule: defaultSanitizationRule.id
        }
        await api.createColumn(params)
      }
    }
    // Since we are making requests in a loop here
    // it's best to make a final request to change the state
    // instead of setting the state inside a loop and have multiple re-renders.
    await getColumns(fileLayoutId, setColumns)
  } catch (error) {
    if (error.response.status === 401) {
      //   setHaveError(true)
      console.error(error)
    }
  }
}

// async function getLayoutById (fileLayoutId) {
//   try {
//     const { data } = await api.getLayoutById(fileLayoutId)
//     return data
//   } catch (error) {
//     console.error(error)
//   }
// }

async function getRows (fileLayoutId, setRows) {
  try {
    const { status, data } = await api.getRows(fileLayoutId)
    if (status === 200) {
      setRows(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function getColumns (fileLayoutId, setColumns) {
  try {
    const { status, data } = await api.getAllColumnsLayout(fileLayoutId)
    if (status === 200) {
      setColumns(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function editColumn (params, setColumns) {
  try {
    // assigning to newParams to avoid a warning when trying to change params
    const newParams = { ...params }

    const { char_start_position, char_end_position } = newParams

    if (char_start_position === '' || window.utils.isFalsy(char_start_position)) {
      delete newParams.char_start_position
    }
    if (char_end_position === '' || window.utils.isFalsy(char_end_position)) {
      delete newParams.char_end_position
    }

    const { status, data } = await api.editColumn(params.id, newParams)
    if (status === 200) {
      setColumns(
        (oldColumns) => oldColumns.map((oldColumn) => oldColumn.id === data.id ? data : oldColumn)
      )
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

export {
  // getLayoutById,
  getRows,
  handleAddLine,
  handleDeleteLine,
  handleUpdateLine,
  getColumns,
  editColumn
}
