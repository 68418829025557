/* eslint-disable no-unused-vars */
import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}
const baseUrl = process.env.REACT_APP_API_URL

const domain = 'contas'
const url = '/contas'

const endpointsDescriptions = {
  // #region Definições de Campos de Contas
  getAllCamposConta: {
    action: 'custom',
    extraParams: ['Retornar todos os campos de contas']
  },
  // Não inclusos
  // [AddCampoConta, getCampoConta, updateCampoConta, deleteCampoConta]
  // #endregion Definições de Campos de Contas

  // #region Definições de Tabs de Contas
  addTabConta: {
    action: 'custom',
    extraParams: ['Adicionar Tab de conta'],
    options: { feedbackOnAction: true }
  },
  editTabConta: {
    action: 'custom',
    extraParams: ['Editar Tab de conta'],
    options: { feedbackOnAction: true }
  },
  getTabsContasByEmpresa: {
    action: 'custom',
    extraParams: ['Retornar Tabs de contas por empresa']
  },
  getTabsByEmpresa: {
    action: 'custom',
    extraParams: ['Retornar Tabs da empresa']
  },
  deleteTabConta: {
    action: 'delete'
  },
  // #endregion Definições de Tabs de Contas

  // #region Definições de Contas
  batchUpdateContas: {
    action: 'custom',
    extraParams: ['Atualizar Contas'],
    options: { feedbackOnAction: true }
  },
  editConta: {
    action: 'update',
    options: { feedbackOnAction: false }
  },
  getContasByTab: {
    action: 'custom',
    extraParams: ['Retornar Contas da Tab']
  },
  checkEmptiesContas: {
    action: 'custom',
    extraParams: ['Checar se há contas vazias']
  }

  // #endregion Definições de Contas
}

const contas = new BaseDomainsProvider(domain, url, endpointsDescriptions)

// #region Endpoints de Campos de Contas
endpoints.getAllCamposConta = () => {
  const endpointDescription = contas.endpointsConfigs.getAllCamposContaConfig
  return axios.get(`${baseUrl}/contas/campo`, {
    endpointInfo: endpointDescription
  })
}
// #endregion Endpoints de Campos de Contas

// #region Endpoints de Tabs de Contas
endpoints.addTabConta = (body) => {
  const endpointDescription = contas.endpointsConfigs.addTabContaConfig
  return axios.post(`${baseUrl}/contas/tab`, body, {
    endpointInfo: endpointDescription
  })
}
endpoints.editTabConta = (id, body) => {
  const endpointDescription = contas.endpointsConfigs.editTabContaConfig
  return axios.put(`${baseUrl}/contas/tab/${id}`, body, {
    endpointInfo: endpointDescription
  })
}
endpoints.getTabsContasByEmpresa = (empresaId) => {
  const endpointDescription =
    contas.endpointsConfigs.getTabsContasByEmpresaConfig
  return axios.get(`${baseUrl}/contas/tab/${empresaId}`, {
    endpointInfo: endpointDescription
  })
}
endpoints.getTabsByEmpresa = (empresaId) => {
  const endpointDescription = contas.endpointsConfigs.getTabsByEmpresaConfig
  return axios.get(`${baseUrl}/tab/${empresaId}`, {
    endpointInfo: endpointDescription
  })
}
endpoints.deleteTabConta = (id) => {
  const endpointDescription = contas.endpointsConfigs.deleteTabContaConfig
  return axios.delete(`${baseUrl}/contas/tab/${id}`, {
    endpointInfo: endpointDescription
  })
}
// #endregion Endpoints de Tabs de Contas

// #region Endpoints de Contas
endpoints.batchUpdateContas = (body) => {
  const endpointDescription = contas.endpointsConfigs.batchUpdateContasConfig
  return axios.post(`${baseUrl}/contas/batch`, body, {
    endpointInfo: endpointDescription
  })
}
endpoints.editConta = (id, body) => {
  const endpointDescription = contas.endpointsConfigs.editContaConfig
  return axios.put(`${baseUrl}/contas/${id}`, body, {
    endpointInfo: endpointDescription
  })
}
endpoints.getContasByTab = (tabId) => {
  const endpointDescription = contas.endpointsConfigs.getContasByTabConfig
  return axios.get(`${baseUrl}/contas/${tabId}`, {
    endpointInfo: endpointDescription
  })
}
endpoints.checkEmptiesContas = (tabId) => {
  const endpointDescription = contas.endpointsConfigs.checkEmptiesContasConfig
  return axios.get(`${baseUrl}/contas/${tabId}/hasEmpties`, {
    endpointInfo: endpointDescription
  })
}
// #endregion Endpoints de Contas

export default endpoints
