import styled from 'styled-components'
import Tick from 'assets/Images/Tick.svg'

export const Container = styled.section`
  display: grid;
  grid-template-areas:
    'imagem dados'
    'imagem senha';
`

export const StepsDescriptor = styled.div`
  grid-area: ${({ area }) => area};
  ${({ area }) => handleStepsDescriptorAlignment(area)}
`

export const StepsImagem = styled.div`
  grid-area: imagem;
  display: grid;
  justify-items: center;
`

export const StepsCircle = styled.div`
  position: relative;
  display: flex;
  width: 50px;
  height: 50px;
  ${({ status }) => {
    const circleStyle = handleCircle(status)
    return `
      background-color: ${circleStyle.backgroundColor}; 
      border:${circleStyle.border};
    `
  }}
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    background-repeat: no-repeat;
    background-size: cover;

    ${({ status }) => {
      const innerCircleStyle = handleInnerCircle(status)
      return `
        background-color: ${innerCircleStyle.backgroundColor}; 
        background-image: ${innerCircleStyle.backgroundImage};
        border-radius: ${innerCircleStyle.borderRadius};
        width: ${innerCircleStyle.width};
        height: ${innerCircleStyle.height};
      `
    }}
  }
`
export const StepsLine = styled.div`
  width: 3px;
  background-color: #101828;
  height: 60px;
  margin: 5px 0;
`

// Helper Functions
const handleCircle = (status) => {
  const circleStyle = {
    backgroundColor: '#eaeaea',
    border: 'none'
  }
  switch (status) {
    case 'filled':
      circleStyle.backgroundColor = 'rgba(0, 191, 111, 0.3)'
      circleStyle.border = '4px solid #00BF6F'
      break
    case 'marked':
      circleStyle.backgroundColor = '#00BF6F'
      circleStyle.border = '4px solid #B2EBD3'
      break
    default:
      circleStyle.backgroundColor = '#eaeaea'
      circleStyle.border = 'none'
  }

  return circleStyle
}

const handleInnerCircle = (status) => {
  const innerCircleStyle = {
    backgroundColor: '#bdbdbd',
    backgroundImage: 'none',
    borderRadius: '50%',
    width: '15px',
    height: '15px'
  }
  switch (status) {
    case 'filled':
      innerCircleStyle.backgroundColor = '#00BF6F'
      innerCircleStyle.backgroundImage = 'none'
      innerCircleStyle.borderRadius = '50%'
      innerCircleStyle.width = '15px'
      innerCircleStyle.height = '15px'
      break
    case 'marked':
      innerCircleStyle.backgroundColor = 'none'
      innerCircleStyle.backgroundImage = `url(${Tick})`
      innerCircleStyle.borderRadius = '0'
      innerCircleStyle.width = '25px'
      innerCircleStyle.height = '25px'
      break
    default:
      innerCircleStyle.backgroundColor = '#bdbdbd'
      innerCircleStyle.backgroundImage = 'none'
      innerCircleStyle.borderRadius = '50%'
      innerCircleStyle.width = '15px'
      innerCircleStyle.height = '15px'
  }

  return innerCircleStyle
}

const handleStepsDescriptorAlignment = (area) => {
  let alignment = ''
  if (area === 'senha') {
    alignment = 'align-self: end;'
  }
  return alignment
}
