import PatternCnpj from './PatternCnpj'
import PatternCpfCnpj from './PatternCpfCnpj'
import PatternPhone from './PatternPhone'
import PatternDate from './PatternDate'

const patterns = {
  'cpf/cnpj': PatternCpfCnpj,
  cnpj: PatternCnpj,
  phone: PatternPhone,
  date: PatternDate
}

export default class Patterns {
  constructor({ pattern, value, setValue, setError }) {
    this.pattern = {}
    this.setPattern(pattern, value, setValue, setError)
  }

  setPattern(pattern, value, setValue, setError) {
    try {
      if (typeof patterns[pattern] === 'undefined') {
        throw new Error(`Não há formatação para o pattern "${pattern}".`)
      }
      this.pattern = new patterns[pattern]({ value, setValue, setError })
    } catch (error) {
      console.error(error)
    }
  }
}
