/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import useNonInitialEffect from 'hooks/useNonInitialEffect'

export function useCampoSistemaContabil({ name, campos }) {
  const [renderedFields, setRenderedFields] = useState(
    setInitialRenderedValues(campos)
  )
  const [field, fieldMeta, fieldHelper] = useField(name)

  const editValue = ({ id, inputName, value }) => {
    let isolatedValue = renderedFields.filter((field) => field.id_field === id)

    if (isolatedValue?.length > 0) {
      isolatedValue = isolatedValue[0]
      isolatedValue[inputName] = value
      const restOfValues = renderedFields.filter(
        (field) => field.id_field !== id
      )
      const resort = [...restOfValues, isolatedValue].sort((a, b) => {
        if (a.id_field < b.id_field) {
          return -1
        }
        if (a.id_field > b.id_field) {
          return 1
        }
        return 0
      })
      setRenderedFields(resort)
    }
  }

  useEffect(() => {
    fieldHelper.setValue(JSON.stringify(setInitialValues(campos)))
  }, [campos])

  useNonInitialEffect(() => {
    if (renderedFields?.length > 0) {
      const onlyEnabled = renderedFields.filter((campos) => campos.enabled)
      const newFields = onlyEnabled.map((campos) => {
        const { id_field, field_type, num_order } = campos
        const removeEnabledField = { id_field, field_type, num_order }
        return removeEnabledField
      })

      fieldHelper.setValue(JSON.stringify(newFields))
    }
  }, [renderedFields])

  return {
    fields: field.value,
    editValue
  }
}

useCampoSistemaContabil.propTypes = {
  name: PropTypes.string,
  campos: PropTypes.array
}

function setInitialValues(campos) {
  const enableds = campos.filter((campo) => campo.is_enabled)
  const initial = enableds.map((campo) => ({
    id_field: campo.id,
    field_type: campo.dataType,
    num_order: campo.num_order
  }))
  return initial
}

function setInitialRenderedValues(campos) {
  const maxOrder = Math.max(...campos.map((campo) => campo.num_order)) + 1

  const fields = campos.map((campo) => ({
    enabled: campo.is_enabled,
    id_field: campo.id,
    field_type: campo.dataType,
    num_order: campo.num_order.toString()
  }))

  return fields
}
