import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'features'
const url = '/features'

const endpointsDescriptions = {
  getAllFeatures: {
    action: 'getAll'
  },
  getSingleFeature: {
    action: 'getOne'
  }
}

const features = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllFeatures = () => {
  const endpointDescription = features.endpointsConfigs.getAllFeaturesConfig
  return axios.get(
    `${baseUrl}/features`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getSingleFeature = (id) => {
  const endpointDescription = features.endpointsConfigs.getSingleFeatureConfig
  return axios.get(
    `${baseUrl}/features/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
