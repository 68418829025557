/*
  Foi realizado um refactor para se utilizar um reducer com os estados do layout ao invés de multiplos estados.

  Ainda é necessário um segundo refactor para separar as responsabilidades
  com diferentes reducers cuidando de diferentes grupos de estados.
    - Header
    - SideBar [Toggle]
    - Main [TopBar]
    - ContextMenu [Toggle, Content]
    - Modal [Top, Middle, Bottom]
*/

const restrictedLayoutStates = {
  headerData: '',
  toggleSideBar: false,
  mainTopBarContent: '',
  contextMenuContent: '',
  toggleContextMenu: false
}

function restrictedLayoutReducer(state, action) {
  switch (action.type) {
    case 'setHeaderData':
      return {
        ...state,
        headerData: action.payload
      }
    case 'setToggleSidebar':
      return {
        ...state,
        toggleSideBar: action.payload
      }
    case 'setMainTopBarContent':
      return {
        ...state,
        mainTopBarContent: action.payload
      }
    case 'setContextMenuContent':
      return {
        ...state,
        contextMenuContent: action.payload
      }
    case 'setToggleContextMenu':
      return {
        ...state,
        toggleContextMenu: action.payload
      }
    default:
      return state
  }
}

export { restrictedLayoutStates, restrictedLayoutReducer }
