import React from 'react'
import PropTypes from 'prop-types'

const PlusIcon = ({ color = 'var(--background-medium-blue)', ...props }) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.71875 0H5.6875V5.6875H0V7.71875H5.6875V13H7.71875V7.71875H13V5.6875H7.71875V0Z"
        fill={color}
      />
    </svg>
  )
}

PlusIcon.propTypes = {
  color: PropTypes.string
}

const IconeEditar = ({ color = 'var(--background-medium-blue)', ...props }) => {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 -4 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4H11.5263V6H0V4ZM0 2H11.5263V0H0V2ZM0 10H7.3349V8H0V10ZM15.7281 6.87L16.4721 6.16C16.8807 5.77 17.5409 5.77 17.9495 6.16L18.6935 6.87C19.1022 7.26 19.1022 7.89 18.6935 8.28L17.9495 8.99L15.7281 6.87ZM14.9841 7.58L9.43058 12.88V15H11.652L17.2056 9.7L14.9841 7.58Z"
        fill={color}
      />
    </svg>
  )
}
IconeEditar.propTypes = {
  color: PropTypes.string
}

const IconeExcluir = ({
  color = 'var(--background-medium-blue)',
  vers = '1',
  ...props
}) => {
  if (vers === '2') {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6L6 18"
          stroke="#667085"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="#667085"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }

  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.42857 5.33333V14.2222H2.57143V5.33333H9.42857ZM8.14286 0H3.85714L3 0.888889H0V2.66667H12V0.888889H9L8.14286 0ZM11.1429 3.55556H0.857143V14.2222C0.857143 15.2 1.62857 16 2.57143 16H9.42857C10.3714 16 11.1429 15.2 11.1429 14.2222V3.55556Z"
        fill={color}
      />
    </svg>
  )
}
IconeExcluir.propTypes = {
  color: PropTypes.string,
  vers: PropTypes.string
}

const IconePermissoesDeUsuario = ({
  color = 'var(--background-medium-blue)',
  ...props
}) => {
  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 5.66667H9.75V4.04762C9.75 1.81333 8.07 0 6 0C3.93 0 2.25 1.81333 2.25 4.04762V5.66667H1.5C0.675 5.66667 0 6.39524 0 7.28571V15.381C0 16.2714 0.675 17 1.5 17H10.5C11.325 17 12 16.2714 12 15.381V7.28571C12 6.39524 11.325 5.66667 10.5 5.66667ZM3.75 4.04762C3.75 2.70381 4.755 1.61905 6 1.61905C7.245 1.61905 8.25 2.70381 8.25 4.04762V5.66667H3.75V4.04762ZM10.5 15.381H1.5V7.28571H10.5V15.381ZM6 12.9524C6.825 12.9524 7.5 12.2238 7.5 11.3333C7.5 10.4429 6.825 9.71429 6 9.71429C5.175 9.71429 4.5 10.4429 4.5 11.3333C4.5 12.2238 5.175 12.9524 6 12.9524Z"
        fill={color}
      />
    </svg>
  )
}
IconePermissoesDeUsuario.propTypes = {
  color: PropTypes.string
}

const IconeResponsavelSim = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill="var(--white)" />
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z"
        fill="var(--green)"
      />
    </svg>
  )
}

const IconeResponsavelNao = ({ ...props }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10.6099" cy="10" r="10" fill="var(--white)" />
      <path
        d="M6.55 3.12L5.1 1.66C6.68 0.61 8.57 0 10.61 0C16.13 0 20.61 4.48 20.61 10C20.61 12.04 20 13.93 18.95 15.51L17.49 14.05C18.2 12.86 18.61 11.48 18.61 10C18.61 5.59 15.02 2 10.61 2C9.13 2 7.75 2.41 6.55 3.12ZM16.27 7.53L14.86 6.12L12.21 8.77L13.62 10.18L16.27 7.53ZM18.39 20.61L16.12 18.34C14.54 19.39 12.65 20 10.61 20C5.09 20 0.61 15.52 0.61 10C0.61 7.96 1.22 6.07 2.27 4.49L0 2.22L1.41 0.81L19.79 19.19L18.39 20.61ZM14.67 16.88L10.79 13L9.2 14.59L4.96 10.35L6.37 8.94L9.2 11.77L9.38 11.59L3.73 5.94C3.02 7.14 2.61 8.52 2.61 10C2.61 14.41 6.2 18 10.61 18C12.09 18 13.47 17.59 14.67 16.88Z"
        fill="var(--green)"
      />
    </svg>
  )
}

const IconePessoaJuridica = ({ ...props }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="var(--green)"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <text>J</text>
    </svg>
  )
}

const IconePessoaFisica = ({ ...props }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="var(--green)"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <text>F</text>
    </svg>
  )
}

const IconeUsuarios = ({
  color = 'var(--background-medium-blue)',
  ...props
}) => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.6 6.875C3.728 6.875 0 7.79429 0 9.625V11H11.2V9.625C11.2 7.79429 7.472 6.875 5.6 6.875ZM1.872 9.42857C2.544 8.97286 4.168 8.44643 5.6 8.44643C7.032 8.44643 8.656 8.97286 9.328 9.42857H1.872ZM5.6 5.5C7.144 5.5 8.4 4.26643 8.4 2.75C8.4 1.23357 7.144 0 5.6 0C4.056 0 2.8 1.23357 2.8 2.75C2.8 4.26643 4.056 5.5 5.6 5.5ZM5.6 1.57143C6.264 1.57143 6.8 2.09786 6.8 2.75C6.8 3.40214 6.264 3.92857 5.6 3.92857C4.936 3.92857 4.4 3.40214 4.4 2.75C4.4 2.09786 4.936 1.57143 5.6 1.57143ZM11.232 6.92214C12.16 7.58214 12.8 8.46214 12.8 9.625V11H16V9.625C16 8.03786 13.2 7.13429 11.232 6.92214ZM10.4 5.5C11.944 5.5 13.2 4.26643 13.2 2.75C13.2 1.23357 11.944 0 10.4 0C9.968 0 9.568 0.102143 9.2 0.275C9.704 0.974286 10 1.83071 10 2.75C10 3.66929 9.704 4.52571 9.2 5.225C9.568 5.39786 9.968 5.5 10.4 5.5Z"
        fill={color}
      />
    </svg>
  )
}
IconeUsuarios.propTypes = {
  color: PropTypes.string
}

const IconeContatos = ({
  color = 'var(--background-medium-blue)',
  ...props
}) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.3 3.33333H1.7C0.765 3.33333 0 4.08333 0 5V15C0 15.9167 0.765 16.6667 1.7 16.6667H15.3C16.235 16.6667 17 15.9167 17 15V5C17 4.08333 16.235 3.33333 15.3 3.33333ZM15.3 15H1.7V5H15.3V15ZM1.7 0H15.3V1.66667H1.7V0ZM1.7 18.3333H15.3V20H1.7V18.3333ZM8.5 10C9.673 10 10.625 9.06667 10.625 7.91667C10.625 6.76667 9.673 5.83333 8.5 5.83333C7.327 5.83333 6.375 6.76667 6.375 7.91667C6.375 9.06667 7.327 10 8.5 10ZM8.5 7.08333C8.9675 7.08333 9.35 7.45833 9.35 7.91667C9.35 8.375 8.9675 8.75 8.5 8.75C8.0325 8.75 7.65 8.375 7.65 7.91667C7.65 7.45833 8.0325 7.08333 8.5 7.08333ZM12.75 13.325C12.75 11.5833 9.9365 10.8333 8.5 10.8333C7.0635 10.8333 4.25 11.5833 4.25 13.325V14.1667H12.75V13.325ZM5.7885 12.9167C6.307 12.4833 7.514 12.0833 8.5 12.0833C9.4945 12.0833 10.7015 12.4833 11.22 12.9167H5.7885Z"
        fill={color}
      />
    </svg>
  )
}
IconeContatos.propTypes = {
  color: PropTypes.string
}

const IconeEmpresas = ({
  color = 'var(--background-medium-blue)',
  ...props
}) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.72727 6.90918H0V16.4092C0 17.3678 0.768636 18.1365 1.72727 18.1365H16.4091V16.4092H1.72727V6.90918Z"
        fill={color}
      />
      <path
        d="M14.6818 3.45455V1.72727C14.6818 0.777273 13.9045 0 12.9545 0H9.49998C8.54998 0 7.77271 0.777273 7.77271 1.72727V3.45455H3.45453V12.9545C3.45453 13.9045 4.2318 14.6818 5.1818 14.6818H17.2727C18.2227 14.6818 19 13.9045 19 12.9545V3.45455H14.6818ZM9.49998 1.72727H12.9545V3.45455H9.49998V1.72727ZM17.2727 12.9545H5.1818V5.18182H17.2727V12.9545Z"
        fill={color}
      />
    </svg>
  )
}
IconeEmpresas.propTypes = {
  color: PropTypes.string
}

const IconeLupaPesquisa = ({
  color = 'var(--white)',
  vers = '1',
  ...props
}) => {
  if (vers === '2') {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="transparent"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 15"
      fill="var(--background-medium-blue)"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      {...props}
    >
      <path
        d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
        fill={color}
      />
    </svg>
  )
}
IconeLupaPesquisa.propTypes = {
  color: PropTypes.string,
  vers: PropTypes.string
}

const IconeCopiar = ({ color = 'var(--background-medium-blue)', ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Text-files">
        <path
          stroke={color}
          strokeWidth="3"
          d="M53.9791489,9.1429005H50.010849c-0.0826988,0-0.1562004,0.0283995-0.2331009,0.0469999V5.0228
        C49.7777481,2.253,47.4731483,0,44.6398468,0h-34.422596C7.3839517,0,5.0793519,2.253,5.0793519,5.0228v46.8432999
        c0,2.7697983,2.3045998,5.0228004,5.1378999,5.0228004h6.0367002v2.2678986C16.253952,61.8274002,18.4702511,64,21.1954517,64
        h32.783699c2.7252007,0,4.9414978-2.1725998,4.9414978-4.8432007V13.9861002
        C58.9206467,11.3155003,56.7043495,9.1429005,53.9791489,9.1429005z M7.1110516,51.8661003V5.0228
        c0-1.6487999,1.3938999-2.9909999,3.1062002-2.9909999h34.422596c1.7123032,0,3.1062012,1.3422,3.1062012,2.9909999v46.8432999
        c0,1.6487999-1.393898,2.9911003-3.1062012,2.9911003h-34.422596C8.5049515,54.8572006,7.1110516,53.5149002,7.1110516,51.8661003z
        M56.8888474,59.1567993c0,1.550602-1.3055,2.8115005-2.9096985,2.8115005h-32.783699
        c-1.6042004,0-2.9097996-1.2608986-2.9097996-2.8115005v-2.2678986h26.3541946
        c2.8333015,0,5.1379013-2.2530022,5.1379013-5.0228004V11.1275997c0.0769005,0.0186005,0.1504021,0.0469999,0.2331009,0.0469999
        h3.9682999c1.6041985,0,2.9096985,1.2609005,2.9096985,2.8115005V59.1567993z"
        />
        <path
          stroke={color}
          strokeWidth="3"
          d="M38.6031494,13.2063999H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0158005
        c0,0.5615997,0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4542999,1.0158997-1.0158997
        C39.6190491,13.6606998,39.16465,13.2063999,38.6031494,13.2063999z"
        />
        <path
          stroke={color}
          strokeWidth="3"
          d="M38.6031494,21.3334007H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0157986
        c0,0.5615005,0.4544001,1.0159016,1.0159006,1.0159016h22.3491974c0.5615005,0,1.0158997-0.454401,1.0158997-1.0159016
        C39.6190491,21.7877007,39.16465,21.3334007,38.6031494,21.3334007z"
        />
        <path
          stroke={color}
          strokeWidth="3"
          d="M38.6031494,29.4603004H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997
        s0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4543991,1.0158997-1.0158997
        S39.16465,29.4603004,38.6031494,29.4603004z"
        />
        <path
          stroke={color}
          strokeWidth="3"
          d="M28.4444485,37.5872993H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997
        s0.4544001,1.0158997,1.0159006,1.0158997h12.1904964c0.5615025,0,1.0158005-0.4543991,1.0158005-1.0158997
        S29.0059509,37.5872993,28.4444485,37.5872993z"
        />
      </g>
    </svg>
  )
}

IconeCopiar.propTypes = {
  color: PropTypes.string
}

const IconeTable = ({ color = 'var(--background-medium-blue)', ...props }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 445 445"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M0,37.215v55v15v300.57h445v-300.57v-15v-55H0z M276.667,277.595H168.333v-70.19h108.334V277.595z M306.667,207.405H415
      v70.19H306.667V207.405z M276.667,307.595v70.19H168.333v-70.19H276.667z M30,207.405h108.333v70.19H30V207.405z M168.333,177.405
      v-70.19h108.334v70.19H168.333z M138.333,107.215v70.19H30v-70.19H138.333z M30,307.595h108.333v70.19H30V307.595z M306.667,377.785
      v-70.19H415v70.19H306.667z M415,177.405H306.667v-70.19H415V177.405z"
      />
    </svg>
  )
}

IconeTable.propTypes = {
  color: PropTypes.string
}

const IconGoBack = ({ color = 'var(--white)', ...props }) => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.062 7.062L15 6L9 12L15 18L16.062 16.938L11.124 12L16.062 7.062Z"
        fill={color}
      />
    </svg>
  )
}

IconGoBack.propTypes = {
  color: PropTypes.string
}

const PlickSimboloIcon = ({ active, ...props }) => {
  return (
    <svg
      width="453"
      height="541"
      viewBox="0 0 453 541"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ marginLeft: '-7px' }}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M111.645 342.915L471.193 -16.8778L542.964 54.957L109.671 488.522H1.5V70.9333H109.084V341.855V345.478L111.645 342.915Z"
          fill="var(--white)"
          stroke="var(--white)"
          strokeWidth="3"
        />
        <path
          d="M373.418 374.102C454.623 399.188 518.452 461.241 543.068 539.5H427.874C403.731 487.808 354.281 451.974 296.856 450.712L373.418 374.102Z"
          fill="var(--white)"
          stroke="var(--white)"
          strokeWidth="3"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="453"
            height="560"
            fill="var(--white)"
            transform="translate(0 -19)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
PlickSimboloIcon.propTypes = {
  active: PropTypes.bool
}

const MainMenuIcon = ({ active, ...props }) => {
  return (
    <svg
      width="33"
      height="29"
      aria-hidden="true"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 448 512"
    >
      <path
        fill={active ? 'var(--white)' : 'var(--white)'}
        d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
      ></path>
    </svg>
  )
}

MainMenuIcon.propTypes = {
  active: PropTypes.bool
}

const NotificationIcon = ({ active, ...props }) => {
  return (
    <svg
      width="25"
      height="30"
      viewBox="0 0 25 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 30C12.5125 30 13.75 28.6615 13.75 27.0256H8.25C8.25 28.6615 9.4875 30 11 30ZM19.25 21.0769V13.641C19.25 9.07539 17.0087 5.25333 13.0625 4.24205V3.23077C13.0625 1.99641 12.1412 1 11 1C9.85875 1 8.9375 1.99641 8.9375 3.23077V4.24205C5.005 5.25333 2.75 9.06051 2.75 13.641V21.0769L0 24.0513V25.5385H22V24.0513L19.25 21.0769ZM16.5 22.5641H5.5V13.641C5.5 9.95282 7.57625 6.94872 11 6.94872C14.4237 6.94872 16.5 9.95282 16.5 13.641V22.5641Z"
        fill={active ? 'var(--white)' : 'var(--white)'}
      />
    </svg>
  )
}

NotificationIcon.propTypes = {
  active: PropTypes.bool
}

const UserProfileIcon = ({ active, ...props }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 3.0875C14.885 3.0875 16.4125 4.615 16.4125 6.5C16.4125 8.385 14.885 9.9125 13 9.9125C11.115 9.9125 9.5875 8.385 9.5875 6.5C9.5875 4.615 11.115 3.0875 13 3.0875ZM13 17.7125C17.8263 17.7125 22.9125 20.085 22.9125 21.125V22.9125H3.0875V21.125C3.0875 20.085 8.17375 17.7125 13 17.7125ZM13 0C9.40875 0 6.5 2.90875 6.5 6.5C6.5 10.0913 9.40875 13 13 13C16.5912 13 19.5 10.0913 19.5 6.5C19.5 2.90875 16.5912 0 13 0ZM13 14.625C8.66125 14.625 0 16.8025 0 21.125V26H26V21.125C26 16.8025 17.3388 14.625 13 14.625Z"
        fill={active ? 'var(--white)' : 'var(--white)'}
      />
    </svg>
  )
}

UserProfileIcon.propTypes = {
  active: PropTypes.bool
}

const ActiveSectionLine = ({ active, ...props }) => {
  return (
    <svg
      width="4"
      height="44"
      viewBox="0 0 4 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="2"
        y1="2"
        x2="2"
        y2="42"
        stroke={active ? 'var(--green)' : 'rgba(0,0,0,0)'}
        strokeWidth="4"
        strokeLinecap="square"
      />
    </svg>
  )
}

ActiveSectionLine.propTypes = {
  active: PropTypes.bool
}

const ActiveSectionIndicator = ({ active, ...props }) => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* <path
        d="M0 6L5.25 0.803847L5.25 11.1962L0 6Z"
        fill={active ? 'var(--white)' : 'var(--black)'}
      /> */}
    </svg>
  )
}

ActiveSectionIndicator.propTypes = {
  active: PropTypes.bool
}

const ArrowHead = ({ active, ...props }) => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="26" height="25" rx="5" fill="var(--green)" />
      <path
        d="M16.062 7.062L15 6L9 12L15 18L16.062 16.938L11.124 12L16.062 7.062Z"
        fill="var(--white)"
      />
    </svg>
  )
}

ArrowHead.propTypes = {
  active: PropTypes.bool
}

const CogIcon = ({ ...props }) => {
  return (
    <svg
      aria-hidden="true"
      width="1rem"
      height="1rem"
      style={{ marginBottom: '-2px' }}
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 512 512"
    >
      <path
        fill="var(--green)"
        d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
      ></path>
    </svg>
  )
}

const LogoutIcon = ({ ...props }) => {
  return (
    <svg
      aria-hidden="true"
      width="1rem"
      height="1rem"
      style={{ marginBottom: '-3px' }}
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 512 512"
    >
      <path
        fill="var(--green)"
        d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
      ></path>
    </svg>
  )
}

const PlayIcon = ({ color = 'var(--white)', ...props }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 20 480 480"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M133,440a35.37,35.37,0,0,1-17.5-4.67c-12-6.8-19.46-20-19.46-34.33V111c0-14.37,7.46-27.53,19.46-34.33a35.13,35.13,0,0,1,35.77.45L399.12,225.48a36,36,0,0,1,0,61L151.23,434.88A35.5,35.5,0,0,1,133,440Z"
      />
    </svg>
  )
}
PlayIcon.propTypes = {
  color: PropTypes.string
}

const FileWithX = ({ color = 'var(--white)', ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M216.00781,88a7.97224,7.97224,0,0,0-2.43164-5.73779L157.65723,26.34375q-.27686-.27694-.58008-.5257c-.04932-.04046-.10254-.075-.15283-.11419-.15577-.12195-.3125-.2428-.477-.35315-.04493-.03021-.09327-.05518-.13868-.08441-.1748-.11182-.35058-.22113-.53418-.3194-.03369-.01813-.06933-.03223-.10351-.04987-.19727-.10235-.39746-.19989-.60449-.28576-.02344-.00977-.04786-.01673-.07178-.02625-.21729-.08783-.4375-.16888-.66358-.23767-.02636-.008-.05371-.01282-.08007-.02057-.22217-.06506-.44678-.12408-.67627-.17-.06543-.01312-.13282-.01874-.19873-.03027-.19092-.03321-.38184-.06678-.57715-.08619A7.98793,7.98793,0,0,0,152,24H55.99219a16.01833,16.01833,0,0,0-16,16V216a16.01833,16.01833,0,0,0,16,16H200a16.01833,16.01833,0,0,0,16-16V88.15948C216.001,88.1059,216.00781,88.05383,216.00781,88ZM160,51.31372,188.68652,80H160ZM200,216H55.99219V40H144V88a8.00008,8.00008,0,0,0,8,8h48.00049l.00976,119.99951Z"
      />
      <path
        fill={color}
        d="M157.62744,122.34326a8,8,0,0,0-11.31348,0L127.9707,140.68652l-18.34326-18.34326A7.99984,7.99984,0,1,0,98.314,133.65674L116.65723,152,98.314,170.34326a7.99984,7.99984,0,1,0,11.31348,11.31348l18.34326-18.34326L146.314,181.65674a7.99984,7.99984,0,1,0,11.31348-11.31348L139.28418,152l18.34326-18.34326A7.99915,7.99915,0,0,0,157.62744,122.34326Z"
      />
    </svg>
  )
}
FileWithX.propTypes = {
  color: PropTypes.string
}

const DoubleCogs = ({ color = 'var(--white)', ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="924 796 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M1049.078,903.431h-3.447c-3.104,0-5.875-1.963-6.904-4.891c-0.626-1.793-1.354-3.536-2.176-5.227
        c-1.361-2.806-0.799-6.167,1.404-8.369l2.381-2.382c4.029-4.028,4.029-10.556,0.002-14.583l-1.717-1.717
        c-4.025-4.024-10.557-4.028-14.58,0l-2.436,2.433c-2.193,2.196-5.538,2.769-8.336,1.425c-1.696-0.811-3.442-1.532-5.236-2.155
        c-2.948-1.017-4.928-3.795-4.928-6.91v-3.37c0-5.693-4.618-10.31-10.309-10.31h-2.43c-5.695,0-10.312,4.616-10.312,10.31v3.444
        c0,3.107-1.962,5.877-4.892,6.906c-1.792,0.627-3.534,1.354-5.224,2.176c-2.803,1.361-6.166,0.796-8.371-1.406l-2.377-2.382
        c-4.03-4.028-10.558-4.028-14.584,0l-1.719,1.717c-4.026,4.028-4.028,10.555,0,14.583l2.434,2.432
        c2.193,2.197,2.765,5.54,1.421,8.341c-0.812,1.691-1.532,3.44-2.15,5.234c-1.021,2.945-3.798,4.926-6.915,4.926h-3.367
        c-5.695,0-10.312,4.617-10.312,10.313v2.429c0,5.693,4.617,10.31,10.312,10.31h3.441c3.106,0,5.876,1.963,6.903,4.893
        c0.63,1.791,1.358,3.537,2.18,5.227c1.361,2.804,0.795,6.164-1.408,8.367l-2.379,2.383c-4.029,4.027-4.027,10.555,0,14.582
        l1.718,1.718c4.025,4.023,10.553,4.026,14.58-0.003l2.431-2.432c2.195-2.194,5.54-2.768,8.341-1.424
        c1.694,0.813,3.441,1.533,5.236,2.155c2.946,1.018,4.927,3.795,4.927,6.913v3.364c-0.004,5.699,4.614,10.313,10.311,10.313h2.427
        c5.696,0,10.314-4.614,10.311-10.309v-3.445c0-3.104,1.962-5.875,4.892-6.905c1.792-0.628,3.537-1.354,5.229-2.175
        c2.801-1.362,6.165-0.798,8.368,1.404l2.379,2.38c4.027,4.029,10.555,4.025,14.583,0.002l1.717-1.718
        c4.027-4.026,4.03-10.557,0-14.581l-2.432-2.433c-2.197-2.193-2.768-5.54-1.426-8.337c0.814-1.696,1.533-3.445,2.154-5.24
        c1.021-2.947,3.795-4.926,6.914-4.926h3.367c5.695,0.002,10.31-4.616,10.31-10.312v-2.429
        C1059.385,908.049,1054.771,903.427,1049.078,903.431z M991.694,940.147c-13.852,0-25.081-11.227-25.081-25.078
        c0-13.853,11.229-25.08,25.081-25.08c13.85,0,25.079,11.228,25.079,25.08C1016.772,928.921,1005.544,940.147,991.694,940.147z"
      />
      <path
        fill={color}
        d="M1117.307,845.487h-1.727c-2.557,0-4.847-1.583-5.752-3.974c-0.229-0.609-0.479-1.212-0.746-1.804
        c-1.053-2.329-0.554-5.07,1.256-6.876l1.219-1.221c2.613-2.611,2.613-6.853,0-9.466l-0.473-0.473c-2.613-2.612-6.852-2.612-9.465,0
        l-1.219,1.221c-1.809,1.809-4.547,2.308-6.877,1.258c-0.593-0.268-1.192-0.516-1.805-0.747c-2.389-0.903-3.975-3.196-3.975-5.748
        v-1.729c0-3.697-2.996-6.692-6.689-6.692h-0.668c-3.698,0-6.696,2.995-6.696,6.692v1.724c0,2.557-1.581,4.85-3.972,5.753
        c-0.609,0.231-1.215,0.479-1.805,0.747c-2.328,1.05-5.069,0.551-6.876-1.256l-1.22-1.221c-2.611-2.614-6.854-2.613-9.467,0.001
        l-0.472,0.472c-2.613,2.613-2.613,6.853,0,9.465l1.219,1.22c1.806,1.806,2.31,4.547,1.257,6.876
        c-0.268,0.592-0.517,1.194-0.748,1.804c-0.903,2.391-3.193,3.977-5.748,3.977h-1.727c-3.695-0.002-6.691,2.997-6.691,6.69v0.669
        c0,3.696,2.996,6.693,6.691,6.693h1.722c2.557-0.001,4.85,1.582,5.753,3.973c0.231,0.611,0.48,1.215,0.747,1.809
        c1.052,2.326,0.552,5.065-1.255,6.871l-1.219,1.224c-2.613,2.609-2.613,6.851,0,9.463l0.475,0.473c2.611,2.614,6.852,2.614,9.463,0
        l1.217-1.219c1.807-1.806,4.549-2.308,6.877-1.255c0.592,0.269,1.197,0.517,1.809,0.748c2.389,0.901,3.974,3.193,3.974,5.747v1.724
        c-0.004,3.694,2.995,6.692,6.692,6.692h0.669c3.693,0,6.692-2.994,6.692-6.692v-1.721c0-2.556,1.582-4.849,3.971-5.752
        c0.612-0.23,1.216-0.479,1.809-0.746c2.326-1.053,5.068-0.551,6.873,1.251l1.223,1.222c2.609,2.615,6.85,2.615,9.465,0l0.473-0.475
        c2.611-2.611,2.611-6.851,0-9.464l-1.221-1.22c-1.805-1.806-2.307-4.547-1.256-6.875c0.268-0.59,0.518-1.194,0.749-1.805
        c0.901-2.391,3.191-3.976,5.747-3.976h1.725c3.694,0.004,6.691-2.995,6.695-6.69v-0.669
        C1123.996,848.483,1121,845.487,1117.307,845.487z M1080.717,867.24c-8.131,0-14.723-6.592-14.723-14.724
        s6.592-14.724,14.723-14.724c8.133,0,14.725,6.592,14.725,14.724S1088.85,867.24,1080.717,867.24z"
      />
    </svg>
  )
}
DoubleCogs.propTypes = {
  color: PropTypes.string
}

const XIcon = ({ color = 'var(--white)', ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={color}
        d="M84.707,68.752L65.951,49.998l18.75-18.752c0.777-0.777,0.777-2.036,0-2.813L71.566,15.295
        c-0.777-0.777-2.037-0.777-2.814,0L49.999,34.047l-18.75-18.752c-0.746-0.747-2.067-0.747-2.814,0L15.297,28.431
        c-0.373,0.373-0.583,0.88-0.583,1.407c0,0.527,0.21,1.034,0.583,1.407L34.05,49.998L15.294,68.753
        c-0.373,0.374-0.583,0.88-0.583,1.407c0,0.528,0.21,1.035,0.583,1.407l13.136,13.137c0.373,0.373,0.881,0.583,1.41,0.583
        c0.525,0,1.031-0.21,1.404-0.583l18.755-18.755l18.756,18.754c0.389,0.388,0.896,0.583,1.407,0.583c0.511,0,1.019-0.195,1.408-0.583
        l13.138-13.137C85.484,70.789,85.484,69.53,84.707,68.752z"
      />
    </svg>
  )
}
XIcon.propTypes = {
  color: PropTypes.string
}

const CheckMarkIcon = ({ color = 'var(--white)', ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 42 42"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M39.04,7.604l-2.398-1.93c-1.182-0.95-1.869-0.939-2.881,0.311L16.332,27.494l-8.111-6.739
        c-1.119-0.94-1.819-0.89-2.739,0.26l-1.851,2.41c-0.939,1.182-0.819,1.853,0.291,2.78l11.56,9.562c1.19,1,1.86,0.897,2.78-0.222
        l21.079-25.061C40.331,9.294,40.271,8.583,39.04,7.604z"
      />
    </svg>
  )
}
CheckMarkIcon.propTypes = {
  color: PropTypes.string
}

const DeleteIcon = ({ color = 'var(--white)' }) => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z"
      />
    </svg>
  )
}

DeleteIcon.propTypes = {
  color: PropTypes.string
}

const EditIcon = ({ color }) => {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M0 4.26667H12.7396V6.4H0V4.26667ZM0 2.13333H12.7396V0H0V2.13333ZM0 10.6667H8.10699V8.53333H0V10.6667ZM17.3837 7.328L18.206 6.57067C18.6577 6.15467 19.3873 6.15467 19.839 6.57067L20.6612 7.328C21.1129 7.744 21.1129 8.416 20.6612 8.832L19.839 9.58933L17.3837 7.328ZM16.5614 8.08533L10.4233 13.7387V16H12.8785L19.0167 10.3467L16.5614 8.08533Z"
      />
    </svg>
  )
}

EditIcon.propTypes = {
  color: PropTypes.string.isRequired
}

const MailAddIcon = ({ color = 'var(--background-medium-blue)' }) => {
  return (
    <svg
      // width="22px"
      height="20px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          fill={color}
          d="M22 13h-2V7.238l-7.928 7.1L4 7.216V19h10v2H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v9zM4.511 5l7.55 6.662L19.502 5H4.511zM21 18h3v2h-3v3h-2v-3h-3v-2h3v-3h2v3z"
        />
      </g>
    </svg>
  )
}

MailAddIcon.propTypes = {
  color: PropTypes.string
}

const FilterIcon = () => {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6H14M1.5 1H16.5M6.5 11H11.5"
        stroke="#667085"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const PlusCircle = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 8V16"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 12H16.5"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const OpenedEye = ({ color = '#667085', ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

OpenedEye.propTypes = {
  color: PropTypes.string
}

const ClosedEye = ({ color = '#667085', ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L23 23"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

ClosedEye.propTypes = {
  color: PropTypes.string
}

export {
  PlusIcon,
  IconeEditar,
  IconeExcluir,
  IconePermissoesDeUsuario,
  IconeResponsavelSim,
  IconeResponsavelNao,
  IconePessoaJuridica,
  IconePessoaFisica,
  IconeUsuarios,
  IconeContatos,
  IconeEmpresas,
  IconeLupaPesquisa,
  IconeCopiar,
  IconeTable,
  IconGoBack,
  PlickSimboloIcon,
  MainMenuIcon,
  NotificationIcon,
  UserProfileIcon,
  ActiveSectionLine,
  ActiveSectionIndicator,
  ArrowHead,
  CogIcon,
  LogoutIcon,
  PlayIcon,
  FileWithX,
  DoubleCogs,
  XIcon,
  CheckMarkIcon,
  DeleteIcon,
  EditIcon,
  MailAddIcon,
  FilterIcon,
  PlusCircle,
  OpenedEye,
  ClosedEye
}
