import React from 'react'
import Modal from 'components/Modal'
import PropTypes from 'prop-types'

function ModalErrosDeValidacao({
  showModalErrosValidacao,
  setShowModalErrosValidacao,
  listaDeErrosDeValidacao
}) {
  return (
    <Modal
      showModal={showModalErrosValidacao}
      setShowModal={setShowModalErrosValidacao}
      title={'Verifique os dados abaixo:'}
    >
      {listaDeErrosDeValidacao.map((erro, index) => {
        const lista = []
        for (const property in erro) {
          lista.push(<p key={index + 100}>{erro[property]}</p>)
        }
        return <div key={index}>{lista}</div>
      })}
    </Modal>
  )
}

ModalErrosDeValidacao.propTypes = {
  showModalErrosValidacao: PropTypes.bool,
  listaDeErrosDeValidacao: PropTypes.array,
  setShowModalErrosValidacao: PropTypes.func
}
export default ModalErrosDeValidacao
