import React, { useState, useReducer, useContext } from 'react'
import useNonInitialEffect from 'hooks/useNonInitialEffect'
import { EventModalContext } from 'context/EventModalContext/EventModalContext'
import * as ErrorsReducers from './ErrorsReducers'
import {
  ErrorsContainer,
  ErrorsTitle,
  ErrorsList,
  ErrorListItem
} from './styles'

export function useErrorEvent(data) {
  const { dispatchEventModal } = useContext(EventModalContext)

  const [errorsState, dispatchErrorsStates] = useReducer(
    ErrorsReducers.reducer,
    ErrorsReducers.states
  )

  const [elementErrors, setElementErrors] = useState(<></>)

  useNonInitialEffect(() => {
    if (data && typeof data !== 'undefined') {
      const { title, errors } = data
      dispatchErrorsStates({ type: 'setTitle', payload: title })
      dispatchErrorsStates({ type: 'setErrors', payload: errors })
      dispatchErrorsStates({ type: 'setDisplay', payload: true })
    }
  }, [data])

  useNonInitialEffect(() => {
    if (window.utils.isFalsy(errorsState.errors?.length)) {
      setElementErrors(
        <ErrorsContainer>
          <ErrorsTitle>Erro:</ErrorsTitle>
          <ErrorsList>
            <ErrorListItem>Erro</ErrorListItem>
          </ErrorsList>
        </ErrorsContainer>
      )
    }

    if (errorsState.errors?.length > 0) {
      setElementErrors(
        <ErrorsContainer>
          <ErrorsTitle>
            {errorsState.errors.length > 1 ? 'Erros:' : 'Erro:'}
          </ErrorsTitle>
          <ErrorsList>
            {errorsState.errors.map((message, key) => {
              const errorMessage = Object.values(message)[0]
              return <ErrorListItem key={key}>{errorMessage}</ErrorListItem>
            })}
          </ErrorsList>
        </ErrorsContainer>
      )
    }
  }, [errorsState.errors])

  useNonInitialEffect(() => {
    if (errorsState.display) {
      dispatchEventModal({ type: 'setModalContentTop', payload: <></> })
      dispatchEventModal({
        type: 'setModalContentMiddle',
        payload: elementErrors
      })
      dispatchEventModal({ type: 'setModalContentBottom', payload: <></> })
      window.eventBus.dispatch('openModal', { title: errorsState.title })
    }
  }, [errorsState.display, elementErrors])
}
