/**
 * Return an Array of strings with years in the set configuration
 * @param {string} orientation  ['past','future','both'] | Direction of the years to return
 * * @param {integer} limit  Number of years you want in the return
 * @returns {Array} Array of strings with years.
 */
function getYears(orientation = 'both', limit = 20, order = 'desc') {
  const currentYear = new Date().getFullYear()

  let yearsArray
  switch (orientation) {
    case 'both':
      yearsArray = Array(2 * limit + 1)
      yearsArray = [
        ...[...yearsArray].map((item, index) => currentYear - limit + index)
      ]
      break
    case 'past':
      yearsArray = Array(limit + 1)
      yearsArray = [
        ...[...yearsArray].map((item, index) => currentYear - index)
      ]
      break
    case 'future':
      yearsArray = Array(limit + 1)
      yearsArray = [
        ...[...yearsArray].map((item, index) => currentYear + index)
      ]
      break
  }

  if (order === 'desc') {
    yearsArray = yearsArray.sort((a, b) => a - b)
  }
  if (order === 'asc') {
    yearsArray = yearsArray.sort((a, b) => b - a)
  }

  return yearsArray
}

/**
 * Return an Array of strings with months in the set configuration
 * @param {string} orientation  ['all','past','future'] | Direction of the months to return
 * * @param {boolean} includeCurrentMonth Flag to include or exclude current month from response for past and future orientations
 * @returns {Array} Array of strings with months names
 */
function getMonths(
  orientation = 'all',
  includeCurrentMonth = true,
  order = 'desc'
) {
  let currentMonth = new Date().getMonth()
  let months

  switch (orientation) {
    case 'all':
      months = new Array(12).fill('')
      months = months.map((item, index) => {
        let date = new Date(new Date().setDate('01'))
        date = new Date(date)
        date = new Date(date.setMonth(date.getMonth() + index))
        return date
      })
      break
    case 'past':
      currentMonth = includeCurrentMonth ? currentMonth : currentMonth - 1
      months = new Array(currentMonth + 1).fill('')
      months = months.map((item, index) => {
        let date = new Date(new Date().setDate('01'))
        date = new Date(date.setMonth('00'))
        date = new Date(date.setMonth(date.getMonth() + index))
        return date
      })
      break
    case 'future':
      currentMonth = includeCurrentMonth ? currentMonth : currentMonth + 1
      months = new Array(12 - currentMonth).fill('')
      months = months.map((item, index) => {
        let date = new Date(new Date().setDate('01'))
        date = new Date(date.setMonth(currentMonth))
        date = new Date(date.setMonth(date.getMonth() + index))
        return date
      })
      break
  }

  if (order === 'desc') {
    months = months.sort((a, b) => a.getMonth() - b.getMonth())
  }
  if (order === 'asc') {
    months = months.sort((a, b) => b.getMonth() - a.getMonth())
  }

  months = months.map((month) => {
    month = month.toLocaleDateString('pt-BR', { month: 'long' })
    month = window.utils.treatString(month, 'capitalizeFirstLetter')
    return month
  })

  return months
}

const treatMonth = (options) => {
  const monthNames = [
    ['Janeiro', '01'],
    ['Fevereiro', '02'],
    ['Março', '03'],
    ['Abril', '04'],
    ['Maio', '05'],
    ['Junho', '06'],
    ['Julho', '07'],
    ['Agosto', '08'],
    ['Setembro', '09'],
    ['Outubro', '10'],
    ['Novembro', '11'],
    ['Dezembro', '12']
  ]
  const operations = {
    nameToNumber: () => {
      return new Map(monthNames).get(month)
    },
    numberToName: () => {
      const reversed = monthNames.map((monthArr) => monthArr.reverse())
      return new Map(reversed).get(month)
    }
  }

  const { transform, month } = options

  return operations[transform]()
}

const dateHelpers = {
  getYears,
  getMonths,
  treatMonth
}

export default dateHelpers
