/* eslint-disable no-unused-vars */
import React from 'react'
import { useLocation } from 'react-router-dom'
import {
  Container,
  StepsImagem,
  StepsDescriptor,
  StepsCircle,
  StepsLine
} from './style'

export function Passos() {
  return (
    <Container>
      <Imagem />
      <StepsDescriptor area="dados">
        <h2>Seus Dados</h2>
        <p>Digite seu email</p>
      </StepsDescriptor>
      <StepsDescriptor area="senha">
        <h2>Nova Senha</h2>
        <p>Escolha uma nova senha</p>
      </StepsDescriptor>
    </Container>
  )
}

function Imagem() {
  const { hash } = useLocation()

  return (
    <StepsImagem>
      <StepsCircle status={hash === '' ? 'filled' : 'marked'} />
      <StepsLine />
      <StepsCircle status={hash === '' ? 'empty' : 'filled'} />
    </StepsImagem>
  )
}
