import React, { useContext } from 'react'
import { Button } from '@plick-solucoes-contabeis/ui-components'
import { getEndpoint } from 'hooks/useFetch'
import { LoginContext } from 'services/Auth/AuthContext'

const getCsvReportEmpresa = getEndpoint('empresas', 'getCsvReportEmpresas')
const getCsvReportEscritorio = getEndpoint(
  'escritorios',
  'getCsvReportEscritorios'
)
const getCsvReportUsuarios = getEndpoint('usuarios', 'getCsvReportUsuarios')

// eslint-disable-next-line react/prop-types
function CsvButton({ source, setLoading }) {
  const {
    authStates: {
      loggedUserData: { uuid }
    }
  } = useContext(LoginContext)

  const generateReport = async (report) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await report({
        userId: uuid,
        key: process.env.REACT_APP_REPORTS_SECRET_KEY
      })

      window.location.href = `${process.env.REACT_APP_API_URL}${data.url}`
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const exportCsv = async () => {
    setLoading(true)

    switch (source) {
      case 'escritorio':
        await generateReport(getCsvReportEscritorio)
        break
      case 'empresa':
        await generateReport(getCsvReportEmpresa)
        break
      case 'usuarios':
        await generateReport(getCsvReportUsuarios)
        break
      default:
        setLoading(false)
        break
    }
  }

  return (
    <Button
      style={{ marginLeft: 10 }}
      title="Exportar CSV"
      onClick={exportCsv}
    />
  )
}

export default CsvButton
