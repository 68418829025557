import React from 'react'

export default function withFlexLabel({ name, title }, Component) {
  const inputStyle = {
    borderRadius: '3px',
    border: '0',
    borderBottom: '1px solid rgb(118, 118, 118)',
    height: '1.7rem',
    backgroundColor: 'var(--white)'
  }
  const ModifiedComponent = (props) => {
    return (
      <label
        htmlFor={name}
        style={{
          display: 'flex',
          margin: '10px 0',
          color: '#333333',
          alignItems: 'end'
        }}
      >
        <span style={{ width: '50%' }}>{title}</span>
        <Component {...{ ...props, inputStyle }} />
      </label>
    )
  }
  return ModifiedComponent
}
