import { useContext, useState, useEffect } from 'react'
import permissionHelpers from 'helpers/permissionHelper'
import { LoginContext } from 'services/Auth/AuthContext'
import { TableContext } from 'context/TableContext/TableContext'
import useNonInitialEffect from 'hooks/useNonInitialEffect'

export default function useActions(
  featureName,
  actionPermissions,
  dependencies = []
) {
  const { authStates } = useContext(LoginContext)
  const { dispatchTableConfigs } = useContext(TableContext)
  const [permissionLevel, setPermissionLevel] = useState('')

  useEffect(() => {
    if (Object.keys(authStates.loggedUserData).length > 0) {
      setPermissionLevel(
        permissionHelpers.getPermissionLevel(authStates, featureName)
      )
    }
  }, [authStates.loggedUserData])

  useNonInitialEffect(() => {
    const actions = []
    if (permissionLevel === 'read') {
      actions.push(...actionPermissions.read)
    } else if (permissionLevel === 'manage') {
      actions.push(...actionPermissions.manage)
    }

    dispatchTableConfigs({ type: 'setActions', payload: actions })
  }, [permissionLevel, ...dependencies])
}
