import React, { useContext } from 'react'
import useNonInitialEffect from '../../useNonInitialEffect'
import { EventModalContext } from 'context/EventModalContext/EventModalContext'
import { Button } from 'components/Button'
import BottomButtonsContainer from 'components/EventModal/EventsComponents/BottomButtonsContainer'

import { ConfirmationContainer, ConfirmationMessage } from './style'

export function useConfirmationEvent(data) {
  const { dispatchEventModal } = useContext(EventModalContext)
  const Buttons = (
    <BottomButtonsContainer justify={'space-between'}>
      <Button
        value="SIM"
        buttonFunction="ButtonCustom"
        customStyle={`
          background-color: var(--background-medium-blue);
          border-radius: 5px;
          width: 80px;
          height: 30px;
          padding: 5px;
          color: var(--text-color-light);
          font-size: 1em;
          font-weight: bold;

          :hover {
            text-decoration: underline;
          }
        `}
        onClick={() => {
          data.callback({ response: true })
          window.eventBus.dispatch('closeModal')
        }}
      />
      <Button
        value="NÃO"
        buttonFunction="ButtonCustom"
        customStyle={`
          background-color: transparent;
          border-radius: 5px;
          border: 1px solid var(--background-medium-blue);
          width: 80px;
          height: 30px;
          padding: 5px;
          color: var(--background-medium-blue);;
          font-size: 1em;
          font-weight: bold;

          :hover {
            text-decoration: underline;
          }
        `}
        onClick={() => {
          data.callback({ response: false })
          window.eventBus.dispatch('closeModal')
        }}
      />
    </BottomButtonsContainer>
  )

  useNonInitialEffect(() => {
    if (data && typeof data !== 'undefined') {
      const { title } = data
      dispatchEventModal({ type: 'setModalContentTop', payload: <></> })
      dispatchEventModal({
        type: 'setModalContentMiddle',
        payload: (
          <ConfirmationContainer>
            <ConfirmationMessage>Deseja {title}?</ConfirmationMessage>
          </ConfirmationContainer>
        )
      })
      dispatchEventModal({ type: 'setModalContentBottom', payload: Buttons })
      window.eventBus.dispatch('openModal', { title: title })
    }
  }, [data])
}
