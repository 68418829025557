import useHeaders from './useHeaders'
import useTopBar from './useTopBar'
import useActions from './useActions'
import useCellComponents from './useCellComponents'
import useCrudFields from './useCrudFields'
import useTable from './useTable'
import useReset from './useReset'

const tableHooks = {
  useHeaders,
  useTopBar,
  useActions,
  useCellComponents,
  useCrudFields,
  useTable,
  useReset
}

export default tableHooks
