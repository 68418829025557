/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import api from 'services/api'
import AutoComplete from 'components/AutoComplete'
import withFlexLabel from 'components/AutoComplete/logic/hoc/withFlexLabel'
/*
  dataSource:
    - 'sistemascontabeis' => campo homônimo de escritório.
    - 'escritorio' => Sistemas contábeis atrelados a Escritório especifico.
    - 'all' => Endpoint que traz todos os sistemasContabeis do sistema.
*/

function InputSelectorSistemaContabilPadrao({ name, dataSource = 'all' }) {
  // const [allSistemasContabeis, setAllSistemasContabeis] = useState([])
  const [SistemasContabeis, setSistemasContabeis] = useState([])

  const [sistemasContabeisField] = useField('sistemascontabeis')
  const [escritorioField] = useField('escritorio_id')
  const [field, fieldMeta, fieldHelper] = useField(name)

  const inputRef = useRef()
  const defaultValuesRef = useRef()

  const dataOptionsMethods = {
    all: async () => {
      const response = {
        list: []
      }

      const { data } = await api
        .getAllSistemaContabil()
        .catch((error) => console.error(error))

      response.list = data
      return response
    },
    sistemascontabeis: async () => {
      const response = { list: [] }

      const { data } = await api
        .getAllSistemaContabil()
        .catch((error) => console.error(error))

      if (sistemasContabeisField.value?.length > 0 && data.length > 0) {
        response.list = sistemasContabeisField.value.map((fieldValue) =>
          data.find((value) => value.id.toString() === fieldValue.id.toString())
        )
      }
      return response
    },
    escritorio: async () => {
      const response = { list: [] }

      if (escritorioField.value) {
        const escritorio = await api
          .getOneEscritorio(escritorioField.value)
          .catch((error) => console.error(error))
        response.list = escritorio.data.sistemascontabeis
        response.default = escritorio.data.sistema_contabil_padrao
      }
      return response
    }
  }

  const handleAll = async () => {
    const sistemasContabeisData = await dataOptionsMethods.all()
    return sistemasContabeisData.list
  }

  const handleEscritorio = async () => {
    const sistemasContabeisData = await dataOptionsMethods.escritorio()
    defaultValuesRef.current = sistemasContabeisData.default
    return sistemasContabeisData.list
  }

  const handleSistemasContabeis = async () => {
    const sistemasContabeisData = await dataOptionsMethods.sistemascontabeis()
    return sistemasContabeisData.list
  }

  const handleSearchDeps = () => {
    switch (dataSource) {
      case 'all':
        return []
      case 'escritorio':
        return [escritorioField.value]
      case 'sistemascontabeis':
        return [sistemasContabeisField.value.length]
    }
  }

  const handleFreeze = () => {
    switch (dataSource) {
      case 'all':
        return false
      case 'escritorio':
        return !escritorioField.value
      case 'sistemascontabeis':
        return !sistemasContabeisField.value.length
    }
  }

  return (
    <AutoComplete
      name={name}
      oneTimeRequest
      helper={({ ref }) => {
        inputRef.current = ref
      }}
      options={{
        freezeOnMount: handleFreeze(),
        searchDeps: handleSearchDeps()
      }}
      inputProps={{
        disabled: SistemasContabeis?.length <= 0
      }}
      renderMenuItem={(value, labelProps) => (
        <span {...labelProps}>{value.nome}</span>
      )}
      filter={(value, searchValue) =>
        value.nome.match(new RegExp(`${searchValue.trim()}`, 'gi'))
      }
      startSearch={async () => {
        try {
          if (dataSource === 'all') {
            const data = await handleAll()
            return data
          }
          if (dataSource === 'escritorio') {
            const data = await handleEscritorio()
            return data
          }
          if (dataSource === 'sistemascontabeis') {
            const data = await handleSistemasContabeis()
            return data
          }
        } catch (e) {
          throw new Error(e)
        }
      }}
      afterSearch={(items, defaultValue) => {
        if (
          dataSource === 'sistemascontabeis' &&
          inputRef.current.nodeRef.value
        ) {
          const result = items.find(
            (value) => value.nome === inputRef.current.nodeRef.value
          )

          if (!result) {
            inputRef.current.setValue('')
            fieldHelper.setValue('')
          }
        }

        if (dataSource === 'escritorio') {
          let value = null

          if (defaultValue) {
            value = items.find((sistema) => defaultValue === sistema.id)
          } else {
            value = items.find(
              (sistema) => defaultValuesRef.current === sistema.id
            )
          }
          if (value) {
            inputRef.current.setValue(value.nome)
            fieldHelper.setValue(value.id)
          } else {
            inputRef.current.setValue('')
            fieldHelper.setValue('')
          }
        }

        setSistemasContabeis(items)
      }}
      extractKey={(item) => item.id}
      createOptions={(data) => {
        if (data instanceof Array) {
          return data.map((item) => ({
            label: item.nome,
            value: item.id
          }))
        }
        return {
          label: data.nome,
          value: data.id
        }
      }}
    />
  )
}
export default withFlexLabel(
  { name: 'sistema_contabil_padrao', title: 'Sistema Contabil Padrão' },
  InputSelectorSistemaContabilPadrao
)
InputSelectorSistemaContabilPadrao.propTypes = {
  name: PropTypes.string,
  dataSource: PropTypes.string
}
