import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

function InputSelectorStatusDoSistemaContabil({ name }) {
  return (
    <Field
      as="select"
      name={name}
      style={{
        marginLeft: '10px',
        width: 'calc(100% - 20px)'
      }}
    >
      <option value="" disabled hidden>
        Escolha o status desse Sistema Contabil
      </option>
      return (<option value={'em_analise'}>Em análise</option>
      <option value={'suportado'}>Suportado</option>
      <option value={'nao_suportado'}>Não Suportado</option>)
    </Field>
  )
}

InputSelectorStatusDoSistemaContabil.propTypes = {
  option: PropTypes.string,
  name: PropTypes.string
}
export default InputSelectorStatusDoSistemaContabil
