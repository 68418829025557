import React from 'react'
import PropTypes from 'prop-types'
import * as Buttons from './styles'
import * as Icons from 'assets/icons'
export function Button({
  type = 'button',
  value,
  colorText,
  bgColor,
  buttonFunction,
  buttonIcon,
  size,
  ...props
}) {
  if (!window.utils.isFalsy(buttonFunction)) {
    let Icon = <></>
    const Button = Buttons[buttonFunction]

    if (!window.utils.isFalsy(buttonIcon)) {
      Icon = Icons[buttonIcon]
      Icon = <Icon color={colorText} />
    }

    switch (buttonFunction) {
      default:
        return (
          <Button
            type={type}
            bgColor={bgColor}
            colorText={colorText}
            {...props}
          >
            {value || '' + ' '}
            {Icon}
          </Button>
        )
    }
  }

  return (
    <Buttons.Container
      type={type}
      colorText={colorText}
      bgColor={bgColor}
      size={size}
      {...props}
    >
      {value}
    </Buttons.Container>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any,
  colorText: PropTypes.string,
  buttonFunction: PropTypes.string,
  bgColor: PropTypes.string,
  buttonIcon: PropTypes.string,
  size: PropTypes.string
}
