/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { LoginContext } from 'services/Auth/AuthContext'
import { Form } from 'components/Form'
import { InputComponent } from 'components/InputComponent'
import { Button } from 'components/Button'
import { handleLogin, handleRefresh } from './loginLogic'
import { LoginContainer, LoginError, Loader, Content, Title } from './style'

export function Login() {
  const { authStates, dispatchAuthStates } = useContext(LoginContext)

  const [haveError, setHaveError] = useState(false)
  const [lembrarDeMim, setLembrar] = useState(false)

  const initialValues = {
    email: sessionStorage.getItem('loginEmail') || '',
    password: ''
  }

  const handleSubmit = async (values) => {
    await handleLogin(
      values.email,
      values.password,
      dispatchAuthStates,
      setHaveError,
      lembrarDeMim
    )
  }

  const schemaValidate = Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Campo obrigatório.'),
    password: Yup.string().required('Campo obrigatório.')
  })

  useEffect(() => {
    const refreshToken = sessionStorage.getItem('refreshToken')
    if (refreshToken) {
      const lastLocation = sessionStorage.getItem('lastLocation')
      if (lastLocation) {
        dispatchAuthStates({ type: 'setLoading', payload: true })
        handleRefresh(dispatchAuthStates)
      }
    }
  }, [])

  // const handleInputChange = (event) => {
  //   setLembrar(event.target.checked)
  // }

  const handleEmailInput = (event) => {
    sessionStorage.setItem('loginEmail', event.target.value)
  }

  return authStates.loading ? (
    <Loader />
  ) : (
    <LoginContainer>
      <Title>Entrar na Plataforma</Title>
      <Form
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        schemaValidate={schemaValidate}
        reset={false}
      >
        <InputComponent
          label="E-mail"
          type="text"
          name="email"
          placeholder="Escreva seu e-mail"
          onInput={handleEmailInput}
        />
        <InputComponent
          label="Senha"
          type="password"
          name="password"
          placeholder="Digite sua senha"
        />

        <Content>
          {/* <div>
            <input
              type="checkbox"
              id="lembrar"
              name="lembrar"
              onChange={handleInputChange}
            />
            <label htmlFor="lembrar">Lembrar de mim</label>
          </div> */}
          <p>
            Esqueceu a senha? <Link to="/recuperar-senha">Redefinir Senha</Link>
          </p>
        </Content>

        <Button type="submit" value="Entrar" />
      </Form>

      {haveError ? <LoginError>Credenciais Invalidas</LoginError> : ''}
    </LoginContainer>
  )
}
