import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'periodicidade'
const url = '/periodicidade'

const endpointsDescriptions = {
  getAllPeriodicidades: {
    action: 'getAll'
  },
  getPeriodicidadeById: {
    action: 'getOne'
  }
}

const periodicidade = new BaseDomainsProvider(
  domain,
  url,
  endpointsDescriptions
)

endpoints.getAllPeriodicidades = () => {
  const endpointDescription =
    periodicidade.endpointsConfigs.getAllPeriodicidadesConfig

  return axios.get(`${baseUrl}/periodicidade`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getPeriodicidadeById = (id) => {
  const endpointDescription =
    periodicidade.endpointsConfigs.getPeriodicidadeByIdConfig

  return axios.get(`${baseUrl}/periodicidade/${id}`, {
    endpointInfo: endpointDescription
  })
}

export default endpoints
