/* eslint-disable no-unused-vars */
import React from 'react'
import { Form } from 'components/Form'
import PropTypes from 'prop-types'
import { ColunaFormFields } from './ColunaFormFields'
import { Button } from 'components/Button'

import * as logic from '../../../subPages/LinhaSaida/linhaSaidaLogic'

import * as Yup from 'yup'

import { FormContainer } from './style'

export function ColunaForm({ colunaInitialValues, setColumns }) {
  const handleSubmitColuna = async (values) => {
    if (values.update) {
      await logic.editOutputColumn(values, setColumns)
    } else {
      await logic.createOutputColumn(values, setColumns)
    }
  }

  const schemaValidateColuna = Yup.object().shape({
    nome: Yup.string().required('Nome não pode ser vazio'),
    output_column_name: Yup.string().required('Descrição não pode ser vazio'),
    char_start_position: Yup.number()
      .optional()
      .min(0, 'Deve ser maior ou igual a zero')
      .integer('Deve ser numérico'),
    char_end_position: Yup.number()
      .optional()
      .min(0, 'Deve ser maior ou igual a zero')
      .integer('Deve ser numérico')
  })

  return (
    <>
      <FormContainer>
        <header>
          <h3>
            {colunaInitialValues.update
              ? `Editar Coluna ${[
                  '',
                  ...Array(colunaInitialValues.column)
                ].reduce((previous) => logic.incrementChar(previous))}-${
                  colunaInitialValues.line
                }`
              : `Cadastrar nova Coluna ${[
                  '',
                  ...Array(colunaInitialValues.column)
                ].reduce((previous) => logic.incrementChar(previous))}-${
                  colunaInitialValues.line
                }`}
          </h3>
        </header>
        <Form
          handleSubmit={handleSubmitColuna}
          initialValues={colunaInitialValues}
          schemaValidate={schemaValidateColuna}
          reset={!colunaInitialValues.update}
        >
          <ColunaFormFields />

          <Button
            bgColor="var(--green)"
            colorText="var(--white)"
            value={!colunaInitialValues.update ? 'Criar' : 'Editar'}
            type="submit"
          />
        </Form>
      </FormContainer>
    </>
  )
}

ColunaForm.propTypes = {
  colunaInitialValues: PropTypes.object,
  setColumns: PropTypes.func
}
