import styled from 'styled-components'
import { FormContainer } from '../../subPages/style'

export const IgnoresFormContainer = styled(FormContainer)`
   margin-top:49px;
   form {
     gap:25px;
     height:min-content;
   }

   button {
       grid-column: 2;
       margin-top:30%;
       justify-self: auto;
   }
`
