import React from 'react'
import PropTypes from 'prop-types'
import history from 'services/history'
import { Button } from 'components/Button'
import { Tooltip } from '@plick-solucoes-contabeis/ui-components'

function EditContatosButton({ rowValues }) {
  return (
    <Tooltip title="Contatos" delay={900}>
      <Button
        buttonFunction={'ButtonTable'}
        buttonIcon={'IconeContatos'}
        colorText={'var(--background-medium-blue)'}
        onClick={() => {
          history.push('/contatos', {
            id: rowValues.uuid,
            type: 'EscritorioRelation',
            relation: 'contato'
          })
        }}
      />
    </Tooltip>
  )
}
EditContatosButton.propTypes = {
  rowValues: PropTypes.object
}
export default EditContatosButton
