/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Wrapper, TabButton } from './style'

export default function Tabs({
  tabs,
  customTabContainerStyle,
  selectedTab,
  tabName = 'tabs-container-01'
}) {
  const TabButtons = useMemo(
    () =>
      tabs.map((tab, index) => (
        <TabButton
          key={index}
          value={tab.title}
          onClick={() => tab.onClickTab(tab.value)}
          customTabButtonStyle={`
            ${selectedTab === tab.value ? tab.active : tab.inactive}
          `}
        />
      )),
    [tabs]
  )

  return (
    <Wrapper
      customTabContainerStyle={customTabContainerStyle}
      className={tabName}
    >
      {TabButtons}
    </Wrapper>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array,
  customTabContainerStyle: PropTypes.string,
  selectedTab: PropTypes.string,
  tabName: PropTypes.string
}
