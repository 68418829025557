import React from 'react'
import PropTypes from 'prop-types'
import history from 'services/history'
import { Button } from 'components/Button'
import { Tooltip } from '@plick-solucoes-contabeis/ui-components'

function EditUsuariosButton({ rowValues }) {
  return (
    <Tooltip title="Usuários" delay={900}>
      <Button
        buttonFunction={'ButtonTable'}
        buttonIcon={'IconeUsuarios'}
        colorText={'var(--background-medium-blue)'}
        onClick={() => {
          history.push('usuarios', {
            id: rowValues.uuid,
            type: 'EscritorioRelation',
            relation: 'usuario'
          })
        }}
      />
    </Tooltip>
  )
}

EditUsuariosButton.propTypes = {
  rowValues: PropTypes.object
}
export default EditUsuariosButton
