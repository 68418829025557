/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import { LoginContext } from 'services/Auth/AuthContext'
import PropTypes from 'prop-types'
import api from 'services/api'
import styled from 'styled-components'
import { Button } from 'components/Button'
import PermissionLevelOptions from 'components/FormCRUD/subComponents/PermissionLevelOptions'
import { humanNameFor } from 'helpers/humanNamesForCodeNames'
import { Tooltip } from '@plick-solucoes-contabeis/ui-components'

function UsuariosPermissoesButton(props) {
  const handleClick = ({ modalConfig, setModalVisible, rowValues }) => {
    modalConfig.current = {
      title: `Permissões de ${rowValues.nome}`,
      externalComp: true,
      comp: <ModalPermissoes uuid={rowValues.uuid} />
    }
    setModalVisible(true)
  }

  return (
    <Tooltip title="Permissões" delay={900}>
      <Button
        buttonFunction={'ButtonTable'}
        buttonIcon={'IconePermissoesDeUsuario'}
        colorText={'var(--background-medium-blue)'}
        onClick={() => handleClick(props)}
      />
    </Tooltip>
  )
}
UsuariosPermissoesButton.propTypes = {
  setShowModal: PropTypes.func,
  setModalContent: PropTypes.func,
  rowValues: PropTypes.object
}

const ModalPermissoes = ({ uuid }) => {
  const { reloadLoggedUserData, authStates } = useContext(LoginContext)
  const [userInThisRowPermissions, setUserInThisRowPermissions] = useState([])
  const [globalFeaturesList, setGlobalFeaturesList] = useState([])

  useEffect(() => {
    api.getAllFeatures().then((response) => {
      setGlobalFeaturesList(response.data)
    })
  }, [])

  useEffect(() => {
    api.getUserPermissions(uuid).then((response) => {
      setUserInThisRowPermissions(response.data)
    })
  }, [])

  return (
    authStates.loggedUserData?.claims?.length > 0 &&
    userInThisRowPermissions.length > 0 && (
      <div
        style={{
          maxHeight: '80vh'
        }}
      >
        {authStates.loggedUserData?.claims
          .filter(
            (singlePermissionOfLoggedUser) =>
              singlePermissionOfLoggedUser.valor === 'manage'
          )
          .map((singlePermissionOfLoggedUser) => ({
            name: singlePermissionOfLoggedUser.feature.feature,
            value: singlePermissionOfLoggedUser.valor
          }))
          .map((singlePermissionOfLoggedUser, index) => {
            let permissionLevel = 'none'
            let featureId = 0

            const currentPermission = userInThisRowPermissions.filter(
              (singlePermissionOfRowUser) =>
                singlePermissionOfRowUser.name ===
                singlePermissionOfLoggedUser.name
            )

            permissionLevel =
              currentPermission.length > 0 ? currentPermission[0].value : 'none'

            const feature = globalFeaturesList.filter(
              (singleFeature) =>
                singleFeature.feature === singlePermissionOfLoggedUser.name
            )

            let featureName = ''
            if (feature.length > 0) {
              featureId = feature[0].id
              featureName = feature[0].feature
            }

            return (
              <TableRow key={index} zebra={index}>
                <Botton bgcolor="#4070A0" color="#FFEEDD" className={'botton'}>
                  {humanNameFor[singlePermissionOfLoggedUser.name] ||
                    singlePermissionOfLoggedUser.name}
                </Botton>
                <PermissionLevelOptions
                  valores={{
                    featureName: featureName,
                    feature_id: featureId,
                    usuario_id: uuid,
                    permissionLevel: permissionLevel
                  }}
                  reloadLoggedUserData={reloadLoggedUserData}
                />
              </TableRow>
            )
          })}
      </div>
    )
  )
}

ModalPermissoes.propTypes = {
  uuid: PropTypes.string
}

const Botton = styled.p`
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  border-radius: 7px;
  width: 150px;
  font-size: 12px;
  padding: 5px;
  text-align: center;
  transform: translate(-10px);
`

const TableRow = styled.div`
  background-color: ${(props) =>
    props.zebra % 2 === 0 ? 'rgb(243, 243, 243)' : 'var(--white)'};
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  height: 50px;
  padding: 0 10px 0 30px;
`
export default UsuariosPermissoesButton
