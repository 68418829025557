import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/Button'
import { Table } from './style'
import { Tooltip } from '@plick-solucoes-contabeis/ui-components'

export function LayoutEntradaTable({ items, fields, actionButtons }) {
  const Headers = (
    <tr>
      {fields.map((field, index) => {
        return (
          <th key={index}>
            {' '}
            <h5>{field.as}</h5>{' '}
          </th>
        )
      })}
      {!window.utils.isFalsy(actionButtons) ? (
        <th>
          <h5>ACÕES</h5>
        </th>
      ) : (
        ''
      )}
    </tr>
  )

  let Rows = <></>
  if (items.length > 0) {
    Rows = useMemo(
      () =>
        items.map((item, indexItem) => {
          return (
            <tr key={indexItem}>
              {fields.map((field, indexParam) => {
                if (
                  !window.utils.isFalsy(field.fromData) &&
                  !window.utils.isFalsy(field.refName)
                ) {
                  if (field.fromData.length > 0) {
                    const value = field.fromData.find(
                      (dataItem) =>
                        (dataItem.id || dataItem.uuid) === item[field.fieldName]
                    )[field.refName]
                    const formattedValue = window.utils.treatString(
                      value.toString(),
                      'textLimiter',
                      {
                        limit: 36
                      }
                    )
                    return (
                      <td key={indexParam} title={value}>
                        <span>{formattedValue}</span>
                      </td>
                    )
                  } else {
                    const value = item[field.fieldName]
                    const formattedValue = window.utils.treatString(
                      value.toString(),
                      'textLimiter',
                      {
                        limit: 36
                      }
                    )
                    return (
                      <td key={indexParam} title={value}>
                        {' '}
                        <span>{formattedValue}</span>{' '}
                      </td>
                    )
                  }
                } else {
                  const value = item[field.fieldName]
                  const formattedValue = window.utils.treatString(
                    value.toString(),
                    'textLimiter',
                    {
                      limit: 36
                    }
                  )
                  return (
                    <td key={indexParam} title={value}>
                      {' '}
                      <span>{formattedValue}</span>{' '}
                    </td>
                  )
                }
              })}
              {!window.utils.isFalsy(actionButtons) ? (
                <td>
                  {' '}
                  {actionButtons.map((actionButton, index) => {
                    return (
                      <Tooltip
                        key={index}
                        title={actionButton.tooltipText}
                        delay={900}
                      >
                        <Button
                          data-id={item.id || item.uuid}
                          {...actionButton}
                        />
                      </Tooltip>
                    )
                  })}
                </td>
              ) : (
                <></>
              )}
            </tr>
          )
        }),
      [items]
    )
  }

  return (
    <Table>
      <thead>{Headers}</thead>
      <tbody>{Rows}</tbody>
    </Table>
  )
}

LayoutEntradaTable.propTypes = {
  items: PropTypes.array,
  fields: PropTypes.array,
  actionButtons: PropTypes.array
}
