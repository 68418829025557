import React from 'react'
import PropTypes from 'prop-types'

function Cell({
  type,
  CellComponent,
  value,
  apiID,
  apiFetcher,
  listUrl,
  setModalContent,
  setShowModal,
  setCrudTimeStamp,
  setMainTopBarContent,
  headers,
  actions,
  rowValues,
  perPage,
  setActive,
  setRows,
  numberOfPages,
  crudFields,
  modalConfig,
  setModalVisible
}) {
  if (type === 'Ações') {
    return (
      <CellComponent
        apiID={apiID}
        apiFetcher={apiFetcher}
        listUrl={listUrl}
        setModalContent={setModalContent}
        setShowModal={setShowModal}
        setCrudTimeStamp={setCrudTimeStamp}
        setMainTopBarContent={setMainTopBarContent}
        headers={headers}
        actions={actions}
        rowValues={rowValues}
        perPage={perPage}
        setActive={setActive}
        setRows={setRows}
        numberOfPages={numberOfPages}
        crudFields={crudFields}
        modalConfig={modalConfig}
        setModalVisible={setModalVisible}
      />
    )
  } else {
    return <CellComponent value={value} type={type} />
  }
}

Cell.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array
  ]),
  apiID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  apiFetcher: PropTypes.string,
  listUrl: PropTypes.string,
  setModalContent: PropTypes.func,
  setShowModal: PropTypes.func,
  setCrudTimeStamp: PropTypes.func,
  setMainTopBarContent: PropTypes.func,
  headers: PropTypes.array,
  actions: PropTypes.array,
  CellComponent: PropTypes.elementType,
  rowValues: PropTypes.object,
  modalConfig: PropTypes.object,
  setModalVisible: PropTypes.func,
  perPage: PropTypes.number,
  setActive: PropTypes.func,
  setRows: PropTypes.func,
  numberOfPages: PropTypes.number,
  crudFields: PropTypes.array
}
export default Cell
