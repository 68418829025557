import api from 'services/api'

function nextLetter (char) {
  return char === 'Z' ? 'A' : String.fromCharCode(char.charCodeAt(0) + 1)
}

function incrementChar (l) {
  let newCharArray = []
  const lastChar = l[l.length - 1]
  const remString = l.slice(0, l.length - 1)
  const newChar = lastChar === undefined ? 'A' : nextLetter(lastChar)
  newCharArray.unshift(newChar)

  if (lastChar === 'Z') {
    return incrementChar(remString) + 'A'
  }

  const batchString = remString + [...newCharArray].join('')
  newCharArray = []
  return batchString
}

async function getColumns (rowId, setColumns) {
  try {
    const { status, data } = await api.getOutputRowColumns(rowId)
    if (status === 200) {
      setColumns(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

export {
  incrementChar,
  getColumns
}
