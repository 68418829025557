import React from 'react'
import PropTypes from 'prop-types'
import { InputComponent } from 'components/InputComponent'

import {
  ButtonAddSanitization
} from './style'

export function SanitizationRuleForm ({ sanitizationRules, setShowSanitizationRuleModal }) {
  return (
    <>
        <InputComponent label='Regra Sanitização:' type='select' name='sanitization_rule'>
            {sanitizationRules.length > 0
              ? sanitizationRules.map((sanitizationRule, index) => {
                return <option key={index} value={sanitizationRule.id}>{sanitizationRule.nome}</option>
              })
              : ''
        }
        </InputComponent>

        <ButtonAddSanitization
            bgColor='var(--green)'
            colorText='var(--blue)'
            buttonFunction='ButtonTextIcon'
            buttonIcon='PlusIcon'
            value='ADICIONAR'
            onClick={(e) => {
              setShowSanitizationRuleModal(true)
              e.preventDefault()
            }}
        />
    </>
  )
}

SanitizationRuleForm.propTypes = {
  sanitizationRules: PropTypes.array,
  setShowSanitizationRuleModal: PropTypes.func
}
