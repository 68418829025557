import actionsList from './Lists/endpointsActionsList'

function EndpointConfig(domain, endpointName, endpointContent) {
  this.endpointName = endpointName
  this.domain = domain.domain
  this.action = checkAction(endpointContent.action)
  this.description = setDescription(endpointContent, domain.translatedDomain)
  this.options = setDefaultOptionsByAction(
    endpointContent.action,
    endpointContent?.options
  )
}

const defaultOptions = {
  displayOnErrors: true,
  confirmOnDelete: true,
  feedbackOnAction: false,
  activateLoader: true
}

function checkAction(action) {
  try {
    if (actionsList.indexOf(action) !== -1) {
      return action
    } else {
      throw new Error(`Action "${action}" not in the list`)
    }
  } catch (error) {
    console.error(error)
  }
}

function setDefaultOptionsByAction(action, receivedOptions) {
  let options = {
    displayOnErrors: defaultOptions.displayOnErrors,
    confirmOnDelete: defaultOptions.confirmOnDelete,
    feedbackOnAction: defaultOptions.feedbackOnAction,
    activateLoader: defaultOptions.activateLoader
  }

  switch (action) {
    case 'getAll':
    case 'getOne':
    case 'getBy':
    case 'getPaginated':
    case 'getReports':
    case 'custom':
      options.activateLoader = false
      break
    case 'add':
      options.feedbackOnAction = true
      break
    case 'update':
      options.feedbackOnAction = true
      break
    case 'delete':
      options.confirmOnDelete = true
      options.feedbackOnAction = true
      break
    case 'upload':
      options.feedbackOnAction = true
      break
    case 'process':
      options.feedbackOnAction = true
      break
    default:
      break
  }

  if (typeof receivedOptions !== 'undefined') {
    options = {
      ...options,
      ...receivedOptions
    }
  }

  return options
}

function setDescription(endpointContent, domain) {
  const description = ''
  switch (endpointContent.action) {
    case 'custom':
    case 'getAll':
    case 'getOne':
    case 'getBy':
    case 'getPaginated':
    case 'add':
    case 'update':
    case 'delete':
    case 'upload':
    case 'download':
    case 'process':
    case 'duplicate':
      return description
  }
}

export default EndpointConfig
