/* eslint-disable camelcase */
import api from 'services/api'

function nextLetter (char) {
  return char === 'Z' ? 'A' : String.fromCharCode(char.charCodeAt(0) + 1)
}

function incrementChar (l) {
  let newCharArray = []
  const lastChar = l[l.length - 1]
  const remString = l.slice(0, l.length - 1)
  const newChar = lastChar === undefined ? 'A' : nextLetter(lastChar)
  newCharArray.unshift(newChar)

  if (lastChar === 'Z') {
    return incrementChar(remString) + 'A'
  }

  const batchString = remString + [...newCharArray].join('')
  newCharArray = []
  return batchString
}

async function handleUpdateLine (
  values,
  setRows
) {
  const { id, created_at, updated_at, ...rest } = values

  try {
    const { status, data } = await api.editOutputRow(id, rest)
    if (status === 200) {
      setRows((oldRows) => oldRows.map((oldRow) => oldRow.id === id ? data : oldRow))
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    if (error.response.status === 401) {
    //   setHaveError(true)
      console.error(error)
    }
  }
}

async function getOutputRows (fileLayoutId, setRows) {
  try {
    const { status, data } = await api.getOutputRows(fileLayoutId)
    if (status === 200) {
      setRows(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function getOutputRowColumns (rowId, setColumns) {
  try {
    const { status, data } = await api.getOutputRowColumns(rowId)
    if (status === 200) {
      setColumns(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function treatColumnParams (params) {
  // assigning to newParams to avoid a warning when trying to change params
  const newParams = { ...params }

  const { char_start_position, char_end_position, fill_char, fill_direction } = newParams

  if (char_start_position === '' || window.utils.isFalsy(char_start_position)) {
    delete newParams.char_start_position
  }
  if (char_end_position === '' || window.utils.isFalsy(char_end_position)) {
    delete newParams.char_end_position
  }
  if (fill_char === '' || window.utils.isFalsy(fill_char)) {
    delete newParams.fill_char
  }
  if (fill_direction === '' || window.utils.isFalsy(fill_direction)) {
    delete newParams.fill_direction
  }

  return newParams
}

async function createOutputColumn (params, setColumns) {
  try {
    const newParams = await treatColumnParams(params)

    const { status, data } = await api.createOutputColumn(newParams)
    if (status === 201) {
      setColumns((oldColumns) => [...oldColumns, data])
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function editOutputColumn (params, setColumns) {
  try {
    // assigning to newParams to avoid a warning when trying to change params
    const newParams = await treatColumnParams(params)

    const { status, data } = await api.editOutputColumn(params.id, newParams)
    if (status === 200) {
      setColumns(
        (oldColumns) => oldColumns.map((oldColumn) => oldColumn.id === data.id ? data : oldColumn)
      )
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function deleteOutputColumn (id, setColumns) {
  try {
    const { status, data } = await api.deleteOutputColumn(id)
    if (status === 204) {
      setColumns((oldColumns) => oldColumns.filter((oldColumns) => parseInt(oldColumns.id) !== parseInt(id)))
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

export {
  incrementChar,
  getOutputRows,
  handleUpdateLine,
  getOutputRowColumns,
  createOutputColumn,
  editOutputColumn,
  deleteOutputColumn
}
