const domainsTranslationList = {
  arquivosOriginais: {
    singular: 'Arquivo',
    plural: 'Arquivos'
  },
  balancetesPlanosDeContas: {
    singular: 'Balancete/Plano de Contas',
    plural: 'Balancetes/Planos de Contas'
  },
  cellValidationRules: {
    singular: 'Regra de Validação de Célula',
    plural: 'Regras de Validação de Célula'
  },
  columnValidationRules: {
    singular: 'Regra de Validação de Coluna',
    plural: 'Regras de Validação de Coluna'
  },
  constantesEmpresas: {
    singular: 'Regra de De/Para',
    plural: 'Regras de De/Para'
  },
  contatos: {
    singular: 'Contato',
    plural: 'Contatos'
  },
  empresas: {
    singular: 'Empresa',
    plural: 'Empresas'
  },
  escritorios: {
    singular: 'Escritório',
    plural: 'Escritórios'
  },
  features: {
    singular: 'Funcionalidade',
    plural: 'Funcionalidades'
  },
  fileCards: {
    singular: 'Card de Arquivo',
    plural: 'Cards de Arquivo'
  },
  inputFileCell: {
    singular: 'Célula do layout de entrada',
    plural: 'Células do layout de entrada'
  },
  inputFileColumn: {
    singular: 'Coluna do layout de entrada',
    plural: 'Colunas do layout de entrada'
  },
  inputFileIgnore: {
    singular: 'Regra à ignorar',
    plural: 'Regras à ignorar'
  },
  inputFileLayout: {
    singular: 'Layout de Entrada',
    plural: 'Layouts de Entrada'
  },
  inputFileRowFormats: {
    singular: 'Formato de Linha do Layout de Entrada',
    plural: 'Formatos de Linha do Layout de Entrada'
  },
  outputFileCell: {
    singular: 'Célula do layout de saída',
    plural: 'Células do layout de saída'
  },
  outputFileColumn: {
    singular: 'Coluna do layout de saída',
    plural: 'Colunas do layout de saída'
  },
  outputFileHeader: {
    singular: 'Cabeçalho do layout de saída',
    plural: 'Cabeçalhos do layout de saída'
  },
  outputFileLayout: {
    singular: 'Layout de Saída',
    plural: 'Layouts de Saída'
  },
  outputFileRowFormats: {
    singular: 'Formato de Linha do Layout de Saída',
    plural: 'Formatos de Linha do Layout de Saída'
  },
  roles: {
    singular: 'Papel de permissionamento',
    plural: 'Papéis de permissionamento'
  },
  sanitizationRule: {
    singular: 'Regra de Sanitização',
    plural: 'Regras de Sanitização'
  },
  sistemaGestao: {
    singular: 'Sistema de Gestão',
    plural: 'Sistemas de Gestão'
  },
  sistemasContabeis: {
    singular: 'Sistema Contábil',
    plural: 'Sistemas Contábeis'
  },
  termoDeUso: {
    singular: 'Termo de uso',
    plural: 'Termos de Uso'
  },
  tipoMovimento: {
    singular: 'Tipo Movimento',
    plural: 'Tipos Movimento'
  },
  tipoMovimentoCategoria: {
    singular: 'Categoria de movimento',
    plural: 'Categorias de movimento'
  },
  tiposConstantesEmpresas: {
    singular: 'Aba de De/Para',
    plural: 'Abas de De/Para'
  },
  usuarios: {
    singular: 'Usuário',
    plural: 'Usuários'
  },
  validationRules: {
    singular: 'Regra de Validação',
    plural: 'Regras de Validação'
  },
  filter: {
    singular: 'Filtro',
    plural: 'Filtros'
  },
  periodicidade: {
    singular: 'Periodicidade',
    plural: 'Periodicidades'
  },
  camposConstantesEmpresas: {
    singular: 'Campo',
    plural: 'Campos'
  },
  templatesCamposConstantesEmpresas: {
    singular: 'Template de Campos',
    plural: 'Templates de Campos'
  },
  contas: {
    singular: 'Conta',
    plural: 'Contas'
  }
}

export default domainsTranslationList
