import React, { useEffect, useState, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/Button'
import { FormTabsContainer } from '../../subComponents/ColunaForm/style'

import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'

import * as logic from './linhaEntradaLogic'

import { LinhaContainer } from '../../subComponents/LinhaContainer'
import { LinhaForm } from '../../subComponents/LinhaForm'
import { ColunaForm } from '../../subComponents/ColunaForm'

function controlContextMenuReducer(state, action) {
  switch (action.type) {
    case 'displayLinhaForm':
      return {
        ...state,
        showLinhaForm: true,
        toggleContextMenu: false
      }
    case 'displayColunaForm':
      return {
        ...state,
        showColunaForm: true,
        toggleContextMenu: false
      }
    case 'removeLinhaForm':
      return {
        ...state,
        showLinhaForm: false
      }
    case 'removeColunaForm':
      return {
        ...state,
        showColunaForm: false
      }
    default:
      return {
        ...state
      }
  }
}

const initialState = {
  showLinhaForm: false,
  showColunaForm: false,
  toggleContextMenu: true
}

export function LinhasEntrada({ layoutId }) {
  const [contextMenuController, dispatchContextMenuController] = useReducer(
    controlContextMenuReducer,
    initialState
  )

  const { dispatchRestrictedLayout } = useContext(RestrictedLayoutContext)

  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(async () => {
    await logic.getRows(layoutId, setRows)
    await logic.getColumns(layoutId, setColumns)
  }, [layoutId])

  const [linhaInitialValues, setLinhaInitialValues] = useState({
    nome: '',
    row_lines: '',
    row_columns: '',
    update: false
  })

  const handleShowAddLinhaForm = (values) => {
    setLinhaInitialValues({
      nome: '',
      row_lines: '',
      row_columns: '',
      update: false
    })
    dispatchContextMenuController({ type: 'displayLinhaForm' })
  }

  const handleShowUpdateLine = (row, rowCells, rowId) => {
    setLinhaInitialValues({
      id: rowId,
      nome: row.nome,
      row_lines: row.row_lines.toString(),
      row_columns: (rowCells.length / row.row_lines).toString(),
      update: true
    })
    dispatchContextMenuController({ type: 'displayLinhaForm' })
  }

  const handleSubmitLinha = async (values) => {
    if (values.update) {
      await logic.handleUpdateLine(
        values.id,
        values.nome,
        values.row_lines,
        values.row_columns,
        layoutId,
        setRows,
        setColumns
      )
    } else {
      await logic.handleAddLine(
        values.nome,
        values.row_lines,
        values.row_columns,
        layoutId,
        setRows,
        setColumns
      )
    }
  }

  const [updateColunaInitialValues, setUpdateColunaInitialValues] = useState({})

  const handleShowColunaForm = (columnParams) => {
    // avoids sending null values to the inputs
    Object.keys(columnParams).map(function (key, index) {
      if (columnParams[key] === null) {
        columnParams[key] = ''
      }
      return columnParams[key]
    })
    setUpdateColunaInitialValues(columnParams)

    dispatchContextMenuController({ type: 'displayColunaForm' })
  }

  const handleDeleteLine = async (id) => {
    if (id === updateColunaInitialValues.file_row_id) {
      dispatchContextMenuController({ type: 'removeColunaForm' })
    }
    if (id === linhaInitialValues.id) {
      dispatchContextMenuController({ type: 'removeLinhaForm' })
    }

    await logic.handleDeleteLine(id, layoutId, setRows)
  }

  useEffect(() => {
    if (
      !contextMenuController.showColunaForm &&
      !contextMenuController.showLinhaForm
    ) {
      dispatchRestrictedLayout({
        type: 'setContextMenuContent',
        payload: <></>
      })
    } else {
      dispatchRestrictedLayout({
        type: 'setContextMenuContent',
        payload: (
          <>
            {contextMenuController.showColunaForm ? (
              <ColunaForm
                updateColunaInitialValues={updateColunaInitialValues}
                setColumns={setColumns}
              />
            ) : (
              ''
            )}
            {contextMenuController.showLinhaForm &&
            !contextMenuController.showColunaForm ? (
              <FormTabsContainer></FormTabsContainer>
            ) : (
              ''
            )}
            {contextMenuController.showLinhaForm ? (
              <LinhaForm
                linhaInitialValues={linhaInitialValues}
                handleSubmitLinha={handleSubmitLinha}
              />
            ) : (
              ''
            )}
          </>
        )
      })
    }
  }, [
    contextMenuController.showColunaForm,
    contextMenuController.showLinhaForm,
    updateColunaInitialValues,
    linhaInitialValues
  ])

  useEffect(() => {
    dispatchRestrictedLayout({
      type: 'setToggleContextMenu',
      payload: contextMenuController.toggleContextMenu
    })
  }, [
    contextMenuController.toggleContextMenu,
    updateColunaInitialValues,
    linhaInitialValues
  ])

  return (
    <>
      <Button
        bgColor="var(--background-medium-blue)"
        colorText="var(--white)"
        buttonFunction="ButtonTextIcon"
        buttonIcon="PlusIcon"
        value="ADICIONAR"
        onClick={handleShowAddLinhaForm}
      />
      {rows.length > 0
        ? rows.map((row, index) => {
            return (
              <LinhaContainer
                key={index}
                row={row}
                handleShowUpdateLine={handleShowUpdateLine}
                handleDeleteLine={handleDeleteLine}
                columns={columns}
                handleShowColunaForm={handleShowColunaForm}
                logic={logic}
              />
            )
          })
        : ''}
    </>
  )
}

LinhasEntrada.propTypes = {
  layoutId: PropTypes.number
}
