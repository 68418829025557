import React, { useEffect, useReducer, useContext } from 'react'
import { EventModalContext } from 'context/EventModalContext/EventModalContext'

import { XIcon } from 'assets/icons'
import * as ModalReducers from './EventModalReducer'
import {
  ModalContainer,
  ModalHeader,
  ModalContentTop,
  ModalContentMiddle,
  ModalContentBottom,
  ModalLayer
} from './styles'

export default function EventModal() {
  const [modalState, dispatchModal] = useReducer(
    ModalReducers.reducer,
    ModalReducers.states
  )

  const { dispatchEventModal, eventModalState } = useContext(EventModalContext)

  useEffect(() => {
    window.eventBus.on('openModal', async (data) => {
      dispatchModal({ type: 'open' })
      if (data?.title) {
        dispatchModal({ type: 'setTitle', payload: data.title })
      }
    })
    window.eventBus.on('closeModal', async (data) => {
      dispatchModal({ type: 'close' })
    })

    return () => {
      window.eventBus.remove('openModal')
      window.eventBus.remove('closeModal')
    }
  }, [])

  function closeModalHandler() {
    dispatchEventModal({ type: 'setModalContentTop', payload: <></> })
    dispatchEventModal({ type: 'setModalContentMiddle', payload: <></> })
    dispatchEventModal({ type: 'setModalContentBottom', payload: <></> })
    window.eventBus.dispatch('closeModal')
    // dispatchModal({ type: 'resetModal' })
  }

  return (
    <ModalLayer display={`${modalState.display ? 'grid' : 'none'}`}>
      <ModalContainer>
        <ModalHeader>
          <h3>{modalState.title}</h3>
          <XIcon
            style={{
              backgroundColor: 'transparent',
              width: '18px',
              height: '18px',
              cursor: 'pointer'
            }}
            onClick={closeModalHandler}
          />
        </ModalHeader>
        {eventModalState?.modalContentTop ? (
          <ModalContentTop>{eventModalState.modalContentTop}</ModalContentTop>
        ) : (
          <></>
        )}
        {eventModalState?.modalContentMiddle ? (
          <ModalContentMiddle>
            {eventModalState.modalContentMiddle}
          </ModalContentMiddle>
        ) : (
          <></>
        )}
        {eventModalState?.modalContentBottom ? (
          <ModalContentBottom>
            {eventModalState.modalContentBottom}
          </ModalContentBottom>
        ) : (
          <></>
        )}
      </ModalContainer>
    </ModalLayer>
  )
}
