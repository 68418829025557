import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'arquivosOriginais'
const url = '/arquivos-originais'

const endpointsDescriptions = {
  getAllArquivosOriginais: {
    action: 'getAll'
  },
  getSingleArquivoOriginal: {
    action: 'getOne'
  },
  createArquivoOriginal: {
    action: 'add'
  },
  addSingleArquivoOriginal: {
    action: 'add'
  },
  updateSingleArquivoOriginal: {
    action: 'update'
  },
  deleteSingleArquivoOriginal: {
    action: 'delete'
  },
  uploadArquivoOriginal: {
    action: 'upload'
  },
  downloadArquivoOriginal: {
    action: 'download'
  },
  processArquivoOriginal: {
    action: 'process'
  },
  downloadArquivoTransformado: {
    action: 'download'
  },
  uploadArquivoTransformado: {
    action: 'upload'
  }
}

const arquivosOriginais = new BaseDomainsProvider(
  domain,
  url,
  endpointsDescriptions
)

endpoints.getAllArquivosOriginais = () => {
  const endpointDescription =
    arquivosOriginais.endpointsConfigs.getAllArquivosOriginaisConfig
  return axios.get(`${baseUrl}/arquivos-originais`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getSingleArquivoOriginal = (id) => {
  const endpointDescription =
    arquivosOriginais.endpointsConfigs.getSingleArquivoOriginalConfig
  return axios.get(`${baseUrl}/arquivos-originais/${id}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.createArquivoOriginal = (body) => {
  const endpointDescription =
    arquivosOriginais.endpointsConfigs.createArquivoOriginalConfig
  return axios.post(`${baseUrl}/arquivos-originais`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.addSingleArquivoOriginal = (body) => {
  const endpointDescription =
    arquivosOriginais.endpointsConfigs.addSingleArquivoOriginalConfig
  return axios.post(`${baseUrl}/arquivos-originais`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.updateSingleArquivoOriginal = (id, body) => {
  const endpointDescription =
    arquivosOriginais.endpointsConfigs.updateSingleArquivoOriginalConfig
  return axios.patch(`${baseUrl}/arquivos-originais/${id}`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.deleteSingleArquivoOriginal = (id) => {
  const endpointDescription =
    arquivosOriginais.endpointsConfigs.deleteSingleArquivoOriginalConfig
  return axios.delete(`${baseUrl}/arquivos-originais/${id}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.uploadArquivoOriginal = (body) => {
  const endpointDescription =
    arquivosOriginais.endpointsConfigs.uploadArquivoOriginalConfig
  endpointDescription.options.displayOnErrors = true
  endpointDescription.options.feedbackOnAction = true
  endpointDescription.options.activateLoader = true
  return axios.post(`${baseUrl}/arquivos-originais/upload`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.downloadArquivoOriginal = (id) => {
  const endpointDescription =
    arquivosOriginais.endpointsConfigs.downloadArquivoOriginalConfig
  return axios.get(`${baseUrl}/file/arquivos-originais/download/${id}`, {
    responseType: 'arraybuffer',
    endpointInfo: endpointDescription
  })
}

endpoints.processArquivoOriginal = (id) => {
  const endpointDescription =
    arquivosOriginais.endpointsConfigs.processArquivoOriginalConfig
  return axios.get(`${baseUrl}/arquivos-originais/process/${id}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.downloadArquivoTransformado = (id) => {
  const endpointDescription =
    arquivosOriginais.endpointsConfigs.downloadArquivoTransformadoConfig
  return axios.get(`${baseUrl}/file/arquivos-transformados/download/${id}`, {
    responseType: 'arraybuffer',
    endpointInfo: endpointDescription
  })
}

endpoints.uploadArquivoTransformado = (id, body) => {
  const endpointDescription =
    arquivosOriginais.endpointsConfigs.uploadArquivoTransformadoConfig
  endpointDescription.options.displayOnErrors = true
  endpointDescription.options.feedbackOnAction = true
  endpointDescription.options.activateLoader = true
  return axios.post(`${baseUrl}/arquivos-transformados/upload/${id}`, body, {
    endpointInfo: endpointDescription
  })
}

export default endpoints
