export const LAYOUT_TYPES = {
  CLASSICAL: 'CLASSICAL',
  TYPE_1: 'TYPE_1'
}

/* 
-----------------------------------------------------
CLASSICAL: 
-----------------------------------------------------
O CLASSICAL Layout tem componentes de layout renderizados de forma independentes do conteúdo principal.
fazento então constante uso do 'RestrictedLayoutContext' durante o desenvolvimento de suas páginas principais
para injetar estados contendo os componentes a serem renderizados em seus componentes de layout.
Sendo estes componentes de layout por exemplo:
    - <Header> principal do Layout.
    - <MainTopBar> (sub-header de <Main>).
    - <RestrictedContextMenu> (componente redimensionável que aparece sob area inferior de <Main>).

-----------------------------------------------------
TYPE_1:
-----------------------------------------------------
O TYPE_1 Layout segue uma proposta mais limpa 
que deixa sob responsabilidade do desenvolvedor reforçar algumas das regras do Layout.

Foram então removidos de sua versão anterior (CLASSICAL) 
    - O "<Header> principal do Layout".
    - As subdivisões de <Main> [<MainTopBar>, <RestrictedContextMenu>].

E foi incluso:
    - Abaixo de <Main> um <Footer> estático.

A renderização do cabeçalho passa portanto a ser de responsabilidade do conteúdo da página em <Main>.
*/
