import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'empresas'
const url = '/empresas'

const endpointsDescriptions = {
  paginateEmpresas: {
    action: 'getPaginated'
  },
  getAllEmpresas: {
    action: 'getAll'
  },
  getOneEmpresa: {
    action: 'getOne'
  },
  getEmpresasFromEscritorio: {
    action: 'getBy',
    extraParams: ['do Escritório']
  },
  addEmpresas: {
    action: 'add'
  },
  editEmpresas: {
    action: 'update'
  },
  deleteEmpresas: {
    action: 'delete'
  },
  getPieChartReport: {
    action: 'getReports'
  },
  getCsvReport: {
    action: 'getReports'
  }
}

const empresas = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.paginateEmpresas = (page, perPage) => {
  const endpointDescription = empresas.endpointsConfigs.paginateEmpresasConfig
  return axios.get(`${baseUrl}/empresas/paginate/${page}/${perPage}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getAllEmpresas = () => {
  const endpointDescription = empresas.endpointsConfigs.getAllEmpresasConfig
  return axios.get(`${baseUrl}/empresas`, { endpointInfo: endpointDescription })
}

endpoints.getOneEmpresa = (id) => {
  const endpointDescription = empresas.endpointsConfigs.getOneEmpresaConfig
  return axios.get(`${baseUrl}/empresas/${id}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getEmpresasFromEscritorio = (escritorioId) => {
  const endpointDescription =
    empresas.endpointsConfigs.getEmpresasFromEscritorioConfig
  return axios.get(`${baseUrl}/empresas/escritorio/${escritorioId}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.addEmpresas = (body) => {
  const endpointDescription = empresas.endpointsConfigs.addEmpresasConfig
  // cliente definiu que empresas ao serem criadas sempre serão ativas
  body.ativo = 1

  return axios.post(
    `
  ${baseUrl}/empresas`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.editEmpresas = (id, body) => {
  const endpointDescription = empresas.endpointsConfigs.editEmpresasConfig
  body.cpf_cnpj = body.cpf_cnpj.toString()
  return axios.put(`${baseUrl}/empresas/${id}`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.deleteEmpresas = (id) => {
  const endpointDescription = empresas.endpointsConfigs.deleteEmpresasConfig
  return axios.delete(`${baseUrl}/empresas/${id}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getPieChartReportEmpresas = (body) => {
  const endpointDescription = empresas.endpointsConfigs.getPieChartReportConfig
  return axios.post(`${baseUrl}/empresas/reports/pieChart`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.getCsvReportEmpresas = (body) => {
  const endpointDescription = empresas.endpointsConfigs.getCsvReportConfig
  return axios.post(`${baseUrl}/empresas/reports/download/csv`, body, {
    endpointInfo: endpointDescription
  })
}

export default endpoints
