/* eslint-disable react/prop-types */
import React from 'react'
import { Router } from 'react-router-dom'
import { AuthProvider } from './services/Auth/AuthContext'
import { EventModalProvider } from 'context/EventModalContext/EventModalContext'
import Events from './services/Events'
import Routes from './routes'
import history from './services/history'
import GlobalStyles from './styles/globalStyles'
import { events } from '@plick-solucoes-contabeis/ui-components'

// Makes some helper functions global
import utils from 'helpers/utils'
import { RestrictedLayoutProvider } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'
import { TableProvider } from 'context/TableContext/TableContext'

window.utils = utils

require('dotenv').config()

function App() {
  return (
    <AppProviders>
      <Router history={history}>
        <Routes />
        <GlobalStyles />
      </Router>
      <Events />
    </AppProviders>
  )
}

const AppProviders = ({ children }) => {
  return (
    <AuthProvider>
      <events.EventProvider>
        <EventModalProvider>
          <RestrictedLayoutProvider>
            <TableProvider>{children}</TableProvider>
          </RestrictedLayoutProvider>
        </EventModalProvider>
      </events.EventProvider>
    </AuthProvider>
  )
}

export default App
