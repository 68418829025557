import styled from 'styled-components'

export const PagesTabsContainer = styled.ul`
  display: flex;
  list-style-type: none;
  align-self: flex-end;
  width: calc(100% + 41px);
  margin-left: -41px;
  padding-left: 41px;
  gap: 1px;
`

export const PagesTabs = styled.li`
  position: relative;
  background-color: ${(props) =>
    props.selected ? 'var(--blue)' : 'var(--background-unfocus)'};
  color: ${(props) =>
    props.selected ? 'var(--white)' : 'var(--text-unfocus)'};
  font-weight: bold;
  padding: 10px 35px;
  cursor: pointer;

  :hover {
    background-color: ${(props) =>
      props.selected ? 'var(--blue)' : '#DDDDDD'};
    color: ${(props) =>
      props.selected ? 'var(--white)' : 'var(--text-unfocus)'};
  }

  :before {
    content: ${(props) => (props.selected ? '""' : 'none')};
    border-style: solid;
    border-width: 8px 8px 8px 0;
    border-color: transparent var(--blue) transparent transparent;
    position: absolute;
    top: calc(100% - 5px);
    left: 50%;
    transform: rotate(-90deg) translateY(-50%);
  }
`

export const FormContainer = styled.div`
  background-color: hsl(0, 0%, 96%);
  float: right;
  border-radius: 15px;
  width: 80%;
  justify-self: center;
  align-items: end;
  grid-column-start: ${(props) => props.gridColumnStart};
  height: min-content;
  padding-bottom: 10px;

  header {
    background-color: var(--green);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    padding: 10px 30px;
    h3 {
      font-size: 1em;
      color: var(--white);
    }
  }

  form {
    padding: 15px 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fill, 50px);
    gap: 15px 30px;
    > div {
      gap: 1px;
      grid-column: span 2;
      margin: 0;
    }
    > div:nth-child(n + 2) {
      grid-column: auto;
    }
    label {
      font-size: 1em;
    }
    input,
    select {
      height: 30px;
    }
  }
`

export const Button = styled.button`
  height: 38px;
  margin: 0;
  grid-row: ${(props) => props.row};
  grid-column: ${(props) => props.column};
  width: auto;
  justify-self: end;
  padding: 10px 20px;
  color: var(--white);
  background: var(--green);
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;

  &:hover {
    filter: opacity(0.9);
  }
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  > div {
    width: 100%;
    gap: 3px;
    margin: 0;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    > input {
      width: 30px;
    }
  }
`
