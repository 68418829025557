import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

function Checkbox({
  option,
  name,
  enabled = true,
  checked = false,
  labelPos = 'after'
}) {
  return (
    <label htmlFor={name} style={{ display: 'flex', margin: '10px 0 5px 0px' }}>
      {labelPos === 'before' ? (
        <span
          style={{
            width: 'auto',
            color: '#333333'
          }}
        >
          {option}
        </span>
      ) : (
        <></>
      )}

      <Field
        type="checkbox"
        name={name}
        id={name}
        style={{ width: '5%', height: '1rem', marginLeft: '-5px' }}
        disabled={!enabled}
      />
      {labelPos === 'after' ? (
        <span
          style={{
            width: 'auto',
            color: '#333333'
          }}
        >
          {option}
        </span>
      ) : (
        <></>
      )}
    </label>
  )
}

Checkbox.propTypes = {
  option: PropTypes.string,
  name: PropTypes.string,
  enabled: PropTypes.bool,
  checked: PropTypes.bool,
  labelPos: PropTypes.string
}
export default Checkbox
