function getTagElements(thisForm, selectedTabFields) {
  const tagBelongingElements = selectedTabFields.map((fieldName) => {
    return thisForm.querySelector(`[name='${fieldName}']`)
  })

  const elementsToDisplay = tagBelongingElements.map((element) => {
    let elementToReturn = element

    while (true) {
      if (isDirectFormChild(elementToReturn)) {
        break
      }
      elementToReturn = elementToReturn.parentElement
    }
    return elementToReturn
  })

  return elementsToDisplay
}

function isDirectFormChild(element) {
  const parentElement = element.parentElement
  if (parentElement.className.split(' ').indexOf('form-crud') !== -1) {
    return true
  }
  return false
}

function hideElements(elements) {
  elements.forEach((element) => {
    if (typeof element !== 'undefined') {
      let currentStyle = element.getAttribute('style')
      currentStyle = typeof currentStyle !== 'string' ? '' : currentStyle
      currentStyle = currentStyle.replace('display: none;', '')
      element.setAttribute('style', currentStyle + 'display: none;')
    }
  })
}

function showElements(elements) {
  elements.forEach((element) => {
    if (typeof element !== 'undefined') {
      let currentStyle = element.getAttribute('style')
      currentStyle = typeof currentStyle !== 'string' ? '' : currentStyle
      currentStyle = currentStyle.replace('display: none;', '')
      element.setAttribute('style', currentStyle)
    }
  })
}

export { getTagElements, hideElements, showElements }
