const getPermissionLevel = (authStates, featureName) => {
  const permissionLevel = authStates.loggedUserData?.claims
    .filter((claim) => claim.feature.feature === featureName)
    .map((claim) => claim.valor)

  return permissionLevel.length > 0 ? permissionLevel[0] : false
}

const permissionHelpers = {
  getPermissionLevel
}

export default permissionHelpers
