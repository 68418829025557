import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'tipoMovimentoCategoria'
const url = '/tipo-movimento-categorias'

const endpointsDescriptions = {
  getAllMovimentoCategorias: {
    action: 'getAll'
  }
}

const tipoMovimentoCategoria = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllMovimentoCategorias = () => {
  const endpointDescription = tipoMovimentoCategoria.endpointsConfigs.getAllMovimentoCategoriasConfig
  return axios.get(
    `${baseUrl}/tipo-movimento-categorias/`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
