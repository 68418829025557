import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'sistemaGestao'
const url = '/sistema-gestao'

const endpointsDescriptions = {
  paginateSistemaGestao: {
    action: 'getPaginated'
  },
  getAllSistemaGestao: {
    action: 'getAll'
  },
  addSistemaGestao: {
    action: 'add'
  },
  editSistemaGestao: {
    action: 'update'
  },
  deleteSistemaGestao: {
    action: 'delete'
  }
}

const sistemaGestao = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.paginateSistemaGestao = (page, perPage) => {
  const endpointDescription = sistemaGestao.endpointsConfigs.paginateSistemaGestaoConfig
  return axios.get(
    `${baseUrl}/sistema-gestao/paginate/${page}/${perPage}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getAllSistemaGestao = () => {
  const endpointDescription = sistemaGestao.endpointsConfigs.getAllSistemaGestaoConfig
  return axios.get(
    `${baseUrl}/sistema-gestao`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.addSistemaGestao = (body) => {
  const endpointDescription = sistemaGestao.endpointsConfigs.addSistemaGestaoConfig
  return axios.post(
    `${baseUrl}/sistema-gestao`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.editSistemaGestao = (id, body) => {
  const endpointDescription = sistemaGestao.endpointsConfigs.editSistemaGestaoConfig
  return axios.put(
    `${baseUrl}/sistema-gestao/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteSistemaGestao = (id) => {
  const endpointDescription = sistemaGestao.endpointsConfigs.deleteSistemaGestaoConfig
  return axios.delete(
    `${baseUrl}/sistema-gestao/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
