import React, { useState, useEffect, useContext } from 'react'
import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'
import * as logic from './layoutEntradaLogic'
import { LayoutEntradaTable } from './subComponents/Table'
import { LayoutForm } from './subComponents/LayoutForm'
import history from 'services/history'

import {
  ButtonAdd,
  HeaderFilter,
  SelectWrapper,
  MainTopBarHeaderButtons
} from './style'
import useNonInitialEffect from 'hooks/useNonInitialEffect'

export function LayoutEntrada() {
  const { dispatchRestrictedLayout } = useContext(RestrictedLayoutContext)

  const [inputFileLayouts, setInputFileLayouts] = useState([])
  const [showFileLayoutForm, setShowFileLayoutForm] = useState(false)

  const [layoutInitialValues, setLayoutInitialValues] = useState({})

  const [sistemasGestao, setSistemasGestao] = useState([])
  const [sistemaGestaoFilter, setSistemaGestaoFilter] = useState(0)

  useEffect(async () => {
    await logic.getAllSistemaGestao(setSistemasGestao)
  }, [])

  useNonInitialEffect(async () => {
    dispatchRestrictedLayout({
      type: 'setHeaderData',
      payload: 'Layouts de entrada'
    })
    await logic.getAllInputLayouts(setInputFileLayouts)
    dispatchRestrictedLayout({
      type: 'setMainTopBarContent',
      payload: (
        <MainTopBarHeaderButtons>
          <ButtonAdd
            bgColor=""
            colorText="var(--white)"
            buttonFunction="ButtonTextIcon"
            buttonIcon="PlusIcon"
            value="ADICIONAR"
            onClick={handleShowAddFileLayoutForm}
          />
          <HeaderFilter>
            <label>Sistema de Gestão:</label>
            <SelectWrapper>
              <select
                onChange={(event) =>
                  setSistemaGestaoFilter(parseInt(event.target.value))
                }
              >
                <option value="0">Sem Filtro</option>
                {sistemasGestao.map((sistemaGestao, index) => {
                  return (
                    <option key={index} value={sistemaGestao.id}>
                      {sistemaGestao.nome}
                    </option>
                  )
                })}
              </select>
            </SelectWrapper>
          </HeaderFilter>
        </MainTopBarHeaderButtons>
      )
    })
  }, [sistemasGestao])

  const handleShowAddFileLayoutForm = () => {
    setLayoutInitialValues({
      nome: '',
      default: false,
      sistema_gestao_id: '',
      file_type: 'txt',
      start_row: 1,
      delimiter_char: '',
      sheets: '',
      update: false
    })

    setShowFileLayoutForm(true)
    dispatchRestrictedLayout({ type: 'setToggleContextMenu', payload: false })
  }

  const handleShowUpdateFileLayout = (id) => {
    const data = inputFileLayouts.filter(
      (inputLayout) => inputLayout.id === parseInt(id)
    )[0]

    // eslint-disable-next-line camelcase
    const { updated_at, created_at, ...rest } = data

    // avoids to initialize null parameters
    for (const key in rest) {
      if (rest[key] === null) {
        rest[key] = ''
      }
    }
    setLayoutInitialValues({
      ...rest,
      update: true
    })
    setShowFileLayoutForm(true)
    dispatchRestrictedLayout({ type: 'setToggleContextMenu', payload: false })
  }

  const handleDelete = async (id) => {
    if (!window.utils.isFalsy(layoutInitialValues.id)) {
      if (layoutInitialValues.id === parseInt(id)) {
        dispatchRestrictedLayout({
          type: 'setToggleContextMenu',
          payload: false
        })
        dispatchRestrictedLayout({
          type: 'setContextMenuContent',
          payload: <></>
        })
      }
    }
    await logic.deleteInputLayout(id, setInputFileLayouts)
  }

  const handleDuplicate = async (id) => {
    await logic.duplicateInputLayout(id, setInputFileLayouts)
  }

  const handleEnterDetailsCadastroLayoutEntrada = (id) => {
    if (inputFileLayouts.length > 0) {
      const thisLayout = inputFileLayouts.find(
        (layout) => layout.id === parseInt(id)
      )
      history.push('input-file-layout-details', {
        layoutId: thisLayout.id,
        nome: thisLayout.nome
      })
    }
  }

  useEffect(() => {
    if (showFileLayoutForm) {
      dispatchRestrictedLayout({
        type: 'setContextMenuContent',
        payload: (
          <>
            <LayoutForm
              layoutInitialValues={layoutInitialValues}
              setInputFileLayouts={setInputFileLayouts}
              sistemasGestao={sistemasGestao}
            />
          </>
        )
      })
    }
  }, [showFileLayoutForm, layoutInitialValues])

  return (
    <>
      {inputFileLayouts.length > 0 ? (
        <LayoutEntradaTable
          items={inputFileLayouts.filter((inputFileLayout) => {
            if (sistemaGestaoFilter === 0) {
              return inputFileLayout
            }

            if (
              parseInt(inputFileLayout.sistema_gestao_id) ===
              sistemaGestaoFilter
            ) {
              return inputFileLayout
            }

            return ''
          })}
          fields={[
            { fieldName: 'nome', as: 'NOME' },
            { fieldName: 'file_type', as: 'TIPO ARQUIVO' },
            {
              fieldName: 'sistema_gestao_id',
              as: 'SISTEMA GESTÃO',
              fromData: sistemasGestao,
              refName: 'nome'
            }
          ]}
          actionButtons={[
            {
              buttonFunction: 'ButtonTable',
              buttonIcon: 'IconeEditar',
              onClick: (event) =>
                handleShowUpdateFileLayout(event.target.dataset.id),
              tooltipText: 'Editar'
            },
            {
              buttonFunction: 'ButtonTable',
              buttonIcon: 'IconeTable',
              onClick: (event) =>
                handleEnterDetailsCadastroLayoutEntrada(
                  event.target.dataset.id
                ),
              tooltipText: 'Configurar'
            },
            {
              buttonFunction: 'ButtonTable',
              buttonIcon: 'IconeCopiar',
              onClick: (event) => handleDuplicate(event.target.dataset.id),
              tooltipText: 'Copiar'
            },
            {
              buttonFunction: 'ButtonTable',
              buttonIcon: 'IconeExcluir',
              onClick: (event) => handleDelete(event.target.dataset.id),
              tooltipText: 'Excluir'
            }
          ]}
        />
      ) : (
        ''
      )}
    </>
  )
}
