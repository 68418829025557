import React, { createContext, useReducer } from 'react'
import * as Reducer from './reducer'
import PropTypes from 'prop-types'

const EventModalContext = createContext({})
function EventModalProvider({ children }) {
  const [eventModalState, dispatchEventModal] = useReducer(
    Reducer.eventModalReducer,
    Reducer.eventModalStates
  )

  return (
    <EventModalContext.Provider
      value={{
        eventModalState,
        dispatchEventModal
      }}
    >
      {children}
    </EventModalContext.Provider>
  )
}

EventModalProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export { EventModalContext, EventModalProvider }
