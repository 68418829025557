const states = {
  eventType: false,
  errorPayload: false,
  feedbackPayload: false,
  confirmationPayload: false,
  activatorToggle: false
}

function reducer(eventState, action) {
  switch (action.type) {
    case 'errorEvent':
      return {
        eventType: 'errorEvent',
        errorPayload: action.payload,
        activatorToggle: !eventState.activatorToggle
      }
    case 'feedbackEvent':
      return {
        eventType: 'feedbackEvent',
        feedbackPayload: action.payload,
        activatorToggle: !eventState.activatorToggle
      }
    case 'confirmationEvent':
      return {
        eventType: 'confirmationEvent',
        confirmationPayload: action.payload,
        activatorToggle: !eventState.activatorToggle
      }
    case 'formEvent':
      return {
        eventType: 'formEvent',
        formPayload: action.payload,
        activatorToggle: !eventState.activatorToggle
      }
    default:
      return {
        eventType: false,
        payload: false
      }
  }
}

export { states, reducer }
