/* eslint-disable camelcase */
import api from 'services/api'

async function getAllInputLayouts(setInputFileLayouts) {
  try {
    const { status, data } = await api.getAllInputLayouts()
    if (status === 200) {
      setInputFileLayouts(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

function treatValues(values) {
  const newValues = { ...values }

  newValues.default = newValues.default ? 1 : 0

  if (newValues.delimiter_char === '') {
    delete newValues.delimiter_char
  }

  return newValues
}

async function createInputLayout(values, setInputFileLayouts) {
  try {
    const newValues = treatValues(values)
    const { status, data } = await api.createInputLayout(newValues)
    if (status === 201) {
      setInputFileLayouts((oldInputLayouts) => [...oldInputLayouts, data])
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function editInputLayout(id, values, setInputFileLayouts) {
  try {
    const newValues = treatValues(values)

    const { status, data } = await api.editInputLayout(id, newValues)
    if (status === 200) {
      setInputFileLayouts((oldInputLayouts) =>
        oldInputLayouts.map((oldInputLayout) =>
          oldInputLayout.id === id ? data : oldInputLayout
        )
      )
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function deleteInputLayout(id, setInputFileLayouts) {
  try {
    const { status, data } = await api.deleteInputLayout(id)
    if (status === 204) {
      setInputFileLayouts((oldInputLayouts) =>
        oldInputLayouts.filter(
          (oldInputLayout) => parseInt(oldInputLayout.id) !== parseInt(id)
        )
      )
    } else {
      console.error(data)
      throw new Error('Não foi possível realizar a operação')
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function duplicateInputLayout(id, setInputFileLayouts) {
  try {
    const { status, data } = await api.duplicateInputLayout(id)
    if (status === 200) {
      setInputFileLayouts((oldInputLayouts) => [...oldInputLayouts, data])
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

async function getAllSistemaGestao(setSistemasGestao) {
  try {
    const { status, data } = await api.getAllSistemaGestao()
    if (status === 200) {
      setSistemasGestao(data)
    }
    return window.utils.checkIfValidData(data)
  } catch (error) {
    console.error(error)
  }
}

export {
  getAllInputLayouts,
  // getInputLayoutBySistemaGestao,
  createInputLayout,
  editInputLayout,
  deleteInputLayout,
  duplicateInputLayout,
  getAllSistemaGestao
}
