import styled from 'styled-components'
import { Form } from 'formik'

export const StyledForm = styled(Form)`
  & div {
    margin: 0 0 25px 0;
  }

  & input[type='text'] {
    border-radius: 3px;
    border: 0;
    border-bottom: 1px solid rgb(118, 118, 118);
    height: 1.7rem;
  }
  & input[type='password'] {
    border-radius: 3px;
    border: 0;
    border-bottom: 1px solid rgb(118, 118, 118);
    height: 1.7rem;
  }

  & input[type='date'] {
    border: 0;
    border-bottom: 1px solid rgb(118, 118, 118);
    height: 1.7rem;
    width: 50%;
  }
`

export const Label = styled.label`
  color: var(--text-color-dark);
`

export const InputContainer = styled.div`
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
`

export const CheckboxContainer = styled(InputContainer)`
  flex-direction: row;
  justify-content: space-between;

  & > * {
    width: 50%;
  }
`

export const CheckboxInput = styled.input`
  border-radius: 3px;
`

export const TextFieldInput = styled.input`
  border-radius: 3px;
  border: 0;
  border-bottom: 1px solid rgb(118, 118, 118);
  height: 1.7rem;
`

export const ButtonEnviar = styled.button`
  max-width: 150px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: var(--blue);
  font-weight: bold;
  background-color: var(--green);
  margin-top: 10px;
  padding: 3px 5px 5px 5px;
`

export const InputSelectContainer = styled.div`
  width: 100%;
  border: 1px solid #777;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  cursor: pointer;
  background-color: var(--white);
  background-image: linear-gradient(to top, #f9f9f9, var(--white) 33%);

  & > *::before,
  *::after {
    box-sizing: border-box;
  }
  & > select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
  }
`

export const WrapperSelectorInline = styled.div`
  display: ${(props) =>
    props.visible || typeof props.visible === 'undefined' ? 'flex' : 'none'};
  margin: 10px 0;
  align-items: end;
  & > label {
    width: 50%;
    color: #333333;
  }

  & > select {
    margin-left: 10px;
    width: 50%;
    border-radius: 3px;
    border: 0;
    border-bottom: 1px solid rgb(118, 118, 118);
    height: 1.7rem;
    background-color: var(--white);
  }
`

export const WrapperMultiSelectorGroupers = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  min-height: 120px;
  & > label {
    width: 50%;
    color: #333333;
  }

  & > div {
    display: flex;
    margin: 0;
    padding: 0;
    gap: 25px;
    flex-direction: column;
    justify-content: center;
    & > div {
      margin: 0;
      padding: 0;
    }

    & > div:nth-child(1) {
      transform: rotate(180deg);
    }

    & > div:active rect {
      fill: hsl(154, 99%, 30%);
    }
  }

  & > select {
    min-width: 150px;

    &::-webkit-scrollbar {
      width: 0.6em;
    }
    &::-webkit-scrollbar-track {
      margin-block: 0;
    }
  }
`

export const ErrorMessage = styled.p`
  font-size: 0.88rem;
  bottom: 0;
  color: #ff0000;
`
