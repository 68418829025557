/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { WrapperMultiSelectorGroupers } from 'components/FormCRUD/style'
import { ArrowHead } from 'assets/icons'
import api from 'services/api'
import useNonInitialEffect from 'hooks/useNonInitialEffect'

function MultiSelectGrouper({
  title,
  name,
  endpoint,
  endpointParams,
  optionsTextParam,
  optionsValueParam,
  initialOutValues
}) {
  const [outValues, setOutValues] = useState([])
  const [isOutValuesInitialized, setIsOutValuesInitialized] = useState(false)

  const [inValues, setInValues] = useState([])

  const outSelector = useRef(null)
  const inSelector = useRef(null)

  const [multiSelectInField, multiSelectInMeta, multiSelectInHelpers] =
    useField(name)

  useEffect(() => {
    setInValues(multiSelectInField.value)

    if (initialOutValues?.length > 0) {
      setOutValues(initialOutValues)
      setIsOutValuesInitialized(true)
    }
  }, [])
  useNonInitialEffect(async () => {
    if (!isOutValuesInitialized && typeof endpoint !== 'undefined') {
      const { status, data } = await api[endpoint](endpointParams).catch(
        (error) => console.error(error)
      )
      if (status === 200) {
        if (inValues?.length > 0) {
          setOutValues(
            data.filter(
              (item) =>
                inValues
                  .map((includedItem) => includedItem[optionsValueParam])
                  .indexOf(item[optionsValueParam]) === -1
            )
          )
        } else {
          setOutValues(data)
        }
        setIsOutValuesInitialized(true)
      }
    } else {
      setOutValues(
        outValues.filter(
          (item) =>
            inValues
              .map((includedItem) => includedItem[optionsValueParam])
              .indexOf(item[optionsValueParam]) === -1
        )
      )
    }
  }, [inValues])

  const moveItems = (action) => {
    switch (action) {
      case 'moveIn': {
        const outSelected = Object.values(outSelector.current.children).filter(
          (option) => option.selected
        )
        const outSelectedValues = outSelected.map((selected) =>
          outValues.find(
            (item) =>
              item[optionsValueParam].toString() === selected.value.toString()
          )
        )

        if (outSelectedValues?.length > 0) {
          // Remove from OUT Box.
          setOutValues(
            outValues.filter((item) => outSelectedValues.indexOf(item) === -1)
          )

          // Include in IN Box.
          setInValues([...inValues, ...outSelectedValues])
          multiSelectInHelpers.setValue([...inValues, ...outSelectedValues])
        }

        break
      }
      case 'moveOut': {
        const inSelected = Object.values(inSelector.current.children).filter(
          (option) => option.selected
        )
        const inSelectedValues = inSelected.map((selected) =>
          inValues.find(
            (item) =>
              item[optionsValueParam].toString() === selected.value.toString()
          )
        )

        if (inSelectedValues?.length > 0) {
          // Remove from IN Box.
          setInValues(
            inValues.filter((item) => inSelectedValues.indexOf(item) === -1)
          )
          multiSelectInHelpers.setValue(
            inValues.filter((item) => inSelectedValues.indexOf(item) === -1)
          )

          // Include in OUT Box.
          setOutValues([...outValues, ...inSelectedValues])
        }
        break
      }
    }
  }

  return (
    <WrapperMultiSelectorGroupers>
      <label>{title}</label>
      <select multiple ref={outSelector}>
        {outValues.map((item, index) => {
          return (
            <option key={index} value={item[optionsValueParam]}>
              {item[optionsTextParam]}
            </option>
          )
        })}
      </select>
      <div>
        <div onClick={() => moveItems('moveIn')}>
          <ArrowHead />
        </div>
        <div onClick={() => moveItems('moveOut')}>
          <ArrowHead />
        </div>
      </div>
      <select multiple ref={inSelector}>
        {inValues?.length > 0 ? (
          inValues.map((item, index) => {
            return (
              <option key={index} value={item[optionsValueParam]}>
                {item[optionsTextParam]}
              </option>
            )
          })
        ) : (
          <></>
        )}
      </select>
    </WrapperMultiSelectorGroupers>
  )
}

MultiSelectGrouper.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  endpoint: PropTypes.string,
  endpointParams: PropTypes.any,
  optionsTextParam: PropTypes.string,
  optionsValueParam: PropTypes.string,
  initialOutValues: PropTypes.array
}
export default MultiSelectGrouper
