import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'inputFileIgnore'
const url = '/input-file-ignore'

const endpointsDescriptions = {
  getInputIgnores: {
    action: 'getAll'
  },
  getInputIgnoreById: {
    action: 'getOne'
  },
  createInputIgnore: {
    action: 'add'
  },
  editInputIgnore: {
    action: 'update'
  },
  deleteInputIgnore: {
    action: 'delete'
  }
}

const inputFileIgnore = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getInputIgnores = (layout) => {
  const endpointDescription = inputFileIgnore.endpointsConfigs.getInputIgnoresConfig
  return axios.get(
    `${baseUrl}/input-file-ignore/layout/${layout}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getInputIgnoreById = (id) => {
  const endpointDescription = inputFileIgnore.endpointsConfigs.getInputIgnoreByIdConfig
  return axios.get(
    `${baseUrl}/input-file-ignore/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createInputIgnore = (body) => {
  const endpointDescription = inputFileIgnore.endpointsConfigs.createInputIgnoreConfig
  return axios.post(
    `${baseUrl}/input-file-ignore`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.editInputIgnore = (id, body) => {
  const endpointDescription = inputFileIgnore.endpointsConfigs.editInputIgnoreConfig
  return axios.put(
    `${baseUrl}/input-file-ignore/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteInputIgnore = (id) => {
  const endpointDescription = inputFileIgnore.endpointsConfigs.deleteInputIgnoreConfig
  return axios.delete(
    `${baseUrl}/input-file-ignore/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
