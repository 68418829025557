import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'sistemasContabeis'
const url = '/sistemas-contabeis'

const endpointsDescriptions = {
  paginateSistemaContabil: {
    action: 'getPaginated'
  },
  getAllSistemaContabil: {
    action: 'getAll'
  },
  getSistemaContabilByStatus: {
    action: 'getAll'
  },
  getOneSistemaContabil: {
    action: 'getOne'
  },
  addSistemaContabil: {
    action: 'add'
  },
  editSistemaContabil: {
    action: 'update'
  },
  deleteSistemaContabil: {
    action: 'delete'
  }
}

const sistemasContabeis = new BaseDomainsProvider(
  domain,
  url,
  endpointsDescriptions
)

endpoints.paginateSistemaContabil = (page, perPage) => {
  const endpointDescription =
    sistemasContabeis.endpointsConfigs.paginateSistemaContabilConfig
  return axios.get(
    `${baseUrl}/sistemas-contabeis/paginate/${page}/${perPage}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getAllSistemaContabil = () => {
  const endpointDescription =
    sistemasContabeis.endpointsConfigs.getAllSistemaContabilConfig
  return axios.get(`${baseUrl}/sistemas-contabeis`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getSistemaContabilByStatus = (status) => {
  const endpointDescription =
    sistemasContabeis.endpointsConfigs.getSistemaContabilByStatusConfig
  return axios.get(`${baseUrl}/sistemas-contabeis/status/${status}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getOneSistemaContabil = (id) => {
  const endpointDescription =
    sistemasContabeis.endpointsConfigs.getOneSistemaContabilConfig
  return axios.get(`${baseUrl}/sistemas-contabeis/${id}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.addSistemaContabil = (body) => {
  const endpointDescription =
    sistemasContabeis.endpointsConfigs.addSistemaContabilConfig
  if (!body.status) {
    body.status = 'em_analise'
  }
  return axios.post(`${baseUrl}/sistemas-contabeis`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.editSistemaContabil = (id, body) => {
  const endpointDescription =
    sistemasContabeis.endpointsConfigs.editSistemaContabilConfig
  if (!body.status) {
    body.status = 'em_analise'
  }

  return axios.put(`${baseUrl}/sistemas-contabeis/${id}`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.deleteSistemaContabil = (id) => {
  const endpointDescription =
    sistemasContabeis.endpointsConfigs.deleteSistemaContabilConfig
  return axios.delete(`${baseUrl}/sistemas-contabeis/${id}`, {
    endpointInfo: endpointDescription
  })
}

export default endpoints
