import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'inputFileRowFormats'
const url = '/input-file-row-formats'

const endpointsDescriptions = {
  getRows: {
    action: 'getAll'
  },
  getRowById: {
    action: 'getOne'
  },
  createRow: {
    action: 'add'
  },
  editRow: {
    action: 'update'
  },
  deleteRow: {
    action: 'delete'
  }
}

const inputFileRowFormats = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getRows = (layout) => {
  const endpointDescription = inputFileRowFormats.endpointsConfigs.getRowsConfig
  return axios.get(
    `${baseUrl}/input-file-row-formats/layout/${layout}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getRowById = (id) => {
  const endpointDescription = inputFileRowFormats.endpointsConfigs.getRowByIdConfig
  return axios.get(
    `${baseUrl}/input-file-row-formats/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createRow = (body) => {
  const endpointDescription = inputFileRowFormats.endpointsConfigs.createRowConfig
  return axios.post(
    `${baseUrl}/input-file-row-formats`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.editRow = (id, body) => {
  const endpointDescription = inputFileRowFormats.endpointsConfigs.editRowConfig
  return axios.put(
    `${baseUrl}/input-file-row-formats/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteRow = (id) => {
  const endpointDescription = inputFileRowFormats.endpointsConfigs.deleteRowConfig
  return axios.delete(
    `${baseUrl}/input-file-row-formats/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
