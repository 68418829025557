import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'components/Button'
import getHumanNameForEndpoint from 'helpers/getHumanNameForEndpoint'

function AddButton({ setModalVisible, modalConfig, apiFetcher }) {
  return (
    <Button
      bgColor="var(--background-medium-blue)"
      colorText="var(--white)"
      buttonFunction="ButtonTextIcon"
      buttonIcon="PlusIcon"
      value="ADICIONAR"
      onClick={() => {
        modalConfig.current = {
          title: `Adicionar ${getHumanNameForEndpoint(apiFetcher, 'singular')}`,
          initialValues: {}
        }
        setModalVisible(true)
      }}
    />
  )
}

AddButton.propTypes = {
  setModalVisible: PropTypes.func,
  setModalContent: PropTypes.func,
  setShowModal: PropTypes.func,
  setCrudTimeStamp: PropTypes.func,
  headers: PropTypes.array,
  apiFetcher: PropTypes.string,
  listUrl: PropTypes.string,
  perPage: PropTypes.number,
  setActive: PropTypes.func,
  setRows: PropTypes.func,
  numberOfPages: PropTypes.number,
  modalConfig: PropTypes.object
}

export default AddButton
