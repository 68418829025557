// Initialize Object that will contain all functions from the domains modules in this folder
const domains = {}

// Set the files that won't be built in this webpack context.
const excludedFiles = ['./index.js', './00-templateDomain.js']

// Import all functions from the modules defaults into an object
function importAll(domainContext) {
  domainContext.keys().forEach((key) => {
    if (!excludedFiles.includes(key)) {
      const cache = domainContext(key).default
      Object.keys(cache).forEach((module) => {
        domains[module] = domainContext(key).default[module]
      })
    }
  })
}

// Create a new webpack context for this folder
const domainContext = require.context('./', false, /.*\.js/)

importAll(domainContext)

export default domains
