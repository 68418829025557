import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as logic from './validationRulesLogic'
import { Form } from 'components/Form'
import { InputComponent } from 'components/InputComponent'
import { ValidationRulesCustomInputs } from './ValidationRulesCustomInputs'
import { DeleteIcon } from 'assets/icons.js'

import { Button, CheckBoxWrapper } from '../../subPages/style'

import {
  ConsolidatedRulesButton,
  ValidationRulesAdditionals,
  ConsolidatedRulesModal
} from './style'

export function ValidationRulesForm({
  updateElementInitialValues,
  elementType
}) {
  const [validationRules, setValidationRules] = useState([])
  const [
    consolidatedElementValidationRules,
    setConsolidatedElementValidationRules
  ] = useState([])
  const [highestRuleNumOrder, setHighestRuleNumOrder] = useState(0)
  const [modalConsolidatedRulesToggle, setModalConsolidatedRulesToggle] =
    useState(false)

  useEffect(async () => {
    await logic.getAllRegrasValidacao(setValidationRules)
  }, [])

  useEffect(async () => {
    await logic.getElementValidationRules(
      updateElementInitialValues.id,
      setConsolidatedElementValidationRules,
      elementType
    )
  }, [updateElementInitialValues])

  // When consolidatedElementValidationRules is initialized or changes
  // get the highest value of num_order and increment 1
  // in order to suggest the next num_order to the user
  useEffect(() => {
    if (consolidatedElementValidationRules.length > 0) {
      const ruleWithHighestNumOrder = consolidatedElementValidationRules.reduce(
        (previousConsolidatedRule, currentConsolidatedRule) => {
          if (
            previousConsolidatedRule.num_order >
            currentConsolidatedRule.num_order
          ) {
            return previousConsolidatedRule
          }
          return currentConsolidatedRule
        }
      )
      if (ruleWithHighestNumOrder.num_order >= highestRuleNumOrder) {
        setHighestRuleNumOrder(ruleWithHighestNumOrder.num_order + 1)
      }
    }
  }, [consolidatedElementValidationRules])

  const handleSubmitValidationRules = async (values) => {
    values.validation_rule_id = validationRules.find(
      (validationRule) => values.validation_rule_name === validationRule.nome
    ).id
    await logic.handleAddElementValidationRule(
      values,
      setConsolidatedElementValidationRules,
      elementType
    )
  }

  const handleDeleteConsolidatedRule = async (id) => {
    await logic.handleDeleteElementValidationRule(
      id,
      setConsolidatedElementValidationRules,
      elementType
    )
  }

  const initialValues = {
    validation_rule_name: 'Transformar em maiúsculas',
    parameters: {
      param1: '',
      param2: '',
      param3: ''
    },
    validation_rule_id: '',
    validation_rule_param_type: '',
    num_order: highestRuleNumOrder,
    continue_on_error: false,
    continue_on_success: false,
    on_fail: 'ignore'
  }

  switch (elementType) {
    case 'coluna':
      initialValues.column_id = updateElementInitialValues.id
      break
    case 'celula':
      initialValues.cell_id = updateElementInitialValues.id
      break
  }

  return (
    <>
      {/* Button to open modal with details of previously registered rules */}
      <ConsolidatedRulesButton
        onClick={() => {
          if (consolidatedElementValidationRules.length > 0)
            setModalConsolidatedRulesToggle(true)
        }}
      >
        {consolidatedElementValidationRules.length} Regras Consolidadas
      </ConsolidatedRulesButton>
      <Form
        initialValues={initialValues}
        handleSubmit={handleSubmitValidationRules}
        reset={false}
      >
        {/* Select Validation Rule */}
        {validationRules.length > 0 ? (
          <InputComponent
            label="Selecione a regra de validação:"
            type="select"
            name="validation_rule_name"
          >
            {validationRules.map((validationRule, index) => {
              return (
                <option
                  key={index}
                  value={validationRule.nome}
                  title={validationRule.description}
                >
                  {validationRule.nome}
                </option>
              )
            })}
          </InputComponent>
        ) : (
          ''
        )}

        {/* Inputs Validation Rule */}
        <ValidationRulesCustomInputs />

        <ValidationRulesAdditionals>
          {/* Input Number Num Order */}
          <InputComponent
            label="N. de Ordenação:"
            type="number"
            name="num_order"
            min={0}
          />

          {/* Select onFail */}
          <InputComponent
            label="Ação quando houver falha:"
            name="on_fail"
            type="select"
          >
            <option value="ignore">Ignorar</option>
            <option value="reject">Rejeitar</option>
            <option value="setEmpty">Tornar Vazio</option>
            <option value="useFixedValue">Usar Valor Fixo</option>
          </InputComponent>

          {/* checkboxes for "Continue on error" and "Continue on success"  */}
          <CheckBoxWrapper style={{ height: '30px', alignSelf: 'end' }}>
            <InputComponent
              label="Continue após erro"
              type="checkbox"
              name="continue_on_error"
            />
          </CheckBoxWrapper>
          <CheckBoxWrapper>
            <InputComponent
              label="Continue após sucesso"
              type="checkbox"
              name="continue_on_success"
            />
          </CheckBoxWrapper>
        </ValidationRulesAdditionals>

        <Button type="submit">Enviar</Button>
      </Form>

      <ConsolidatedRulesModalItem
        modalConsolidatedRulesToggle={modalConsolidatedRulesToggle}
        setModalConsolidatedRulesToggle={setModalConsolidatedRulesToggle}
        validationRules={validationRules}
        consolidatedElementValidationRules={consolidatedElementValidationRules}
        handleDeleteConsolidatedRule={handleDeleteConsolidatedRule}
      />
    </>
  )
}

const ConsolidatedRulesModalItem = ({
  modalConsolidatedRulesToggle,
  setModalConsolidatedRulesToggle,
  validationRules,
  consolidatedElementValidationRules,
  handleDeleteConsolidatedRule
}) => {
  return (
    <ConsolidatedRulesModal toggle={modalConsolidatedRulesToggle}>
      <header>
        Detalhamento de Regras Consolidadas
        <div
          title="Fechar Janela"
          onClick={() => setModalConsolidatedRulesToggle(false)}
        >
          X
        </div>
      </header>
      <div>
        {validationRules.length > 0
          ? consolidatedElementValidationRules.map(
              (consolidatedRule, index) => {
                const validationRule = validationRules.find(
                  (validationRule) =>
                    validationRule.id === consolidatedRule.validation_rule_id
                )
                return (
                  <details key={index}>
                    <summary>
                      <h5>{validationRule.nome}</h5>
                    </summary>
                    <div
                      title="Deletar o item"
                      onClick={() =>
                        handleDeleteConsolidatedRule(consolidatedRule.id)
                      }
                    >
                      <DeleteIcon color="var(--white)" />
                    </div>
                    <ul>
                      <li>
                        <strong>Descrição: </strong>
                        <span>{validationRule.description}</span>
                      </li>
                      <li>
                        <strong>Ordem: </strong>
                        <span>{consolidatedRule.num_order}</span>
                      </li>
                      <li>
                        <strong>Tipo: </strong>
                        <span>{validationRule.type}</span>
                      </li>
                      <li>
                        <strong>Parâmetros: </strong>
                        <span>
                          {consolidatedRule.validation_rule_param !== ''
                            ? consolidatedRule.validation_rule_param
                            : 'Sem parâmetros'}
                        </span>
                      </li>
                      <li>
                        <strong>Referência: </strong>
                        <span>
                          {consolidatedRule.validation_rule_param_type !== ''
                            ? consolidatedRule.validation_rule_param_type
                            : 'Sem referência'}
                        </span>
                      </li>
                      <li>
                        <strong>Continuar após erro: </strong>
                        <span>
                          {consolidatedRule.continue_on_error ? 'Sim' : 'Não'}
                        </span>
                      </li>
                      <li>
                        <strong>Continuar após sucesso: </strong>
                        <span>
                          {consolidatedRule.continue_on_success ? 'Sim' : 'Não'}
                        </span>
                      </li>
                    </ul>
                  </details>
                )
              }
            )
          : ''}
      </div>
    </ConsolidatedRulesModal>
  )
}

ValidationRulesForm.propTypes = {
  updateElementInitialValues: PropTypes.object,
  elementType: PropTypes.string
}

ConsolidatedRulesModalItem.propTypes = {
  modalConsolidatedRulesToggle: PropTypes.bool,
  setModalConsolidatedRulesToggle: PropTypes.func,
  validationRules: PropTypes.array,
  consolidatedElementValidationRules: PropTypes.array,
  handleDeleteConsolidatedRule: PropTypes.func
}
