import React from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const SpinnerWrapper = styled.div`
  border: 3px solid ${({ borderColor }) => borderColor || 'rgba(0,0,0,0.1)'};
  border-left-color: ${({ indicatorColor }) => indicatorColor || '#00bf6f'};
  border-radius: 100vw;
  width: ${({ size }) => (size ? `${size}px` : '30px')};
  height: ${({ size }) => (size ? `${size}px` : '30px')};
  animation: ${spin} 1s linear infinite;
`

// eslint-disable-next-line react/prop-types
const Spinner = ({ style, size, indicatorColor, borderColor }) => {
  return (
    <SpinnerWrapper
      style={style}
      size={size}
      indicatorColor={indicatorColor}
      borderColor={borderColor}
    />
  )
}

export default Spinner
