/* eslint-disable no-unused-vars */
import React, { forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Item, StyledError, StyledMenu } from '../styles'
import withFormikMenu from '../logic/hoc/withFormikMenu'
import TableLoader from 'components/Table/TableLoader'

const labelProps = {
  className: 'autocomplete-item-label',
  style: {
    flexShrink: 1,
    flexGrow: 0.9,
    flexBasis: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}
function Error({ error, errorConfig, stateConfig }) {
  const { state } = stateConfig

  return (
    <StyledError data-hide-error={state.loading}>
      <h1>{error}</h1>

      {errorConfig.addItem && (
        <button
          type="button"
          onClick={errorConfig.handleAdd}
          onMouseDown={(e) => e.preventDefault()}
        >
          {errorConfig.buttonTitle ?? 'Inserir na lista'}
        </button>
      )}
    </StyledError>
  )
}
function Menu(
  { errorConfig, extractKey, stateConfig, renderItem, handleSelect },
  ref
) {
  const { state } = stateConfig

  const items = useMemo(
    () =>
      state.data.map((item, index) => (
        <Item
          key={extractKey(item) ?? index}
          data-hide-item={state.loading}
          onClick={() => handleSelect(index)}
          onMouseDown={(e) => e.preventDefault()}
        >
          {renderItem(item, labelProps)}
        </Item>
      )),
    [state.data.length, state.loading]
  )

  const loaderView = () => {
    return state.loading ? <TableLoader /> : <></>
  }
  const itemsView = () => {
    return state.errors.length ? (
      <Error
        error={state.errors[0]}
        stateConfig={stateConfig}
        errorConfig={errorConfig}
      />
    ) : (
      items
    )
  }

  return (
    <StyledMenu
      ref={ref}
      data-hide="true"
      openMenu={state.data.length}
      showError={state.errors.length}
      showLoader={state.loading}
    >
      {loaderView()}
      {itemsView()}
    </StyledMenu>
  )
}

const MenuRef = forwardRef(Menu)
export default MenuRef
export const MenuWithFormik = withFormikMenu(MenuRef)

Menu.propTypes = {
  useFormik: PropTypes.bool,
  stateConfig: PropTypes.object,
  renderItem: PropTypes.func,
  formikConfig: PropTypes.object,
  inputRef: PropTypes.object,
  handleSelect: PropTypes.func,
  errorConfig: PropTypes.object,
  extractKey: PropTypes.func
}

Error.propTypes = {
  error: PropTypes.string,
  stateConfig: PropTypes.object,
  errorConfig: PropTypes.object
}
