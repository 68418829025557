import React, { forwardRef, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

function Input(
  { style, name, handleInitialValue, deps, freezeOnMount, ...rest },
  ref
) {
  const [field] = useField(name)
  const isMounted = useRef(false)
  const freeze = useRef(freezeOnMount)

  useEffect(() => {
    if (field.value && !isMounted.current) {
      handleInitialValue(field.value)
    } else {
      if (!freeze.current) {
        handleInitialValue(null)
      }
    }

    freeze.current = false
    isMounted.current = true
  }, [...deps])
  return (
    <>
      <input ref={ref} style={style} type="text" {...rest} />
    </>
  )
}

export default forwardRef(Input)

Input.propTypes = {
  ref: PropTypes.any,
  handleInitialValue: PropTypes.func,
  deps: PropTypes.array,
  freezeOnMount: PropTypes.bool,
  style: PropTypes.object,
  name: PropTypes.string,
  startSearch: PropTypes.func,
  delay: PropTypes.number
}

// const renderXButton = () => {
//   return (
//     <svg
//       width="12"
//       height="12"
//       viewBox="0 0 460.775 460.775"
//       xmlns="http://www.w3.org/2000/svg"
//       style={{
//         position: 'absolute',
//         right: 2,
//         top: '50%',
//         transform: 'translateY(-50%)',
//         cursor: 'pointer'
//       }}
//     >
//       <path
//         fill="#a1a1a1"
//         d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
// c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
// c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
// c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
// l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
// c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"
//       />
//     </svg>
//   )
// }
