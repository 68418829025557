/*
  This file is a tutorial on how to setup new Domains endpoints and is not imported by the ./index.js
*/

import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

/*
  {endpoints} will be filled with {key:function}
  containing the axios calls to each endpoint set on this domain
*/
const endpoints = {}
const baseUrl = process.env.REACT_APP_API_URL

/*
  {domain} = camelCase domain name
  {url} = Domain base url
*/

const domain = 'domainName'
const url = '/domain-name'

/*
  {endpointsDescriptions} will contain the setup of the declared endpoints
  Every endpoint will have a relative action
  The list of actions is at src/services/api/documentation/Lists/endpointsActionsList.js

  The endpoint actions will be used when instantiating new Endpoint Objects to set their default behavior
  And will help generating their descriptions, linking them to appropriate SUBJECT-VERB agreement.

  Some action cases allow extra params, like 'getBy' or 'custom'
  These extra params will be added into the description or even replace them.

  We can also set custom options that will override the default:
  customOptionsEndpoint: {
    action: 'add',
    options: { feedbackOnAction: false, activateLoader: false }
  },

*/

const endpointsDescriptions = {
  getAllDomainName: {
    action: 'getAll'
  },
  getDomainName: {
    action: 'getOne'
  },
  createDomainName: {
    action: 'add'
  },
  updateDomainName: {
    action: 'update'
  },
  deleteDomainName: {
    action: 'delete'
  },
  uploadDomainName: {
    action: 'upload'
  },
  downloadDomainName: {
    action: 'download'
  },
  processDomainName: {
    action: 'process'
  }
}

/*
  Here we instantiate a new Domain.

  The Domain have a constructor that will build all the endpoint config inside it.
  src/services/api/documentation/Domain.js
*/

const DomainName = new BaseDomainsProvider(domain, url, endpointsDescriptions)

/*
  Here we start declaring the endpoints methods
  At each new endpoint we inject their configs into the axios config for the HTTP request.
*/

endpoints.getAllDomainName = () => {
  const endpointDescription = DomainName.endpointsConfigs.getAllDomainNameConfig
  return axios.get(
    `${baseUrl}/domain-name`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getDomainName = (id) => {
  const endpointDescription = DomainName.endpointsConfigs.getDomainNameConfig
  return axios.get(
    `${baseUrl}/domain-name/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.createDomainName = (body) => {
  const endpointDescription = DomainName.endpointsConfigs.createDomainNameConfig
  return axios.post(
    `${baseUrl}/domain-name`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.updateDomainName = (id, body) => {
  const endpointDescription = DomainName.endpointsConfigs.updateDomainNameConfig
  return axios.patch(
    `${baseUrl}/domain-name/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteDomainName = (id) => {
  const endpointDescription = DomainName.endpointsConfigs.deleteDomainNameConfig
  return axios.delete(
    `${baseUrl}/domain-name/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.uploadDomainName = (body) => {
  const endpointDescription = DomainName.endpointsConfigs.uploadDomainNameConfig
  return axios.post(
    `${baseUrl}/domain-name/upload`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.downloadDomainName = (id) => {
  const endpointDescription = DomainName.endpointsConfigs.downloadDomainNameConfig
  return axios.get(
      `${baseUrl}/file/domain-name/download/${id}`,
      { responseType: 'arraybuffer', endpointInfo: endpointDescription }
  )
}

endpoints.processDomainName = (id) => {
  const endpointDescription = DomainName.endpointsConfigs.processDomainNameConfig
  return axios.get(
    `${baseUrl}/domain-name/process/${id}`,
    { endpointInfo: endpointDescription }
  )
}

/*
  At the end we export the endpoints object
  It's important to notice that each endpoint must have a unique name
  So that the index.js file can create a new webpack context
  containing all endpoints declared in all Domains files of this folder spreaded into a single object
  And serve this object to the main axios controller at src/services/api/index.js
*/
export default endpoints
