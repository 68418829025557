import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import OpenedEye from 'assets/Images/opened_eye.svg'
import ClosedEye from 'assets/Images/closed_eye.svg'

import {
  Label,
  InputContainer,
  InputStyle,
  ErrorMessage,
  ToolTipContainer,
  SelectDiv,
  CheckBoxField,
  PasswordEye
} from './styles'

export function InputComponent({
  label,
  type,
  name,
  placeholder,
  toolTip,
  smallFont,
  min,
  max,
  disabled = false,
  customStyle,
  children,
  customChildren,
  ...props
}) {
  if (toolTip) {
    toolTip = toolTip.split('\\n').map((str) => {
      return (
        <>
          {str}
          <br />
        </>
      )
    })
  }

  return (
    <InputContainer customStyle={customStyle}>
      <Label smallFont={smallFont}>{label}</Label>
      {(() => {
        switch (type) {
          case 'select':
            return (
              <SelectDiv>
                <Field as="select" disabled={disabled} name={name} {...props}>
                  {customChildren ? customChildren() : children}
                </Field>
              </SelectDiv>
            )
          case 'checkbox':
            return (
              <Field name={name}>
                {({ field, meta }) => (
                  <>
                    <CheckBoxField
                      type={type}
                      {...field}
                      checked={field.value}
                      {...props}
                    />
                    {meta.touched && meta.error && (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    )}
                  </>
                )}
              </Field>
            )
          case 'password':
            return (
              <Field name={name}>
                {({ field, meta }) => (
                  <PasswordField
                    placeholder={placeholder}
                    disabled={disabled}
                    meta={meta}
                    field={field}
                    props={props}
                  />
                )}
              </Field>
            )
          default:
            return (
              <Field name={name}>
                {({ field, meta }) => (
                  <>
                    <InputStyle
                      type={type}
                      placeholder={placeholder}
                      min={min}
                      max={max}
                      disabled={disabled}
                      {...field}
                      {...props}
                    />
                    {meta.touched && meta.error && (
                      <ErrorMessage>{meta.error}</ErrorMessage>
                    )}
                  </>
                )}
              </Field>
            )
        }
      })()}
      {toolTip ? (
        <ToolTipContainer>
          ?<span>{toolTip}</span>
        </ToolTipContainer>
      ) : (
        ''
      )}
    </InputContainer>
  )
}

InputComponent.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.any,
  placeholder: PropTypes.string,
  toolTip: PropTypes.string,
  smallFont: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  customStyle: PropTypes.string,
  customChildren: PropTypes.any,
  children: PropTypes.any
}

function PasswordField({ placeholder, disabled, meta, field, props }) {
  const [eyeToggle, setEyeToggle] = useState(false)

  const handleEyeToggler = () => {
    setEyeToggle(!eyeToggle)
  }

  return (
    <>
      <InputStyle
        type={eyeToggle ? 'text' : 'password'}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
        {...props}
      />
      {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
      <PasswordEye
        src={eyeToggle ? OpenedEye : ClosedEye}
        onClick={handleEyeToggler}
      />
    </>
  )
}

PasswordField.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  customStyle: PropTypes.string,
  customChildren: PropTypes.any,
  children: PropTypes.any,
  meta: PropTypes.any,
  field: PropTypes.any,
  props: PropTypes.any
}
