import styled from 'styled-components'

export const CabecalhosContainerMain = styled.section`
  margin: 25px 0;
  > * {
    margin-left: 41px;
  }
`
export const CabecalhosContainerHeader = styled.header`
  display: flex;
  align-items: center;
  background-color: var(--background-light-blue);
  margin-left: 0;
  padding: 15px 0;
  gap: 25px;

  h3 {
    margin-left: 41px;
  }
  form {
    display: flex;
    gap: 15px;
    > div {
      gap: 5px;
      flex-direction: row;
      > label {
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-color-dark);
    > input {
      width: 30px;
      height: 30px;
    }
  }
`

export const CabecalhosContainer = styled.div`
  display: grid;
  background-color: #ddd;
  max-width: max-content;
  margin: 25px 70px;
  overflow: auto;
  grid-template-rows: 35px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(${(props) => props.columns}, 130px);
  border: 1px solid #ddd;
  gap: 1px;
`

export const CabecalhoContainerHeader = styled.div`
  height: 35px;
  background-color: #3fa1d8;
  color: var(--white);
  display: grid;
  place-items: center;
`
