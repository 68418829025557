/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, memo } from 'react'
import { Formik, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { ButtonEnviar, StyledForm } from './style'
import { useCNPJ } from './logic/hooks/useCNPJ'
import { dateTimeString } from 'helpers/formatters'

function Fields({ crudFields, isUpdate }) {
  return crudFields.map((CrudField, index) => {
    return (
      <CrudField.component
        {...CrudField.properties}
        isUpdate={isUpdate}
        key={index}
      />
    )
  })
}
const MemoizedFields = memo(Fields)

function FormFields({ apiFetcher, crudFields, isUpdate }) {
  if (['Escritorio', 'Empresas'].indexOf(apiFetcher) !== -1) {
    useCNPJ(crudFields, isUpdate)
  }
  return <MemoizedFields crudFields={crudFields} isUpdate={isUpdate} />
}

function FormCRUD(props) {
  // alguns valores string devem ser transformados de volta para objects
  const parsedRowValues = {}
  // useCNPJ(props.crudFields)

  for (const valor in props.rowValues) {
    parsedRowValues[valor] = props.rowValues[valor]

    if (valor === 'tipo') {
      parsedRowValues[valor] = JSON.parse(props.rowValues[valor])
    }
    if (valor === 'escritorio' && !props.rowValues.escritorio_id) {
      parsedRowValues.escritorio_id = props.rowValues[valor][0]?.uuid ?? ''
    }
    if (valor === 'municipio' && !props.rowValues[valor]) {
      parsedRowValues[valor] = ''
    }
  }

  // cria initialValues de acordo com as properties 'name' do props crudFields em <Table> transmitidas até aqui
  const initialValues = {}

  props.crudFields.find((item) => item.properties.name === 'created_at')
  props.crudFields.forEach((crudField) => {
    if (crudField?.type === 'boolean') {
      initialValues[crudField.properties.name] = false
      if (
        props.apiFetcher === 'Empresas' &&
        crudField.properties.name === 'ativo'
      ) {
        crudField.properties.enabled = Object.keys(parsedRowValues).length > 0
        initialValues[crudField.properties.name] = true
      }
    } else {
      initialValues[crudField.properties.name] = ''

      if (
        props.apiFetcher === 'Escritorio' &&
        crudField.properties.name === 'inicio_contrato'
      ) {
        if (
          props.rowValues.inicio_contrato !== '' &&
          typeof props.rowValues.inicio_contrato !== 'undefined'
        ) {
          while (props.rowValues.inicio_contrato.includes('T')) {
            props.rowValues.inicio_contrato =
              props.rowValues.inicio_contrato.replace('T', '')
          }
        } else {
          const today = new Date()
          const year = today.getFullYear()
          const month =
            today.getMonth() > 9
              ? today.getMonth() + 1
              : '0' + (today.getMonth() + 1)
          const day =
            today.getDate() > 10 ? today.getDate() : '0' + today.getDate()

          const parsedToday = `${year}-${month}-${day}`
          initialValues[crudField.properties.name] = parsedToday
        }
      }

      if (crudField.properties.name === 'sistemascontabeis') {
        initialValues[crudField.properties.name] = []
      }
    }

    if (crudField.properties?.name === 'created_at') {
      initialValues[crudField.properties.name] = dateTimeString(
        new Date().toISOString()
      )
    }

    if (crudField.properties?.initWithFirstOption) {
      initialValues[crudField.properties.name] =
        crudField.properties.options[0].value
    }

    if (crudField.properties?.name === 'sistemaContabilTabs') {
      delete initialValues[crudField.properties.name]
    }
  })

  // RowValues chegar aqui preenchido implica que a ação é um Update. Caso contrário é um Create usando initialValues
  const isUpdate = Object.keys(parsedRowValues).length > 0

  return (
    <Formik
      initialValues={isUpdate ? parsedRowValues : initialValues}
      onSubmit={async (values) => {
        await props.handleSubmit(props, values)
      }}
    >
      <StyledForm className="form-crud">
        <FormFields {...{ ...props, isUpdate }} />
        <ComponentButtonEnviar />
      </StyledForm>
    </Formik>
  )
}

function ComponentButtonEnviar() {
  const { status } = useFormikContext()
  if (!window.utils.isFalsy(status)) {
    if (Object.keys(status).length > 0) {
      return (
        <ButtonEnviar className="form-crud__btn-enviar" type="submit" disabled>
          Enviar
        </ButtonEnviar>
      )
    }
  }
  return (
    <ButtonEnviar className="form-crud__btn-enviar" type="submit">
      Enviar
    </ButtonEnviar>
  )
}

FormCRUD.propTypes = {
  apiFetcher: PropTypes.string,
  handleSubmit: PropTypes.func,
  crudFields: PropTypes.array,
  rowValues: PropTypes.object
}

export default FormCRUD
