import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

function InputDate({ name, label }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <label htmlFor={name} style={{ width: 'auto' }}>
        {label}
      </label>
      <Field type="date" name={name} />
    </div>
  )
}

InputDate.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
}
export default InputDate
