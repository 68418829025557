import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

function InputRange({ min, max, name, labelText }) {
  return (
    <label
      htmlFor={name}
      style={{
        display: 'flex',
        margin: '2px 0 2px 10px',
        justifyContent: 'space-between',
        paddingRight: '10px'
      }}
    >
      <p style={{ width: 'auto' }}>{labelText}</p>
      <Field type="range" name={name} min={min} max={max} />
    </label>
  )
}

InputRange.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  name: PropTypes.string,
  labelText: PropTypes.string
}
export default InputRange
