/* eslint-disable no-unused-vars */
import { cpfValidation, cnpjValidation } from 'helpers/documentNumberValidation'

import Pattern from './Pattern'
export default class PatternCpfCnpj extends Pattern {
  constructor({ value, setValue, setError }) {
    super(setValue, setError)
    super.setCleanValue(value.replace(/\D/g, ''))
    return {
      applyMask: (value) => this.applyMask(value),
      validate: () => this.validate()
    }
  }

  applyMask(value) {
    super.setMaskedValue(value)
    const elementValue = Boolean(this.maskedValue?.match(/\d/g))

    if (!elementValue && this.maskedValue !== null) {
      const as = this.maskedValue.replace(value, '')
      super.setMaskedValue(as)
    }

    if (this.cleanValue.length === 15) {
      super.setMaskedValue(this.maskedValue.replace(/(\d{1}$)/, ''))
      super.setValue()
      return
    }

    if (this.cleanValue.length <= 11) {
      super.setMaskedValue(
        this.cleanValue
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
      )
    } else {
      super.setMaskedValue(
        this.cleanValue
          .replace(/^(\d{2})(\d)/, '$1.$2')
          .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
          .replace(/\.(\d{3})(\d)/, '.$1/$2')
          .replace(/(\d{4})(\d)/, '$1-$2')
      )
    }
    super.setValue()
  }

  validate() {
    const cpfLength = 11
    const cnpjLength = 14
    if (
      this.cleanValue?.length !== cpfLength &&
      this.cleanValue?.length !== cnpjLength
    ) {
      super.setError('Numero incorreto de digitos.')
      return
    }
    if (this.cleanValue?.length === cpfLength) {
      const isValidCpf = cpfValidation(this.cleanValue)
      if (!isValidCpf) {
        super.setError('CPF Inválido.')
        return
      }
    }
    if (this.cleanValue?.length === cnpjLength) {
      const isValidCnpj = cnpjValidation(this.cleanValue)
      if (!isValidCnpj) {
        super.setError('CNPJ Inválido.')
        return
      }
    }
    super.removeError()
  }
}
