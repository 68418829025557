import React from 'react'
// import { Field } from 'formik'
// import PropTypes from 'prop-types'

// function Toggler({ option, name, enabled = true }) {
function Toggler() {
  return <></>
  //   return (
  //     <label
  //       htmlFor={option}
  //       style={{ display: 'flex', margin: '10px 0 5px 0px' }}
  //     >
  //       <Field
  //         type="checkbox"
  //         name={name}
  //         style={{ width: '5%', height: '1rem', marginLeft: '-5px' }}
  //         disabled={!enabled}
  //       />
  //       <span
  //         style={{
  //           width: 'auto',
  //           color: '#333333'
  //         }}
  //       >
  //         {option}
  //       </span>
  //     </label>
  //   )
}

// Toggler.propTypes = {
//   option: PropTypes.string,
//   name: PropTypes.string,
//   enabled: PropTypes.bool
// }
export default Toggler
