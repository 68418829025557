/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react'
import useNonInitialEffect from 'hooks/useNonInitialEffect'
import { TableContext } from 'context/TableContext/TableContext'
import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'
import { Table as NewTable } from 'components/Table'

export default function useTable(apiFetcher, dependencies = []) {
  const { tableConfigsState, dispatchTableConfigs } = useContext(TableContext)
  const { dispatchRestrictedLayout } = useContext(RestrictedLayoutContext)
  const [Table, setTable] = useState(<></>)

  useNonInitialEffect(() => {
    if (tableConfigsState.headers.length > 0) {
      dispatchTableConfigs({ type: 'setDisplayTable', payload: true })
    } else {
      dispatchTableConfigs({ type: 'setDisplayTable', payload: false })
    }
  }, [
    tableConfigsState.headers,
    tableConfigsState.topBarItems,
    tableConfigsState.actions,
    tableConfigsState.cellComponents,
    tableConfigsState.crudFields
  ])

  useNonInitialEffect(() => {
    if (tableConfigsState.displayTable) {
      let headers = tableConfigsState.headers
      if (tableConfigsState.actions.length === 0) {
        headers = headers.filter((header) => header !== 'Ações')
      }
      setTable(
        <NewTable
          apiFetcher={apiFetcher}
          dispatchRestrictedLayout={dispatchRestrictedLayout}
          topBarItems={tableConfigsState.topBarItems}
          headers={headers}
          actions={tableConfigsState.actions}
          cellComponents={tableConfigsState.cellComponents}
          crudFields={tableConfigsState.crudFields}
        />
      )
    }
  }, [tableConfigsState.displayTable, ...dependencies])

  return { Table }
}
