/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Styled from '../style'
import { transformData } from 'components/Table/logic/utils'

export const UltimaPaginaButton = ({
  numberOfPages,
  perPage,
  setRows,
  setActive,
  apiFetcher,
  listUrl,
  setNumberOfPages,
  paginatorControl
}) => {
  const handleClick = async () => {
    const data = await paginatorControl(
      numberOfPages,
      perPage,
      apiFetcher,
      listUrl
    )
    const result = transformData(data.rows || data)
    setRows(result)
    setActive(numberOfPages)
    setNumberOfPages(data.numberOfPages)
  }

  return (
    <Styled.ButtonPrimeiraUltima onClick={handleClick}>
      Última
    </Styled.ButtonPrimeiraUltima>
  )
}

UltimaPaginaButton.propTypes = {
  numberOfPages: PropTypes.number,
  value: PropTypes.number,
  perPage: PropTypes.number,
  setRows: PropTypes.func,
  active: PropTypes.bool,
  setActive: PropTypes.func,
  apiFetcher: PropTypes.string,
  listUrl: PropTypes.string,
  setNumberOfPages: PropTypes.func,
  paginatorControl: PropTypes.func
}
