/* eslint-disable no-unused-vars */
import { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import {
  getTagElements,
  hideElements,
  showElements
} from '../utils/formTabsHelper'

export function useFormTabs({ tabName, tabsList, selectedTab }) {
  useLayoutEffect(() => {
    const { fields: selectedTabFields } = tabsList.filter(
      (tab) => tab.value === selectedTab
    )[0]

    const { thisForm, thisFormInputs } = getMainElements(tabName)
    hideElements(thisFormInputs)
    const elementsToDisplay = getTagElements(thisForm, selectedTabFields)
    showElements(elementsToDisplay)
  }, [selectedTab])
}

function getMainElements(tabName) {
  const thisTab = document.getElementsByClassName(tabName)[0]
  const thisForm = thisTab.parentElement
  const thisFormElements = thisForm.children

  const thisFormInputs = Object.values(thisFormElements).filter((element) => {
    const elementClasses = element.className.split(' ')
    if (
      elementClasses.filter(
        (elementClass) =>
          elementClass === tabName || elementClass === 'form-crud__btn-enviar'
      ).length > 0
    ) {
      return false
    }
    return true
  })

  return {
    thisForm,
    thisFormInputs
  }
}

useFormTabs.propTypes = {
  tabName: PropTypes.string,
  tabsList: PropTypes.array,
  selectedTab: PropTypes.string
}
