import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

function GroupOfRadioboxesPForPJ({ name }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'end'
      }}
    >
      <span style={{ width: '50%', color: '#333333' }}>Tipo de Pessoa:</span>
      {['Fisica', 'Juridica'].map((singleOption, i) => {
        return (
          <label
            htmlFor={name}
            key={i}
            style={{
              display: 'flex',
              margin: '2px 0 2px 10px',
              color: '#333333'
            }}
          >
            <Field
              type="radio"
              label={singleOption}
              name={name}
              value={singleOption.toLowerCase() === 'fisica' ? 'F' : 'J'}
            />
            <span
              style={{ width: 'auto', marginLeft: '10px', color: '#333333' }}
            >
              {singleOption}
            </span>
          </label>
        )
      })}
    </div>
  )
}

GroupOfRadioboxesPForPJ.propTypes = {
  name: PropTypes.string
}
export default GroupOfRadioboxesPForPJ
