import React from 'react'
import { useEventsModal } from 'hooks/EventsHooks/useEventsModal'
// import EventLoader from 'components/EventLoader'
export default function Events() {
  const { EventModal } = useEventsModal()
  return (
    <>
      <EventModal />
      {/* <EventLoader /> */}
    </>
  )
}
