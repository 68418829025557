import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'components/Button'

import { Tooltip } from '@plick-solucoes-contabeis/ui-components'

import getHumanNameForEndpoint from 'helpers/getHumanNameForEndpoint'

function EditButton({ modalConfig, setModalVisible, rowValues, apiFetcher }) {
  return (
    <Tooltip title="Editar" delay={900}>
      <Button
        buttonFunction={'ButtonTable'}
        buttonIcon={'IconeEditar'}
        colorText={'var(--background-medium-blue)'}
        onClick={() => {
          modalConfig.current = {
            title: `Editar ${getHumanNameForEndpoint(apiFetcher, 'singular')}`,
            initialValues: rowValues
          }
          setModalVisible(true)
        }}
      />
    </Tooltip>
  )
}

EditButton.propTypes = {
  modalConfig: PropTypes.object,
  setModalVisible: PropTypes.func,
  rowValues: PropTypes.any,
  apiFetcher: PropTypes.string
}

export default EditButton
