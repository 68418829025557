/* eslint-disable no-unused-vars */
import { useContext, useEffect } from 'react'
import { TableContext } from 'context/TableContext/TableContext'

export default function useReset(dependencies = []) {
  const { dispatchTableConfigs } = useContext(TableContext)

  useEffect(() => {
    dispatchTableConfigs({ type: 'reset' })
  }, dependencies)
}
