import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'roles'
const url = '/roles'

const endpointsDescriptions = {
  paginateRoles: {
    action: 'getPaginated'
  },
  getAllRoles: {
    action: 'getAll'
  },
  getSingleRole: {
    action: 'getOne'
  },
  addRoles: {
    action: 'add'
  },
  editRoles: {
    action: 'update'
  },
  deleteRoles: {
    action: 'delete'
  }
}

const roles = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.paginateRoles = () => {
  const endpointDescription = roles.endpointsConfigs.paginateRolesConfig
  return axios.get(
    `${baseUrl}/roles`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getAllRoles = () => {
  const endpointDescription = roles.endpointsConfigs.getAllRolesConfig
  return axios.get(
    `${baseUrl}/roles`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.getSingleRole = (id) => {
  const endpointDescription = roles.endpointsConfigs.getSingleRoleConfig
  return axios.get(
    `${baseUrl}/roles/${id}`,
    { endpointInfo: endpointDescription }
  )
}

endpoints.addRoles = (body) => {
  const endpointDescription = roles.endpointsConfigs.addRolesConfig
  return axios.post(
    `${baseUrl}/roles`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.editRoles = (id, body) => {
  const endpointDescription = roles.endpointsConfigs.editRolesConfig
  return axios.put(
    `${baseUrl}/roles/${id}`,
    body,
    { endpointInfo: endpointDescription }
  )
}

endpoints.deleteRoles = (id) => {
  const endpointDescription = roles.endpointsConfigs.deleteRolesConfig
  return axios.delete(
    `${baseUrl}/roles/${id}`,
    { endpointInfo: endpointDescription }
  )
}

export default endpoints
