import React, { useState, useEffect } from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import api from 'services/api'

function InputSelectorSistemaDeGestao({ name }) {
  const [todosSistemasDeGestao, setSistemaDeGestao] = useState([])

  useEffect(async () => {
    const resposta = await api.getAllSistemaGestao()
    setSistemaDeGestao(resposta.data)
  }, [])

  return (
    <label
      htmlFor={name}
      style={{
        display: 'flex',
        margin: '10px 0',
        color: '#333333',
        alignItems: 'end'
      }}
    >
      <span style={{ width: '50%' }}>Sistema de Gestão:</span>
      <Field
        as="select"
        name={name}
        style={{
          marginLeft: '10px',
          width: '50%',
          borderRadius: '3px',
          border: '0',
          borderBottom: '1px solid rgb(118, 118, 118)',
          height: '1.7rem',
          backgroundColor: 'var(--white)'
        }}
      >
        <option value="" disabled hidden>
          ...
        </option>
        {todosSistemasDeGestao &&
          todosSistemasDeGestao.map((sistemaDeGestao, index) => (
            <option key={index} value={sistemaDeGestao.id}>
              {sistemaDeGestao.nome}
            </option>
          ))}
      </Field>
    </label>
  )
}

InputSelectorSistemaDeGestao.propTypes = {
  name: PropTypes.string
}
export default InputSelectorSistemaDeGestao
