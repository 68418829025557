import styled from 'styled-components'

export const ModalLayer = styled.section`
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
`

export const LoaderContainer = styled.div`
  /* background-color: hsl(215,89%,14%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* background-color: hsl(215,89%,100%); */
  width: 200px;
  border-radius: 8px;
  padding: 15px;
  /* opacity:0.8; */
  /* box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3); */

  & svg {
    width: 90px;
    height: 90px;
    border-radius: 8px;
    padding: 10px;
    background-color: hsl(215, 89%, 14%);

    & .layer1,
    & .layer2,
    & .layer3 {
      stroke-linecap: round;
    }

    & .layer1 {
      stroke-width: 40px;
      stroke: hsl(155, 100%, 37%);
      stroke-dasharray: 2122;
    }
    & .layer2 {
      stroke-width: 28px;
      stroke: hsl(215, 89%, 14%);
      stroke-dasharray: 2122;
    }
    & .layer3 {
      stroke-width: 15px;
      stroke: hsl(155, 100%, 37%);
      stroke-dasharray: 1061;
      stroke-dashoffset: 0;
      animation: dash 4.5s linear infinite both;
    }

    .leg {
      stroke: hsl(155, 100%, 37%);
      fill: hsl(155, 100%, 37%);
      stroke-width: 40px;
      stroke-linecap: round;
      stroke-dasharray: 626;
      stroke-dashoffset: 0;
    }

    @keyframes dash {
      to {
        stroke-dashoffset: 4230;
      }
    }
  }
`
