import axios from 'axios'
import BaseDomainsProvider from 'services/api/documentation/Domain'

const endpoints = {}

const baseUrl = process.env.REACT_APP_API_URL

const domain = 'termoDeUso'
const url = '/termo-de-uso'

const endpointsDescriptions = {
  getAllTermosDeUso: {
    action: 'getAll'
  },
  getTermoDeUsoByVersion: {
    action: 'getOne'
  },
  getTermoDeUsoLastVersion: {
    action: 'getOne'
  },
  addTermoDeUso: {
    action: 'add'
  },
  updateTermoDeUsoByVersion: {
    action: 'update'
  }
}

const termoDeUso = new BaseDomainsProvider(domain, url, endpointsDescriptions)

endpoints.getAllTermosDeUso = () => {
  const endpointDescription =
    termoDeUso.endpointsConfigs.getAllTermosDeUsoConfig
  return axios.get(`${baseUrl}${url}/`, { endpointInfo: endpointDescription })
}

endpoints.getTermoDeUsoByVersion = (version) => {
  const endpointDescription =
    termoDeUso.endpointsConfigs.getTermoDeUsoByVersionConfig
  return axios.get(`${baseUrl}${url}/${version}`, {
    endpointInfo: endpointDescription
  })
}

endpoints.getTermoDeUsoLastVersion = () => {
  const endpointDescription =
    termoDeUso.endpointsConfigs.getTermoDeUsoLastVersionConfig
  return axios.get(`${baseUrl}${url}/last-version`, {
    endpointInfo: endpointDescription
  })
}

endpoints.addTermoDeUso = (body) => {
  const endpointDescription = termoDeUso.endpointsConfigs.addTermoDeUsoConfig
  return axios.post(`${baseUrl}${url}`, body, {
    endpointInfo: endpointDescription
  })
}

endpoints.updateTermoDeUsoByVersion = (version, body) => {
  const endpointDescription =
    termoDeUso.endpointsConfigs.updateTermoDeUsoByVersionConfig
  return axios.put(`${baseUrl}${url}/${version}`, body, {
    endpointInfo: endpointDescription
  })
}

export default endpoints
