import { useState } from 'react'
import { paginationFetcher } from 'components/Paginator/subComponents/paginationFetcher'
import api from 'services/api'

export const control = (feature, search) => ({
  default: () => async (value, perPage, apiFetcher, listUrl) => {
    return await paginationFetcher(value, perPage, apiFetcher, listUrl)
  },
  filtered: () => async (value, perPage, apitFetcher, listUrl) => {
    const { data } = await api.filteredPaginated(
      feature,
      search,
      value,
      perPage
    )
    return data
  }
})

export default function usePaginatorControl() {
  const [paginatorControl, setPaginatorControl] = useState(control().default)

  return { paginatorControl, setPaginatorControl }
}
