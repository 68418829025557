// falta: logica para atualizar página no update
import React, { useEffect, useState, useContext } from 'react'
import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'
import { Button } from '../../components/Button'
import { Container } from './styles'
import { getUuid } from 'helpers/jwtHelper'
import api from 'services/api'
import * as Yup from 'yup'
import { InputComponent } from 'components/InputComponent'
import { REGEX } from 'helpers/formValidationRules'
import { Formik, Form } from 'formik'

const schemaValidate = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório.'),
  email: Yup.string().email('Email inválido').required('Campo obrigatório.'),
  telefone: Yup.string()
    .matches(REGEX.telefone, 'formato inválido')
    .required('Campo Obrigatório.')
})

export function AtualizarDados() {
  const { dispatchRestrictedLayout } = useContext(RestrictedLayoutContext)
  const [userData, setUserData] = useState({})
  const [reloadForm, setReloadForm] = useState('ping')

  const getUserData = async () => {
    api.getSingleUser(getUuid()).then((response) => {
      setUserData({
        nome: response.data.nome,
        email: response.data.email,
        telefone: response.data.telefone
      })
    })
  }

  useEffect(() =>
    dispatchRestrictedLayout({ type: 'setMainTopBarContent', payload: '' })
  )

  useEffect(getUserData, [])
  useEffect(() => {
    dispatchRestrictedLayout({
      type: 'setHeaderData',
      payload: `Atualização dos dados do usuário ${userData.nome}`
    })
  }, [userData])

  return (
    reloadForm &&
    Object.keys(userData).length > 0 && (
      <Container style={{ width: '50%', margin: '0 auto' }}>
        <Formik
          onSubmit={(values, { resetForm }) => {
            api.editUsuarios(getUuid(), values).then(() => {
              setReloadForm(Date.now().toString())
              getUserData().then(() => resetForm(userData))
            })
          }}
          initialValues={reloadForm ? userData : {}}
          validationSchema={schemaValidate}
          enableReinitialize
        >
          <Form>
            <InputComponent type="text" name="nome" placeholder="Nome" />
            <InputComponent type="text" name="email" placeholder="Email" />
            <InputComponent
              type="text"
              name="telefone"
              placeholder="Telefone"
            />
            <Button type="submit" value="Enviar" />
          </Form>
        </Formik>
      </Container>
    )
  )
}
