import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 50px auto 35px;
  grid-template-areas:
    'logo background'
    'content background'
    'trademark background';

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  .p-logo {
    grid-area: logo;
    width: 125px;
    margin: 35px auto;
  }
`

export const Main = styled.main`
  grid-area: content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 40%;
  min-width: 400px;
  /* min-height: 70vh; */
`

export const Background = styled.div`
  grid-area: background;
  position: relative;
  background-color: magenta;
  background: var(--green); /* display: flex; */
  & > img {
    position: absolute;
  }
  & > .background-top-pattern {
    right: 0;
  }
  & > .background-k-logo {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  & > .background-bottom-pattern {
    bottom: 0;
    left: 0;
  }
`
export const Trademark = styled.p`
  grid-area: trademark;
  margin: 0 auto;
`
