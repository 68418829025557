import { useEffect, useContext } from 'react'
import crudFields from 'components/FormCRUD/subComponents'
import TextField from 'components/TextField'
import PasswordField from 'components/PasswordField'
import PasswordValidator from 'components/PasswordValidator'
import TextFieldInline from 'components/TextFieldInline'

import { TableContext } from 'context/TableContext/TableContext'
import MaskedText from 'components/MaskedText'
import AutoComplete from 'components/AutoComplete'
import withFlexLabel from 'components/AutoComplete/logic/hoc/withFlexLabel'
import InputSelectorEscritorio from 'components/FormCRUD/subComponents/InputSelectorEscritorio'

export default function useCrudFields(crudFieldsList, dependencies = []) {
  const { tableConfigsState, dispatchTableConfigs } = useContext(TableContext)

  useEffect(() => {
    if (crudFieldsList.length > 0) {
      const fields = crudFieldsList.map((field) => {
        switch (field.component) {
          case 'TextField':
            field.component = TextField
            break
          case 'PasswordField':
            field.component = PasswordField
            break
          case 'PasswordValidator':
            field.component = PasswordValidator
            break
          case 'TextFieldInline':
            field.component = TextFieldInline
            break
          case 'MaskedText':
            field.component = MaskedText
            break
          case 'AutoComplete':
            field.component = field.properties.withFlexLabel
              ? withFlexLabel(field.properties, AutoComplete)
              : AutoComplete
            break
          case 'InputSelectorEscritorio':
            field.component = field.properties.withFlexLabel
              ? withFlexLabel(field.properties, InputSelectorEscritorio)
              : InputSelectorEscritorio
            break
          default:
            field.component = crudFields[field.component]
        }
        return field
      })
      dispatchTableConfigs({ type: 'setCrudFields', payload: fields })
    }
  }, dependencies)

  return tableConfigsState.crudFields
}
