import React from 'react'

import PropTypes from 'prop-types'
import EditContatosButton from './EditContatosButton'
import EditEmpresasButton from './EditEmpresasButton'
import EditUsuariosButton from './EditUsuariosButton'
import UsuariosPermissoesButton from './UsuariosPermissoesButton'
import RolesPermissoesButton from './RolesPermissoesButton'
import EditButton from './EditButton'
import DeleteButton from './DeleteButton'
import CadastroDeParaButton from './CadastroDeParaButton'
import styled from 'styled-components'

const AcoesCellWrapper = styled.div`
  display: flex;
  justify-content: center;
`

function AcoesCell({
  apiID,
  setModalContent,
  setShowModal,
  setCrudTimeStamp,
  apiFetcher,
  headers,
  actions,
  rowValues,
  crudFields,
  modalConfig,
  setModalVisible
}) {
  return (
    <AcoesCellWrapper>
      {actions.indexOf('usuarios') !== -1 && (
        <EditUsuariosButton rowValues={rowValues} />
      )}
      {actions.indexOf('permissions') !== -1 && (
        <UsuariosPermissoesButton
          setModalContent={setModalContent}
          setShowModal={setShowModal}
          rowValues={rowValues}
          modalConfig={modalConfig}
          setModalVisible={setModalVisible}
        />
      )}
      {actions.indexOf('de-para') !== -1 && (
        <CadastroDeParaButton
          setModalContent={setModalContent}
          setShowModal={setShowModal}
          rowValues={rowValues}
        />
      )}
      {actions.indexOf('roles') !== -1 && (
        <RolesPermissoesButton
          setModalContent={setModalContent}
          setShowModal={setShowModal}
          rowValues={rowValues}
          modalConfig={modalConfig}
          setModalVisible={setModalVisible}
        />
      )}
      {actions.indexOf('contatos') !== -1 && (
        <EditContatosButton rowValues={rowValues} />
      )}
      {actions.indexOf('empresas') !== -1 && (
        <EditEmpresasButton rowValues={rowValues} />
      )}
      {actions.indexOf('edit') !== -1 && (
        <EditButton
          apiFetcher={apiFetcher}
          rowValues={rowValues}
          modalConfig={modalConfig}
          setModalVisible={setModalVisible}
        />
      )}
      {actions.indexOf('delete') !== -1 && (
        <DeleteButton
          apiFetcher={apiFetcher}
          setModalVisible={setModalVisible}
          apiID={apiID}
          setCrudTimeStamp={setCrudTimeStamp}
        />
      )}
    </AcoesCellWrapper>
  )
}

AcoesCell.propTypes = {
  actions: PropTypes.array,
  apiID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setModalContent: PropTypes.func,
  setShowModal: PropTypes.func,
  setCrudTimeStamp: PropTypes.func,
  setMainTopBarContent: PropTypes.func,
  apiFetcher: PropTypes.string,
  headers: PropTypes.array,
  crudFields: PropTypes.array,
  rowValues: PropTypes.object,
  perPage: PropTypes.number,
  setActive: PropTypes.func,
  setRows: PropTypes.func,
  numberOfPages: PropTypes.number,
  modalConfig: PropTypes.object,
  setModalVisible: PropTypes.func
}
export default AcoesCell
