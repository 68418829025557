import { cnpjValidation } from 'helpers/documentNumberValidation'
import Pattern from './Pattern'
export default class PatternCnpj extends Pattern {
  constructor({ value, setValue, setError }) {
    super(setValue, setError)
    super.setCleanValue(value.replace(/\D/g, ''))
    return {
      applyMask: (value) => this.applyMask(value),
      validate: () => this.validate()
    }
  }

  applyMask(value) {
    super.setMaskedValue(value)
    const isNum = Boolean(this.maskedValue?.match(/\d/g))

    if (!isNum) {
      const as = this.maskedValue.replace(this.maskedValue, '')
      super.setMaskedValue(as)
    }

    if (this.cleanValue.length === 15) {
      super.setMaskedValue(this.maskedValue.replace(/(\d{1}$)/, ''))
      super.setValue()
      return
    }

    super.setMaskedValue(
      this.cleanValue
        .replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/\.(\d{3})(\d)/, '.$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
    )
    super.setValue()
  }

  validate() {
    if (this.cleanValue?.length !== 14) {
      super.setError('Numero incorreto de digitos.')
      return
    }

    if (this.cleanValue?.length === 14) {
      const isValidCnpj = cnpjValidation(this.cleanValue)
      if (!isValidCnpj) {
        super.setError('CNPJ Inválido.')
        return
      }
    }
    super.removeError()
  }
}
