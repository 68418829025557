// Initialize Object that will contain all functions from the domains modules in this folder
const crudFields = {}

const excludedFiles = ['./index.js']

// Import all functions from the modules defaults into an object
function importAll(crudFieldsContext) {
  crudFieldsContext.keys().forEach((key) => {
    if (!excludedFiles.includes(key)) {
      const myKey = key.split('./')[1].split('.js')[0]
      crudFields[myKey] = crudFieldsContext(key).default
    }
  })
}

// Create a new webpack context for this folder
const crudFieldsContext = require.context('./', false, /.*\.js/)

importAll(crudFieldsContext)

export default crudFields
