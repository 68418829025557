/* eslint-disable no-unused-vars */
import React, { useRef } from 'react'
import withFlexLabel from 'components/AutoComplete/logic/hoc/withFlexLabel'
import { useFormikContext, useField } from 'formik'
import AutoComplete from 'components/AutoComplete'

function InputMunicipio() {
  const {
    values: { uf }
  } = useFormikContext()
  const [field] = useField('municipio')

  const inputRef = useRef()

  return (
    <AutoComplete
      name="municipio"
      oneTimeRequest
      helper={({ ref }) => {
        inputRef.current = ref
      }}
      options={{
        freezeOnMount: !uf,
        searchDeps: [uf]
      }}
      extractKey={(item) => {
        return item.nome
      }}
      createOptions={(data) => {
        if (data instanceof Array) {
          return data.map((item) => ({
            label: item.nome,
            value: item.nome
          }))
        }
        return {
          label: data.nome,
          value: data.nome
        }
      }}
      renderMenuItem={(value, labelProps) => {
        return (
          <>
            <span {...labelProps}>{value.nome}</span>
          </>
        )
      }}
      filter={(value, searchValue) => {
        return value.nome.match(new RegExp(`${searchValue.trim()}`, 'gi'))
      }}
      startSearch={async () => {
        const response = await fetch(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`
        )
        let data = await response.json()
        data = data.map((item) => ({ nome: item.nome }))

        return data
      }}
      afterSearch={(value) => {
        const isSelected = value.filter(
          (value) =>
            value.nome.normalize('NFD').replace(/\p{Diacritic}/gu, '') ===
            field.value.normalize('NFD').replace(/\p{Diacritic}/gu, '')
        )
        if (isSelected.length > 0) {
          inputRef.current.setValue(isSelected[0].nome)
        }
      }}
    />
  )
}

export default withFlexLabel(
  { name: 'municipio', title: 'Município' },
  InputMunicipio
)
