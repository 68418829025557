import React, {
  useState,
  useContext,
  useRef,
  useCallback,
  useEffect
} from 'react'

import history from 'services/history'

import { ContextMenu, ContextMenuToggle, Draggy } from '../../style'

import { RestrictedLayoutContext } from 'context/RestrictedLayoutContext/RestrictedLayoutContext'

export default function RestrictedContextMenu() {
  const { restrictedLayoutState, dispatchRestrictedLayout } = useContext(
    RestrictedLayoutContext
  )

  useEffect(() => {
    dispatchRestrictedLayout({ type: 'setContextMenuContent', payload: '' })
  }, [history.location.pathname])

  const [captureEvent, setCaptureEvent] = useState(false)
  const refCaptureEvent = useRef(null)
  refCaptureEvent.current = { captureEvent, setCaptureEvent }
  const [initialContextMenuHeight, setInitialContextMenuHeight] = useState(
    (localStorage.getItem('menuContextHeight') || 500) + 'px'
  )
  const refInitialContextMenuHeight = useRef(null)
  refInitialContextMenuHeight.current = {
    initialContextMenuHeight,
    setInitialContextMenuHeight
  }
  const refContextMenu = useRef(null)
  const refContextMenuToggle = useRef(null)
  const refCloseIcon = useRef(null)

  const handleMouseButtonEvents = useCallback(function handleMouseButtonEvents(
    event
  ) {
    if (event.type === 'mousedown') {
      refCaptureEvent.current.setCaptureEvent(true)
      document
        .getElementById('plick-main')
        .addEventListener('mousemove', handleMouseMoveEvent)
      document
        .getElementById('plick-main')
        .addEventListener('mouseup', handleMouseButtonEvents)
    }
    if (event.type === 'mouseup') {
      refCaptureEvent.current.setCaptureEvent(false)
      document
        .getElementById('plick-main')
        .removeEventListener('mousemove', handleMouseMoveEvent)
      document
        .getElementById('plick-main')
        .removeEventListener('mouseup', handleMouseButtonEvents)

      if (!window.utils.isFalsy(refContextMenu.current)) {
        if (refContextMenu.current !== null) {
          let newHeight =
            window.innerHeight - refContextMenu.current.getClientRects()[0].y
          newHeight = newHeight > 585 ? 585 : newHeight
          newHeight = newHeight < 250 ? 250 : newHeight
          localStorage.setItem('menuContextHeight', newHeight)
          refInitialContextMenuHeight.current.setInitialContextMenuHeight(
            newHeight + 'px'
          )
        }
      }
      refContextMenu.current.style.height = ''
      refContextMenuToggle.current.style.bottom = ''
    }
  },
  [])

  const handleMouseMoveEvent = useCallback(
    function handleMouseMoveEvent(event) {
      event.preventDefault()
      if (
        window.utils.isFalsy(refContextMenu) ||
        window.utils.isFalsy(refContextMenuToggle) ||
        window.utils.isFalsy(refCloseIcon) ||
        refContextMenu.current === null ||
        refContextMenuToggle.current === null ||
        refCloseIcon.current === null ||
        !refCaptureEvent.current.captureEvent
      ) {
        return false
      }

      let newHeight = window.innerHeight - event.clientY

      newHeight = newHeight > 585 ? 585 : newHeight
      newHeight = newHeight < 250 ? 250 : newHeight
      newHeight = newHeight + 'px'

      refContextMenu.current.style.height = newHeight
      refContextMenuToggle.current.style.bottom = newHeight
      refCloseIcon.current.style.bottom = newHeight
    },
    [captureEvent]
  )

  if (!window.utils.isFalsy(restrictedLayoutState.contextMenuContent.props)) {
    if (
      window.utils.isFalsy(
        restrictedLayoutState.contextMenuContent.props.children
      ) ||
      restrictedLayoutState.contextMenuContent.props.children.length === 0
    ) {
      return <></>
    }
  }
  if (window.utils.isFalsy(restrictedLayoutState.contextMenuContent.props)) {
    return <></>
  }
  if (restrictedLayoutState.contextMenuContent === '') {
    return <></>
  }

  return (
    <>
      <ContextMenuToggle
        ref={refContextMenuToggle}
        bottom={
          restrictedLayoutState.toggleContextMenu
            ? '3px'
            : initialContextMenuHeight
        }
        onClick={() =>
          dispatchRestrictedLayout({
            type: 'setToggleContextMenu',
            payload: !restrictedLayoutState.toggleContextMenu
          })
        }
      >
        <svg
          style={
            restrictedLayoutState.toggleContextMenu
              ? { transform: 'rotate(90deg)' }
              : { transform: 'rotate(270deg)' }
          }
          width="26"
          height="25"
          viewBox="0 0 26 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.062 7.062L15 6L9 12L15 18L16.062 16.938L11.124 12L16.062 7.062Z"
            fill="var(--white)"
          />
        </svg>
      </ContextMenuToggle>
      <svg
        ref={refCloseIcon}
        style={{
          position: 'absolute',
          backgroundColor: 'var(--blue)',
          padding: '4px 10px 1px 10px',
          right: '25px',
          width: '40px',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
          cursor: 'pointer',
          bottom: restrictedLayoutState.toggleContextMenu
            ? '3px'
            : initialContextMenuHeight,
          transition: 'all 0.3s'
        }}
        onClick={() => {
          dispatchRestrictedLayout({
            type: 'setContextMenuContent',
            payload: ''
          })
        }}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="var(--white)"
          d="M84.707,68.752L65.951,49.998l18.75-18.752c0.777-0.777,0.777-2.036,0-2.813L71.566,15.295
          c-0.777-0.777-2.037-0.777-2.814,0L49.999,34.047l-18.75-18.752c-0.746-0.747-2.067-0.747-2.814,0L15.297,28.431
          c-0.373,0.373-0.583,0.88-0.583,1.407c0,0.527,0.21,1.034,0.583,1.407L34.05,49.998L15.294,68.753
          c-0.373,0.374-0.583,0.88-0.583,1.407c0,0.528,0.21,1.035,0.583,1.407l13.136,13.137c0.373,0.373,0.881,0.583,1.41,0.583
          c0.525,0,1.031-0.21,1.404-0.583l18.755-18.755l18.756,18.754c0.389,0.388,0.896,0.583,1.407,0.583c0.511,0,1.019-0.195,1.408-0.583
          l13.138-13.137C85.484,70.789,85.484,69.53,84.707,68.752z"
        />
      </svg>
      <ContextMenu
        ref={refContextMenu}
        height={
          restrictedLayoutState.toggleContextMenu
            ? '3px'
            : initialContextMenuHeight
        }
      >
        <Draggy onMouseDown={handleMouseButtonEvents}></Draggy>
        {restrictedLayoutState.contextMenuContent}
      </ContextMenu>
    </>
  )
}
