import React from 'react'
import PropTypes from 'prop-types'
import * as logic from './sanitizationRuleLogic'
import { Form } from 'components/Form'
import * as Yup from 'yup'
import { InputComponent } from 'components/InputComponent'
import { Button } from 'components/Button'

import {
  ContainerFormSanitizacao
} from './style'

export function SanitizationRuleModal ({
  setSanitizationRules,
  showSanitizationRuleModal,
  setShowSanitizationRuleModal
}) {
  const sanitizationRuleInitialValues = {
    nome: '',
    tipo: '1',
    regex_match: '',
    regex_extract: ''
  }

  const handleSubmitSanitizationRule = (values) => {
    logic.handleAddRegraSanitizacao(
      values.nome,
      values.tipo,
      values.regex_match,
      values.regex_extract,
      setSanitizationRules
    )
    setShowSanitizationRuleModal(false)
  }

  const schemaValidateSanitization = Yup.object().shape({
    nome: Yup.string()
      .required('Nome não pode ser vazio')
      .min(2, 'Nome precisa ter ao menos 2 caracteres'),
    regex_match: Yup.string()
      .required('Regex Match não pode ser vazio')
      .min(2, 'Regex Match precisa ter ao menos 2 caracteres')
      .test('checkRegexStart', 'Regex Match deve começar com "/"', (regex) => {
        if (regex !== '' && !window.utils.isFalsy(regex)) {
          regex = regex.split('/')
          if (regex[0] !== '') {
            return false
          }
          return true
        }
      }).test('checkRegexModifier', 'Regex Match deve conter um modificador', (regex) => {
        if (regex !== '' && !window.utils.isFalsy(regex)) {
          regex = regex.split('/')
          const modificator = regex.pop()

          if (!modificator) {
            return false
          }
          return true
        }
      }),
    regex_extract: Yup.string()
      .required('Regex Extract não pode ser vazio')
      .min(2, 'Regex Extract precisa ter ao menos 2 caracteres')
      .test('checkRegexStart', 'Regex Extract deve começar com "/"', (regex) => {
        if (regex !== '' && !window.utils.isFalsy(regex)) {
          regex = regex.split('/')
          if (regex[0] !== '') {
            return false
          }
          return true
        }
      }).test('checkRegexModifier', 'Regex Extract deve conter um modificador', (regex) => {
        if (regex !== '' && !window.utils.isFalsy(regex)) {
          regex = regex.split('/')
          const modificator = regex.pop()

          if (!modificator) {
            return false
          }
          return true
        }
      })
  })

  return (
    <ContainerFormSanitizacao show={showSanitizationRuleModal}>
  <header>
    <h3>Criar Nova Regra</h3>
    <a title="Fechar Janela" onClick={() => setShowSanitizationRuleModal(false)} >X</a>
  </header>
  <Form
    handleSubmit={handleSubmitSanitizationRule}
    initialValues={sanitizationRuleInitialValues}
    schemaValidate={schemaValidateSanitization}
    reset={true}
  >
    <InputComponent label='Nome:' type='text' name='nome' />
    <InputComponent label='Tipo:' type='select' name='tipo'>
      {logic.getSanitizationRulesTypes().map((sanitizationRuleType, index) => {
        return <option key={index} value={sanitizationRuleType.id}>{sanitizationRuleType.nome}</option>
      })}
    </InputComponent>
    <InputComponent label='Regex Match:' type='text' name='regex_match'/>
    <InputComponent label='Regex Extract:' type='text' name='regex_extract'/>
    <Button type='submit' value='Enviar' />
  </Form>
</ContainerFormSanitizacao>
  )
}

SanitizationRuleModal.propTypes = {
  setSanitizationRules: PropTypes.func,
  showSanitizationRuleModal: PropTypes.bool,
  setShowSanitizationRuleModal: PropTypes.func
}
