import styled from 'styled-components'

export const Wrapper = styled.section`
  position: relative;
  width: 'auto';
  margin: 0;
  flex: auto;
  > input {
    width: 100%;
  }
`

export const StyledMenu = styled.ul`
  position: absolute;
  top: 35px;
  left: 0;
  background: whitesmoke;
  border-radius: 10px;
  border: ${({ openMenu, showError }) =>
    openMenu || showError ? '1px solid #e7e7e7;' : 'none'};
  width: 100%;
  height: ${({ openMenu, showError, showLoader }) => {
    if (openMenu || showLoader) return `auto`
    if (showError) return `120px`
    return 0
  }};
  max-height: 200px;
  padding: ${({ showError }) => (showError ? '10px' : 0)};
  list-style: none;
  overflow-y: ${({ showLoader }) => (showLoader ? 'none' : 'auto')};
  z-index: 2;

  &[data-hide='true'] {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`

export const Item = styled.li`
  color: #555555;
  font-size: 14px;
  padding: 13px;
  display: flex;
  position: relative;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;

  &[data-hide-item='true'] {
    display: none;
  }

  :hover {
    background: #e9e9e9;
  }

  ~ ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    background: #e4e4e4;
  }
`

export const StyledError = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  height: 100%;
  margin: 0 !important;

  &[data-hide-error='true'] {
    display: none;
  }

  > * {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: 14px;
  }

  button {
    background: var(--green);
    width: 100%;
    height: 50px;
    color: #fff;
    font-weight: bold;
    border-radius: 8px;
    text-transform: uppercase;
  }
`
