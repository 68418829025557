import React from 'react'
import { Button, Input } from '@plick-solucoes-contabeis/ui-components'
import styled from 'styled-components'

function mountTermoDeUsoNotification(
  termoLastVersion,
  acceptTermoDeUso,
  termoDeUsoCallback
) {
  const {
    title: termoTitle,
    body: termoBody,
    version
  } = { ...termoLastVersion }
  sessionStorage.setItem('termoDeUsoCheck', false)

  const termoDeUsoNotification = {
    header: { title: termoTitle },
    content: {
      title: [],
      body: [
        <TermoDeUsoWrapper
          key={'termo-uso-container'}
          dangerouslySetInnerHTML={{ __html: termoBody }}
        />,
        <Input
          key={'termo-uso-input'}
          type={'Checkbox'}
          id={'termo-uso-input'}
          name={'termo-uso-input'}
          title={'Li e concordo com os termos de uso'}
          style={{ margin: '25px 0 15px 0' }}
          onChange={handleInputChange}
        />
      ]
    },
    footer: {
      body: [
        <Button
          disabled={!sessionCheck()}
          key={'termo-uso-button'}
          size={'full'}
          style={{
            backgroundColor: !sessionCheck()
              ? 'hsl(0, 0%, 90%)'
              : 'hsl(155, 100%, 37%)',
            color: 'hsl(0,100%,100%)'
          }}
          onClick={handleButtonClick}
        >
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Enviar</span>
        </Button>
      ]
    },
    configs: { header: { hideCloseButton: true } }
  }

  function sessionCheck() {
    return sessionStorage.getItem('termoDeUsoCheck') === 'true'
  }

  function handleButtonClick() {
    const checked = sessionCheck()
    if (checked) {
      acceptTermoDeUso({ version: version })
        .then((response) => {
          sessionStorage.setItem('termoDeUsoCheck', false)
          window.eventBus.dispatch('event-modal-close')
          termoDeUsoCallback(true)
        })
        .catch((error) => {
          termoDeUsoCallback(false)
          console.error(error)
        })
    }
  }

  function handleInputChange(event) {
    const checked = event.target.checked
    if (checked) {
      sessionStorage.setItem('termoDeUsoCheck', true)
    } else {
      sessionStorage.setItem('termoDeUsoCheck', false)
    }
    termoDeUsoNotification.footer.body = [
      <Button
        disabled={!sessionCheck()}
        key={'termo-uso-button'}
        size={'full'}
        style={{
          backgroundColor: !sessionCheck()
            ? 'hsl(0, 0%, 90%)'
            : 'hsl(155, 100%, 37%)',
          color: 'hsl(0,100%,100%)'
        }}
        onClick={handleButtonClick}
      >
        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Enviar</span>
      </Button>
    ]
    window.eventBus.dispatch('event-modal-notification', termoDeUsoNotification)
  }
  window.eventBus.dispatch('event-modal-notification', termoDeUsoNotification)
}

const TermoDeUsoWrapper = styled.article`
  & h3 {
    margin-block: 25px;
  }

  & p {
    margin-block: 15px;
  }

  & ol {
    padding: 0 40px;
  }

  & li {
    list-style-type: inherit;
    margin-block: 15px;
  }
`

export const termoDeUsoNotificationEventHelpers = {
  mountTermoDeUsoNotification
}
