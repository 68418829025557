import React from 'react'
import PropTypes from 'prop-types'
import icones from './Icones'

function ResponsavelCell({ value }) {
  if (value) {
    return (
      <div>
        <icones.IconeResponsavelSim />
      </div>
    )
  } else {
    return (
      <div>
        <icones.IconeResponsavelNao />
      </div>
    )
  }
}

ResponsavelCell.propTypes = {
  value: PropTypes.bool
}

export default ResponsavelCell
