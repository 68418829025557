import React from 'react'
import PropTypes from 'prop-types'

function ContatoRelacoesCell({ type, value }) {
  let nomeRelacao = '---'
  switch (type) {
    case 'Empresa':
      if (value?.length > 0) {
        nomeRelacao = value[0].nome
      }
      break
    case 'Escritorio':
      if (value?.length > 0) {
        const escritorio = value[0].escritorio
        const empresa = value[0].empresa

        if (escritorio?.length > 0) {
          nomeRelacao = value[0].escritorio[0].nome
        } else if (empresa?.length > 0) {
          nomeRelacao = value[0].empresa[0].escritorio.nome
        }
      }
      break
  }
  return <span>{nomeRelacao}</span>
}

ContatoRelacoesCell.propTypes = {
  value: PropTypes.array,
  type: PropTypes.string
}
export default ContatoRelacoesCell
