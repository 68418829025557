import styled from 'styled-components'

export const CamposWrapper = styled.div`
  padding: 25px;
  margin: 0 !important;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & div {
    margin: 0 !important;
  }
`
export const CampoWrapper = styled.div`
  flex: 0 0 calc(33.3% - 20px);
  border: 1px solid black;
  border-radius: 5px;
  *:disabled {
    opacity: 1;
  }

  ${({ isDefault }) => {
    if (isDefault) {
      return `
        opacity: 0.7;
        & > header {
          background-color: hsl(0,0%,58%);
        }
      `
    }
  }}
`

export const CampoHeader = styled.header`
  background-color: var(--background-medium-blue);
  color: var(--text-color-light);
  font-weight: bold;
  padding: 5px;
  text-align: center;
  border-radius: 5px 5px 0 0;
`

export const CampoInputsContainer = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  & > div {
    width: 100%;
    display: flex;

    & > label {
      display: block;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .toggler-label {
      cursor: pointer;
    }

    & > select {
      width: 100%;
    }
  }
`

export const InputWrapper = styled.div``

export const Toggler = styled.input`
  margin-right: 55px;
  width: 0;
  height: 0;
  border: none;
  outline: none;
  position: relative;

  &::after,
  &:checked::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 25px;
    top: 0;
    left: 0;
    background-color: hsl(0, 0%, 50%);
    border-radius: 100vmax;
    border: 2px solid hsl(0, 0%, 35%);
  }

  &:checked::after {
    background-color: var(--green);
  }

  &:disabled::after {
    background-color: hsl(0, 0%, 30%);
  }
  &:checked:disabled::after {
    background-color: var(--green) !important;
  }

  &::before,
  &:checked::before {
    content: '';
    position: absolute;
    width: 21px;
    height: 21px;
    top: 3.5px;
    left: 6px;
    z-index: 1;
    background-color: var(--white);
    border-radius: 50%;
    transition: transform ease 0.3s;
  }

  &:checked::before {
    transform: translateX(22px);
  }
`
