import styled from 'styled-components'

export const LinhaContainerMain = styled.section`
  margin: 25px 0;
  > * {
    margin-left: 41px;
  }
`
export const LinhaContainerHeader = styled.header`
  display: flex;
  align-items: center;
  background-color: var(--background-light-blue);
  margin-left: 0;
  padding: 15px 0;
  gap: 25px;

  button {
    background-color: var(--background-unfocus);
    color: #7a8286;
    display: flex;
    align-items: center;
    padding: 7px 15px;
    border-radius: 8px;
    gap: 10px;
    font-weight: bold;
    cursor: pointer;
    border: 2px solid #7a8286;
  }
  button:hover {
    background-color: #7a8286;
    color: var(--background-unfocus);
  }
  button:hover > svg path {
    fill: var(--white);
  }

  h3 {
    margin-left: 41px;
  }
`

export const ColumnsContainer = styled.div`
  display: grid;
  background-color: #ddd;
  max-width: max-content;
  margin: 25px 70px;
  overflow: auto;
  grid-template-rows: 35px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(${(props) => props.columns}, 130px);
  border: 1px solid #ddd;
  gap: 1px;
`

export const ColumnsContainerHeader = styled.div`
  height: 35px;
  background-color: #3fa1d8;
  color: var(--white);
  display: grid;
  place-items: center;
`

export const ColumnsContainerCell = styled.div`
  background-color: ${(props) =>
    props.isEven ? 'var(--background-unfocus)' : 'var(--white)'};
  height: 60px;
  display: flex;
  gap: 13px;
  align-items: center;
  justify-content: center;

  :hover > button {
    display: grid;
  }

  svg {
    width: 20px;
    height: 20px;
  }
  button {
    display: none;
    place-items: center;
    background-color: #c6e6ff;
    border: 2px solid #3fa1d8;
    padding: 5px 10px;
    border-radius: 8px;
  }
  button:hover {
    background-color: #d4f4e7;
    border-color: var(--green);
  }
  button:hover > svg path {
    fill: var(--green);
  }
  button:nth-child(2):hover {
    background-color: hsl(14, 78%, 80%);
    border-color: hsl(14, 78%, 40%);
  }
  button:nth-child(2):hover > svg path {
    fill: hsl(14, 78%, 40%);
  }
`
