import React from 'react'
import PropTypes from 'prop-types'

import BackgroundK from 'assets/Images/default_background_k_logo.svg'
import BackgroundTopPattern from 'assets/Images/default_background_top_pattern.svg'
import BackgroundBottomPattern from 'assets/Images/default_background_bottom_pattern.svg'
import Logo from 'assets/Images/p_logo.svg'

import { Wrapper, Main, Background, Content, Trademark } from './style'

function Default({ children }) {
  return (
    <Wrapper>
      <img className="p-logo" src={Logo} />
      <Main>
        <Content>{children}</Content>
      </Main>
      <Background>
        <img className="background-top-pattern" src={BackgroundTopPattern} />
        <img className="background-k-logo" src={BackgroundK} />
        <img
          className="background-bottom-pattern"
          src={BackgroundBottomPattern}
        />
      </Background>
      <Trademark>© Plick {new Date().getFullYear()}</Trademark>
    </Wrapper>
  )
}

Default.propTypes = {
  children: PropTypes.element.isRequired
}

export default Default
