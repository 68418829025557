import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const TipoPessoaFakeButton = styled.p`
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  border-radius: 7px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  width: 30px;
  height: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ContainerCentralizador = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

function TipoPessoaCell({ value }) {
  if (value === 'J') {
    return (
      <ContainerCentralizador title="Jurídica">
        <TipoPessoaFakeButton bgcolor={'var(--green)'}>J</TipoPessoaFakeButton>
      </ContainerCentralizador>
    )
  } else if (value === 'F') {
    return (
      <ContainerCentralizador title="Fisica">
        <TipoPessoaFakeButton bgcolor={'var(--green)'}>F</TipoPessoaFakeButton>
      </ContainerCentralizador>
    )
  } else {
    return (
      <ContainerCentralizador>
        <TipoPessoaFakeButton bgcolor={'#f30'}>X</TipoPessoaFakeButton>
      </ContainerCentralizador>
    )
  }
}

TipoPessoaCell.propTypes = {
  value: PropTypes.string
}
export default TipoPessoaCell
