import React from 'react'
import PropTypes from 'prop-types'
import icones from './Icones'

function AtivoCell({ value }) {
  if (value) {
    return (
      <div title="Ativo">
        <icones.IconeResponsavelSim />
      </div>
    )
  } else {
    return (
      <div title="Inativo">
        <icones.IconeResponsavelNao />
      </div>
    )
  }
}

AtivoCell.propTypes = {
  value: PropTypes.bool
}
export default AtivoCell
