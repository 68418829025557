import React from 'react'
import PropTypes from 'prop-types'
import { LoaderContainer } from 'components/EventLoader/styles'
import { TableLoaderWrapper } from './style'

export default function TableLoader(display = false) {
  return (
    <TableLoaderWrapper id={'table-loader-wrapper'}>
      <LoaderContainer>
        <svg
          viewBox="-60 0 660 520"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="layer1"
            d="M111.645 342.915 L471.193 -16.8778 L542.964 54.957 L109.671 488.522 H 1.5 V 70.9333 H 109.084 Z"
          />
          <path
            className="layer2"
            d="M111.645 342.915 L471.193 -16.8778 L542.964 54.957 L109.671 488.522 H 1.5 V 70.9333 H 109.084 Z"
          />
          <path
            className="layer3"
            d="M111.645 342.915 L471.193 -16.8778 L542.964 54.957 L109.671 488.522 H 1.5 V 70.9333 H 109.084 Z"
          />
          <path
            className="leg"
            d="M373.418 374.102C454.623 399.188 518.452 461.241 543.068 539.5H 427.874C403.731 487.808 354.281 451.974 296.856 450.712L373.418 374.102Z"
          />
        </svg>
        <strong>Carregando...</strong>
      </LoaderContainer>
    </TableLoaderWrapper>
  )
}

TableLoader.propTypes = {
  display: PropTypes.bool
}
