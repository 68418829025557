const cnpjCpfFormat = (value) => {
  if (value.length === 11) {
    const formattedValue = value.replace(
      /(^\d{3})(\d{3})(\d{3})(\d{2}$)/g,
      '$1.$2.$3-$4'
    )

    return formattedValue
  }
  if (value.length === 14) {
    const formattedValue = value.replace(
      /(^\d{2})(\d{3})(\d{3})(\d{4})(\d{2}$)/g,
      '$1.$2.$3/$4-$5'
    )

    return formattedValue
  }

  return value
}

const cnpjFormat = (value) => {
  const formattedValue = value.replace(
    /(^\d{2})(\d{3})(\d{3})(\d{4})(\d{2}$)/g,
    '$1.$2.$3/$4-$5'
  )

  return formattedValue
}

const phoneFormat = (value) => {
  let formattedValue
  if (value.length < 11) {
    formattedValue = value.replace(/(^\d{2})(\d{4})(\d{4}$)/g, '($1) $2-$3')
  } else {
    formattedValue = value.replace(
      /(^\d{2})(\d{1})(\d{4})(\d{4}$)/g,
      '($1) $2 $3-$4'
    )
  }

  return formattedValue
}

const dateTimeString = (value) => {
  const timeStampIndex = value.indexOf('T')
  const newString = value.slice(0, timeStampIndex)

  const formattedValue = newString.replace(
    /(\d{4})-(\d{2})-(\d{2})/g,
    '$3/$2/$1'
  )

  return formattedValue
}

export { cnpjCpfFormat, cnpjFormat, phoneFormat, dateTimeString }
