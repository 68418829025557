import styled, { css } from 'styled-components'
// import { Field } from 'formik'

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 5px;
  gap: 25px;

  ${(props) => props.customStyle}
`

export const Label = styled.label`
  font-size: 1.2em;
  font-weight: 500;
  color: var(--text-color-dark);

  ${(props) =>
    props.smallFont &&
    css`
      font-size: 1rem;
    `};
`

export const InputStyle = styled.input`
  background: var(--white);
  border: 1px solid var(--green);
  box-sizing: border-box;
  border-radius: 5px;
  height: 37px;
  width: 100%;

  &:focus {
    outline: none;
    border: 2px solid var(--blue);
  }
  &[type='text'] {
    padding-left: 10px;
    border: solid 1px var(--green);
  }
  &[type='number'] {
    align-self: flex-start;
    height: 30px;
    padding-left: 10px;
  }
  &[type='password'] {
    padding-left: 10px;
  }
`

export const ErrorMessage = styled.p`
  font-size: 0.88rem;
  bottom: 0;
  transform: translateY(calc(100% + 1px));
  color: #ff0000;
  margin-top: 5px;
  position: absolute;
`

export const ToolTipContainer = styled.div`
  position: relative;
  background-color: #666;
  color: var(--white);
  font-weight: bold;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  align-self: flex-start;
  display: grid;
  place-content: center;
  cursor: help;

  span {
    position: absolute;
    background-color: #333;
    opacity: 0;
    padding: 10px;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%) scale(0.1);
    border-radius: 5px;
    font-size: 1rem;
    text-align: center;
    min-width: 300px;
    white-space: pre-line;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }

  &:hover span {
    transform: translate(calc(100% + 25px), -50%) scale(1);
    opacity: 0.8;
  }
`
export const SelectDiv = styled.div`
  position: relative;

  > select {
    position: relative;
    background: var(--white);
    border: 1px solid var(--green);
    box-sizing: border-box;
    border-radius: 5px;
    padding-right: 35px;
    height: 30px;
    width: 100%;
  }

  ::after {
    position: absolute;
    pointer-events: none;
    content: '';
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='15px'%3E%3Ctext x='0' y='10' fill='white'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.4em 0.6em;
    background-position: 120% 50%;
    width: 30px;
    height: 100%;
    right: 0px;
    bottom: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--green);
  }
`
export const CheckBoxField = styled.input`
  width: 37px;
  height: 37px;
`

export const PasswordEye = styled.img`
  position: absolute;
  top: calc(100% - 22px);
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`
