import { checkAuthenticated } from 'helpers/jwtHelper'

const authStates = {
  authenticated: checkAuthenticated(),
  loading: false,
  loggedUserData: {}
}

function authReducer(state, action) {
  switch (action.type) {
    case 'setAuthenticated':
      return {
        ...state,
        authenticated: action.payload
      }
    case 'setLoading':
      return {
        ...state,
        loading: action.payload
      }
    case 'setLoggedUserData':
      return {
        ...state,
        loggedUserData: action.payload
      }
  }
}

export { authStates, authReducer }
