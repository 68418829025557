import React, { useState } from 'react'
import PropTypes from 'prop-types'
import api from 'services/api'
import { getUuid } from 'helpers/jwtHelper'

const niveisDeAcesso = [
  {
    apiValue: 'manage',
    label: 'Gerenciar',
    color: 'orange'
  },
  {
    apiValue: 'read',
    label: 'Acessar',
    color: 'green'
  },
  {
    apiValue: 'none',
    label: 'Nenhum',
    color: 'red'
  }
]

// adicionar props.outerState com default none
function PermissionLevelOptions({ valores, reloadLoggedUserData }) {
  const [currentPermissionLevel, setCurrentPermissionLevel] = useState(
    valores.permissionLevel
  )
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {niveisDeAcesso.map((nivelDeAcesso, index) => (
        <button
          style={{
            color: 'white',
            backgroundColor: `${
              currentPermissionLevel === nivelDeAcesso.apiValue
                ? nivelDeAcesso.color
                : '#ccc'
            }`,
            padding: '5px 10px',
            borderRadius: '5px'
          }}
          key={index}
          onClick={async () => {
            const body = {
              featureName: valores.featureName,
              feature_id: valores.feature_id,
              usuario_id: valores.usuario_id,
              valor: nivelDeAcesso.apiValue
            }
            api.updateUserPermissions(body).then(() => {
              setCurrentPermissionLevel(nivelDeAcesso.apiValue)
              if (body.usuario_id === getUuid()) {
                reloadLoggedUserData()
              }
            })
          }}
        >
          {nivelDeAcesso.label}
        </button>
      ))}
    </div>
  )
}

PermissionLevelOptions.propTypes = {
  valores: PropTypes.object,
  reloadLoggedUserData: PropTypes.func
}
export default PermissionLevelOptions
