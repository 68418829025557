import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const Container = styled.section`
  border: 1 px solid gray;
  max-height: calc(100% - 100px);
`

export const Table = styled.ul`
  background-color: var(--white);

  & > li:nth-of-type(odd) {
    background: #f3f3f3;
  }
`
export const Row = styled.li`
  display: grid;
  grid-auto-rows: 2.5rem;
  grid-auto-columns: minmax(100px, 1fr);
  grid-auto-flow: column;
  padding: 0 20px;

  height: 2.5rem;
  align-items: center;
  font-size: 12px;
  color: #7a8286;

  & > * {
    word-wrap: break-word;
    flex: 1;
    text-align: center;
  }

  & > div:not([role='tooltip']),
  & > span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const TableHeaderRow = styled(Row)`
  display: grid;
  grid-auto-rows: 2.5rem;
  grid-auto-columns: minmax(100px, 1fr);
  grid-auto-flow: column;

  color: hsl(215, 89%, 14%);
  font-size: 14px;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 2;
  margin: 0 0 10px;
  border: 2px solid #f1f1f1;
  border-radius: 10px;
  margin-bottom: 10px;
`

export const HeaderWord = styled.h5`
  text-align: center;
`

export const Button = styled.button`
  background-color: transparent;
  border-radius: 5px;
  width: 32px;
  height: 30px;
  margin-right: 5px;

  &:hover {
    border: solid 1px var(--background-medium-blue);
  }
`

export const ButtonAdd = styled.button`
  color: var(--blue);
  background-color: ${(props) => (props.color ? props.color : 'var(--green)')};
  font-size: 1rem;
  font-weight: bold;
  height: 2.5rem;
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span {
    margin-right: 5px;
  }
`

export const ButtonConfirmar = styled(ButtonAdd)`
  color: white;
  font-weight: normal;
`

export const ButtonCancelar = styled(ButtonAdd)`
  color: white;
  font-weight: normal;
`

const SubmitButtonStyle = styled(ButtonAdd)`
  min-width: 100px;
  color: var(--blue);
  padding-bottom: 5px;
  background-color: var(--green);
`

export const SubmitButton = (props) => {
  return <SubmitButtonStyle>{props.label}</SubmitButtonStyle>
}

SubmitButton.propTypes = {
  label: PropTypes.string
}

export const TableLoaderWrapper = styled.div`
  height: 100%;
  display: grid;
  justify-items: center;
  margin-right: 41px;
  margin-top: 50px;

  & svg {
    width: 100px;
    height: 100px;
  }
`
