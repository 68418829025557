import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getAllRegrasSanitizacao } from '../SanitizationRuleForm/sanitizationRuleLogic'
import * as logic from '../../subPages/LinhasEntrada/linhaEntradaLogic'
import { Form } from 'components/Form'

import * as Yup from 'yup'
import { InputComponent } from 'components/InputComponent'
import { SanitizationRuleForm } from '../SanitizationRuleForm'
import { SanitizationRuleModal } from '../SanitizationRuleForm/SanitizationRuleModal'
import { ValidationRulesForm } from '../ValidationRulesForm'

import {
  FormContainer,
  Button,
  CheckBoxWrapper
} from '../../subPages/style'

import {
  FormTabsContainer,
  FormTabs,
  CellFormSection,
  ButtonNext,
  ValidationRulesContainer
} from './style'

export function ColunaForm ({
  updateColunaInitialValues,
  setColumns
}) {
  const [selectedForm, setSelectedForm] = useState('dados')
  const [sanitizationRules, setSanitizationRules] = useState([])
  const [showSanitizationRuleModal, setShowSanitizationRuleModal] = useState(false)

  useEffect(async () => {
    await getAllRegrasSanitizacao(setSanitizationRules)
  }, [])

  const handleSubmitColuna = (values) => {
    logic.editColumn(values, setColumns)
    setSelectedForm('dados')
  }

  const schemaValidateColuna = Yup.object().shape({
    nome: Yup.string().required('Nome não pode ser vazio'),
    char_start_position: Yup.number().optional().min(0, 'Deve ser maior ou igual a zero').integer('Deve ser numérico'),
    char_end_position: Yup.number().optional().min(0, 'Deve ser maior ou igual a zero').integer('Deve ser numérico')
  })

  return (
    <>
        <FormTabsContainer>
          <FormTabs selected={selectedForm === 'dados'} onClick={ () => setSelectedForm('dados')} >Dados da Coluna</FormTabs>
          <FormTabs selected={selectedForm === 'sanitizacao'} onClick={ () => setSelectedForm('sanitizacao')} >Sanitização</FormTabs>
          <FormTabs selected={selectedForm === 'validacao'} onClick={ () => setSelectedForm('validacao')} >Validação</FormTabs>
        </FormTabsContainer>
        <FormContainer>
          <header>
            <h3>
              {selectedForm === 'dados' ? `Editar dados da célula na Linha-${updateColunaInitialValues.line} Coluna-${updateColunaInitialValues.column}` : ''}
              {selectedForm === 'sanitizacao' ? 'Regras de sanitização ' : ''}
              {selectedForm === 'validacao' ? 'Regras de validação' : ''}
            </h3>
          </header>
          <div style={{ display: selectedForm === 'validacao' ? 'none' : 'block' }}>
            <Form
              handleSubmit={handleSubmitColuna}
              initialValues={updateColunaInitialValues}
              schemaValidate={schemaValidateColuna}
              reset={false}
            >
              <CellFormSection selected={selectedForm === 'dados'}>
                <InputComponent label='Nome da linha:' type='text' name='nome'/>
                <InputComponent label='Caracter inicial:' type='number' name='char_start_position' min={0} />
                <InputComponent label='Caracter final:' type='number' name='char_end_position' min={0} />

                <ButtonNext type='button' onClick={ () => setSelectedForm('sanitizacao')}>Próximo</ButtonNext>
              </CellFormSection>
              <CellFormSection selected={selectedForm === 'sanitizacao'}>
                <SanitizationRuleForm
                  sanitizationRules={sanitizationRules}
                  setShowSanitizationRuleModal={setShowSanitizationRuleModal}
                />
                <CheckBoxWrapper>
                    <InputComponent label="Descartar a Linha se Nulo" type='checkbox' name='discard_line_when_null' />
                </CheckBoxWrapper>

                <Button type='submit' style={{ marginTop: '30px' }}>
                    Editar
                </Button>
              </CellFormSection>
              </Form>
            </div>
              <CellFormSection selected={selectedForm === 'validacao'}>
                <ValidationRulesContainer>
                  <ValidationRulesForm
                    updateElementInitialValues={updateColunaInitialValues}
                    elementType="coluna"
                  />
              </ValidationRulesContainer>
            </CellFormSection>
        </FormContainer>
        <SanitizationRuleModal
          setSanitizationRules={setSanitizationRules}
          showSanitizationRuleModal={showSanitizationRuleModal}
          setShowSanitizationRuleModal={setShowSanitizationRuleModal}
        />
      </>
  )
}

ColunaForm.propTypes = {
  updateColunaInitialValues: PropTypes.object,
  setColumns: PropTypes.func
}
