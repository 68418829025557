import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const FakeButton = styled.p`
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  border-radius: 7px;
  width: 100px;
  height: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

function StatusSistemaContabilCell({ value }) {
  switch (value) {
    case 'em_analise':
      return (
        <Container>
          <FakeButton bgcolor={'#FAE847'} color={'#7A8286'}>
            em análise
          </FakeButton>
        </Container>
      )
    case 'suportado':
      return (
        <Container>
          <FakeButton
            bgcolor={'#85D272'}
            color={'var(--background-medium-blue)'}
          >
            suportado
          </FakeButton>
        </Container>
      )
    case 'nao_suportado':
      return (
        <Container>
          <FakeButton
            bgcolor={'var(--text-unfocus)'}
            color={'var(--background-unfocus)'}
          >
            não suportado
          </FakeButton>
        </Container>
      )
    default:
      return (
        <Container>
          <FakeButton bgcolor={'#FA2247'}>Status Inexistente</FakeButton>
        </Container>
      )
  }
}

StatusSistemaContabilCell.propTypes = {
  value: PropTypes.string
}
export default StatusSistemaContabilCell
