/* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useMemo,
  useLayoutEffect,
  useRef
} from 'react'
import PropTypes from 'prop-types'
import api from 'services/api'
import useNonInitialEffect from 'hooks/useNonInitialEffect'
import * as Styled from './style'
import TableHeader from './subcomponents/TableHeader'
import Cell from './subcomponents/Cell'
import Paginator from 'components/Paginator'
import Modal, { Body, Header } from 'components/Modal'
import MainTopBarContent from 'components/MainTopBarContent'
import history from 'services/history'
import { transformData } from './logic/utils'
import { dateTimeString } from 'helpers/formatters'
import usePaginatorControl from 'components/Paginator/logic/hooks/usePaginatorControl'
import TableLoader from './TableLoader'
import { paginationFetcher } from 'components/Paginator/subComponents/paginationFetcher'
import {
  parseCnpj,
  parseCnpjCpf,
  parsePhone,
  parseTipoPessoa
} from 'helpers/inputsParser'
import FormCRUD from 'components/FormCRUD'

const ITEMSPORPAGINA = parseInt(process.env.REACT_APP_PER_PAGE)

const valuesToParse = new Set(['cpf_cnpj', 'telefone', 'cnpj'])
const valuesToParseMethods = {
  cpf_cnpj: (value) => {
    return parseCnpjCpf(value)
  },
  telefone: (value) => {
    return parsePhone(value)
  },
  cnpj: (value) => {
    return parseCnpj(value)
  },
  tipo_pessoa: (value) => {
    if (value) {
      return parseTipoPessoa(value)
    }
  }
}

export function Table(props) {
  const [fileiras, setFileiras] = useState(false)
  const modalConfig = useRef({
    title: '',
    initialValues: {}
  })
  const [modalVisible, setModalVisible] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [crudTimeStamp, setCrudTimeStamp] = useState(Date.now().toString())
  const [numberOfPages, setNumberOfPages] = useState(0)

  const [listUrl, setListUrl] = useState('')
  const [showTableLoader, setShowTableLoader] = useState(true)
  const { paginatorControl, setPaginatorControl } = usePaginatorControl()

  const handlePagination = async () => {
    const data = await paginationFetcher(
      numberOfPages,
      ITEMSPORPAGINA,
      props.apiFetcher,
      listUrl
    )
    setActivePage(data.numberOfPages)
  }

  const addReq = async (apiFetcher, body) => {
    Object.entries(body).forEach((attribute) => {
      const attributeName = attribute[0]
      const attributeValue = attribute[1]
      // Convert null string to null value
      if (attributeValue === 'null') {
        body[attributeName] = null
      }
      // Convert boolean to numeric
      if (typeof attributeValue === 'boolean') {
        body[attributeName] = attributeValue & 1
      }
    })

    return api[`add${apiFetcher}`](body)
      .then((response) => response)
      .catch((error) => error.response)
  }

  const handleSubmit = async (props, values, close) => {
    const parsed = {}

    for (const valor in values) {
      const valorLowerCase = valor.toLowerCase()

      // if (typeof values[valor] === typeof []) {
      // parsed[valorLowerCase] = JSON.stringify(values[valor])
      // parsed[valorLowerCase] = values[valor]
      // } else {
      parsed[valorLowerCase] = values[valor]

      const hasValueToParse = valuesToParse.has(valorLowerCase)

      if (hasValueToParse) {
        parsed[valorLowerCase] = valuesToParseMethods[valorLowerCase](
          values[valorLowerCase]
        )
      }

      // }
    }
    parsed.tipo_pessoa = valuesToParseMethods.tipo_pessoa(values.cpf_cnpj)

    return addReq(props.apiFetcher, parsed)
      .then((response) => {
        const resultado = {}

        if (response.status === 201) {
          resultado.sucesso = true
          setCrudTimeStamp(Date.now().toString())
          close()
        }
        if (response.status === 422) {
          resultado.sucesso = false
          resultado.erros = response.data.errors
          throw resultado
        }
        return resultado
      })
      .catch((error) => error)
  }

  const editReq = async (apiFetcher, id, body) => {
    Object.entries(body).forEach((attribute) => {
      const attributeName = attribute[0]
      const attributeValue = attribute[1]
      // Convert null string to null value
      if (attributeValue === 'null') {
        body[attributeName] = null
      }
      // Convert boolean to numeric
      if (typeof attributeValue === 'boolean') {
        body[attributeName] = attributeValue & 1
      }
    })

    return api[`edit${apiFetcher}`](id, body)
      .then((response) => response)
      .catch((error) => error.response)
  }

  const handleEditSubmit = async (props, values, close) => {
    const parsed = {}
    const isPlick = Number(values.role_id) === 1 || Number(values.role_id) === 2

    for (const valor in values) {
      const valorLowerCase = valor.toLowerCase()

      // if (typeof values[valor] === typeof []) {
      // parsed[valorLowerCase] = JSON.stringify(values[valor])
      // parsed[valorLowerCase] = values[valor]
      // } else {
      parsed[valorLowerCase] = values[valor]

      const hasValueToParse = valuesToParse.has(valorLowerCase)

      if (hasValueToParse) {
        parsed[valorLowerCase] = valuesToParseMethods[valorLowerCase](
          values[valorLowerCase]
        )
      }

      if (isPlick && valorLowerCase === 'escritorio_id') {
        parsed[valorLowerCase] = ''
      }
      // }
    }

    return editReq(props.apiFetcher, props.apiID, parsed)
      .then((data) => {
        const resultado = {}

        if (data.status === 200 || data.status === 201) {
          resultado.sucesso = true
          setCrudTimeStamp(Date.now().toString())
          close()
        }

        if (data.status !== 200) {
          resultado.sucesso = false
          throw resultado
        }
        return resultado
      })
      .catch((error) => error)
  }

  const openModalFromHistoryLocation = () => {
    if (history.location.state?.modalConfig) {
      const { modalConfig: modalConfig_ } = history.location.state
      modalConfig.current = { ...modalConfig_ }

      setModalVisible(true)
      history.replace({})
    }
  }

  const renderModalBody = ({ close }) => {
    if (!modalConfig.current.externalComp) {
      return (
        <FormCRUD
          {...props}
          apiID={
            modalConfig.current.initialValues.id ??
            modalConfig.current.initialValues.uuid
          }
          rowValues={
            modalConfig.current.title.includes('Editar')
              ? modalConfig.current.initialValues
              : {}
          }
          handlePagination={handlePagination}
          handleSubmit={(props, values) => {
            if (modalConfig.current.title.includes('Editar')) {
              handleEditSubmit(props, values, close)
            } else {
              handleSubmit(props, values, close)
            }
          }}
        />
      )
    }

    return modalConfig.current.comp
  }

  // Monta MainTopBarContent com props.topBarItems
  useEffect(() => {
    if (props.dispatchRestrictedLayout) {
      props.dispatchRestrictedLayout({
        type: 'setMainTopBarContent',
        payload: (
          <MainTopBarContent
            items={props.topBarItems}
            setModalVisible={setModalVisible}
            setCrudTimeStamp={setCrudTimeStamp}
            apiFetcher={props.apiFetcher}
            listUrl={listUrl}
            setNumberOfPages={setNumberOfPages}
            headers={props.headers}
            perPage={ITEMSPORPAGINA}
            setActive={setActivePage}
            setRows={setFileiras}
            numberOfPages={numberOfPages}
            setLoading={(value) => setShowTableLoader(value)}
            setPaginatorControl={setPaginatorControl}
            crudFields={props.crudFields}
            modalConfig={modalConfig}
          />
        )
      })
    }
  }, [listUrl, props])

  useEffect(() => {
    if (typeof history.location?.state?.type !== 'undefined') {
      const historyState = history.location?.state
      setListUrl(`${historyState.type}`)
    } else {
      setListUrl(props.apiFetcher)
    }
  }, [history.location?.state])

  // popula a tabela na primeira renderização e caso alguma operação CRUD for realizada
  useNonInitialEffect(async () => {
    const data = await paginatorControl(
      activePage,
      ITEMSPORPAGINA,
      props.apiFetcher,
      listUrl
    )
    const result = transformData(data.rows || data)
    setFileiras(result)
    setNumberOfPages(data.numberOfPages)
  }, [crudTimeStamp, listUrl])

  const getValueFromRow = (data, dataProperty) => {
    const propertyName = dataProperty.toLowerCase().replace(/\s+/g, '_')
    let value = data[propertyName]

    switch (props.apiFetcher) {
      case 'Usuarios':
        if (dataProperty === 'Cargo') {
          value = data.role?.nome
        }
        if (dataProperty === 'Escritorio') {
          if (data.escritorio.length > 0) {
            value = data.escritorio[0]?.nome
          } else {
            value = '-'
          }
        }
        break
      case 'Escritorio':
        if (propertyName === 'sistema_contabil_padrao') {
          value = data.sistemacontabil.nome
        }
        if (propertyName === 'inicio_de_contrato') {
          data.inicio_contrato = data.inicio_contrato + 'T'
          value = dateTimeString(data.inicio_contrato)
        }
        break
      case 'Contatos':
        if (dataProperty === 'Escritorio') {
          value = [{ empresa: data.empresa, escritorio: data.escritorio }]
        }
        break
      case 'SistemaContabil':
        if (propertyName === 'extensão_dos_arquivos') {
          value = data.files_extension
        }
        break
    }

    return value
  }

  const Rows = useMemo(() => {
    return fileiras ? (
      fileiras.map((fileira, i) => {
        return (
          <Styled.Row key={i} className="table__row">
            {props.headers.map((header, index) => {
              return (
                <Cell
                  type={typeof header !== 'string' ? header.key : header}
                  CellComponent={
                    props.cellComponents[
                      typeof header !== 'string'
                        ? header.key
                        : header.toLowerCase().replace(/\s+/g, '_')
                    ]
                  }
                  setModalVisible={setModalVisible}
                  modalConfig={modalConfig}
                  value={getValueFromRow(
                    fileira,
                    typeof header !== 'string' ? header.key : header
                  )}
                  apiID={fileira.id ? fileira.id : fileira.uuid}
                  headers={props.headers}
                  actions={props.actions}
                  apiFetcher={props.apiFetcher}
                  setCrudTimeStamp={setCrudTimeStamp}
                  key={index}
                  rowValues={fileira}
                  crudFields={props.crudFields}
                />
              )
            })}
          </Styled.Row>
        )
      })
    ) : (
      <></>
    )
  }, [fileiras, props])

  useEffect(() => {
    if (activePage > numberOfPages && numberOfPages !== 0) {
      setActivePage(numberOfPages)
    }
  }, [numberOfPages])

  useEffect(() => {
    openModalFromHistoryLocation()
  }, [])

  useLayoutEffect(() => {
    if (showTableLoader) {
      setTimeout(() => {
        setShowTableLoader(false)
      }, 150)
    }
  }, [])

  if (fileiras && !showTableLoader) {
    return (
      <div style={{ marginRight: '41px', height: 'auto' }}>
        <TableHeader columns={props.headers} />
        <Styled.Container>
          <Styled.Table>{Rows}</Styled.Table>
          <Modal
            isVisible={modalVisible}
            onClose={() => setModalVisible(false)}
          >
            <Header>
              <h3>{modalConfig.current.title}</h3>
            </Header>
            <Body>
              {({ close, isVisible }) => {
                return isVisible && renderModalBody({ close })
              }}
            </Body>
          </Modal>
          {numberOfPages > 0 ? (
            <Paginator
              setRows={setFileiras}
              perPage={ITEMSPORPAGINA}
              active={activePage}
              setActive={setActivePage}
              apiFetcher={props.apiFetcher}
              listUrl={listUrl}
              numberOfPages={numberOfPages}
              paginatorControl={paginatorControl}
              setNumberOfPages={setNumberOfPages}
            />
          ) : (
            ''
          )}
        </Styled.Container>
      </div>
    )
  } else {
    return <TableLoader />
  }
}

Table.propTypes = {
  dispatchRestrictedLayout: PropTypes.func,
  topBarItems: PropTypes.array,
  crudFields: PropTypes.array,
  setMainTopBarContent: PropTypes.func,
  headers: PropTypes.array,
  actions: PropTypes.array,
  apiFetcher: PropTypes.string,
  cellComponents: PropTypes.object,
  apiID: PropTypes.number
}
