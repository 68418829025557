import styled from 'styled-components'
import { Button } from 'components/Button'

export const FormTabsContainer = styled.ul`
  display: flex;
  height: 40px;
  list-style-type: none;
  width: calc(90%);
  justify-self: center;
  margin-bottom: 5px;
  gap: 1px;
  grid-column: 1 / span 2;
  border-bottom: 2px solid #b9bbbc;
`

export const FormTabs = styled.li`
  position: relative;
  background-color: ${(props) =>
    props.selected ? 'var(--blue)' : 'var(--background-unfocus)'};
  color: ${(props) =>
    props.selected ? 'var(--white)' : 'var(--text-unfocus)'};
  font-weight: bold;
  padding: 10px 35px;
  cursor: pointer;

  :hover {
    background-color: ${(props) =>
      props.selected ? 'var(--blue)' : '#DDDDDD'};
    color: ${(props) =>
      props.selected ? 'var(--white)' : 'var(--text-unfocus)'};
  }

  :before {
    content: ${(props) => (props.selected ? '""' : 'none')};
    border-style: solid;
    border-width: 8px 8px 8px 0;
    border-color: transparent var(--blue) transparent transparent;
    position: absolute;
    top: calc(100% - 5px);
    left: 50%;
    transform: rotate(-90deg) translateY(-50%);
  }
`
export const CellFormSection = styled.section`
  display: ${(props) => (props.selected ? 'flex' : 'none')};
  grid-column: 1 / span 2;
  gap: 20px;
  flex-direction: column;
  width: 100%;

  > div {
    width: 100%;
    gap: 1px;
    grid-column: span 2;
    margin: 0;
  }
  > div:nth-child(n + 2) {
    grid-column: auto;
  }
  label {
    font-size: 1em;
  }
  input,
  select {
    height: 30px;
  }
  button[type='submit'] {
    width: 105px;
    height: 38px;
    align-self: flex-end;
  }
`

export const ButtonNext = styled.button`
  margin: 0;
  width: 105px;
  height: 38px;
  padding: 10px 20px;
  color: var(--white);
  background: var(--green);
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  align-self: flex-end;

  &:hover {
    filter: opacity(0.9);
  }
`

export const ValidationRulesContainer = styled.article`
  position: relative;
  padding: 5px 30px 0px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  form {
    display: grid;
    gap: 10px 30px;
    grid-template-rows: 50px auto auto;
    padding: 0 0 8px 0;
    > button {
      width: 105px;
      height: 38px;
      padding: 8px 30px;
      margin-bottom: 6px;
      grid-column: 2;
    }
  }
`

export const ButtonAddSanitization = styled(Button)`
  :hover {
    filter: opacity(0.9);
    color: var(--white);
  }
  :hover > svg path {
    fill: var(--white);
  }
`
