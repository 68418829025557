const parseCnpjCpf = (value) => {
  const cpf = value.match(/^\d{3}.\d{3}.\d{3}-\d{2}$/g)
  const cnpj = value.match(/^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}/g)

  if (cpf) {
    return value.replace(/(^\d{3})\.(\d{3})\.(\d{3})-(\d{2}$)/, '$1$2$3$4')
  }
  if (cnpj) {
    return value.replace(
      /(^\d{2})\.(\d{3})\.(\d{3})\/(\d{4})-(\d{2}$)/,
      '$1$2$3$4$5'
    )
  }
}

const parseTipoPessoa = (value) => {
  const cpf = value.match(/^\d{3}.\d{3}.\d{3}-\d{2}$/g)
  const cnpj = value.match(/^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}/g)

  return cpf ? 'F' : cnpj ? 'J' : ''
}

const parsePhone = (value) => {
  return value
    .replace(/(^\(\d{2}\))\s(\d{1})\s(\d{4})-(\d{4}$)/g, '$1$2$3$4')
    .replace(/\D/g, '')
}

const parseCnpj = (value) => {
  return value.replace(
    /(^\d{2})\.(\d{3})\.(\d{3})\/(\d{4})-(\d{2}$)/g,
    '$1$2$3$4$5'
  )
}

export { parseCnpjCpf, parseCnpj, parsePhone, parseTipoPessoa }
