import styled from 'styled-components'

export const FormContainer = styled.div`
  background-color: hsl(0, 0%, 96%);
  float: right;
  border-radius: 15px;
  width: 80%;
  justify-self: center;
  align-items: end;
  grid-column-start: ${(props) => props.gridColumnStart};
  height: min-content;
  padding-bottom: 10px;
  margin-top: 49px;

  header {
    background-color: var(--green);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    padding: 10px 30px;
    h3 {
      font-size: 1em;
      color: var(--white);
    }
  }

  form {
    padding: 15px 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fill, 50px);
    gap: 15px 30px;
    > div {
      gap: 1px;
      grid-column: span 2;
      margin: 0;
    }
    > div:nth-child(n + 3) {
      grid-column: auto;
    }
    label {
      font-size: 1em;
    }
    input,
    select {
      height: 30px;
    }
  }
`
