/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Tabs from 'components/Tabs'
import { useFormTabs } from '../logic/hooks/useFormTabs'

const tabsStyles = {
  container: `
    justify-content: flex-start;
    gap: 8px;
  `,
  activetabs: `
    background-color: var(--green); 
    color:var(--white);
  `,
  inactivetabs: `
    background-color: var(--background-unfocus);
    color: var(--text-unfocus);
  `
}

function FormTabs({ tabName, tabsList }) {
  const [selectedTab, setSelectedTab] = useState(tabsList[0].value)

  function onClickTab(value) {
    setSelectedTab(value)
  }

  useFormTabs({ tabName, tabsList, selectedTab })

  const tabs = tabsList.map((tab) => {
    return {
      ...tab,
      active: tabsStyles.activetabs,
      inactive: tabsStyles.inactivetabs,
      onClickTab: onClickTab
    }
  })

  return (
    <Tabs
      tabs={tabs}
      customTabContainerStyle={tabsStyles.container}
      selectedTab={selectedTab}
      initialTabValue={tabsList[0].value}
      tabName={tabName}
    />
  )
}

FormTabs.propTypes = {
  tabName: PropTypes.string,
  tabsList: PropTypes.array
}
export default FormTabs
