import styled from 'styled-components'
import { Button } from 'components/Button'
import { SelectDiv } from 'components/InputComponent/styles'

export const ButtonAdd = styled(Button)`
    align-self: flex-end;
`
export const HeaderFilter = styled.div`
    margin-left: auto;
    align-self: flex-end;
`

export const SelectWrapper = styled(SelectDiv)`
    min-width:200px;
`

export const MainTopBarHeaderButtons = styled.ul`
    display: flex;
    width:100%;
    align-items:center;
`
