import styled from 'styled-components'

export const ConsolidatedRulesButton = styled.a`
  position: absolute;
  bottom: calc(100% + 5px);
  right: 20px;
  text-align: center;
  padding: 5px 25px;
  font-weight: bold;
  cursor: pointer;
  color: var(--white);
  background-color: var(--blue);
  border-radius: 4px;
  &:hover {
    background-color: var(--background-medium-blue);
  }
`

export const ValidationRulesAdditionals = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
  > div {
    gap: 0px;
    margin: 0;
  }
`

export const ConsolidatedRulesModal = styled.article`
  display: ${(props) => (props.toggle === true ? 'block' : 'none')};
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  min-width: 750px;
  min-height: 500px;
  max-height: 700px;
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.2);
  overflow-y: overlay;

  header {
    font-size: 1.3em;
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
    > div {
      font-weight: bold;
      color: var(--white);
      border: 2px solid var(--white);
      padding: 2px 6px;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  > div {
    color: #222;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    padding: 15px 18px;
  }

  details {
    position: relative;
    text-align: start;
    box-shadow: 0 0 0 1px var(--blue), 0 0 0 2px hsl(0, 0%, 96%),
      0 0 0 3px var(--blue);
    border-radius: 3px;
    width: 731px;
    overflow-y: hidden;
    transition: height 0.5s ease, background-color 0.6s ease-in-out;

    &:not([open]) {
      height: 38px;
    }

    &[open] {
      height: 330px;
      background-color: var(--blue);
    }

    &[open] summary::before {
      color: var(--green);
      transform: rotate(180deg);
    }
    &[open] summary {
      color: var(--white);
    }

    > summary {
      position: relative;
      width: 670px;
      height: 38px;
      padding: 0 10px 0 25px;
      font-size: 1.13em;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::before {
        content: '▲';
        position: absolute;
        transform: rotate(90deg);
        left: 5px;
        top: 9px;
        transition: all 0.6s ease;
      }
    }
  }
  details > div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: calc(100% - 40px);
    top: 4px;
    font-weight: bold;
    border-radius: 8px;
    border: 2px solid hsl(0, 100%, 35%);
    padding: 3px 8px;
    cursor: pointer;
  }

  details[open] > div {
    border: 2px solid var(--white);
    background-color: hsl(0, 100%, 35%);
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 6px;
    list-style-type: none;
    padding: 8px 10px;
    font-size: 1em;
    background-color: hsl(215, 89%, 90%);
  }
  li {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-auto-flow: column;
    gap: 6px;
  }
  li * {
    background-color: var(--white);
    display: flex;
    align-items: center;
    border: 2px solid var(--blue);
    padding: 3px 5px;
    box-shadow: 0 0 0 1px hsl(215, 89%, 75%), 0 0 0 1.5px hsl(215, 89%, 0%);
  }
`

export const ColumnValidationRulesSection = styled.section`
  position: relative;
  box-shadow: 0 0 0 1px var(--blue), 0 0 0 3px hsl(0, 0%, 96%),
    0 0 0 4px var(--blue);
  margin: 5px 0;
  padding: 15px;
  height: fit-content;
  &::before {
    position: absolute;
    content: 'Parâmetros da Regra:';
    background-color: hsl(0, 0%, 96%);
    bottom: calc(100% - 6px);
    left: 3px;
    font-size: 0.9rem;
    padding: 0 4px;
  }

  > div {
    gap: 0;
  }
`
