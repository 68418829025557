/* eslint-disable react/prop-types */
import React from 'react'
import { mergeStyles } from 'styles/mergeStyles'
import { StyledDiv } from './style'

export default function Div({ children, ...props }) {
  const { flex, flow, flexValues } = props
  const { grid, columns, rows, area, gridColumn, gridRow } = props
  const {
    width,
    height,
    gap,
    justifyContent,
    alignItems,
    alignContent,
    style,
    as,
    ...otherProps
  } = props

  if (flex && grid) {
    throw new Error('Não é possível usar flex e grid juntos.')
  }

  return (
    <StyledDiv
      as={as}
      style={mergeStyles({
        display: flex ? 'flex' : grid ? 'grid' : null,
        flexFlow: flow,
        flex: flexValues,
        alignItems,
        alignContent,
        justifyContent,
        gridTemplateColumns: columns,
        gridTemplateRows: rows,
        gridArea: area,
        gridColumn,
        gridRow,
        gap,
        ...style
      })}
      {...otherProps}
    >
      {children}
    </StyledDiv>
  )
}
