import { LAYOUT_TYPES } from 'layout/Master/Constants/LayoutTypes'

export function setWrapperGrid(layoutType) {
  switch (layoutType) {
    case LAYOUT_TYPES.CLASSICAL:
      return `
        grid-template-rows: 45px calc(100vh - 45px);
        grid-template-areas:
        'aside header header'
        'aside content content';
      `
    case LAYOUT_TYPES.TYPE_1:
      return `
        grid-template-rows: 100vh;
        grid-template-areas:
        'aside content content';
      `
  }
}

export function setHeaderLayout(layoutType) {
  switch (layoutType) {
    case LAYOUT_TYPES.CLASSICAL:
      return `display: flex;`
    case LAYOUT_TYPES.TYPE_1:
      return `display: none;`
  }
}

export function setMainLayout(layoutType) {
  switch (layoutType) {
    case LAYOUT_TYPES.CLASSICAL:
      return `grid-template-rows: auto 1fr auto;`
    case LAYOUT_TYPES.TYPE_1:
      return `grid-template-rows: auto;`
  }
}

export function setMainContentMenuLayout(layoutType) {
  switch (layoutType) {
    case LAYOUT_TYPES.CLASSICAL:
      return `padding: 25px 0 50px 0;`
    case LAYOUT_TYPES.TYPE_1:
      return `
      min-height:100vh;
      justify-content: space-between;
      `
  }
}

export function setMainContexMenuLayout(layoutType) {
  switch (layoutType) {
    case LAYOUT_TYPES.CLASSICAL:
      return `display: grid;`
    case LAYOUT_TYPES.TYPE_1:
      return `display: none;`
  }
}
export function setFooterLayout(layoutType) {
  switch (layoutType) {
    case LAYOUT_TYPES.CLASSICAL:
      return `display: none;`
    case LAYOUT_TYPES.TYPE_1:
      return `display: flex;`
  }
}
