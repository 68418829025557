import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'components/Form'
import * as Yup from 'yup'
import { InputComponent } from 'components/InputComponent'

import {
  FormContainer,
  Button
} from '../../subPages/style'

export function LinhaForm ({
  linhaInitialValues,
  handleSubmitLinha
}) {
  const schemaValidateLinha = Yup.object().shape({
    nome: Yup.string().required('Nome não pode ser vazio'),
    row_lines: Yup.number().required('Qtd de Linhas não pode ser vazio').positive('Qtd de Linhas deve ser maior que zero').integer('Qtd de Linhas deve ser numérico'),
    row_columns: Yup.number().required('Qtd de Colunas não pode ser vazio').positive('Qtd de Colunas deve ser maior que zero').integer('Qtd de Colunas deve ser numérico')
  })

  return (
    <FormContainer gridColumnStart="2">
      <header>
        <h3>{linhaInitialValues.update ? 'Editar' : 'Criar nova'} linha</h3>
      </header>
      <Form
          handleSubmit={handleSubmitLinha}
          initialValues={linhaInitialValues}
          schemaValidate={schemaValidateLinha}
          reset={!linhaInitialValues.update}
        >
            <InputComponent label='Nome da linha:' type='text' name='nome'/>
            <InputComponent label='Qtd de Linhas:' type='number' name='row_lines' min={1} disabled={linhaInitialValues.update}/>
            <InputComponent label='Qtd de Colunas:' type='number' name='row_columns' min={1} disabled={linhaInitialValues.update}/>

            <Button type='submit' row='4' column='2'>
              {linhaInitialValues.update ? 'Editar' : 'Enviar' }
            </Button>
      </Form>
    </FormContainer>
  )
}

LinhaForm.propTypes = {
  linhaInitialValues: PropTypes.object,
  handleSubmitLinha: PropTypes.func
}
