import React from 'react'
import PropTypes from 'prop-types'

import {
  ButtonContainer
} from './style'

export default function BottomButtonsContainer ({
  justify = 'flex-end',
  children
}) {
  return (
    <ButtonContainer justify={justify}>
      {children}
    </ButtonContainer>)
}

BottomButtonsContainer.propTypes = {
  justify: PropTypes.string,
  children: PropTypes.any
}
