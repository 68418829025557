/* eslint-disable no-unused-vars */
import React, { useRef } from 'react'
import withFlexLabel from 'components/AutoComplete/logic/hoc/withFlexLabel'
import AutoComplete from 'components/AutoComplete'
import { useField } from 'formik'
import useNonInitialEffect from 'hooks/useNonInitialEffect'

const listaEstados = [
  { sigla: 'RO', nome: 'Rondônia' },
  { sigla: 'AC', nome: 'Acre' },
  { sigla: 'AM', nome: 'Amazonas' },
  { sigla: 'RR', nome: 'Roraima' },
  { sigla: 'PA', nome: 'Pará' },
  { sigla: 'AP', nome: 'Amapá' },
  { sigla: 'TO', nome: 'Tocantins' },
  { sigla: 'MA', nome: 'Maranhão' },
  { sigla: 'PI', nome: 'Piauí' },
  { sigla: 'CE', nome: 'Ceará' },
  { sigla: 'RN', nome: 'Rio Grande do Norte' },
  { sigla: 'PB', nome: 'Paraíba' },
  { sigla: 'PE', nome: 'Pernambuco' },
  { sigla: 'AL', nome: 'Alagoas' },
  { sigla: 'SE', nome: 'Sergipe' },
  { sigla: 'BA', nome: 'Bahia' },
  { sigla: 'MG', nome: 'Minas Gerais' },
  { sigla: 'ES', nome: 'Espírito Santo' },
  { sigla: 'RJ', nome: 'Rio de Janeiro' },
  { sigla: 'SP', nome: 'São Paulo' },
  { sigla: 'PR', nome: 'Paraná' },
  { sigla: 'SC', nome: 'Santa Catarina' },
  { sigla: 'RS', nome: 'Rio Grande do Sul' },
  { sigla: 'MS', nome: 'Mato Grosso do Sul' },
  { sigla: 'MT', nome: 'Mato Grosso' },
  { sigla: 'GO', nome: 'Goiás' },
  { sigla: 'DF', nome: 'Distrito Federal ' }
]

function InputUf() {
  const [field] = useField('uf')
  const inputRef = useRef()
  useNonInitialEffect(() => {
    inputRef.current.setValue(field.value)
  }, [field.value])

  return (
    <AutoComplete
      name="uf"
      title="UF"
      oneTimeRequest={true}
      helper={({ ref }) => {
        inputRef.current = ref
      }}
      withFlexLabel={true}
      extractKey={(item) => {
        return item.sigla
      }}
      createOptions={(data) => {
        if (data instanceof Array) {
          return data.map((item) => ({
            label: item.nome,
            value: item.sigla
          }))
        }
        return {
          label: data.nome,
          value: data.sigla
        }
      }}
      renderMenuItem={(value) => {
        return <span>{value.nome}</span>
      }}
      filter={(value, searchValue) => {
        return value.nome.match(new RegExp(`${searchValue.trim()}`, 'gi'))
      }}
      startSearch={() => {
        return listaEstados
      }}
    />
  )
}

export default withFlexLabel({ name: 'uf', title: 'UF' }, InputUf)
