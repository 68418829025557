import { withMaskedText } from 'components/MaskedText'
import { useField } from 'formik'
import React from 'react'
import { InputContainer, Label, TextFieldInput } from '../style'

const TextFieldMasked = withMaskedText(TextFieldInput)

// eslint-disable-next-line react/prop-types
export default function CreatedAtInput({ label, isUpdate, ...props }) {
  const [field] = useField(props)

  return (
    <InputContainer
      className={'InputContainer'}
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: '10px 0',
        alignItems: 'end'
      }}
    >
      <Label htmlFor={field.name} style={{ width: '50%' }}>
        {label}
      </Label>
      <TextFieldMasked
        type="text"
        autoComplete="off"
        autoFocus={label === 'Nome'}
        pattern="date"
        disabled={!isUpdate}
        {...props}
        style={{ width: '50%', marginLeft: '10px' }}
      />
    </InputContainer>
  )
}
