import styled from 'styled-components'

export const FeedbackContainer = styled.div`
  background-color: var(--white);
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`
export const FeedbackMessage = styled.h1`
  font-size: 1.5em;
`
