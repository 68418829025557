/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './style'
import RestrictedHeader from './SubComponents/RestrictedHeader'
import RestrictedMain from './SubComponents/RestrictedMain'
import SidebarWrapper from 'components/SideBar'

function Restricted({ children, layoutType }) {
  // const { EventModal } = useEventsModal()
  return (
    <Wrapper layoutType={layoutType}>
      {/* Componente do Header */}
      <RestrictedHeader layoutType={layoutType} />

      {/* Componente do SideBar */}

      <SidebarWrapper />

      {/* Componente do Main */}
      <RestrictedMain layoutType={layoutType}>{children}</RestrictedMain>
    </Wrapper>
  )
}

Restricted.propTypes = {
  children: PropTypes.array,
  layoutType: PropTypes.string
}

export default Restricted
