/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import * as Yup from 'yup'
import { Form } from 'components/Form'
import { InputComponent } from 'components/InputComponent'
import { Button } from 'components/Button'
import { Button as Button_ } from '@plick-solucoes-contabeis/ui-components'
import useFetch from 'hooks/useFetch'
import { ForgotFormError, Title } from '../../style'
import history from 'services/history'

export function SendEmailForm() {
  const [haveError, setHaveError] = useState(false)

  const initialValues = {
    email: sessionStorage.getItem('loginEmail') || ''
  }

  const { fetchData } = useFetch({
    domain: 'usuarios',
    endpoint: 'sendForgotPasswordEmail',
    sync: false
  })

  const handleSubmit = async (values) => {
    const response = await fetchData([values])
    if (response?.data.message) {
      window.eventBus.dispatch('event-modal-success', {
        title: response.config.endpointInfo.description,
        message: response.data.message
      })
    }
  }

  const schemaValidate = Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Campo obrigatório.')
  })

  return (
    <>
      <Title>Digite Seu Email</Title>
      <Form
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        schemaValidate={schemaValidate}
        reset={false}
      >
        <InputComponent
          label="E-mail"
          type="text"
          name="email"
          onBlur={(event) =>
            sessionStorage.setItem('loginEmail', event.target.value)
          }
          placeholder="Escreva seu e-mail"
        />
        <Button type="submit" value="Enviar" />

        <Button_
          type="button"
          title="Voltar"
          onClick={() => history.push('/')}
        />
      </Form>

      {haveError ? (
        <ForgotFormError>Credenciais Invalidas</ForgotFormError>
      ) : (
        ''
      )}
    </>
  )
}
