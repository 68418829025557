import React from 'react'
import PropTypes from 'prop-types'
import api from 'services/api'

import { Button } from 'components/Button'
import { Tooltip } from '@plick-solucoes-contabeis/ui-components'

const deleteReq = async (apiFetcher, id) => {
  return api[`delete${apiFetcher}`](id)
    .then((response) => response)
    .catch((error) => console.error(error))
}

function DeleteButton({
  setModalVisible,
  apiFetcher,
  apiID,
  setCrudTimeStamp
}) {
  return (
    <Tooltip title="Excluir" delay={900}>
      <Button
        buttonFunction={'ButtonTable'}
        buttonIcon={'IconeExcluir'}
        colorText={'var(--background-medium-blue)'}
        onClick={() => {
          deleteReq(apiFetcher, apiID)
            .then((data) => {
              if (data?.status === 204) {
                setCrudTimeStamp(Date.now().toString())
              }
            })
            .catch((error) => console.error('Erro com a api', error))
            .finally(() => {
              setModalVisible(false)
            })
        }}
      />
    </Tooltip>
  )
}

DeleteButton.propTypes = {
  apiID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setModalVisible: PropTypes.func,
  apiFetcher: PropTypes.string,
  setCrudTimeStamp: PropTypes.func
}
export default DeleteButton
