/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Form } from 'components/Form'
import PropTypes from 'prop-types'
import { CabecalhoFormFields } from './CabecalhoFormFields'

import * as logic from '../cabecalhoContainerLogic'

import * as Yup from 'yup'

import {
  Button
} from '../../../subPages/style'

import { FormContainer } from './style'

export function CabecalhoForm ({
  cabecalhoFormInitialValues,
  setHeaders
}) {
  const handleSubmitCabecalho = async (values) => {
    if (values.update) {
      await logic.editOutputHeader(values, setHeaders)
    } else {
      await logic.createOutputHeader(values, setHeaders)
    }
  }

  const schemaValidateCabecalho = Yup.object().shape({
    value: Yup.string().required('Nome não pode ser vazio'),
    char_start_position: Yup.number().optional().min(0, 'Deve ser maior ou igual a zero').integer('Deve ser numérico'),
    char_end_position: Yup.number().optional().min(0, 'Deve ser maior ou igual a zero').integer('Deve ser numérico')
  })

  return (
    <>
        <FormContainer>
          <header>
            <h3>
              {cabecalhoFormInitialValues.update
                ? `Editar Cabeçalho ${['', ...Array(cabecalhoFormInitialValues.column)].reduce((previous) => logic.incrementChar(previous))}-1`
                : `Cadastrar novo Cabeçalho ${['', ...Array(cabecalhoFormInitialValues.column)].reduce((previous) => logic.incrementChar(previous))}-1`
              }
            </h3>
          </header>
          <Form
            handleSubmit={handleSubmitCabecalho}
            initialValues={cabecalhoFormInitialValues}
            schemaValidate={schemaValidateCabecalho}
            reset={!cabecalhoFormInitialValues.update}
          >
            <CabecalhoFormFields />

            <Button type='submit' >
              {!cabecalhoFormInitialValues.update ? 'Criar' : 'Editar'}
            </Button>
          </Form>
        </FormContainer>
      </>
  )
}

CabecalhoForm.propTypes = {
  cabecalhoFormInitialValues: PropTypes.object,
  setHeaders: PropTypes.func
}
