import React from 'react'
import * as Styled from './../style'
import PropTypes from 'prop-types'

const TableHeader = ({ columns }) => {
  const headerTitles = columns.map((column, index) => {
    return (
      <Styled.HeaderWord key={index}>
        {typeof column === 'string' ? column : column.title}
      </Styled.HeaderWord>
    )
  })

  return <Styled.TableHeaderRow>{headerTitles}</Styled.TableHeaderRow>
}

TableHeader.propTypes = {
  columns: PropTypes.array
}

export default TableHeader
