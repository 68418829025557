import { useState, useReducer, useEffect } from 'react'
import useNonInitialEffect from 'hooks/useNonInitialEffect'
import { useErrorEvent } from './Error/useErrorEvent'
import { useFeedbackEvent } from './Feedback/useFeedbackEvent'
import { useConfirmationEvent } from './Confirmation/useConfirmationEvent'
import { useFormEvent } from './Form/useFormEvent'
import * as EventsReducers from './EventsReducer'
import EventModal from 'components/EventModal'

export function useEventsModal() {
  const [eventState, dispatchEventState] = useReducer(
    EventsReducers.reducer,
    EventsReducers.states
  )

  const [errorEventActivator, setErrorEventActivator] = useState(false)
  const [feedbackEventActivator, setFeedbackEventActivator] = useState(false)
  const [confirmationEventActivator, setConfirmationEventActivator] =
    useState(false)
  const [formEventActivator, setFormEventActivator] = useState(false)

  useEffect(() => {
    // dispatchEventState({ type: 'none' })

    window.eventBus.on('renderErrors', async (data) => {
      dispatchEventState({ type: 'errorEvent', payload: data })
    })

    window.eventBus.on('renderFeedback', async (data) => {
      dispatchEventState({ type: 'feedbackEvent', payload: data })
    })

    window.eventBus.on('renderConfirmation', async (data) => {
      dispatchEventState({ type: 'confirmationEvent', payload: data })
    })

    window.eventBus.on('renderForm', async (data) => {
      dispatchEventState({ type: 'formEvent', payload: data })
    })

    return () => {
      window.eventBus.remove('renderErrors')
      window.eventBus.remove('renderFeedback')
      window.eventBus.remove('renderConfirmation')
      window.eventBus.remove('renderForm')
    }
  }, [])

  const doError = useErrorEvent(eventState.errorPayload)
  const doFeedback = useFeedbackEvent(eventState.feedbackPayload)
  const doConfirmation = useConfirmationEvent(eventState.confirmationPayload)
  const doForm = useFormEvent(eventState.formPayload)

  useNonInitialEffect(() => doError, [errorEventActivator])

  useNonInitialEffect(() => doFeedback, [feedbackEventActivator])

  useNonInitialEffect(() => doConfirmation, [confirmationEventActivator])

  useNonInitialEffect(() => doForm, [formEventActivator])

  useNonInitialEffect(() => {
    switch (eventState.eventType) {
      case 'errorEvent':
        setErrorEventActivator(!errorEventActivator)
        break
      case 'feedbackEvent':
        setFeedbackEventActivator(!feedbackEventActivator)
        break
      case 'confirmationEvent':
        setConfirmationEventActivator(!confirmationEventActivator)
        break
      case 'formEvent':
        setFormEventActivator(!formEventActivator)
        break
    }
  }, [eventState.activatorToggle])

  return {
    EventModal
  }
}
