const actionsList = [
  'custom',
  'getAll',
  'getOne',
  'getBy',
  'getPaginated',
  'getReports',
  'add',
  'update',
  'delete',
  'upload',
  'download',
  'process',
  'duplicate',
  ''
]

export default actionsList
