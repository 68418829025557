import { useEffect, useRef } from 'react'

// Avoid being run during initialRender stage,
// only runs when dependencies changes
export default function useNonInitialEffect(effect, dependencies = []) {
  const initialRender = useRef(true)

  useEffect(() => {
    let effectReturns = () => {}

    if (initialRender.current) {
      initialRender.current = false
    } else {
      effectReturns = effect()
    }

    if (effectReturns && typeof effectReturns === 'function') {
      return effectReturns
    }
  }, dependencies)
}
